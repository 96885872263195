import React, { useState } from 'react';
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { colors } from 'utils/colorsSteps';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import { useUserTypes } from 'hooks/useUserTypes';
import PmService from 'services/PM/pmService';

const useStyles = makeStyles(theme => ({
  titleConfidential: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  titleRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  containerTitleBtns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerConfidential: {
    padding: theme.spacing(0, 1.5),
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
  },
  containerGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerGridItem: {
    display: 'flex',
    justifyContent: 'start',
  },
  result: {
    color: colors.navBar,
    fontSize: 14,
    margin: theme.spacing(0.5, 0),
  },
  result2: {
    color: colors.navBar,
    fontSize: 14,
    fontWeight: 'bold',
    margin: theme.spacing(0.5, 0),
  },
  containerResult: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const BailInquiry = props => {
  const { inquiryId, write, otherStep, bail, refreshPage, canEdit = false } = props;

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const [editValues, setEditValues] = useState(false);

  const [formState, setFormState] = useState({
    bail: !bail ? false : true,
  });

  const handleChangeConfidential = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }));
  };

  const handleEditValuesToTrue = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    setEditValues(true);
  };

  const handleEditValuesToFalse = () => {
    setFormState(formState => ({
      ...formState,
      bail: !bail ? false : true,
    }));
    setEditValues(false);
  };

  const changeValuesRequest = () => {
    let params = {
      bail: formState.bail,
    };

    if (isPM) {
      PmService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        setEditValues(false);
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        setEditValues(false);
        refreshPage();
      });
    }
  };

  return (
    <div className={classes.containerConfidential}>
      <div className={classes.containerTitleBtns}>
        <Typography className={classes.titleConfidential}>Fiança</Typography>
        <div className={classes.titleRight}>
          {!editValues && ((!otherStep && !isPM) || canEdit) ? (
            <Tooltip title="Alterar status da fiança" arrow placement="top">
              <IconButton
                className={classes.buttonEditar}
                size="small"
                onClick={handleEditValuesToTrue}
              >
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            editValues && (
              <>
                <Box style={{ marginRight: 10 }}>
                  <Tooltip title="Salvar alterações" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={changeValuesRequest}
                    >
                      <SaveIcon style={{ color: '#1976d2' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="Cancelar" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleEditValuesToFalse}
                    >
                      <ClearIcon style={{ color: '#e1296a' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )
          )}
        </div>
      </div>
      <Grid container spacing={2} className={classes.containerGrid}>
        <Grid item xs={12} className={classes.containerGridItem}>
          {editValues ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.bail}
                  value={formState.bail}
                  onChange={handleChangeConfidential}
                  name="bail"
                  color="primary"
                />
              }
              label="Houve fiança"
            />
          ) : (
            <div className={classes.containerResult}>
              <Typography variant="body1" className={classes.result2}>
                {bail ? 'Houve fiança' : 'Não houve fiança'}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default BailInquiry;

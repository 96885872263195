import React from 'react';
import { Router } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FilterInquiryProvider } from './hooks/useFilterInquiry';
import history from 'utils/history';
import 'react-toastify/dist/ReactToastify.css';

import { ToastConfig } from 'components';

import theme from './theme';
//import { configureStore } from './store';
import { ScrollReset, GoogleAnalytics, CookiesNotification } from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/index.scss';

import 'moment-timezone';

import 'moment/locale/pt-br';

import { store, persistor } from './store/configureStore';
import { PersistGate } from 'redux-persist/integration/react';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js';
import TermsOfUseGuard from 'components/TermsOfUseGuard/TermsOfUseGuard';

Chart.plugins.unregister(ChartDataLabels);

//const store = configureStore();

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils} locale="pt-br">
            <Router basename={process.env.PUBLIC_URL || ''} history={history}>
              <ScrollReset />
              <GoogleAnalytics />
              <CookiesNotification />
              <ToastConfig />
              <FilterInquiryProvider>
                <TermsOfUseGuard />
              </FilterInquiryProvider>
            </Router>
            {/* <FilterInquiryProvider>
              <RoutersApp />
            </FilterInquiryProvider> */}
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;

import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import inquiryService from 'services/inquiries/inquiryService';
import ButtonConsensus from 'components/ButtonConsensus';
import colors from 'utils/colorsSteps';
import ModalConfirm from 'components/ModalConfirm';
import { Typography, Switch, Grid, Box, FormControlLabel } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import history from 'utils/history';
import { toast } from 'react-toastify';

const ButtonDesarquivarOuReabrir = props => {
  const { inquiry, step, write, reopenIp } = props;

  const initialValues = {
    applyToAll: true,
  };

  const [formState, setFormState] = useState({ ...initialValues });

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [loadingDesarquivar, setLoadingDesarquivar] = useState(false);

  const actionType = useMemo(() => (step === 'ARQUIVAR' ? 'desarquivar' : 'reabrir'), [step]);

  const unarchiveOrReopen = () => {
    setLoadingDesarquivar(true);
    const action = step === 'ARQUIVAR' ? 'desarquivado' : 'reaberto';
    if (!reopenIp) {
      inquiryService
        .unarchiveOrReopenInquiry(inquiry.id, { applyToAll: formState.applyToAll })
        .then(() => {
          toast.success(`Inquérito ${action} com sucesso!`);
          setOpenModalConfirm(false);
          history.goBack();
        })
        .finally(() => setLoadingDesarquivar(false));
    } else {
      inquiryService
        .reopenImportedFromArquimedes(inquiry.id)
        .then(() => {
          toast.success(`Inquérito ${action} com sucesso!`);
          setOpenModalConfirm(false);
          history.goBack();
        })
        .finally(() => setLoadingDesarquivar(false));
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }));
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <>
      <ButtonConsensus
        title={reopenIp ? 'REABRIR IP' : actionType.toUpperCase()}
        backgroundColor={colors.navBar}
        hovercolor={colors.navBar}
        onClick={() => {
          !write ? handleOpenSnackbar() : setOpenModalConfirm(true);
        }}
        disabled={loadingDesarquivar}
      />

      {openModalConfirm && (
        <ModalConfirm
          title="Confirmação"
          open={openModalConfirm}
          handleClose={() => setOpenModalConfirm(false)}
          onClick={unarchiveOrReopen}
          disabledConfirm={loadingDesarquivar}
          noCloseOnConfirm={true}
        >
          <Box mb={2}>
            <Typography variant="h5">Deseja {actionType} o inquérito?</Typography>
          </Box>
          {!reopenIp && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" justifyContent="flex-end">
                <Alert severity="warning" variant="outlined">
                  <Box display="flex" flexDirection="column">
                    Deseja aplicar aos demais investigados deste inquérito?
                    <Box display="flex" justifyContent="flex-end">
                      <FormControlLabel
                        style={{ marginRight: 0 }}
                        control={
                          <Switch
                            checked={formState.applyToAll}
                            onChange={handleChange}
                            name="applyToAll"
                            color="primary"
                            size="small"
                          />
                        }
                        label={formState.applyToAll ? 'Sim' : 'Não'}
                      />
                    </Box>
                  </Box>
                </Alert>
              </Box>
            </Grid>
          )}
        </ModalConfirm>
      )}
    </>
  );
};

ButtonDesarquivarOuReabrir.propTypes = {
  inquiry: PropTypes.object,
};

ButtonDesarquivarOuReabrir.defaultProps = {
  inquiry: {},
};

export default ButtonDesarquivarOuReabrir;

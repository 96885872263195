import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, Grid, Typography, IconButton, Tooltip } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import axios from '../../../../utils/axios';
import { useSelector } from 'react-redux';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ModalConfirm, HeaderModal } from 'components';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#00597b',
    height: 55,
    color: '#ffffff',
  },
  textTitle: {
    color: '#fff',
  },
  /*containerModal: {
        minHeight: 450,
        maxHeight: 550,
        width: '100%',
        overflow: 'auto',
    },
    body: {
        padding: theme.spacing(0, 2, 0, 2)
    },*/
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
  },
  dadosCriminais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 18,
  },
  containerDadosPessoais: {
    backgroundColor: '#ededed',
    marginTop: 5,
    padding: 10,
    borderRadius: 25,
  },
  labelDadoaPessoais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
  },
  labelEmpty: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
    padding: 10,
  },
  labelCrime: {
    fontWeight: 'bold',
    color: '#000',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8cf5f',
    width: '60%',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  textArea: {
    width: '100%',
    padding: 5,
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
  },
  checkboxInativo: {
    cursor: 'pointer',
    color: '#000',
  },
  containerBtnRetry: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const ModalSocialEconomicData = props => {
  const { open, onClose, inquiry, refreshPage, write, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const permissionsReducer = useSelector(state => state.logedUser.permissions);

  const [phones, setPhones] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  useEffect(() => {
    //console.log(inquiry);
    if (inquiry.investigated.personalData) {
      var array = { ...inquiry.investigated.personalData.phones };

      var arrayPhones = [];

      Array.prototype.push.apply(arrayPhones, array.cellphone);
      Array.prototype.push.apply(arrayPhones, array.commercial);
      Array.prototype.push.apply(arrayPhones, array.landline);
      Array.prototype.push.apply(arrayPhones, array.others);

      setPhones(arrayPhones);
    }
  }, [inquiry]);

  const handleCloseModalConfirm = () => {
    setConfirm(false);
    setShowModalConfirm(false);
  };

  const handleOpenModalConfirm = () => {
    setShowModalConfirm(true);
    setConfirm(true);
  };

  const handlePersonalRetry = () => {
    personalRetry(inquiry.id);
  };

  const personalRetry = async inquiryInvestigatedId => {
    await axios
      .post(`inquiries/investigated/${inquiryInvestigatedId}/personal-data/retry`)
      .then(() => {
        //console.log(response);
        toast.success('Solicitação realizada com sucesso!');
        refreshPage();
      });
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <div {...rest}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        scroll="paper"
        maxWidth="md"
        fullWidth
      >
        <HeaderModal onClose={onClose}>Dados Socioeconômicos</HeaderModal>
        <DialogContent>
          <div className={classes.containerBtnRetry}>
            <Typography className={classes.label}>Detalhes</Typography>
            {inquiry.personalDataSearchDone ? (
              <CheckCircleIcon style={{ color: '#00b1ac' }} />
            ) : write ? (
              <Tooltip title="Clique para atualizar os dados pessoais" arrow placement="left">
                <IconButton
                  onClick={() =>
                    !permissionsReducer.inquiriesDistribuicao.write
                      ? handleOpenSnackbar()
                      : handleOpenModalConfirm()
                  }
                >
                  <CheckCircleIcon className={classes.checkboxInativo} />
                </IconButton>
              </Tooltip>
            ) : (
              <></>
            )}
          </div>
          <div className={classes.body}>
            <Grid container spacing={1}>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Typography className={classes.label}>Nome</Typography>
                {inquiry.investigated.personalData?.name !== null &&
                inquiry.investigated.personalData?.name !== undefined ? (
                  <Typography className={classes.labelDadoaPessoais}>
                    {inquiry.investigated.personalData?.name}
                  </Typography>
                ) : (
                  <Typography className={classes.labelDadoaPessoais}>Não informado</Typography>
                )}
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className={classes.label}>Apelido</Typography>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {inquiry.investigated.personalData?.alternativeNameSpellings !== null &&
                  inquiry.investigated.personalData?.alternativeNameSpellings !== undefined &&
                  inquiry.investigated.personalData?.alternativeNameSpellings.length > 0 ? (
                    inquiry.investigated.personalData.alternativeNameSpellings.map(item => (
                      <Typography key={item.name} className={classes.labelDadoaPessoais}>
                        {item.name}
                      </Typography>
                    ))
                  ) : (
                    <Typography className={classes.labelDadoaPessoais}>Não informado</Typography>
                  )}
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <Typography className={classes.label}>Nascimento</Typography>
                {inquiry.investigated.personalData?.birthDate !== undefined &&
                inquiry.investigated.personalData?.birthDate !== null ? (
                  <Typography className={classes.labelDadoaPessoais}>
                    {inquiry.investigated.personalData?.birthDate}
                  </Typography>
                ) : (
                  <Typography className={classes.labelDadoaPessoais}>Não informada</Typography>
                )}
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '50%' }}>
                    <Typography className={classes.label}>Idade</Typography>
                    {inquiry.investigated.personalData?.age !== null &&
                    inquiry.investigated.personalData?.age !== undefined ? (
                      <Typography className={classes.labelDadoaPessoais}>
                        {inquiry.investigated.personalData?.age}
                      </Typography>
                    ) : (
                      <Typography className={classes.labelDadoaPessoais}>Não informado</Typography>
                    )}
                  </div>
                  <div style={{ width: '50%' }}>
                    <Typography className={classes.label}>UF</Typography>
                    {inquiry.investigated.personalData?.uf !== null &&
                    inquiry.investigated.personalData?.uf !== undefined ? (
                      <Typography className={classes.labelDadoaPessoais}>
                        {inquiry.investigated.personalData?.uf}
                      </Typography>
                    ) : (
                      <Typography className={classes.labelDadoaPessoais}>Não informado</Typography>
                    )}
                  </div>
                </div>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography className={classes.label}>Endereço</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Endereço</TableCell>
                        <TableCell align="center">N°</TableCell>
                        <TableCell align="center">Apartamento</TableCell>
                        <TableCell align="center">Bloco</TableCell>
                        <TableCell align="center">Complemento</TableCell>
                        <TableCell align="center">Bairro</TableCell>
                        <TableCell align="center">Cidade</TableCell>
                        {/*<TableCell align="center">Court</TableCell>
                                                                <TableCell align="center">House</TableCell>*/}
                        <TableCell align="center">CEP</TableCell>
                        <TableCell align="center">UF</TableCell>
                      </TableRow>
                    </TableHead>
                    {inquiry.investigated.personalData?.addresses !== null &&
                    inquiry.investigated.personalData?.addresses !== undefined &&
                    inquiry.investigated.personalData?.addresses.length > 0 ? (
                      inquiry.investigated.personalData?.addresses.map((v, i) => {
                        return (
                          <TableBody key={i}>
                            <TableRow>
                              <TableCell component="th" scope="row" align="center">
                                {v.publicPlace}
                              </TableCell>
                              <TableCell align="center">{v.address}</TableCell>
                              <TableCell align="center">{v.number}</TableCell>
                              <TableCell align="center">{v.apartment}</TableCell>
                              <TableCell align="center">{v.block}</TableCell>
                              <TableCell align="center">{v.complement}</TableCell>
                              <TableCell align="center">{v.neighborhood}</TableCell>
                              <TableCell align="center">{v.city}</TableCell>
                              {/*<TableCell align="center">{v.court}</TableCell>
                                                                <TableCell align="center">{v.house}</TableCell>*/}
                              <TableCell align="center">{v.zipCode}</TableCell>
                              <TableCell align="center">{v.uf}</TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })
                    ) : (
                      <Typography className={classes.labelEmpty}>Não informado</Typography>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Typography className={classes.label}>E-mail</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Email</TableCell>
                      </TableRow>
                    </TableHead>

                    {inquiry.investigated.personalData?.emails !== null &&
                    inquiry.investigated.personalData?.emails !== undefined &&
                    inquiry.investigated.personalData?.emails.length > 0 ? (
                      inquiry.investigated.personalData?.emails.map((v, i) => {
                        return (
                          <TableBody key={i}>
                            <TableRow>
                              <TableCell component="th" scope="row" align="center">
                                {v.email}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })
                    ) : (
                      <Typography className={classes.labelEmpty}>Não informado</Typography>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                <Typography className={classes.label}>Telefone</Typography>

                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Telefone</TableCell>
                      </TableRow>
                    </TableHead>
                    {phones !== null && phones !== undefined && phones.length > 0 ? (
                      phones.map((v, i) => {
                        return (
                          <TableBody key={i}>
                            <TableRow>
                              <TableCell component="th" scope="row" align="center">
                                ({v.ddd}) {v.phone}
                                {','} {v.operator}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })
                    ) : (
                      <Typography className={classes.labelEmpty}>Não informado</Typography>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ padding: 10 }}>
                <Typography className={classes.label}>Membros da Família</Typography>
                <TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Nome</TableCell>
                        <TableCell align="center">Parentesco</TableCell>
                      </TableRow>
                    </TableHead>
                    {inquiry.investigated.personalData?.familyMembers !== null &&
                    inquiry.investigated.personalData?.familyMembers !== undefined ? (
                      inquiry.investigated.personalData?.familyMembers.map((member, i) => (
                        <TableBody key={i}>
                          <TableRow>
                            <TableCell component="th" scope="row" align="center">
                              {member.name}
                            </TableCell>
                            <TableCell align="center">{member.type}</TableCell>
                          </TableRow>
                        </TableBody>
                      ))
                    ) : (
                      <Typography className={classes.labelEmpty}>Não informado</Typography>
                    )}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
      {showModalConfirm && (
        <ModalConfirm
          title="Confirmação"
          open={confirm}
          handleClose={handleCloseModalConfirm}
          onClick={handlePersonalRetry}
        >
          <Typography variant="h5">Deseja realmente fazer esta solicitação?</Typography>
        </ModalConfirm>
      )}
    </div>
  );
};

export default ModalSocialEconomicData;

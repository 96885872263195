import * as actionTypes from 'actions';

const initialState = {
  id: '',
  name: '',
  codeArquimedes: '',
  email: '',
  registration: null,
  createdAt: '',
  updatedAt: '',
  alternativeEmails: '',
};

const organOriginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ORGAN_ORIGIN:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        codeArquimedes: action.payload.codeArquimedes,
        email: action.payload.email,
        registration: action.payload.registration,
        alternativeEmails: action.payload.alternativeEmails,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
      };

    case actionTypes.SET_NAME_ORGAN_ORIGIN:
      return {
        ...state,
        name: action.payload,
      };

    case actionTypes.SET_CITY_ORGAN_ORIGIN:
      return {
        ...state,
        city: action.payload,
      };

    case actionTypes.SET_UF_ORGAN_ORIGIN:
      return {
        ...state,
        uf: action.payload,
      };

    case actionTypes.SET_CODE_ARQUIMEDES_ORGAN_ORIGIN:
      return {
        ...state,
        codeArquimedes: action.payload,
      };
    case actionTypes.CLEAR_ORGAN_ORIGIN:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default organOriginReducer;

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, CircularProgress, Typography, Box } from '@material-ui/core';
import { ForwardToInstitutionRadioGroup, InputConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';
import associationService from 'services/association/associationService';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const PenaltiesInstitution = props => {
  const {
    institutionType,
    setInstitutionType,
    institutionSelected,
    setInstitutionSelected,
    institutionTypeOptions,
    disabledInstitutionType,
    enableResponsibleSector = false,
  } = props;

  const { isMPDFT } = useCustomerConfig();

  const [listInstitutions, setListInstitutions] = useState([]);
  const [loadingInstitutions, setLoadingInstitutions] = useState(false);
  const [inputSearchInstitutions, setInputSearchInstitutions] = useState('');
  const [pageInstitutions, setPageInstitutions] = useState(1);
  const [lastPageInstitutions, setLastPageInstitutions] = useState(1);

  const institutionTitle = useMemo(
    () =>
      institutionType === institutionTypeOptions.encaminhadora ? 'Encaminhadora' : 'Fiscalizadora',
    [institutionType, institutionTypeOptions.encaminhadora],
  );

  const handleChangeInstitutionType = event => {
    setInstitutionType(event.target.value);
  };

  const updateValueInstitution = newValue => {
    if (!listInstitutions.find(inst => inst.name === newValue)) {
      debouncedGetInstitutions(newValue);
      setInputSearchInstitutions(newValue);
    }
  };

  const handleScrollInstitutions = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageInstitutions < lastPageInstitutions &&
      !loadingInstitutions
    ) {
      loadMoreInstitutionsResults();
    }
  };

  const handleChangeInstitution = (_event, newValue) => {
    setInstitutionSelected(newValue);
  };

  useEffect(() => {
    debouncedGetInstitutions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetInstitutions = useCallback(
    debounce(newValue => getInstitutions(newValue), 500),
    [],
  );

  const loadMoreInstitutionsResults = () => {
    const nextPage = pageInstitutions + 1;

    setPageInstitutions(nextPage);

    setLoadingInstitutions(true);

    const params = {
      page: nextPage,
      size: 20,
      name: inputSearchInstitutions,
      forward: true,
    };

    associationService
      .getAssociationsService(params)
      .then(response => {
        setListInstitutions([...listInstitutions, ...response.data.data]);
        setLastPageInstitutions(response.data.lastPage);
      })
      .finally(() => setLoadingInstitutions(false));
  };

  const getInstitutions = (input = '') => {
    setLoadingInstitutions(true);

    setPageInstitutions(1);

    const params = {
      page: 1,
      size: 20,
      name: input,
      forward: true,
    };

    associationService
      .getAssociationsService(params)
      .then(response => {
        setListInstitutions(response.data.data);
        setLastPageInstitutions(response.data.lastPage);
      })
      .finally(() => setLoadingInstitutions(false));
  };

  return (
    <>
      {setInstitutionType && (
        <ForwardToInstitutionRadioGroup
          value={institutionType}
          onChange={handleChangeInstitutionType}
          institutionTypeOptions={institutionTypeOptions}
          disabledInstitutionType={disabledInstitutionType}
        />
      )}
      {institutionType in institutionTypeOptions && (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box mb={1}>
            <Typography variant="h5" align="left">
              {enableResponsibleSector || isMPDFT
                ? 'Selecione o SEMA responsável'
                : `Selecione a instituição ${institutionTitle}`}
            </Typography>
          </Box>
          <Autocomplete
            disabled={disabledInstitutionType || enableResponsibleSector}
            value={institutionSelected}
            onInputChange={(_, newInputValue) => updateValueInstitution(newInputValue)}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            fullWidth
            noOptionsText="Nada foi encontrado"
            loadingText="Buscando..."
            loading={loadingInstitutions}
            clearOnBlur={false}
            options={listInstitutions}
            onChange={handleChangeInstitution}
            renderOption={option => <>{option.name}</>}
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Instituição "
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingInstitutions ? <CircularProgress color="primary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: handleScrollInstitutions,
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default PenaltiesInstitution;

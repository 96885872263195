import React, { forwardRef } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const CustomInput = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '2px solid #000',
    },
    '& .MuiInput-input': {
      color: '#000',
      fontSize: 14,
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& label.MuiFormLabel-root': {
      color: '#000',
      fontSize: 12,
    },
    '& .Mui-error': {
      fontSize: 10,
      color: 'red',
    },
  },
})(TextField);

const InputConsensus = forwardRef((props, ref) => {
  const { name, label, onChange, value, onClick, ...rest } = props;

  return (
    <CustomInput
      {...rest}
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      onClick={onClick}
      autoComplete="one-time-code"
      inputRef={ref}
    />
  );
});

export default InputConsensus;

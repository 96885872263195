import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
  },
}));

const ReportData = props => {
  const { total, page, totalData, totalPages } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body2">
        {`Exibindo ${
          totalPages === page ? total : totalData * page
        } de ${total} resultado(s) de acordos`}
      </Typography>
    </div>
  );
};

export default ReportData;

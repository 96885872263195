import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@material-ui/core';
import { HeaderModal } from 'components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ModalConfirm } from 'components';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import agreementService from 'services/agreement/agreementService';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    //minHeight: 200,
    marginTop: theme.spacing(2),
  },
  action: {
    padding: theme.spacing(0, 3, 3, 0),
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textTitle: {},
  btnAnexoFile: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
}));

export default function Form(props) {
  const { open, onClose, hearingId } = props;

  const classes = useStyles();

  const initialValues = {
    hearingDone: true,
    agreementDone: false,
    scheduleNewHearing: false,
    attachment: {
      name: '',
      base64: '',
    },
  };

  // eslint-disable-next-line no-unused-vars
  const [formState, setFormState] = useState({
    values: { ...initialValues },
  });

  const [showFiles, setShowFiles] = useState(true);
  const [uploadObject, setUploadObject] = useState([]);
  const [fileInBase64, setFileInBase64] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [params, setParams] = useState(null);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    setShowFiles(true);
  }, [showFiles]);

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: false,
    onDrop: files => filterFiles(files),
  });

  const filterFiles = files => {
    files.forEach(v => {
      var index = uploadObject.findIndex(upload => upload.name === v.name);
      if (index === -1) {
        setUploadObject(_file => [v]);
        getBase64(v);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    });
  };

  const getBase64 = file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      var result = reader.result;

      var index = fileInBase64.findIndex(upload => upload.name === file.name);

      if (index === -1) {
        setFileInBase64(_setFileInBase64 => [
          {
            name: file.name,
            base64: result,
          },
        ]);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    };
  };

  const handleRemoveFile = (fileANex, i) => {
    setShowFiles(false);
    uploadObject.splice(i, 1);
    const newList = uploadObject.filter(file => file.name !== fileANex.name);
    setUploadObject(newList);
    const newListReducer = uploadObject.filter(file => file.name !== fileANex.name);
    setFileInBase64(newListReducer);
  };

  const handleCancelAgreement = async params => {
    await agreementService.hearingCloseService(hearingId, params).then(() => {
      toast.success('Acordo cancelado com sucesso!');
    });
  };

  const handleSubmit = () => {
    let payload = {
      hearingDone: formState.values.hearingDone,
      agreementDone: formState.values.agreementDone,
      scheduleNewHearing: formState.values.scheduleNewHearing,
    };

    if (fileInBase64.length) {
      payload.attachment = fileInBase64[0];
    }

    setParams(payload);

    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
    setParams(null);
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" fullScreen={fullScreen} open={open} onClose={onClose}>
        <HeaderModal onClose={onClose}>Acordo não realizado</HeaderModal>
        <DialogContent>
          <form className={classes.form}>
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ minHeight: 150, padding: 20 }}
              >
                <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                  <input {...getInputProps()} />
                  <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ padding: 20, marginBottom: 15 }}
              >
                <aside>
                  <Typography variant="body1">Arquivos {uploadObject.length}</Typography>

                  {uploadObject.map((file, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {showFiles && (
                          <>
                            <Typography
                              style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}
                            >
                              <PictureAsPdfIcon style={{ marginRight: 5 }} />
                              {file !== null ? file.name : ''} {/*- {file.size} bytes*/}
                            </Typography>
                            <HighlightOffIcon
                              onClick={() => handleRemoveFile(file, i)}
                              style={{ color: '#e81224', cursor: 'pointer', marginLeft: 5 }}
                            />
                          </>
                        )}
                      </div>
                    );
                  })}
                </aside>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions className={classes.action}>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
      {/* <ModalAddFileGeneric
                open={openModalAddFile}
                onClose={() => setOpenModalAddFile(false)}
                fileInBase64={fileInBase64}
                setFileInBase64={setFileInBase64}
                uploadObject={uploadObject}
                setUploadObject={setUploadObject}
            /> */}
      <ModalConfirm
        title="Confirmação"
        open={openConfirm}
        handleClose={handleCloseConfirm}
        onClick={() => handleCancelAgreement(params)}
      >
        <Typography variant="h5">Você confirma o cancelamento do acordo?</Typography>
      </ModalConfirm>
    </div>
  );
}

import { getOnlyNumbers } from "./currency";

const parcelsHoursSum = parcels => {
  const hours = parcels.map(parcel => parseInt(parcel.hours));
  const total = hours.reduce((accumulator, value) => accumulator + value, 0);
  return total;
};

const parcelsAmountSum = parcels => {
  const amount = parcels.map(parcel => parseInt(parcel.amount));
  const total = amount.reduce((accumulator, value) => accumulator + value, 0);
  return total;
};

const orderParcelsByInitialDate = parcels => {
  return parcels.sort((a, b) => Date.parse(a.initialDate) - Date.parse(b.initialDate));
};

const parcelsSum = parcels => {
  const numbers = parcels.map(parcel => parseInt(getOnlyNumbers(parcel.value)));
  const total = numbers.reduce((accumulator, value) => accumulator + value, 0);
  return total / 100;
};

export { parcelsSum, parcelsHoursSum, parcelsAmountSum, orderParcelsByInitialDate };

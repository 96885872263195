import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Card, Typography, Box, Tooltip, FormControlLabel, Checkbox } from '@material-ui/core';
import Moment from 'react-moment';
import '../../font.css';
import { verifyMaskInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import ReportIcon from '@material-ui/icons/Report';
import HelpIcon from '@material-ui/icons/Help';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { isInfraction } from 'utils/isInfraction';

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(112,112,112,0.95)',
    color: 'rgba(112,112,112,0.95)',
  },
}))(Tooltip);

const CardPendingInquiry = props => {
  const {
    className,
    backgroundColor,
    inquiry,
    isSearch,
    isBatchReturn,
    batchReturnCheckboxValue,
    handleChangeBatchReturnCheckbox,
    checkboxNumber,
    ...rest
  } = props;

  const { allOriginNumbersAreNpu } = useCustomerConfig();

  const useStyles = makeStyles(theme => ({
    root: {
      boxSizing: 'border-box',
      boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 3px 3px 0 rgb(163 163 163)',
      height: '100%',
      width: '100%',
      cursor: 'pointer',
    },
    shadow: {
      width: '100%',
      height: '100%',
      padding: theme.spacing(1, 1, 1, 2),
      boxShadow: `7px 0px 0px 0px ${backgroundColor} inset`,
    },
    titleCard: {
      fontSize: 14,
      textAlign: 'center',
      marginBottom: theme.spacing(1),
    },
    numeroInterno: {
      color: '#000',
      fontSize: 12,
    },
    containerCard: {
      height: '100%',
      width: '100%',
      padding: theme.spacing(1, 1, 1, 2),
      boxShadow: `7px 0px 0px 0px ${backgroundColor} inset`,
    },
    prosecutorTooltip: {
      color: '#FFF',
    },
  }));

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Box className={classes.containerCard}>
        {isBatchReturn && (
          <Checkbox
            checked={batchReturnCheckboxValue}
            onClick={e => {
              e.stopPropagation();
              handleChangeBatchReturnCheckbox(e, checkboxNumber);
            }}
            name="letter"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            id="batch-distribution-checkbox"
          />
        )}
        <Typography variant="h6" className={classes.titleCard}>
          {verifyMaskInquiryNumber(
            inquiry.inquiryData?.policeInquiryNumber,
            inquiry.inquiryData?.classeDescription,
            allOriginNumbersAreNpu,
          )}
        </Typography>
        <Typography variant="h6" className={classes.numeroInterno} noWrap>
          Classe:{' '}
          {`${inquiry.inquiryData?.classe || 'código não informado'}, ${inquiry.inquiryData
            ?.classeDescription || 'classe não informada'}`}
        </Typography>
        <Typography variant="h6" className={classes.numeroInterno} noWrap>
          Grupo Ofício: {inquiry?.inquiry?.prosecutorOfficeGroup?.name || 'não informado'}
        </Typography>
        <Typography variant="h6" className={classes.numeroInterno}>
          Data do {isInfraction(inquiry?.inquiryData?.classe) ? 'ato infracional' : 'crime'}:{' '}
          <Moment format="DD/MM/YYYY" local>
            {inquiry.inquiryData?.openingDate || inquiry.inquiryData?.fact?.date}
          </Moment>
        </Typography>
        <Box display="flex" alignItems="center">
          {inquiry.inquiryData?.status && (
            <HtmlTooltip
              arrow
              title={
                <Typography variant="body2" className={classes.prosecutorTooltip}>
                  {inquiry.inquiryData.status}
                </Typography>
              }
              placement="top"
            >
              <HelpIcon fontSize="small" />
            </HtmlTooltip>
          )}
          {inquiry.inquiryData?.fact?.isFlagrant && (
            <HtmlTooltip
              arrow
              title={
                <Typography variant="body2" className={classes.prosecutorTooltip}>
                  Flagrante
                </Typography>
              }
              placement="top"
            >
              <ReportIcon fontSize="small" />
            </HtmlTooltip>
          )}
          {inquiry.returnations && inquiry.returnations.length > 0 && (
            <HtmlTooltip
              arrow
              title={
                <Typography variant="body2" className={classes.prosecutorTooltip}>
                  {inquiry.returnations[inquiry.returnations.length - 1].emailText}
                </Typography>
              }
              placement="top"
            >
              <HelpIcon fontSize="small" />
            </HtmlTooltip>
          )}
        </Box>
      </Box>
    </Card>
  );
};

export default CardPendingInquiry;

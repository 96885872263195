/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid, Button, IconButton, Paper } from '@material-ui/core';
import validate from 'validate.js';
import { useSelector } from 'react-redux';
import axios from '../../utils/axios';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';
import ModalHistoryIntimation from 'components/ModalHistoryIntimation';
import ModalConfirm from './components/ModalConfirm';
import Tooltip from '@material-ui/core/Tooltip';
import ModalAddFile from './components/ModalAddFile';
import ModalListFiles from './components/ModalListFiles';
import { toast } from 'react-toastify';
import intimationService from 'services/intimation/intimationService';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(theme => ({
  containerIntimation: {
    backgroundColor: '#337a95',
    borderRadius: 20,
    padding: theme.spacing(1, 0, 1, 0),
    width: '100%',
    //height: '100%',
    boxShadow: 'none',
  },
  titulo: {
    color: '#ededed',
    fontWeight: 'bold',
    textAlign: 'left',
    padding: theme.spacing(1, 0, 1, 2),
    fontSize: 20,
  },
  inputIntimation: {
    height: 35,
    width: '100%',
    marginTop: 10,
    backgroundColor: '#ededed',
    border: 0,
    padding: 5,
    borderRadius: 15,
    outline: 'none',
  },
  labelIntimation: {
    color: '#ffffff',
    fontSize: 14,
  },
  labelIntimationStatus: {
    color: '#ededed',
    fontSize: 14,
    fontWeight: 'bold',
    backgroundColor: '#00b1ac',
    borderRadius: 10,
    paddingLeft: 5,
    paddingRight: 5,
    display: 'flex',
    width: '100%',
  },
  labelIntimationContato: {
    color: '#ededed',
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 5,
  },
  containerTituloRight: {
    display: 'block',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnsIntimation: {
    height: 40,
    backgroundColor: '#ededed',
    borderRadius: 40,
    '&:hover': {
      backgroundColor: '#00b1ac',
      color: '#ededed',
      border: '1px solid #ededed',
    },
    '@media (max-width:600px)': {
      height: 45,
    },
  },
  containerLeft: {
    marginBottom: theme.spacing(1),
  },
  containerRight: {
    //padding: 10,
    //backgroundColor: '#ededed',
    //borderRadius: 25,
    /*'@media (max-width:768px)': {
            marginTop: 15,
            marginBottom: 0.5
        },
        '@media (max-width:600px)': {
            marginTop: 15,
            marginBottom: 0.5
        }*/
  },
  containerFather: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ededed',
  },
}));

const Intimation = props => {
  const { inquiryId, step = '' } = props;
  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const classes = useStyles();

  const permissionsReducer = useSelector(state => state.logedUser.permissions);
  const { write } = permissionsReducer.inquiriesIntimacao;

  const [isReult, setIsResult] = useState(false);
  const [enableIntimation, setDisabledIntimation] = useState(false);
  const [enableCancel, setDisabledCancel] = useState(false);
  const [enableHistory, setDisabledHistory] = useState(false);
  const [changeInquiry, setChangeInquiry] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [funcao, setFuncao] = useState('');
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [intimation, setIntimation] = useState([]);
  const [openModalAddFile, setOpenModalAddFile] = useState(false);
  const [openModalListFile, setOpenModalListFile] = useState(false);
  const [disabledAddFileIntimation, setDisabledAddFileIntimation] = useState(false);
  const [disabledInputs, setDisabledInputs] = useState(false);

  const initialState = {
    inquiryInvestigatedId: inquiryId,
    intimationDeadline: '',
    hearingDate: '',
    hourHearing: '',
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialState },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    getIntimations(inquiryId);
  }, []);

  useEffect(() => {
    verifyIntimation();
  }, [intimation]);

  useEffect(() => {
    if (changeInquiry) {
      getIntimations(inquiryId);
      setChangeInquiry(false);
    }
  }, [changeInquiry]);

  const verifyIntimation = () => {
    if (intimation.length > 0) {
      if (step === 'TRAMITACAO_CONTROLE') {
        setDisabledIntimation(true);
        setDisabledCancel(true);
        setDisabledHistory(false);
        setDisabledInputs(true);
      } else if (intimation[0].status === 'CANCELED') {
        setDisabledIntimation(false);
        setDisabledCancel(true);
        setDisabledHistory(false);
        setDisabledInputs(false);
      } else if (intimation[0].status === 'FAILED') {
        setDisabledIntimation(false);
        setDisabledCancel(true);
        setDisabledHistory(false);
        setDisabledInputs(false);
      } else if (intimation[0].status === 'AWAITING_RESPONSE') {
        setDisabledIntimation(true);
        setDisabledCancel(false);
        setDisabledHistory(false);
        setDisabledInputs(true);
      } else if (intimation[0].status === 'SUCCESS') {
        setDisabledIntimation(true);
        setDisabledCancel(true);
        setDisabledHistory(false);
        setDisabledInputs(true);
      } else {
        setDisabledCancel(true);
        setDisabledHistory(true);
        setDisabledInputs(false);
      }

      intimation[0].attempts.forEach(({ status, mode }) => {
        //console.log({ status: status, mode: mode });
        if (status === 'AWAITING_RESPONSE' && mode === 'MANUAL') {
          setDisabledAddFileIntimation(false);
        } else {
          setDisabledAddFileIntimation(true);
        }
      });
    } else {
      setDisabledIntimation(false);
      setDisabledCancel(true);
      setDisabledHistory(true);
      setDisabledAddFileIntimation(true);
    }
  };

  const getIntimations = async inquiryInvestigatedId => {
    let params = {
      inquiryInvestigatedId,
    };

    await intimationService.getIntimations(params).then(async response => {
      //console.log(response.data.data);
      setIntimation(response.data.data);
      if (!isReult) {
        setIsResult(true);
      }
    });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    //console.log(formState.values);
  }, [formState.values]);

  const timezone = () => {
    let offset = -new Date().getTimezoneOffset() / 60;
    if (offset > 0) offset = '+' + offset;
    return `GMT${offset}`;
  };

  const functionData = (data, hora = 12, min = 59, typeDate) => {
    data = new Date(`${data} ${timezone()}`);
    if (typeDate === 'audiencia') {
      hora = hora - 3;
      data.setHours(hora, min, 0);
    } else {
      data.setHours(0, 0, 59);
    }
    //console.log({ data });

    //console.log(data.toISOString());
    return data.toISOString();
  };

  const handleSubmit = () => {
    //event.preventDefault();

    if (formState.values.intimationDeadline !== '' && formState.values.hearingDate !== '') {
      saveIntimation(
        formState.values.inquiryInvestigatedId,
        formState.values.intimationDeadline,
        formState.values.hearingDate,
      );

      //setChangeInquiry(true);

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          intimationDeadline: '',
          hearingDate: '',
          hourHearing: '',
        },
      }));
    } else {
      toast.warn(
        `Prencha a data da intimação e da audiência${
          enableHearingForJuvenileOffenders ? '/oitiva' : ''
        }`,
      );
    }
  };

  const handleCancelIntimation = () => {
    cancelIntimation(intimation[0].id);

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        intimationDeadline: '',
        hearingDate: '',
        hourHearing: '',
      },
    }));
    //setChangeInquiry(true);
  };

  const saveIntimation = async (inquiryInvestigatedId, intimationDeadline, hearingDate) => {
    if (
      intimationDeadline !== undefined &&
      intimationDeadline !== null &&
      intimationDeadline !== ''
    ) {
      intimationDeadline = functionData(intimationDeadline);
    }
    if (hearingDate !== undefined && hearingDate !== null && hearingDate !== '') {
      //console.log(hearingDate);
      let hora = formState.values.hourHearing.substring(0, 2);
      let min = formState.values.hourHearing.substring(3, 5);
      hearingDate = functionData(hearingDate, hora, min, 'audiencia');
    }

    let params = {
      inquiryInvestigatedId,
      intimationDeadline,
      hearingDate,
    };

    //console.log(params);
    await axios.post('intimations', params).then(() => {
      //console.log(response);
      //setChangeInquiry(true);
      toast.success('Intimação registrada com sucesso!');
      setChangeInquiry(true);
    });
  };

  const cancelIntimation = async intimationId => {
    await intimationService.cancelIntimation(intimationId).then(() => {
      //console.log(response);
      //setChangeInquiry(true);
      toast.success('Intimação cancelada com sucesso!');
      setChangeInquiry(true);
    });
  };

  const handleCloseModalHistoryIntimation = () => {
    setOpen(false);
  };

  const handleCloseModalAddFile = () => {
    setOpenModalAddFile(false);
  };

  const handleCloseModalListFile = () => {
    setOpenModalListFile(false);
  };

  const handleOpenModalHistoryIntimation = () => {
    setOpen(true);
  };

  const handleCloseModalConfirm = () => {
    setConfirm(false);
    setShowModalConfirm(false);
    setFuncao('');
  };

  const handleOpenModalConfirm = funcao => {
    setShowModalConfirm(true);
    setFuncao(funcao);
    setConfirm(true);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <Card className={classes.containerIntimation}>
      <Typography className={classes.titulo}>Intimação</Typography>
      <form
        style={{ padding: 10 }}
        //onSubmit={handleSubmit}
      >
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            item
            container
            spacing={2}
            className={classes.containerLeft}
            xs={12}
            sm={12}
            md={write ? 7 : 6}
            lg={write ? 7 : 6}
            xl={write ? 7 : 6}
          >
            {write && (
              <>
                <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
                  <label className={classes.labelIntimation}>Data da Intimação</label>
                  <input
                    className={classes.inputIntimation}
                    name="intimationDeadline"
                    type="date"
                    value={formState.values.intimationDeadline}
                    onChange={handleChange}
                    disabled={disabledInputs}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={4} lg={4} xl={4}>
                  <label className={classes.labelIntimation}>
                    Data da Audiência{enableHearingForJuvenileOffenders ? '/Oitiva' : ''}
                  </label>
                  <input
                    className={classes.inputIntimation}
                    type="date"
                    name="hearingDate"
                    value={formState.values.hearingDate}
                    onChange={handleChange}
                    disabled={disabledInputs}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={4} lg={4} xl={4}>
                  <label className={classes.labelIntimation}>Hora</label>
                  <input
                    className={classes.inputIntimation}
                    type="time"
                    name="hourHearing"
                    value={formState.values.hourHearing}
                    onChange={handleChange}
                    disabled={disabledInputs}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Button
                    //type="submit"
                    size="small"
                    disabled={enableIntimation}
                    fullWidth
                    className={classes.btnsIntimation}
                    onClick={() => handleOpenModalConfirm('INTIMAR')}
                    style={{ fontSize: 12 }}
                  >
                    Intimar
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <Button
                    size="small"
                    disabled={enableCancel}
                    fullWidth
                    className={classes.btnsIntimation}
                    onClick={() => handleOpenModalConfirm('CANCELAR_INTIMACAO')}
                    style={{ fontSize: 12 }}
                  >
                    Cancelar
                  </Button>
                </Grid>
              </>
            )}
            <Grid
              item
              xs={write ? 6 : 10}
              sm={write ? 6 : 10}
              md={write ? 4 : 10}
              lg={write ? 4 : 10}
              xl={write ? 4 : 10}
            >
              {!write && (
                <Typography variant="h4" style={{ color: '#ededed', marginBottom: 5 }}>
                  Você possui permissão para visualizar o histórico abaixo
                </Typography>
              )}
              <Button
                size="small"
                disabled={enableHistory}
                fullWidth
                className={classes.btnsIntimation}
                onClick={handleOpenModalHistoryIntimation}
                style={{ fontSize: 12 }}
              >
                Histórico
              </Button>
            </Grid>
          </Grid>
          {isReult && (
            <Grid
              style={{ maxWidth: '95%' }}
              component={Paper}
              className={classes.containerFather}
              container
              item
              xs={12}
              sm={12}
              md={write ? 5 : 6}
              lg={write ? 5 : 6}
              xl={write ? 5 : 6}
            >
              <Grid
                container
                item
                spacing={1}
                className={classes.containerRight}
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className={classes.containerTituloRight}>
                    <Typography className={classes.labelIntimationStatus}>
                      {intimation.length > 0
                        ? intimation[0].status === 'AWAITING_RESPONSE'
                          ? 'Aguardando resposta da intimação'
                          : intimation[0].status === 'CANCELED'
                          ? 'Intimação não executada'
                          : intimation[0].status === 'FAILED'
                          ? 'Intimação não executada'
                          : intimation[0].status === 'SUCCESS'
                          ? 'Intimação realizada com sucesso'
                          : 'Não intimado'
                        : 'Não intimado'}
                    </Typography>
                    <div className={classes.labelIntimationStatus} style={{ marginTop: 5 }}>
                      Meio de contato:
                      {intimation.length > 0
                        ? intimation[0].attempts.map(({ _status, mode }) => (
                            <Typography className={classes.labelIntimationContato}>
                              {mode}
                            </Typography>
                          ))
                        : ''}
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  {disabledAddFileIntimation ? (
                    <span>
                      <IconButton
                        onClick={() => setOpenModalAddFile(true)}
                        style={{ backgroundColor: '#d7d7d7' }}
                        disabled
                      >
                        <AttachFileIcon fontSize="large" style={{ color: '#ededed' }} />
                      </IconButton>
                    </span>
                  ) : (
                    <Tooltip title="Anexar intimação" arrow placement="right">
                      <IconButton
                        onClick={() =>
                          !permissionsReducer.inquiriesIntimacao.write
                            ? handleOpenSnackbar()
                            : setOpenModalAddFile(true)
                        }
                        style={{ backgroundColor: '#00b1ac' }}
                      >
                        <AttachFileIcon fontSize="large" style={{ color: '#ededed' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Tooltip title="Ver arquivo anexado" arrow placement="left">
                    <IconButton
                      onClick={() => setOpenModalListFile(true)}
                      style={{ backgroundColor: '#00b1ac' }}
                    >
                      <DescriptionIcon fontSize="large" style={{ color: '#ededed' }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
      {isReult && intimation.length > 0 && (
        <ModalHistoryIntimation
          open={open}
          intimations={intimation}
          onClose={handleCloseModalHistoryIntimation}
        />
      )}
      {showModalConfirm && (
        <ModalConfirm
          open={confirm}
          onClose={handleCloseModalConfirm}
          cancellIntimation={handleCancelIntimation}
          saveIntimation={handleSubmit}
          funcao={funcao}
        />
      )}
      {isReult && intimation.length > 0 && (
        <ModalAddFile
          open={openModalAddFile}
          onClose={handleCloseModalAddFile}
          intimation={intimation[0]}
        />
      )}
      {openModalListFile && (
        <ModalListFiles
          open={openModalListFile}
          onClose={handleCloseModalListFile}
          intimation={intimation[0]}
        />
      )}
    </Card>
  );
};

export default Intimation;

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  TextField,
  Checkbox,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { ButtonConsensus, ProsecutorOfficeAndProsecutorForm } from 'components';
import { colors } from 'utils/colorsSteps';
import { Input } from '@material-ui/core';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { useSelector } from 'react-redux';
import { USER_TYPE } from 'constants/inquirys/userType';
import { toast } from 'react-toastify';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(() => ({
  root: {},
}));

const ModalHearing = props => {
  const {
    open,
    onClose,
    intimationHearing,
    className,
    inquiry,
    isInPerson,
    loading,
    setLoading,
    ...rest
  } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const loggedUser = useSelector(state => state.logedUser);
  const isProsecutor = loggedUser.type === USER_TYPE.Prosecutor;

  const [formState, setFormState] = useState({
    hearingPlace: inquiry?.inquiry?.prosecutorOfficeGroup?.address || '',
    observation: '',
    publicDefender: true,
    prosecutor: isProsecutor
      ? { ...loggedUser, id: loggedUser.userId }
      : inquiry.inquiry.prosecutor,
    prosecutorOffice: inquiry?.inquiry?.prosecutorOffice || null,
  });

  useEffect(() => {
    if (!open) {
      setFormState({
        hearingPlace: inquiry?.inquiry?.prosecutorOfficeGroup?.address || '',
        observation: '',
        publicDefender: true,
        prosecutor: isProsecutor
          ? { ...loggedUser, id: loggedUser.userId }
          : inquiry.inquiry.prosecutor,
        prosecutorOffice: inquiry?.inquiry?.prosecutorOffice || null,
      });
    }
  }, [open]);

  const handleChangeProsecutor = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      prosecutor: newValue,
    }));
  };

  const handleChangeProsecutorOffice = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      prosecutorOffice: newValue,
    }));
  };

  const handleChangeCheckbox = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      publicDefender: newValue,
    }));
  };

  const handleChangeHearingPlace = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      hearingPlace: event.target.value,
    }));
  };

  const handleChangeObservation = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      observation: newValue,
    }));
  };

  const handleSubmit = () => {
    if (!formState.prosecutor) {
      toast.warn('Promotor obrigatório');
      return;
    }

    if (!formState.prosecutorOffice) {
      toast.warn('Promotoria obrigatória');
      return;
    }

    setLoading(true);
    intimationHearing(formState);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Confirmar Ação</DialogTitle>
        <DialogContent>
          <List>
            {isInPerson && (
              <>
                <ListItem>
                  <Typography variant="h5">Endereço:</Typography>
                </ListItem>
                <ListItem>
                  <Input
                    fullWidth
                    name="hearingPlace"
                    onChange={handleChangeHearingPlace}
                    value={formState.hearingPlace}
                    placeholder={`Digite o local da audiência${
                      enableHearingForJuvenileOffenders ? '/oitiva' : ''
                    }`}
                  />
                </ListItem>
              </>
            )}
            <ListItem>
              <Typography variant="h5">Observação:</Typography>
            </ListItem>
            <ListItem>
              <TextField
                fullWidth
                multiline
                minRows={2}
                maxRows={3}
                name="observation"
                onChange={handleChangeObservation}
                value={formState.observation}
                placeholder={`Obs...`}
              />
            </ListItem>
            <ListItem>
              <Typography variant="h5">Defensor Público:</Typography>
              <Checkbox
                checked={formState.publicDefender}
                value={formState.publicDefender}
                onChange={handleChangeCheckbox}
                name="publicDefender"
                color="primary"
              />
            </ListItem>
            <ListItem>
              <ProsecutorOfficeAndProsecutorForm
                prosecutor={formState.prosecutor}
                prosecutorOffice={formState.prosecutorOffice}
                onProsecutorChange={handleChangeProsecutor}
                onProsecutorOfficeChange={handleChangeProsecutorOffice}
                isProsecutor={isProsecutor}
                prosecutorOfficeGroup={inquiry?.inquiry?.prosecutorOfficeGroup}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <ButtonConsensus
            title="Cancelar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
          />
          <ButtonConsensus
            title="Confirmar"
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={handleSubmit}
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalHearing;

import React from 'react';
import clsx from 'clsx';
import { Dialog, Typography, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';

const useStyles = makeStyles(() => ({
  root: {},
}));

const ModalCancel = props => {
  const { open, onClose, className, cancellIntimation, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    onClose();
  };

  const handleCallbackIntimation = () => {
    cancellIntimation();
    handleClose();
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          <Typography variant="h4">Confirmação</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h5">Deseja realmente fazer esta solicitação?</Typography>
        </DialogContent>
        <DialogActions>
          <ButtonConsensus
            title="Cancelar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={handleClose}
          />
          <ButtonConsensus
            title="Confirmar"
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={handleCallbackIntimation}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalCancel;

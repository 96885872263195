import React, { Fragment, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Paper, Typography } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { Button, NavitagionCompany } from 'components';
import navigationConfig from './navigationConfig';
import { useUserTypes } from 'hooks/useUserTypes';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';
import { ModalVersion } from 'layouts/Dashboard/components';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    //paddingLeft: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    //marginTop: theme.spacing(2)
  },
  footerContainer: {
    position: 'flex',
    bottom: 0,
    width: '100%',
  },
  versionButton: {
    position: 'flex',
    bottom: 0,
    padding: theme.spacing(2),
  },
  versionText: {
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '21px',
    letterSpacing: '-0.05px',
  },
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;

  const { association } = useUserTypes();

  const [frontVersion, setFrontVersion] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const {
    enablePartnerAssociationRegistration,
    enableSemaAndCemaAssociationRegistration,
  } = useSelector(state => state.customerConfig);

  const validationNavigationConfig = useMemo(() => {
    const navigation = cloneDeep(navigationConfig);
    const isPartnerAssociation = !association.forward;
    const isMPPEAssociation = !enableSemaAndCemaAssociationRegistration;
    const isCoordinatingAssociation = association.coordination;

    if (isPartnerAssociation || isMPPEAssociation) {
      navigation.forEach(menu => {
        if (menu.title === 'Menu') {
          menu.pages = menu.pages.filter(page => page.title !== 'Cadastros');
        }
      });
    } else {
      if (!isCoordinatingAssociation) {
        const coordinationItemsToRemove = [
          'Cadastro de Serviços e Materiais',
          'Cadastro de Projetos',
          'Solicitação de Serviços e Materiais',
        ];
        navigation[0].pages.forEach(page => {
          if (page.children) {
            page.children = page.children.filter(
              item => !coordinationItemsToRemove.includes(item.title),
            );
          }
        });
      }
    }
    if (isPartnerAssociation) {
      navigation[0].pages[2].children.shift();
    }
    if (!enablePartnerAssociationRegistration) {
      const partnerRegistrationRemove = ['Cadastro de Instituições Parceiras'];
      navigation[0].pages.forEach(page => {
        if (page.children) {
          page.children = page.children.filter(
            item => !partnerRegistrationRemove.includes(item.title),
          );
        }
      });
    }

    return navigation;
  }, [
    association.forward,
    association.coordination,
    enableSemaAndCemaAssociationRegistration,
    enablePartnerAssociationRegistration,
  ]);

  const classes = useStyles();
  const router = useRouter();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const getFrontVersion = () => {
    try {
      const packageData = require('../../../../../package.json');
      return packageData.version;
    } catch (error) {
      toast.error('Erro ao buscar a versão do front');
      return null;
    }
  };

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }
    setFrontVersion(getFrontVersion());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {validationNavigationConfig.map(list => (
          <NavitagionCompany
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
      {/*<Label color={colors.blue['500']}>v1.2.0</Label>*/}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
            <div className={classes.footerContainer}>
              <Button className={classes.versionButton} onClick={handleOpenModal}>
                <Typography style={{ color: '#C1C6C9' }} className={classes.versionText}>
                  Versão: {frontVersion}
                </Typography>
              </Button>
            </div>
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          {navbarContent}
          <div className={classes.footerContainer}>
            <Button className={classes.versionButton} onClick={handleOpenModal}>
              <Typography style={{ color: '#C1C6C9' }} className={classes.versionText}>
                Versão: {frontVersion}
              </Typography>
            </Button>
          </div>
        </Paper>
      </Hidden>
      {isModalOpen && (
        <ModalVersion frontVersion={frontVersion} open={isModalOpen} onClose={handleCloseModal} />
      )}
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

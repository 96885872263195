import lecturesParser from './lectures.parser';
import generalPenaltiesParser from './generalPenalties.parser';
import materialParser from './material.parser';
import pecuniaryParser from './pecuniary.parser';
import ressarcimentVictimParser from './ressarcimentVictim.parser';
import serviceCommunityParser from './service.parser';

const penaltiesParser = (inquiryInvestigated, isMPDFT = false) => {
  const agreement = inquiryInvestigated.agreement || inquiryInvestigated.proposed;
  if (agreement !== null && agreement !== undefined) {
    return [
      ...materialParser(agreement.materialsForAssociations),
      ...pecuniaryParser(agreement.pecuniaryPenalties),
      ...serviceCommunityParser(agreement.communityServices),
      ...ressarcimentVictimParser(agreement.compensationToVictims, isMPDFT),
      ...lecturesParser(agreement.socioEducationalLecturePenalties),
      ...generalPenaltiesParser(agreement.generalPenalties),
    ].filter(penalty => penalty);
  }
  return [];
};

export default penaltiesParser;

/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import './styles.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, colors } from '@material-ui/core';

import axios from 'utils/axios';
import useRouter from 'utils/useRouter';
import { NotificationsPopover } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#00597b',
    borderColor: '#00597b',
    height: 70,
    justifyContent: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  nameCompany: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    marginLeft: 5,
    fontSize: 20,
  },
  groupeLinks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    marginLeft: 20,
    paddingLeft: 50,
    paddingRight: 50,
  },
  unicLink: {
    paddingLeft: 15,
    paddingRight: 15,
  },
  nameUserStyle: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 12,
    color: '#5d95ab',
    marginRight: 10,
  },
  logoMppe: {
    borderRadius: 50,
    paddingTop: 5,
  },
  avatar: {
    borderRadius: 50,
    paddingTop: 1,
  },
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);

  useEffect(() => {
    let mounted = true;

    const fetchNotifications = () => {
      axios.get('/api/account/notifications').then(response => {
        if (mounted) {
          setNotifications(response.data.notifications);
        }
      });
    };

    fetchNotifications();

    return () => {
      mounted = false;
    };
  }, []);

  const handleLogout = () => {
    history.push('/auth/login');
    // dispatch(logout());
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink>
          <div>
            <img
              className={classes.logoMppe}
              alt="avatar"
              height="60"
              src="./images/logos/logoMPPE.png"
            />
          </div>
        </RouterLink>

        <RouterLink to="/Dashboard">
          <div className={classes.nameCompany}>CONSENSUS</div>
        </RouterLink>

        <div className={classes.groupeLinks}>
          <div className={classes.unicLink}>
            <RouterLink to="/inqueritos">VISUALIZAÇÃO DOS INQUERITOS</RouterLink>
          </div>

          <div className={classes.unicLink}>
            <RouterLink to="/triagem-nao-persecucao">TRIAGEM - NÃO PERSECUÇÃO</RouterLink>
          </div>

          <div className={classes.unicLink}>
            <RouterLink to="/pesquisa-e-distribuicao">PESQUISA E DISTRIBUIÇÃO</RouterLink>
          </div>

          <div className={classes.unicLink}>
            <RouterLink to="/analise">ANÁLISE</RouterLink>
          </div>

          <div className={classes.unicLink}>
            <RouterLink to="/intimacao">INTIMAÇÃO</RouterLink>
          </div>
        </div>

        <div className={classes.flexGrow} />

        <div className={classes.nameUserStyle}>Nome do usuário</div>

        <div>
          <img
            className={classes.avatar}
            alt="avatar"
            height="45"
            src="./images/avatars/avatar_1.png"
          />
        </div>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;

import * as actionTypes from 'actions';

const initialState = {
  date: '',
  time: '',
  shift: '',
  uf: 'PE',
  judicialDistrict: '',
  locality: '',
  isFlagrant: false,
  report: '',
};

const factInquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FACT_INQUIRY:
      return {
        ...state,
        date: action.payload.date,
        time: action.payload.time,
        shift: action.payload.shift,
        uf: action.payload.uf,
        judicialDistrict: action.payload.judicialDistrict,
        locality: action.payload.locality,
        isFlagrant: action.payload.isFlagrant,
        report: action.payload.report,
      };

    case actionTypes.CLEAR_FACT_INQUIRY:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default factInquiryReducer;

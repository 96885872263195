import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
  },
}));

const ContainerBtns = ({ children, className }) => {
  const classes = useStyles();
  return <Box className={clsx(classes.root, className)}>{children}</Box>;
};

ContainerBtns.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ContainerBtns;

import http from '../httpService';

const GoogleApiService = {
  getAuthUrlService: () => http.get('google-api/auth-url'),
  
  getTokenStatusService: () => http.get('google-api/tokens/status'),

  createTokenService: params => http.post('google-api/tokens', params),
};

export default GoogleApiService;

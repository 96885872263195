import http from '../httpService';

const crimeSubjectService = {
  getCrimeSubjectsService: params => http.get('crime-subject', { params }),

  registerCrimeSubjectService: params => http.post('crime-subject', params),

  updateCrimeSubjectService: (subjectId, params) =>
    http.patch(`crime-subject/${subjectId}`, params),
};

export default crimeSubjectService;

/* eslint-disable react/no-multi-comp */
import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { List } from '@material-ui/core';

import useRouter from 'utils/useRouter';
import { NavigationListItem } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}));

const NavigationList = props => {
  const { pages, ...rest } = props;

  return (
    <List>{pages.reduce((items, page) => reduceChildRoutes({ items, page, ...rest }), [])}</List>
  );
};

NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array,
};

const reduceChildRoutes = props => {
  const { router, items, page, depth } = props;

  if (page.children) {
    //const open = page.children.map(item => item.href).includes(router.location.pathname);

    const open = false;

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
      >
        <NavigationList depth={depth + 1} pages={page.children} router={router} />
      </NavigationListItem>,
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
      />,
    );
  }

  return items;
};

const NavitagionCompany = props => {
  const { pages, className, component: Component, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <Component {...rest} className={clsx(classes.root, className)}>
      <NavigationList depth={0} pages={pages} router={router} />
    </Component>
  );
};

NavitagionCompany.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string,
};

NavitagionCompany.defaultProps = {
  component: 'nav',
};

export default NavitagionCompany;

import { axiosMock } from 'utils/axios';

import users from './data/users';
import { configScenario1 } from './data/customerConfig';

axiosMock
  .onPost('/auth/login')
  .reply(200, {
    "accessToken": "",
    "issuedAt": "",
    "expiresAt": "",
    "user": users[0],
    "authType": "Bearer",
    "customerConfig": configScenario1,
  });

export const applyMask = (value, mask) => {
  if (!value) {
    return '';
  }
  const digitArray = Array.from(value.replace(/\D/g, ''));
  const maskArray = Array.from(mask);
  const maskedValue = maskArray.reduce((previousValue, currentValue) => {
    if (digitArray.length === 0) {
      return previousValue;
    }
    if (/\d|\*/.test(currentValue)) {
      return `${previousValue}${digitArray.shift()}`;
    } else {
      return `${previousValue}${currentValue}`;
    }
  }, '');
  return maskedValue;
};

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, IconButton, Paper, Tooltip, Chip } from '@material-ui/core';
import { colors } from 'utils/colorsSteps';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#ededed',
    height: '100%',
    overflow: 'auto',
  },
  shadow: {
    boxShadow: `0px 33px 0px 0px ${colors.greenLight} inset`,
    height: '100%',
    padding: theme.spacing(1),
  },
  containerTypography: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
    marginBottom: theme.spacing(2),
  },
  typography: {
    display: 'inline-block',
  },
  title: {
    fontWeight: 'bold',
  },
  chip: {
    backgroundColor: colors.greenLight,
    color: '#ededed',
    fontSize: 12,
    fontWeight: 'bold',
  },
  chipContact: {
    backgroundColor: colors.navBar,
    color: '#ededed',
    fontSize: 11,
    fontWeight: 'bold',
    margin: theme.spacing(0.5),
    height: 20,
  },
  containerChips: {
    display: 'flex',
    justifyContent: 'space-stretch',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  containerBtns: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  containerBtn: {
    padding: theme.spacing(2),
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
  },
  iconButton: {
    backgroundColor: colors.greenLight,
    '&:hover': {
      backgroundColor: colors.greenLight,
    },
  },
  icon: {
    color: '#fff',
  },
}));

const StatusIntimation = props => {
  const {
    intimations,
    setOpenModalAddFile,
    setOpenModalListFile,
    write,
    handleOpenSnackbar,
    disabledAddFileIntimation,
  } = props;
  const [attempts, setAttempts] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (intimations.length > 0) {
      setAttempts(intimations[0].attempts);
    }
  }, [intimations]);

  const TextStatus = () => {
    return (
      <Typography
        variant="h6"
        style={{ color: '#fff', fontSize: 14 }} /*className={classes.containerTypography}*/
      >
        {intimations.length > 0
          ? intimations[0].status === 'AWAITING_RESPONSE'
            ? 'Aguardando resposta da intimação'
            : intimations[0].status === 'CANCELED'
            ? 'Intimação não executada'
            : intimations[0].status === 'FAILED'
            ? 'Intimação não executada'
            : intimations[0].status === 'SUCCESS'
            ? 'Intimação realizada com sucesso'
            : 'Não intimado'
          : 'Não intimado'}
      </Typography>
    );
  };

  return (
    <Paper elevation={8} className={classes.root}>
      <Box className={classes.shadow}>
        <Box className={classes.containerTypography}>
          {/* <Chip className={classes.chip} size="small" label="Status: aguardando confirmação" /> */}
          <TextStatus />
        </Box>
        <Box className={classes.containerChips}>
          <Typography variant="h6" style={{ marginRight: 3 }}>
            Meios de contato:
          </Typography>
          {attempts.length < 1 ? (
            <Chip className={classes.chipContact} size="small" label="Sem tentativas" />
          ) : (
            attempts.map(item => (
              <Chip key={item.id} className={classes.chipContact} size="small" label={item.mode} />
            ))
          )}
        </Box>
        <Box className={classes.containerBtns}>
          <Box className={classes.containerBtn}>
            {disabledAddFileIntimation ? (
              <Tooltip title="Necessário intimação" arrow placement="right">
                <span>
                  <IconButton
                    className={classes.iconButton}
                    onClick={() => setOpenModalAddFile(true)}
                    style={{ backgroundColor: '#d7d7d7' }}
                    disabled
                  >
                    <AttachFileIcon style={{ color: '#ededed' }} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Anexar intimação" arrow placement="right">
                <IconButton
                  className={classes.iconButton}
                  onClick={() => (write ? setOpenModalAddFile(true) : handleOpenSnackbar())}
                >
                  <AttachFileIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
          <Box className={classes.containerBtn}>
            <Tooltip title="Ver arquivo anexado" arrow placement="left">
              <IconButton className={classes.iconButton} onClick={() => setOpenModalListFile(true)}>
                <DescriptionIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default StatusIntimation;

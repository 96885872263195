import * as actionTypes from 'actions';

const initialStateGroup = {
  codeArquimedes: '',
  createdAt: '',
  dealsOnlyWithClasses: null,
  dealsWith: [],
  id: '',
  integrationOnly: false,
  name: '',
  priority: 0,
  rejectClasses: [],
  updatedAt: '',
};

const initialState = {
  selectedIndexGroup: null,
  group: { ...initialStateGroup },
};

const groupMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_INDEX_GROUP:
      return {
        ...state,
        selectedIndexGroup: action.payload,
      };
    case actionTypes.SET_GROUP:
      return {
        ...state,
        group: action.payload,
      };

    default:
      return state;
  }
};

export default groupMenuReducer;

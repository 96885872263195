import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: 25,
    fontSize: 12,
    paddingLeft: 5,
    margin: 5,
    '@media (max-width:600px)': {
      marginBottom: 20,
    },
  },
}));

const MsgReturnSearch = props => {
  const { page, sizeDataApi, totalPages } = props;

  const classes = useStyles();

  return (
    <Typography className={classes.root}>
      Página {page}: {`Exibindo ${sizeDataApi} de ${totalPages}`}
    </Typography>
  );
};

export default MsgReturnSearch;

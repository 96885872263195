import React from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import { AiOutlineFileWord } from 'react-icons/ai';
import { VscFilePdf } from 'react-icons/vsc';
import agreementService from 'services/agreement/agreementService';
import htmlDocx from 'html-docx-fixed/dist/html-docx';
import saveAs from 'file-saver';

const ButtonShowTemplateAgreement = props => {
  const { agreementId, isProposition } = props;

  const handleOpenModalFileTemplate = async () => {
    await getFileTemplate();
  };

  const getFileTemplate = async () => {
    await agreementService.getPdfTemplateAgreementService(agreementId).then(response => {
      window.open(response.data.url, '_blank');
    });
  };

  const getDocxDownload = async () => {
    await agreementService.getAgreemetDocxService(agreementId).then(response => {
      const converted = htmlDocx.asBlob(response.data);
      saveAs(converted, `${isProposition ? 'Proposta' : 'Termo'} do acordo.docx`);
    });
  };

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Tooltip
        title={`${isProposition ? 'Proposta' : 'Arquivo'} do acordo (Pdf)`}
        arrow
        placement="top"
      >
        <IconButton onClick={handleOpenModalFileTemplate}>
          <VscFilePdf />
        </IconButton>
      </Tooltip>
      <Tooltip
        title={`${isProposition ? 'Proposta' : 'Arquivo'} do acordo (Word)`}
        arrow
        placement="top"
      >
        <IconButton onClick={getDocxDownload}>
          <AiOutlineFileWord />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default ButtonShowTemplateAgreement;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Card } from '@material-ui/core';
import { STEPS_INQUIRY, listSteps } from 'constants/inquirys/stepsInquiry';
import { Autocomplete } from '@material-ui/lab';
import { AutoCompleteConfig } from 'constants/autocomplete';
import InputConsensus from 'components/InputConsensus';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#ededed',
    borderRadius: 20,
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 200,
    boxShadow: 'none',
    border: '1px solid #707070',
  },
  title: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    paddingTop: 5,
    fontFamily: 'roboto-regular',
  },
});

const TargetStep = props => {
  const { targetStep, setTargetStep, disabled } = props;

  const classes = useStyles();

  const customerConfig = useSelector(state => state.customerConfig);

  const options = listSteps(customerConfig).filter(
    step =>
      step.value === STEPS_INQUIRY.TRIAGEM ||
      step.value === STEPS_INQUIRY.PESQUISA ||
      step.value === STEPS_INQUIRY.ANALISE ||
      step.value === STEPS_INQUIRY.TRAMITACAO_CONTROLE ||
      step.value === STEPS_INQUIRY.INTIMACAO ||
      step.value === STEPS_INQUIRY.AUDIENCIA ||
      step.value === STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO ||
      step.value === STEPS_INQUIRY.CUMPRIMENTO_ACORDO,
  );

  const handleChange = (_, newValue) => {
    setTargetStep(newValue);
  };

  return (
    <Card className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classes.title}>Fase de Destino</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          <Autocomplete
            value={targetStep}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            filterOptions={(options, { inputValue }) =>
              options.filter(option =>
                option.name?.toLowerCase().includes(inputValue.toLowerCase()),
              )
            }
            fullWidth
            noOptionsText={AutoCompleteConfig.NO_OPTION_TEXT}
            clearOnBlur={false}
            options={options}
            onChange={handleChange}
            renderOption={option => <>{option.name}</>}
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Fase *"
                fullWidth
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
            PopperProps={{
              placement: 'bottom-start',
            }}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default TargetStep;

import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { HeaderModal, ButtonConsensus, InputConsensus } from 'components';
import { useTheme } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { InquiryItem, ListInquiryItem } from './components';
import { internalSystemNumberNoMask, npuMask } from 'masks/masks';
import { clearInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 400,
    marginTop: theme.spacing(1),
  },
  containerList: {
    padding: 0,
  },
  listitem: {
    width: '100%',
    padding: theme.spacing(2),
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  itemLeft: {
    marginRight: theme.spacing(1),
  },
  importButton: {
    margin: theme.spacing(1, 0, 0, 0),
  },
  containerSwitch: {
    marginTop: theme.spacing(5),
  },
}));

const ModalGiveBackInquiry = props => {
  const { open, onClose, handleChangeInquiry, ...rest } = props;

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [inquiry, setInquiry] = useState(null);
  const [inquiryList, setInquiryList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [erros, setErrors] = useState(false);
  const [loadingImport, setLoadingImport] = useState(false);

  const radioOptions = {
    internalSystemNumber: 'internalSystemNumber',
    ip: 'ip',
    npu: 'npu',
  };

  const [radioList, setRadioList] = useState(radioOptions.internalSystemNumber);

  const [searchValue, setSearchValue] = useState('');

  const { selectedIndexGroup } = useSelector(state => state.groupMenu);
  const { groups } = useSelector(state => state.logedUser);
  const prosecutorOfficeGroupId = groups[selectedIndexGroup]?.id;

  const handleClose = () => {
    onClose();
  };

  const getInquiry = () => {
    if (!searchValue) {
      toast.warn(
        radioList === radioOptions.ip
          ? 'Informe um número ip'
          : radioList === radioOptions.internalSystemNumber
          ? 'Informe um número interno'
          : 'Informe um número npu',
      );
      return;
    }

    setLoading(true);

    const url = {
      internalSystemNumber: `inquiries/arquimedes/${searchValue}?prosecutorOfficeGroupId=${prosecutorOfficeGroupId}`,
      ip: `inquiries/arquimedes?policeInquiryNumber=${searchValue}&prosecutorOfficeGroupId=${prosecutorOfficeGroupId}`,
      npu: `inquiries/arquimedes?npu=${clearInquiryNumber(
        searchValue,
      )}&prosecutorOfficeGroupId=${prosecutorOfficeGroupId}`,
    };

    axios
      .get(`${url[radioList]}`)
      .then(response => {
        setErrors(false);
        if (radioList === radioOptions.ip || radioList === radioOptions.npu) {
          setInquiry(null);
          setInquiryList(response.data.results);
          if (response.data.results.length < 1) setErrors(true);
        } else {
          setInquiry(response.data);
          setInquiryList([]);
        }
      })
      .catch(() => {
        setErrors(true);
        setInquiry(null);
      })
      .finally(() => setLoading(false));
  };

  const handleChangeSearchPlace = event => {
    setRadioList(event.target.value);
  };

  const handleChange = event => {
    event.persist();

    setSearchValue(event.target.value);
  };

  const handleSearchInquiry = () => {
    getInquiry();
  };

  const importInquiryFromArquimedes = async internalSystemNumber => {
    //console.log(internalSystemNumber);
    setLoadingImport(true);
    axios
      .post(
        `inquiries/arquimedes/${internalSystemNumber}/import?prosecutorOfficeGroupId=${prosecutorOfficeGroupId}`,
      )
      .then(response => {
        handleChangeInquiry(null, response.data);
        handleClose();
      })
      .finally(() => setLoadingImport(false));
  };

  const handleSelectInquiry = internalSystemNumber =>
    importInquiryFromArquimedes(internalSystemNumber);

  return (
    <div className={classes.root}>
      <Dialog
        {...rest}
        open={open}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, handleClose)}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        aria-labelledby="modal-integration-inquiry"
      >
        <HeaderModal onClose={handleClose}>Integração de inquérito</HeaderModal>
        <DialogContent className={classes.container}>
          <Typography variant="h5" component="h5" align="center">
            O número que você digitou não foi encontrado na base de dados do Consensus.
          </Typography>
          <Typography variant="h5" component="h5" align="center">
            Para pesquisar na base de dados do Arquimedes, digite abaixo o número interno ou o
            número do IP do inquérito.
          </Typography>
          <Box className={classes.containerSwitch}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Selecione o filtro que deseja usar:</FormLabel>
              <RadioGroup row value={radioList} onChange={handleChangeSearchPlace}>
                <FormControlLabel
                  value={radioOptions.internalSystemNumber}
                  control={<Radio />}
                  label="Número Interno"
                />
                <FormControlLabel value={radioOptions.ip} control={<Radio />} label="Ip" />
                <FormControlLabel value={radioOptions.npu} control={<Radio />} label="Npu" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Grid container spacing={2} alignItems={'center'}>
            <Grid item xl={6} lg={6} md={8} sm={8} xs={12}>
              {radioList === radioOptions.internalSystemNumber ? (
                <InputMask
                  onChange={handleChange}
                  value={searchValue}
                  mask={internalSystemNumberNoMask}
                  maskChar={null}
                >
                  {inputProps => (
                    <InputConsensus
                      {...inputProps}
                      name="inquiryIntregation"
                      label="Pesquisar código interno"
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              ) : radioList === radioOptions.ip ? (
                <InputConsensus
                  onChange={handleChange}
                  value={searchValue}
                  name="inquiryIntregation"
                  label="Pesquisar número Ip"
                  fullWidth
                  size="small"
                  variant="outlined"
                />
              ) : (
                <InputMask
                  onChange={handleChange}
                  value={searchValue}
                  mask={npuMask}
                  maskChar={null}
                >
                  {inputProps => (
                    <InputConsensus
                      {...inputProps}
                      name="inquiryIntregation"
                      label="Pesquisar Npu"
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  )}
                </InputMask>
              )}
            </Grid>

            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
              <ButtonConsensus
                title={loading ? 'Buscando...' : 'Buscar'}
                onClick={handleSearchInquiry}
                disabled={loading}
              />
            </Grid>

            {inquiry ? (
              <InquiryItem
                inquiry={inquiry}
                handleSelectInquiry={handleSelectInquiry}
                loadingImport={loadingImport}
              />
            ) : (
              inquiryList.length > 0 && (
                <ListInquiryItem
                  inquiryList={inquiryList}
                  handleSelectInquiry={handleSelectInquiry}
                  loadingImport={loadingImport}
                />
              )
            )}

            {erros && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography paragraph>Nenhum inquérito encontrado</Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalGiveBackInquiry;

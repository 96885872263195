export const formatedPandoraFieldNames = {
    parentesco: 'Parentesco',
    vizinho: 'Vizinho',
    endereco: 'Endereço',
    empregador: 'Empregador',
    historico_quadro_societario: 'Histórico do Quadro Societário',
    virtual: 'Virtual',
    veiculo: 'Veículo',
    servidor_estadual: 'Servidor Estadual',
    servidor_federal: 'Servidor Federal',
    imovel: 'Imóvel',
    processo: 'Processo',
    eleitoral: 'Eleitoral',
    obito: 'Óbito',
    cpf: 'CPF',
    nome: 'Nome',
    dataNascimento: 'Data de Nascimento',
    logradouro: 'Logradouro',
    numero: 'Número',
    tipoLogradouro: 'Tipo de Logradouro',
    complemento: 'Complemento',
    fonte: 'Fontes',
    rank: 'Rank',
    bairro: 'Bairro',
    cep: 'CEP',
    uf: 'UF',
    municipio: 'Município',
    anoRegistro: 'Ano de Registro',
    ddd: 'DDD',
    telefone: 'Telefone',
    relevancia: 'Relevância',
    tipoTelefone: 'Tipo de telefone',
    ano: 'Ano',
    cnpj: 'CNPF',
    razaoSocial: 'Razão Social',
    nomeFantasia: 'Nome Fantasia',
    pis: 'PIS',
    ctpsNumero: 'Número da CTPS',
    ctpsSerie: 'Série da CTPS',
    cargo: 'Cargo',
    sinonimos: 'Sinônimos',
    dtAdmissao: 'Data de admissão',
    tipoAdmissao: 'Tipo de admissão',
    tipoVinculo: 'Tipo de vínculo',
    dtDesligamento: 'Data de desligamento',
    causaDesligamento: 'Causa do desligamento',
    cargaHoraria: 'Carga Horária',
    tipoSalario: 'Tipo de salário',
    salarioContratado: 'Salário contratado',
    salarioAnual: 'Salário anual',
    mesesTrabalhados: 'Meses trabalhados',
    EMPRESA: 'Empresa',
    CNPJ_EMPRESA: 'CNPJ',
    ORIGEM_INFORMACAO: 'Origem da Informação',
    SOCIO: 'Sócio',
    DOCUMENTO_SOCIO: 'Documento do Sócio',
    ACAO: 'Ação',
    DATA_ACAO: 'Data da ação',
    DT_ENTRADA_SOCIEDADE: 'Data de entrada na sociedade',
    DT_SAIDA_SOCIEDADE: 'Data de saída da sociedade',
    DT_INICIO_MANDATO: 'Data de início do mandato',
    DT_TERMINO_MANDATO: 'Data de término do mandato',
    VINCULO: 'Vínculo',
    MOEDA: 'Moeda',
    VL_PARTICIPACAO: 'Patricipação',
    CARGO_DIRECAO: 'Cargo de direção',
    tipo: 'Tipo',
    dataInicioAtividade: 'Data de início da atividade',
    cpfResponsavel: 'CPF do responsável',
    nomeResponsavel: 'Nome do responsável',
    dataEntradaSociedade: 'Data de entrada na sociedade',
    dataSaidaSociedade: 'Data de saída da sociedade',
    percCapital: 'Percentual capital',
    vinculo: 'Vínculo',
    email: 'E-mail',
    nivel: 'Nível',
    categoria: 'Categoria',
    sexo: 'Sexo',
    idade: 'Idade',
    chassi: 'Chassi',
    placa: 'Placa',
    renavam: 'Renavam',
    situacao: 'Situação',
    marcaModelo: 'Marca/Modelo',
    cor: 'Cor',
    anoFab: 'Ano de frabricação',
    anoMod: 'Ano do modelo',
    cpf_cnpjResponsavel: 'CPF/CNPJ do responsável',
    responsavel: 'Responsável',
    proprietario: 'Proprietário',
    possuidor: 'Possuidor',
    dataAtualizacao: 'Data de Atualização',
    restricao_1: 'Restrição 1',
    restricao_2: 'Restrição 2',
    restricao_3: 'Restrição 3',
    restricao_4: 'Restrição 4',
    tipoDado: 'Tipo de dado',
    especie: 'Espécie',
    dataIncioPosse: 'Data início da posse',
    dataFinalPosse: 'Data final da posse',
    numMotor: 'Número do motor',
    numCambio: 'Número do câmbio',
    combustivel: 'Combustível',
    numLacre: 'Número do lacre',
    imunidade: 'Imunidade',
    isencaoParticular: 'Isenção particular',
    cpfCnpjArrend: 'CPF/CNPJ do arrendatário',
    nomeArrend: 'Nome do arrendatário',
    dtAno: 'Data Ano',
    matricula: 'Matrícula',
    poder: 'Poder',
    orgao: 'Órgão',
    vlBruto: 'Valor Bruto',
    meses: 'Meses',
    media: 'Média',
    orgLotacao: 'Órgão de Lotação',
    unidOrgLotacao: 'Unidade de Órgão de Lotação',
    ufOrg: 'UF do Órgão',
    uPag: 'UPAG',
    jornada: 'Jornada',
    remuneracao: 'Remuneração',
    cartorioCnpj: 'CNPJ do cartório',
    cartorioMunicipio: 'Município do cartório',
    cartorioUf: 'UF do cartório',
    cartorioRazaoSocial: 'Razão Social do cartório',
    tipoCartorio: 'Tipo do cartório',
    dataLavratura: 'Data de lavratura',
    livro: 'Livro',
    folha: 'Folha',
    registro: 'Registro',
    cpfCnpjAlienante: 'CPF/CNPJ do alienante',
    alientante: 'Alienante',
    cpfCnpjAdquirente: 'CPF/CNPJ do adquirente',
    adquirente: 'Adquirente',
    dataCarga: 'Data de carga',
    tipoDevedor: 'Tipo de devedor',
    sistema: 'Sistema',
    numInscricao: 'Número de Inscrição',
    dtInscricao: 'Data de Inscrição',
    receita: 'Receita',
    regional: 'Regional',
    numProcesso: 'Número do Processo',
    valor: 'Valor',
    ue: 'UE',
    partido: 'Partido',
    numeroCandidato: 'Número do Candidato',
    qtd: 'Quantidade',
    obito_cpf: 'CPF',
    obito_nome: 'Nome',
    obito_nomeMae: 'Nome da Mãe',
    obito_dataNascimento: 'Data de Nascimento',
    obito_dataObito: 'Data de Óbito',
    obito_municipioServentia: 'Município de Serventia',
    obito_ufServentia: 'UF de Serventia',
    obito_nomeFantasia: 'Nome Fantasia',
    obito_livro: 'Livro',
    obito_folha: 'Folha',
    obito_termo: 'Termo',
    cpfCnpj: 'Cpf/Cnpj',
    tipoPessoa: 'Tipo de Pessoa',
    embarcacao: 'Embarcação',
    descricao: 'Descrição',
    anoConstrucao: 'Ano de construção',
    comprimento: 'Comprimento',
    constCasco: 'Casco',
    inscricao: 'Inscrição',
    dataInscricao: 'Data de Inscrição',
    dataValidade: 'Data de Validade',
    orgaoInscricao: 'Orgão de Inscrição',
    cidadeOrgao: 'Cidade do Orgão',
    dataAquisicao: 'Data de Aquisição',
    localAquisicao: 'Local de Aquisição',
}
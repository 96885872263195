export const SET_NAME_ORGAN_ORIGIN = 'SET_NAME_ORGAN_ORIGIN';
export const SET_CITY_ORGAN_ORIGIN = 'SET_CITY_ORGAN_ORIGIN';
export const SET_UF_ORGAN_ORIGIN = 'SET_UF_ORGAN_ORIGIN';
export const SET_CODE_ARQUIMEDES_ORGAN_ORIGIN = 'SET_CODE_ARQUIMEDES_ORGAN_ORIGIN';
export const SET_ORGAN_ORIGIN = 'SET_ORGAN_ORIGIN';
export const CLEAR_ORGAN_ORIGIN = 'CLEAR_ORGAN_ORIGIN';

export const setNameOrganOrigin = value => ({
  type: SET_NAME_ORGAN_ORIGIN,
  payload: value,
});

export const setCityOrganOrigin = value => ({
  type: SET_CITY_ORGAN_ORIGIN,
  payload: value,
});

export const setUfOrganOrigin = value => ({
  type: SET_UF_ORGAN_ORIGIN,
  payload: value,
});

export const setCodeArquimedesOrganOrigin = value => ({
  type: SET_CODE_ARQUIMEDES_ORGAN_ORIGIN,
  payload: value,
});

export const clearOrganOrigin = () => ({
  type: CLEAR_ORGAN_ORIGIN,
});

export const setOrganOriginReducer = organOrigin => ({
  type: SET_ORGAN_ORIGIN,
  payload: organOrigin,
});

import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Divider,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { Button, ButtonConsensus, TableProjectBalances } from 'components';
import { colors } from 'utils/colorsSteps';
import TableAssociationAmount from 'components/TableAssociationAmount';

const ModalBalances = props => {
  const {
    projects = [],
    associations = [],
    open,
    onClose,
    onClick,
    loadingModalBalance = false,
    loadingTableProjectBalancesData,
    loadingTableAssociationAmountData,
    tableProjectBalancesData,
    tableAssociationAmountData,
  } = props;

  return (
    <Dialog fullWidth maxWidth={'lg'} open={open} onClose={onClose}>
      <DialogTitle>Saldo restante considerando o acordo atual</DialogTitle>
      <Divider />
      <DialogContent style={{ marginBottom: 30 }}>
        {!loadingTableProjectBalancesData && !loadingTableAssociationAmountData ? (
          <>
            {projects.length ? (
              <TableProjectBalances tableProjectBalancesData={tableProjectBalancesData} />
            ) : null}
            {associations.length ? (
              <TableAssociationAmount tableAssociationAmountData={tableAssociationAmountData} />
            ) : null}
          </>
        ) : (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <ButtonConsensus
          title="Cancelar"
          backgroundColor="#fff"
          hovercolor={colors.greySecundary}
          color={colors.greyShadow}
          onClick={onClose}
          disabled={
            loadingTableProjectBalancesData ||
            loadingTableAssociationAmountData ||
            loadingModalBalance
          }
        />
        <Button
          title="Prosseguir mesmo assim"
          backgroundColor={colors.primary}
          hovercolor={colors.primary}
          color="#ffffff"
          variant="contained"
          onClick={onClick}
          disabled={
            loadingTableProjectBalancesData ||
            loadingTableAssociationAmountData ||
            loadingModalBalance
          }
        >
          {loadingModalBalance ? <CircularProgress size={26} /> : 'Prosseguir mesmo assim'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalBalances;

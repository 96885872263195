import * as actionTypes from 'actions';

const initialState = {
  code: '',
  description: '',
};

const classesInquiry = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CLASSES_INQUIRY: {
      return {
        ...state,
        code: action.payload.code,
        description: action.payload.description,
      };
    }

    case actionTypes.CLEAR_CLASSES_INQUIRY: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default classesInquiry;

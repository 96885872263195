import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { ButtonConsensus, HeaderModal } from 'components';
import RedistributionSuccess from '../RedistributionSuccess';
import RedistributionFailed from '../RedistributionFailed';
import { makeStyles } from '@material-ui/styles';
import { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import history from 'utils/history';

const useStyles = makeStyles(theme => ({
  tituloParagraph: {
    marginBottom: theme.spacing(1),
  },
}));

const ACTION = {
  RETORNAR_PARA_PESQUISA_DISTRIBUICAO: 'RETORNAR_PARA_PESQUISA_DISTRIBUICAO',
  REDISTRIBUIR: 'REDISTRIBUIR',
  REMETER_AO_SISTEMA_INTERNO: 'REMETER_AO_SISTEMA_INTERNO',
};

const ModalRedistribution = props => {
  const {
    onClose,
    open,
    inquiry,
    specialMode,
    displayTargetOfficeGroup,
    displayDistributionType,
    hasSameBranchDeclineTask,
  } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [inquiryInvestigatedCauses, setInquiryInvestigatedCauses] = useState([]);
  const [forceRedistributed, setForceRedistributed] = useState(false);
  const [action, setAction] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleChangeAction = event => {
    setAction(event.target.value);
  };

  const redistributionCheck = useCallback(() => {
    setLoading(true);
    inquiryService
      .redistributionValidateService(inquiry.id)
      .then(response => {
        setInquiryInvestigatedCauses(response.data.inquiryInvestigatedCauses || []);
      })
      .finally(() => setLoading(false));
  }, [inquiry.id]);

  useEffect(() => {
    redistributionCheck();
    // eslint-disable-next-line
  }, []);

  const verifyComponentShow = useMemo(
    () => !inquiryInvestigatedCauses.length || forceRedistributed,
    [forceRedistributed, inquiryInvestigatedCauses],
  );

  const handleClickConfirm = () => {
    setDisabled(true);
    inquiryService
      .updateChangeStepService(inquiry.id, {
        step:
          action === ACTION.REMETER_AO_SISTEMA_INTERNO
            ? STEPS_INQUIRY.REMETIDO_AO_SISTEMA_INTERNO
            : STEPS_INQUIRY.PESQUISA,
      })
      .then(() => {
        if (action === ACTION.REMETER_AO_SISTEMA_INTERNO) {
          toast.success('Inquérito remetido ao Sistema Interno');
        } else {
          toast.success('Inquérito enviado para Pesquisa e Distribuição');
        }
      })
      .finally(() => {
        onClose();
        setDisabled(false);
        history.push('/tramitacao-e-controle', { statusInquiries: '' });
      });
  };

  return (
    <Dialog fullWidth maxWidth={!verifyComponentShow ? 'md' : 'sm'} open={open} onClose={onClose}>
      <HeaderModal onClose={onClose}>
        {!displayDistributionType && displayTargetOfficeGroup ? 'Encaminhamento' : 'Redistribuição'}
      </HeaderModal>
      <DialogContent>
        {specialMode ? (
          <FormControl>
            <FormLabel className={classes.tituloParagraph}>
              <Typography variant="h5">Selecione uma das opções:</Typography>
            </FormLabel>
            <RadioGroup row onChange={handleChangeAction} value={action}>
              <FormControlLabel
                value={ACTION.REDISTRIBUIR}
                control={<Radio color="primary" />}
                label={
                  !displayDistributionType && displayTargetOfficeGroup
                    ? 'Encaminhar'
                    : 'Redistribuir'
                }
              />
              <FormControlLabel
                value={ACTION.RETORNAR_PARA_PESQUISA_DISTRIBUICAO}
                control={<Radio color="primary" />}
                label="Retornar para Pesquisa e Distribuição"
              />
              {hasSameBranchDeclineTask && (
                <FormControlLabel
                  value={ACTION.REMETER_AO_SISTEMA_INTERNO}
                  control={<Radio color="primary" />}
                  label="Remeter ao Sistema Interno"
                />
              )}
            </RadioGroup>
          </FormControl>
        ) : null}
        {(specialMode && action === ACTION.REDISTRIBUIR) ||
        (!specialMode && action !== ACTION.REMETER_AO_SISTEMA_INTERNO) ? (
          <>
            {loading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress />
              </Box>
            ) : verifyComponentShow ? (
              <RedistributionSuccess
                displayTargetOfficeGroup={displayTargetOfficeGroup}
                displayDistributionType={displayDistributionType}
                inquiry={inquiry}
                onClose={onClose}
              />
            ) : (
              <RedistributionFailed
                data={inquiryInvestigatedCauses}
                forceRedistributedState={{ forceRedistributed, setForceRedistributed }}
              />
            )}
          </>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box display="flex" justifyContent="flex-end">
              <ButtonConsensus
                title="Confirmar"
                backgroundColor={colors.primary}
                hovercolor={colors.primary}
                color={colors.greShadow}
                onClick={handleClickConfirm}
                disabled={disabled}
              />
            </Box>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalRedistribution;

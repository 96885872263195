import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import { Typography, Grid, Card, Box, MenuItem, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import InputConsensus from 'components/InputConsensus';
import Moment from 'react-moment';
import userService from 'services/user/userService';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#ededed',
    borderRadius: 20,
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 200,
    boxShadow: 'none',
    border: '1px solid #707070',
  },
  title: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    paddingTop: 5,
    fontFamily: 'roboto-regular',
  },
});

const DISTRIBUTION_TYPE = {
  DIRECTED: 'directed',
  RANDOM: 'random',
};

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FFFFFF',
    color: '#1C6B88',
  },
}))(Tooltip);

const MigrationDistribution = props => {
  const { inquiry, prosecutor, setProsecutor, prosecutorOffice, setProsecutorOffice } = props;

  const classes = useStyles();

  const [prosecutors, setProsecutors] = useState([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [key, setKey] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [typeDistribution, setTypeDistribution] = useState(DISTRIBUTION_TYPE.RANDOM);

  const getProsecutors = async () => {
    let prosecutorList = [];
    let params = {
      prosecutorOfficeGroupIds: inquiry.inquiry.prosecutorOfficeGroup?.id,
    };
    await userService.getUsersProsecutorsService(params).then(response => {
      setProsecutors(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      setShowAutocomplete(true);
      prosecutorList = response.data.data;
    });
    return prosecutorList;
  };

  const handleChangeProsecutor = (_, newValue) => {
    setProsecutor(newValue);
    setProsecutorOffice(null);

    setKey(!key);
  };

  useEffect(() => {
    if (typeDistribution === DISTRIBUTION_TYPE.RANDOM) {
      setDisabled(false);
      setProsecutor(null);
      setProsecutorOffice(null);
    }
    if (typeDistribution === DISTRIBUTION_TYPE.DIRECTED) {
      getProsecutors();
      if (prosecutor && prosecutorOffice) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [typeDistribution]);

  const getExistentValues = async () => {
    const prosecutorList = await getProsecutors();
    const prosec = prosecutorList.find(
      prosec =>
        prosec.name === inquiry.inquiry?.fromMigratedInquiry?.prosecutorOriginalName &&
        prosec.registration === inquiry.inquiry?.fromMigratedInquiry?.prosecutorOriginalId,
    );
    const prosecutorOfficeList = [];
    if (prosec) {
      prosec.designations.forEach(office => {
        const prosecutorOfficeGroupId = inquiry.inquiry.prosecutorOfficeGroup.id;
        const blocking = office?.blockings?.find(
          blocking => blocking.prosecutorOfficeGroup.id === prosecutorOfficeGroupId,
        );
        if (!blocking) {
          prosecutorOfficeList.push(office);
        }
      });
    }
    const office = prosecutorOfficeList.find(
      office =>
        office.name === inquiry.inquiry?.fromMigratedInquiry?.prosecutorOfficeOriginalName &&
        office.designationCode === inquiry.inquiry?.fromMigratedInquiry?.prosecutorOfficeOriginalId,
    );
    return { prosec, office };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (inquiry.inquiry?.fromMigratedInquiry) {
        const { prosec, office } = await getExistentValues();
        if (prosec || office) {
          setProsecutor(prosec);
          setProsecutorOffice(office);
          setTypeDistribution(DISTRIBUTION_TYPE.DIRECTED);
        }
      }
    };

    fetchData();
  }, [inquiry]);

  return (
    <Card className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12} alignItems="center">
          <Typography className={classes.title}>Distribuição</Typography>
          <HtmlTooltip
            arrow
            title={
              <>
                <Typography variant="body2" className={classes.prosecutorTooltip}>
                  Promotor Original:{' '}
                  {inquiry.inquiry?.fromMigratedInquiry?.prosecutorOriginalName || 'Não Informado'}
                </Typography>
                <Typography variant="body2" className={classes.prosecutorTooltip}>
                  Promotoria Original:{' '}
                  {inquiry.inquiry?.fromMigratedInquiry?.prosecutorOfficeOriginalName ||
                    'Não Informado'}
                </Typography>
              </>
            }
            placement="right"
          >
            <AccountCircleIcon fontSize="small" style={{ marginLeft: '8px', color: '#00597B' }} />
          </HtmlTooltip>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          <InputConsensus
            label="Distribuição"
            name="typeDistribution"
            onChange={event => setTypeDistribution(event.target.value)}
            value={typeDistribution || ''}
            select
            fullWidth
          >
            <MenuItem value={DISTRIBUTION_TYPE.DIRECTED}>Específica</MenuItem>
            <MenuItem value={DISTRIBUTION_TYPE.RANDOM}>Aleatória</MenuItem>
          </InputConsensus>
        </Grid>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          {showAutocomplete && typeDistribution !== DISTRIBUTION_TYPE.RANDOM && (
            <Autocomplete
              options={prosecutors}
              fullWidth
              autoHighlight
              onChange={handleChangeProsecutor}
              key={key}
              noOptionsText="Não encontrado"
              value={prosecutors.find(value => value.id === prosecutor?.id)}
              getOptionLabel={option => option.name || ''}
              renderOption={option => <div>{option.name}</div>}
              renderInput={params => (
                <InputConsensus
                  {...params}
                  name="name"
                  label="Promotor"
                  variant="standard"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          )}
        </Grid>
        {prosecutor && (
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ paddingLeft: 15, paddingRight: 15 }}
          >
            <InputConsensus
              select
              label="Promotoria"
              name="prosecutorOffice"
              value={prosecutorOffice || ''}
              onChange={event => setProsecutorOffice(event.target.value)}
              variant="standard"
              size="small"
              fullWidth
            >
              {prosecutor.designations.map(office => {
                const prosecutorOfficeGroupId = inquiry.inquiry.prosecutorOfficeGroup.id;
                const blocking = office?.blockings?.find(
                  blocking => blocking.prosecutorOfficeGroup.id === prosecutorOfficeGroupId,
                );
                return (
                  <MenuItem key={office.name} value={office} disabled={!!blocking}>
                    {blocking ? (
                      blocking?.startDate && blocking?.endDate ? (
                        <Box>
                          <span>Inativo no período: </span>
                          <Moment format="DD/MM/YYYY">{blocking.startDate}</Moment>
                          <span> até </span>
                          <Moment format="DD/MM/YYYY">{blocking.endDate}</Moment>
                          <Box>{office.name}</Box>
                        </Box>
                      ) : (
                        <Box>
                          <Box>Promotor Inativo</Box>
                          <Box>{office.name}</Box>
                        </Box>
                      )
                    ) : (
                      office.name
                    )}
                  </MenuItem>
                );
              })}
            </InputConsensus>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default MigrationDistribution;

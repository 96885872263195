import React, { useState } from 'react';
import { Dialog, Typography, Grid, Box, DialogContent, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import DescriptionIcon from '@material-ui/icons/Description';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import HeaderModal from 'components/HeaderModal';
import { colors } from 'utils/colorsSteps';
import ButtonConsensus from 'components/ButtonConsensus';
import movementTaskService from 'services/movement-task/movementTaskService';
import { useUserTypes } from 'hooks/useUserTypes';
import pmService from 'services/PM/pmService';

const useStyles = makeStyles(() => ({
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
}));

const ModalAddFile = props => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { open, onClose, selectedTask } = props;

  const { isPM } = useUserTypes();

  const [files, setFiles] = useState([]);

  const [loading, setLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: true,
    onDrop: files => getBase64(files),
  });

  //Converter arquivo para Base64
  const getBase64 = files => {
    files.forEach(file => {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setFiles(files => [
          ...files,
          {
            name: file.name,
            base64,
          },
        ]);
      };
      reader.onerror = function(error) {
        toast.warn(error);
      };
    });
  };

  const handleSendFiles = () => {
    setLoading(true);

    const arrayPromisses = [];

    if (isPM) {
      files.forEach(file => {
        arrayPromisses.push(
          pmService
            .uploadDocumentsInTasksPmService(selectedTask.task.id, {
              name: file.name,
              base64: file.base64,
            })
            .then(() => {
              toast.success(`Arquivo ${file.name} anexado com sucesso`);
              setFiles(files => files.filter(item => item.name !== file.name));
            })
            .catch(() => toast.warn(`Erro ao anexar o arquivo ${file.name}`)),
        );
      });
    } else {
      files.forEach(file => {
        arrayPromisses.push(
          movementTaskService
            .uploadDocumentsInTasksService(selectedTask.task.id, {
              name: file.name,
              base64: file.base64,
            })
            .then(() => {
              toast.success(`Arquivo ${file.name} anexado com sucesso`);
              setFiles(files => files.filter(item => item.name !== file.name));
            })
            .catch(() => toast.warn(`Erro ao anexar o arquivo ${file.name}`)),
        );
      });
    }

    Promise.all(arrayPromisses).finally(() => setLoading(false));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={onClose}
      open={open}
      className={classes.modal}
      maxWidth="sm"
      fullWidth
    >
      <HeaderModal
        onClose={onClose}
        backgroundColor={colors.navBar}
        colorIconTitle={colors.greySecundary}
      >
        Adicionar Arquivos
      </HeaderModal>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: 150 }}>
            <Box {...getRootProps({ className: 'dropzone' })} className={classes.dropzone} mt={2}>
              <input {...getInputProps()} />
              <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box mt={2}>
              <Typography variant="h5">Arquivos</Typography>
              {files.length > 0 ? (
                files.map((file, index) => (
                  <Box key={index} display="flex" justifyContent="space-between">
                    <Box display="flex" alignItems="center" mt={1}>
                      <DescriptionIcon style={{ marginRight: 5 }} />
                      <Typography variant="body1">{file.name}</Typography>
                    </Box>
                    <IconButton
                      onClick={() =>
                        setFiles(files => files.filter((_item, fileIndex) => fileIndex !== index))
                      }
                    >
                      <HighlightOffIcon style={{ color: '#e81224' }} />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Typography>Não há arquivos</Typography>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" my={2}>
          <ButtonConsensus
            title="Enviar arquivos"
            onClick={handleSendFiles}
            disabled={loading || !files.length}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddFile;

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Home from '@material-ui/icons/Home';
import DuoIcon from '@material-ui/icons/Duo';
import ChatIcon from '@material-ui/icons/Chat';

export const navigationConfig = customerConfig => [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Home',
        href: '/investigated/dashboard',
        icon: Home,
      },
      {
        title: `Audiência${customerConfig.enableHearingForJuvenileOffenders ? '/Oitiva' : ''}`,
        href: '/investigated/hearing',
        icon: DuoIcon,
      },
      {
        title: 'chat',
        href: '/investigated/chat',
        icon: ChatIcon,
      },
      {
        title: 'Sair',
        href: '/',
        icon: ExitToAppIcon,
      },
    ],
  },
];

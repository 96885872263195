import http from '../httpService';

const crimeService = {
  getCrimesService: params => http.get('crimes', { params }),

  registerCrimesService: params => http.post('crimes', params),

  deleteCrimesService: crimeId => http.delete(`crimes/${crimeId}`),

  updateCrimeService: (crimeId, params) => http.patch(`crimes/${crimeId}`, params),

  getCrimesSubtypes: params => http.get('crimes/subtypes', { params }),
};

export default crimeService;

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import history from 'utils/history';
import { OLD_URLS } from 'constants/old-urls';

const useStyles = makeStyles(() => ({
  root: {},
  container: {
    height: 500,
  },
  containerGrid: {
    height: '100%',
  },
  containerIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 200,
  },
  icon: {
    fontSize: 100,
    backgroundColor: '#ededed',
    borderRadius: '50%',
    padding: 10,
  },
  containerMsg: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btn: {
    /*height: 65,
        borderRadius: '50%'*/
    height: 35,
  },
}));

export default function NoPermission(props) {
  const { open, handleClose } = props;

  const classes = useStyles();

  useEffect(() => {
    localStorage.removeItem(OLD_URLS.user_and_prosecutor);
  }, []);

  const handleClosePattern = () => {
    history.push('/');
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title-no-permission"
        aria-describedby="alert-dialog-description-no-permission"
        fullWidth
        maxWidth={'md'}
        onClose={() => null}
      >
        <DialogContent className={classes.container}>
          <Grid container className={classes.containerGrid}>
            <Grid className={classes.containerIcon} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <NoEncryptionIcon className={classes.icon} />
            </Grid>
            <Grid className={classes.containerMsg} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h3">Ops...</Typography>
              <Typography variant="h3">Você não tem permissão para acessar esta tela :(</Typography>
              <Typography variant="h4">Contate o administrador do sistema</Typography>
            </Grid>
            <Grid className={classes.containerBtn} item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClose || handleClosePattern}
                size="small"
                className={classes.btn}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

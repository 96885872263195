import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Drawer, Paper } from '@material-ui/core';
import { Hidden } from '@material-ui/core';
import useRouter from 'utils/useRouter';
import { NavitagionCompany } from 'components';
import { OLD_URLS } from 'constants/old-urls';
import { setUrlCallbackLogin } from 'utils/setUrlCallbackLogin';
import { navigationConfig } from './navigationConfig';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    //paddingLeft: theme.spacing(2)
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    //marginTop: theme.spacing(2)
  },
}));

const NavBar = props => {
  const { openMobile, onMobileClose, className, ...rest } = props;
  const customerConfig = useSelector(state => state.customerConfig);

  const classes = useStyles();
  const router = useRouter();

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname]);

  useEffect(() => {
    const url = setUrlCallbackLogin();
    localStorage.setItem(OLD_URLS.investigated, url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const navbarContent = (
    <div className={classes.content}>
      <nav className={classes.navigation}>
        {navigationConfig(customerConfig).map(list => (
          <NavitagionCompany
            component="div"
            key={list.title}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
      {/*<Label color={colors.blue['500']}>v1.2.0</Label>*/}
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer anchor="left" onClose={onMobileClose} open={openMobile} variant="temporary">
          <div {...rest} className={clsx(classes.root, className)}>
            {navbarContent}
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper {...rest} className={clsx(classes.root, className)} elevation={1} square>
          {navbarContent}
        </Paper>
      </Hidden>
    </Fragment>
  );
};

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

import React from 'react';
import {
  FormControl,
  FormLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  tituloParagraph: {
    marginBottom: theme.spacing(1),
  },
}));

const ForwardToInstitutionRadioGroup = props => {
  const { value, onChange, institutionTypeOptions, disabledInstitutionType } = props;

  const classes = useStyles();

  return (
    <FormControl>
      <FormLabel className={classes.tituloParagraph}>
        <Typography variant="h5">Encaminhar para instituição:</Typography>
      </FormLabel>
      <RadioGroup row value={value} onChange={onChange}>
        <FormControlLabel
          value={institutionTypeOptions.encaminhadora}
          control={<Radio />}
          label="Encaminhadora"
          disabled={disabledInstitutionType}
        />
        <FormControlLabel
          value={institutionTypeOptions.final}
          control={<Radio />}
          label="Final"
          disabled={disabledInstitutionType}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default ForwardToInstitutionRadioGroup;

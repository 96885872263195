/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Box, TextareaAutosize, Typography, CircularProgress } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { ButtonConsensus, InputConsensus, KeyboardDatePicker } from 'components';
import { toast } from 'react-toastify';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import associationService from 'services/association/associationService';
import { getProjects } from '../utils';
import agreementService from 'services/agreement/agreementService';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { PARTNER_SITUATIONS } from 'constants/partner-situations';
import { colors } from 'utils/colorsSteps';
import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';

const useStyles = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  containerCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const SocioeducationalLectures = props => {
  const {
    setDisabledActionsPenaltyTable = {},
    selectedIndex = null,
    setSelectedIndex = {},
    lectures,
    setLectures,
    institutionType,
    institutionSelected,
  } = props;

  const classes = useStyles();

  const [row, setRow] = useState(null);

  const [showCompany, setShowCompany] = useState(false);
  const [companys, setCompanys] = useState([]);
  const [loadingAssociations, setLoadingAssociations] = useState(false);

  const [listProjects, setListProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [inputSearchProjects, setInputSearchProjects] = useState('');
  const [pageProjects, setPageProjects] = useState(1);
  const [lastPageProjects, setLastPageProjects] = useState(1);

  const { isMPDFT } = useCustomerConfig();

  const initialValues = {
    link: '',
    description: '',
    expectedDate: '',
    penalty: PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES,
    type: '',
    project: null,
    association: null,
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: selectedIndex !== null ? { ...lectures[selectedIndex.index] } : { ...initialValues },
    }));
  }, [selectedIndex]);

  const [showType, setShowType] = useState(false);
  const [types, setTypes] = useState([]);
  const [loadingTypes, setLoadingTypes] = useState(false);

  const { enablePenaltyTypes } = useCustomerConfig();

  const handleChangeType = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        type: newValue,
      },
    }));
  };

  const getTypes = async () => {
    try {
      setLoadingTypes(true);

      await agreementService
        .getPenaltyTypes()
        .then(response => {
          setTypes(response.data.lectureTypes);
        })
        .finally(() => {
          setShowType(true);
        });
    } catch (err) {
      toast.error('Erro ao buscar tipos');
    } finally {
      setLoadingTypes(false);
    }
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleClearFormStat = () => {
    setRow(null);
    if (selectedIndex !== null) {
      setDisabledActionsPenaltyTable(prevState => ({
        ...prevState,
        [selectedIndex.penaltiesIndex]: { ...prevState[selectedIndex.penaltiesIndex], edit: false },
      }));
      setSelectedIndex(null);
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
  };

  const getCompanys = async () => {
    try {
      setLoadingAssociations(true);

      const params = {
        forward: false,
        includeAssociationsWithNullSituation: true,
      };

      if (isMPDFT) {
        delete params.includeAssociationsWithNullSituation;
        params.situation = PARTNER_SITUATIONS.ACCREDITED;
      }

      await associationService
        .getAssociationsService(params)
        .then(response => {
          setCompanys(response.data.data);
        })
        .finally(() => {
          setShowCompany(true);
        });
    } catch (err) {
      toast.error('Erro ao buscar instituições');
    } finally {
      setLoadingAssociations(false);
    }
  };

  const handleChangeCompany = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        association: newValue,
        project: null,
      },
    }));
  };

  const loadMoreProjectsResults = () => {
    const nextPage = pageProjects + 1;

    setPageProjects(nextPage);

    setLoadingProjects(true);

    const params = {
      page: nextPage,
      size: 20,
      name: inputSearchProjects,
    };

    if (formState.values.association?.id) {
      params.associationId = formState.values.association?.id;
    }

    associationService
      .getAssociationsService(params)
      .then(response => {
        setListProjects([...listProjects, ...response.data.data]);
        setLastPageProjects(response.data.lastPage);
      })
      .finally(() => setLoadingProjects(false));
  };

  const handleScrollProjects = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageProjects < lastPageProjects &&
      !loadingProjects
    ) {
      loadMoreProjectsResults();
    }
  };

  const debouncedGetProjects = useCallback(
    debounce(
      (newValue, associationId) =>
        getProjects({
          setLoading: setLoadingProjects,
          setPage: setPageProjects,
          setLastPage: setLastPageProjects,
          setProjects: setListProjects,
          input: newValue,
          associationId,
        }),
      500,
    ),
    [],
  );

  const updateValueProject = newValue => {
    if (!listProjects.find(inst => inst.name === newValue)) {
      debouncedGetProjects(newValue, formState.values.association?.id);
      setInputSearchProjects(newValue);
    }
  };

  const handleChangeProject = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: newValue,
      },
    }));
  };

  useEffect(() => {
    debouncedGetProjects('', formState.values.association?.id);
  }, [formState.values.association]);

  useEffect(() => {
    enablePenaltyTypes && getTypes();
  }, [enablePenaltyTypes]);

  useEffect(() => {
    if (row) {
      setFormState(formState => ({
        ...formState,
        values: { ...row },
      }));
    }
    getCompanys();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
      },
    }));
  }, [row, institutionSelected]);

  const handleSubmit = event => {
    event.preventDefault();

    const { description, expectedDate, type } = formState.values;

    if (description === '' || expectedDate === '' || (!type && enablePenaltyTypes)) {
      toast.warn('Todos os campos são obrigatórios com exceção do link para acesso');
      return;
    }

    if (selectedIndex !== null) {
      let newArray = [...lectures];
      newArray[selectedIndex.index] = {
        penalty: PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES,
        ...formState.values,
      };
      setLectures(newArray);
    } else {
      setLectures([
        ...lectures,
        {
          penalty: PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES,
          ...formState.values,
        },
      ]);
    }

    handleClearFormStat();
  };

  const handleToRead = () => {
    handleClearFormStat();
  };

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} className={classes.containerForm}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {showType && !loadingTypes && enablePenaltyTypes && (
              <Autocomplete
                options={types}
                fullWidth
                autoHighlight
                onChange={handleChangeType}
                noOptionsText="Não encontrado"
                value={formState.values.type}
                getOptionLabel={option => option || ''}
                renderOption={option => <React.Fragment>{option || ''}</React.Fragment>}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Tipo*"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          </Grid>
          {showCompany && institutionType === 'final' && (
            <>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {!loadingAssociations ? (
                  <Autocomplete
                    options={companys}
                    fullWidth
                    autoHighlight
                    onChange={handleChangeCompany}
                    noOptionsText="Não encontrado"
                    value={formState.values.association}
                    getOptionLabel={option => option.name || ''}
                    renderOption={option => <React.Fragment>{option.name || ''}</React.Fragment>}
                    renderInput={params => (
                      <InputConsensus
                        {...params}
                        label="Instituição"
                        variant="standard"
                        inputProps={{
                          ...params.inputProps,
                        }}
                      />
                    )}
                  />
                ) : (
                  institutionType === 'final' && <Skeleton variant="rect" animation="pulse" />
                )}
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                {showCompany && institutionType === 'final' && formState.values.association?.id && (
                  <Autocomplete
                    value={formState.values.project}
                    getOptionSelected={(option, value) => option.name === value.name}
                    getOptionLabel={option => option.name}
                    filterOptions={x => x}
                    onInputChange={(_, newInputValue) => updateValueProject(newInputValue)}
                    fullWidth
                    noOptionsText="Nada foi encontrado"
                    loadingText="Buscando..."
                    loading={loadingProjects}
                    clearOnBlur={false}
                    options={listProjects}
                    onChange={handleChangeProject}
                    renderOption={option => <>{option.name}</>}
                    renderInput={params => (
                      <InputConsensus
                        {...params}
                        name="project"
                        label="Projeto"
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingProjects ? (
                                <CircularProgress color="primary" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                    ListboxProps={{
                      onScroll: handleScrollProjects,
                    }}
                  />
                )}
              </Grid>
            </>
          )}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <KeyboardDatePicker
              variant="inline"
              name="expectedDate"
              label="Data prevista para cumprimento *"
              value={formState.values.expectedDate || null}
              minDate={!selectedIndex ? new Date() : undefined}
              onChange={newDate =>
                setFormState(formState => ({
                  ...formState,
                  values: {
                    ...formState.values,
                    expectedDate: newDate,
                  },
                }))
              }
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <InputConsensus
              fullWidth
              label="Link para acesso"
              name="link"
              onChange={handleChange}
              value={formState.values.link || ''}
            />
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box style={{ marginTop: 5, marginBottom: 5 }}>
              <Typography variant="h5">Descrever detalhes:</Typography>
            </Box>
            <TextareaAutosize
              style={{ width: '100%', padding: 5, resize: 'none' }}
              maxRows={4}
              minRows={4}
              aria-label="maximum height"
              placeholder="Descrever como será cumprido *"
              onChange={handleChange}
              name="description"
              value={formState.values.description || ''}
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Alterar' : 'Incluir'} ${
                isMPDFT ? 'Medida' : 'Penalidade'
              }`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              type="submit"
              size="medium"
            />
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Cancelar' : 'Limpar'}`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              onClick={() => handleToRead()}
              size="medium"
              style={{ marginLeft: '-6px' }}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default SocioeducationalLectures;

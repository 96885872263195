import * as actionTypes from 'actions';

const initialState = {
  page: 1,
  search: '',
  category: '', //INVESTIGATIVE || JUDICIARY
};

const registerOrganReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGINATION_REGISTER_ORGAN: {
      return { ...action.payload };
    }

    default:
      return state;
  }
};

export default registerOrganReducer;

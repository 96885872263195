import * as actionTypes from 'actions';

const initialState = {
  boundInquiryId: null,
  boundInquiryIdInModalLinking: null,
};

const linkingInquiryReducert = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LINKING_INQUIRY_ID: {
      return {
        ...state,
        boundInquiryId: action.payload,
      };
    }

    case actionTypes.CLEAR_LINKING_INQUIRY_ID: {
      return {
        ...state,
        boundInquiryId: null,
      };
    }

    case actionTypes.SET_LINKING_INQUIRY_ID_IN_MODAL_LINKING: {
      return {
        ...state,
        boundInquiryIdInModalLinking: action.payload,
      };
    }

    case actionTypes.CLEAR_LINKING_INQUIRY_ID_IN_MODAL_LINKING: {
      return {
        ...state,
        boundInquiryIdInModalLinking: null,
      };
    }

    default:
      break;
  }

  return state;
};

export default linkingInquiryReducert;

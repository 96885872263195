import React from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Pagination from '@material-ui/lab/Pagination';
import { changeNameStep } from 'utils/changeNameStep';
import { redirectToInquiryIdStep } from 'utils/genericFunctions/redirectToInquiryIdStep';
import { SiMicrosoftexcel } from 'react-icons/si';
import InquiryService from 'services/inquiries/inquiryService';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '45vh',
    [theme.breakpoints.down('sm')]: {
      height: '50vh',
    },
    padding: theme.spacing(1),
  },
  table: {
    width: '100%',
  },
  container: {
    height: '27vh',
    [theme.breakpoints.down('sm')]: {
      height: '35vh',
    },
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  paginateText: {
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      flex: 3,
    },
    [theme.breakpoints.down('lg')]: {
      flex: 2,
    },
  },
  containerPaginate: {
    flex: 11,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '-7%',
    [theme.breakpoints.down('xs')]: {
      flex: 9,
      marginLeft: '-20%',
    },
  },
}));

const InquiryList = props => {
  const { result, page, setPage, totalPage, totalItens, showPaginate, filters } = props;

  const customerConfig = useSelector(state => state.customerConfig);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const generateChartsExcel = () => {
    delete filters.page;
    delete filters.size;
    const params = {
      labels: [],
      values: [],
      inquiryReportFilters: {
        page: undefined,
        size: undefined,
        ...filters,
      },
      chartType: 'table',
      chartTitle: 'Inquéritos',
    };
    InquiryService.getReportsChartsExcelService(params).then(response => {
      window.location.href = response.data;
    });
  };

  return (
    <Card className={classes.root}>
      <Box px={2} display="flex">
        <Tooltip title="Arquivo Excel (Gráficos)" arrow placement="left">
          <IconButton onClick={generateChartsExcel}>
            <SiMicrosoftexcel />
          </IconButton>
        </Tooltip>
      </Box>
      <Typography variant="h6" style={{ textAlign: 'center', marginBottom: 5 }}>
        Inquéritos
      </Typography>
      <Paper elevation={2}>
        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">N° inquérito</TableCell>
                <TableCell align="center">Investigado</TableCell>
                <TableCell align="center">Fase</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {result.map((item, index) => (
                <TableRow
                  key={index}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => redirectToInquiryIdStep(item)}
                >
                  <TableCell component="th" scope="row" align="center">
                    <Typography variant="body2">{item.policeInquiryNumber}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Typography variant="body2">{item.investigatedName}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Typography variant="body2">
                      {changeNameStep(item.step, customerConfig)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {showPaginate && (
          <Box className={classes.pagination}>
            <Box className={classes.paginateText}>
              <Typography variant="body2">
                {totalPage === page
                  ? `${totalItens} de ${totalItens}`
                  : `${filters.page * filters.size} de ${totalItens}`}
              </Typography>
            </Box>
            <Box className={classes.containerPaginate}>
              <Pagination size="small" count={totalPage} page={page} onChange={handleChangePage} />
            </Box>
          </Box>
        )}
      </Paper>
    </Card>
  );
};

export default InquiryList;

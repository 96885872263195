import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  Box,
  TableHead,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { changeNameStep } from 'utils/changeNameStep';
import { redirectToInquiryIdStep } from 'utils/genericFunctions/redirectToInquiryIdStep';
import ButtonConsensus from 'components/ButtonConsensus';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
    minWidth: 700,
  },
  container: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const RedistributionFailed = props => {
  const { data, forceRedistributedState } = props;
  const classes = useStyles();
  const customerConfig = useSelector(state => state.customerConfig);

  return (
    <Box>
      <Grid container spacing={1} component={Box} display="flex" mb={1} alignItems="center">
        <Grid item xl={8} lg={6} md={6} sm={12} xs={12}>
          <Typography variant="h5">
            Os inquéritos abaixo estão apresentando algum problema.
          </Typography>
        </Grid>
        <Grid
          item
          xl={4}
          lg={6}
          md={6}
          sm={12}
          xs={12}
          component={Box}
          display="flex"
          justifyContent="flex-end"
        >
          <ButtonConsensus
            variant="outlined"
            title="Redistribuir mesmo assim"
            size="medium"
            margin={0}
            onClick={() => forceRedistributedState.setForceRedistributed(true)}
          />
        </Grid>
      </Grid>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Investigado</TableCell>
              <TableCell align="center">N° inquérito</TableCell>
              <TableCell align="center">Fase</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item, index) => (
              <Tooltip key={index} arrow placement="top" title="Ir ao card">
                <TableRow
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => redirectToInquiryIdStep(item)}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="body2">{item.investigated?.name || '-'}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Typography variant="body2">{item.inquiry?.policeInquiryNumber}</Typography>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <Typography variant="body2">
                      {changeNameStep(item.step, customerConfig)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Tooltip>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RedistributionFailed;

/* eslint-disable react/display-name */
import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { ListItem, Button, Collapse, Typography } from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import history from 'utils/history';

const useStyles = makeStyles(theme => ({
  item: {
    color: theme.palette.white,
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.menu,
    padding: '8px 16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.menu,
    fontSize: 13,
    padding: '8px 16px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: 'normal !important',
  },
  textButton: {
    color: theme.palette.text.menu,
  },
  icon: {
    color: theme.palette.text.menu,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    height: 18,
  },
  expandIcon: {
    marginLeft: 'auto',
    height: 20,
    width: 20,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  active: {
    color: theme.palette.white,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.white,
    },
  },
  activebg: {
    backgroundColor: '#024463',
  },
  bg: {
    backgroundColor: 'transparent',
  },
}));

const NavigationListItem = props => {
  const {
    title,
    href,
    children,
    icon: Icon,
    className,
    open: openProp,
    label: Label,
    ...rest
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen(open => !open);
  };

  const goToRouter = () => {
    if (href === '/triagem-nao-persecucao') {
      history.push(href, { statusInquiries: '' });
    } else if (href === '/pesquisa-e-distribuicao') {
      history.push(href, { statusInquiries: '' });
    } else if (href === '/analise') {
      history.push(href, { statusInquiries: '' });
    } else if (href === '/tramitacao-e-controle') {
      history.push(href, { statusInquiries: '' });
    } else if (href === '/intimacao') {
      history.push(href, { statusInquiries: '' });
    } else if (href === '/acordo') {
      history.push(href, { statusInquiries: '' });
    } else if (href === '/termo-de-encaminhamento') {
      history.push(href, { statusInquiries: '' });
    } else if (href === '/visualizar-audiencias') {
      history.push(href, { statusInquiries: '' });
    } else {
      history.push(href);
    }
  };

  if (children) {
    return (
      <ListItem {...rest} className={clsx(classes.item, className)} disableGutters>
        <Button className={classes.button} onClick={handleToggle}>
          {Icon && <Icon className={classes.icon} />}
          <Typography className={classes.textButton}>{title}</Typography>
          {open ? (
            <ExpandMore className={classes.expandIcon} color="inherit" />
          ) : (
            <KeyboardArrowRightIcon className={classes.expandIcon} color="inherit" />
          )}
        </Button>
        <Collapse in={open} className={open ? classes.activebg : classes.bg}>
          {children}
        </Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem {...rest} className={clsx(classes.itemLeaf, className)} disableGutters>
        <Button className={classes.buttonLeaf} onClick={goToRouter}>
          {Icon && <Icon className={classes.icon} />}
          <Typography className={classes.textButton}>{title}</Typography>
          {Label && (
            <span className={classes.label}>
              <Label />
            </span>
          )}
        </Button>
      </ListItem>
    );
  }
};

NavigationListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  label: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  havePermission: PropTypes.bool.isRequired,
};

NavigationListItem.defaultProps = {
  depth: 0,
  open: false,
};

export default NavigationListItem;

export const SET_LIFETIME_FILTERS = 'SET_LIFETIME_FILTERS';
export const LOAD_OLD_LIFETIME_RESULTS = 'LOAD_OLD_LIFETIME_RESULTS';

export const setLifetimeFilters = filters => ({
  type: SET_LIFETIME_FILTERS,
  payload: filters,
});

export const loadOldLifetimeResults = loadOldResults => ({
  type: LOAD_OLD_LIFETIME_RESULTS,
  payload: loadOldResults,
});

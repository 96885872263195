import * as actionTypes from 'actions';

const initialState = {
  inquiryId: null,
  inquiryForm: {},
  saveInvestigated: false,
  investigated: [],
  clearForm: false,
  status: '',
  responsible: null,
  requestIntimations: false,
};

const inquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INQUIRY_ID: {
      return {
        ...state,
        inquiryId: action.payload,
      };
    }

    case actionTypes.CLEAR_INQUIRY_ID: {
      return {
        ...state,
        inquiryId: null,
      };
    }

    case actionTypes.INQUIRY_FORM: {
      return {
        ...state,
        inquiryForm: action.payload,
      };
    }

    case actionTypes.CLEAR_INQUIRY_FORM: {
      return {
        ...state,
        clearForm: action.payload,
      };
    }

    case actionTypes.SAVE_INVESTIGATED: {
      return {
        ...state,
        saveInvestigated: action.payload,
      };
    }

    case actionTypes.ARRAY_INVESTIGATED: {
      return {
        ...state,
        investigated: action.payload,
      };
    }

    case actionTypes.SET_STATUS_INQUIRY: {
      return {
        ...state,
        status: action.payload,
      };
    }

    case actionTypes.SET_RESPONSIBLE_INQUIRY: {
      return {
        ...state,
        responsible: action.payload,
      };
    }

    case actionTypes.SET_REQUEST_INTIMATIONS: {
      return {
        ...state,
        requestIntimations: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default inquiryReducer;

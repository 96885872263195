import React, { useState } from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '2px solid #000',
    },
    '& .MuiInput-input': {
      color: '#000',
      fontSize: 14,
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& label.MuiFormLabel-root': {
      color: '#000',
      fontSize: 12,
    },
    '& .Mui-error': {
      fontSize: 10,
      color: 'red',
    },
  },
  confirmButton: {
    marginTop: '10px',
    width: '100%',
  },
});

const ComboBoxMultiSelect = props => {
  const { options = [], label, selectedIds = [], onChange, disabled = false } = props;
  const classes = useStyles();
  const [selected, setSelected] = useState(selectedIds);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleConfirm = () => {
    onChange(selected);
    setOpen(false);
  };

  const handleToggle = selectedId => () => {
    const currentIndex = selected.indexOf(selectedId);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(selectedId);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    setSelected(newSelected);
  };

  return (
    <FormControl className={classes.root} fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        disabled={disabled}
        value={selected}
        onChange={handleToggle}
        open={open}
        onOpen={handleOpen}
        renderValue={selected =>
          selected.map(value => options.find(option => option.id === value).name).join(', ')
        }
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
        }}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox
              checked={selected.indexOf(option.id) !== -1}
              onClick={handleToggle(option.id)}
            />
            {option.name}
          </MenuItem>
        ))}
        {options.length && (
          <div style={{ textAlign: 'center', padding: '10px' }}>
            <Button
              className={classes.confirmButton}
              onClick={handleConfirm}
              variant="contained"
              color="primary"
            >
              Confirmar
            </Button>
          </div>
        )}
      </Select>
    </FormControl>
  );
};

export default ComboBoxMultiSelect;

import React, { useState, useEffect, useCallback } from 'react';
import {
  Typography,
  Grid,
  Card,
  IconButton,
  Box,
  Tooltip,
  Chip,
  CircularProgress,
  MenuItem,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { setCrimeInquiryReducer } from '../../../../actions';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { ButtonConsensus, ComboBoxMultiSelect, InputConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { Autocomplete } from '@material-ui/lab';
import debounce from 'lodash.debounce';
import crimeService from 'services/crime/crimeService';
import { toast } from 'react-toastify';
import lawService from 'services/law/lawService';
import EditIcon from '@material-ui/icons/Edit';
import { isInfraction } from 'utils/isInfraction';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const CrimesPendingInquiry = props => {
  const { crimes = [], undefinedCrimes = [], write, inquiryClassCode } = props;

  const useStyles = makeStyles(theme => ({
    root: {
      //padding: 10
    },
    titulo: {
      color: '#00597b',
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: 20,
      fontFamily: 'roboto-regular',
    },
    containerLabelInputCrime: {
      padding: 5,
    },
    containerLabelInput: {
      padding: 5,
    },
    selectForm: {
      height: 25,
      outline: 0,
      width: '100%',
      border: 0,
      borderBottom: `1px solid #000`,
      backgroundColor: '#ededed',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    form: {
      heigth: '100%',
      width: '100%',
      padding: '0 10px 0 10px',
    },
    labelForm: {
      display: 'flex',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontSize: 11,
    },
    labelFormCrime: {
      display: 'flex',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontSize: 11,
      color: '#00597b',
    },
    inputForm: {
      display: 'block',
      height: 25,
      outline: 0,
      padding: 5,
      width: '100%',
      border: 0,
      borderBottom: `1px solid #000`,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    inputFormCrime: {
      display: 'block',
      height: 20,
      outline: 0,
      width: '100%',
      border: 0,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 'bold',
      color: '#00597b',
      textAlign: 'left',
    },
    inputFormCrimeEdit: {
      display: 'block',
      height: 20,
      outline: 0,
      width: '100%',
      border: 0,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 'bold',
      color: '#00597b',
      borderBottom: '1px solid #000',
    },
    inputMaterial: {
      width: '100%',
    },
    table: {
      width: '100%',
    },
    containerForm: {
      //padding: 15
    },
    textCrime: {
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: 13,
      fontFamily: 'roboto-regular',
    },
    autoContainer: {
      marginTop: -240,
      '@media (max-width:600px)': {
        marginTop: -340,
        Height: 130,
        maxHeight: 300,
        minHeight: 300,
        maxWidth: '100%',
        minWidth: '100%',
        position: 'relative',
      },
      maxHeight: 200,
      minHeight: 200,
      //maxWidth: 300,
      minWidth: 300,
      position: 'relative',
      zIndex: 1,
      //Height: 130,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 1,
      },
      backgroundColor: '#ededed',
      borderRadius: 10,
      padding: 5,
      border: '1px solid #292929',
    },
    containerCrime: {
      position: 'relative',
      '@media (max-width:600px)': {
        maxHeight: '100%',
        minHeight: '100%',
        maxWidth: '100%',
        minWidth: '100%',
      },
      maxHeight: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
    optionCrime: {
      padding: 5,
      borderRadius: 2,
      borderBottom: '1px solid #999',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#b9b9b9',
      },
    },
    itemOptionCrime: {
      cursor: 'pointer',
    },
    addCrimeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 5,
      //width: '98.5%',
      minHeight: 80,
      maxHeight: 160,
      overflow: 'auto',
      //backgroundColor: '#f7f7f7',
      border: '1px solid #999',
      borderRadius: 5,
      marginTop: 5,
    },
    textoCrimeContainer: {
      color: '#00b1ac',
      fontWeight: 'bold',
      borderBottom: '1px solid #999',
      paddingBottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 40,
      textAlign: 'justify',
    },
    containerCrimeGrid: {
      paddingRight: 20,
      paddingLeft: 10,
      marginTop: 5,
    },
    cardCrime: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      minHeight: 40,
      overflowX: 'auto',
      float: 'left',
      '@media (max-width:600px)': {
        float: 'none',
      },
      marginBottom: 10,
    },
    chip: {
      margin: 2,
      backgroundColor: '#00b1ac',
      color: '#ffffff',
    },
    containerTypification: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'start',
      padding: theme.spacing(0, 1, 0, 1.5),
    },
    containerRaw: {
      display: 'flex',
      //flexDirection: 'column',
      justifyContent: 'start',
      alignItems: 'center',
      flexWrap: 'wrap',
      //padding: theme.spacing(0, 1, 0, 1.5)
    },
    titleTypification: {
      color: colors.navBar,
      fontWeight: 'bold',
      fontSize: 13,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

  const classes = useStyles();
  const dispatch = useDispatch();

  const [inputSearchCrime, setInputSearchCrime] = useState('');
  const [loadingCrimes, setLoadingCrimes] = useState(false);
  const [pageCrimes, setPageCrimes] = useState(1);
  const [lastPageCrimes, setLastPageCrimes] = useState(1);
  const [keyCrime, setKeyCrime] = useState(false);
  const [selectedCrime, setSelectedCrime] = useState(null);
  const [subtypeOptions, setSubtypeOptions] = useState([]);
  const [subtypesSelected, setSubtypesSelected] = useState([]);
  const [editingSubtypes, setEditingSubtypes] = useState(false);

  const [inputSearchLaw, setInputSearchLaw] = useState('');
  const [loadingLaws, setLoadingLaws] = useState(false);
  const [pageLaws, setPageLaws] = useState(1);
  const [lastPageLaws, setLastPageLaws] = useState(1);

  const [manipulateCrimes, setManipulateCrimes] = useState([]);
  const [crimesCometidos, setCrimesCometidos] = useState([]);
  const [editCrime, setEditCrime] = useState(false);
  const [raw, setRaw] = useState([]);

  const [selectedLaw, setSelectedLaw] = useState(null);
  const [optionsLaw, setOptionsLaw] = useState([]);

  const initialValues = {
    crimes: [],
  };

  const [formState, setFormState] = useState({
    isValid: false,
    investigated: [],
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setManipulateCrimes([...crimes]);

    let newRaw = [];

    crimes.forEach(crime => {
      if (crime.raw) {
        newRaw.push(crime.raw);
      }
    });

    setRaw(newRaw);
  }, [crimes]);

  const handleEditeCrime = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    setSelectedLaw(null);
    setEditCrime(true);
    debouncedGetLaws('');
    debouncedGetCrimes('');
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimes: [...manipulateCrimes],
      },
    }));
  };

  const handleUpdateCrime = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    setManipulateCrimes(formState.values.crimes);
    dispatch(setCrimeInquiryReducer(formState.values.crimes));
    setEditCrime(false);
  };

  const handleEditeCrimeToFalse = () => {
    setEditCrime(false);
    setSelectedCrime(null);
  };

  const handleRemoveCrime = (event, crimeId) => {
    event.persist();
    let newListCrimes = formState.values.crimes.filter(crime => crime.id !== crimeId);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimes: newListCrimes,
      },
    }));
  };

  const getCrimes = (search = '') => {
    setLoadingCrimes(true);

    setPageCrimes(1);

    let params = {
      page: 1,
      size: 20,
      search,
    };

    if (selectedLaw) params.lawId = selectedLaw?.id;

    crimeService
      .getCrimesService(params)
      .then(response => {
        setCrimesCometidos(response.data.data);
        setLastPageCrimes(response.data.lastPage);
      })
      .finally(() => setLoadingCrimes(false));
  };

  const getLaws = (search = '') => {
    setLoadingLaws(true);

    setPageLaws(1);

    let params = {
      page: 1,
      size: 20,
      search,
    };

    lawService
      .getLawService(params)
      .then(response => {
        setOptionsLaw(response.data.data);
        setLastPageLaws(response.data.lastPage);
      })
      .finally(() => setLoadingLaws(false));
  };

  const loadMoreLawsResults = () => {
    const nextPage = pageLaws + 1;

    setPageLaws(nextPage);

    setLoadingLaws(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchLaw,
    };

    lawService
      .getLawService(params)
      .then(response => {
        setOptionsLaw([...optionsLaw, ...response.data.data]);
        setLastPageLaws(response.data.lastPage);
      })
      .finally(() => setLoadingLaws(false));
  };

  const loadMoreCrimesResults = () => {
    const nextPage = pageCrimes + 1;

    setPageCrimes(nextPage);

    setLoadingCrimes(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchCrime,
    };

    if (selectedLaw) params.lawId = selectedLaw?.id;

    crimeService
      .getCrimesService(params)
      .then(response => {
        setCrimesCometidos([...crimesCometidos, ...response.data.data]);
        setLastPageCrimes(response.data.lastPage);
      })
      .finally(() => setLoadingCrimes(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetLaws = useCallback(
    debounce(newValue => getLaws(newValue), 500),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetCrimes = useCallback(
    debounce(newValue => getCrimes(newValue), 500),
    [],
  );

  const updateValueLaws = newValue => {
    if (!optionsLaw.find(law => law.name === newValue)) {
      debouncedGetLaws(newValue);
      setInputSearchLaw(newValue);
    }
  };

  const updateValueCrimes = newValue => {
    if (!crimesCometidos.find(crime => crime.taxonomy === newValue)) {
      debouncedGetCrimes(newValue);
      setInputSearchCrime(newValue);
    }
  };

  const handleScrollLaw = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && pageLaws < lastPageLaws && !loadingLaws) {
      loadMoreLawsResults();
    }
  };

  const handleScrollCrimes = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && pageCrimes < lastPageCrimes && !loadingCrimes) {
      loadMoreCrimesResults();
    }
  };

  const handleChangeLaw = (event, newValue) => {
    setSelectedLaw(newValue);
  };

  const handleChangeCrimeAutocomplete = (event, newValue) => {
    setSelectedCrime(newValue);
  };

  const handleAddCrime = () => {
    const newValue = selectedCrime;
    const crimeExists = formState.values.crimes.some(crime => crime.id === newValue.id);

    if (crimeExists) {
      return toast.warn('O crime selecionado já existe na lista');
    }

    if (subtypesSelected?.length) {
      const subtypes = subtypesSelected.map(sub => subtypeOptions.find(item => item.id === sub));
      newValue.subtypesSelected = subtypes;
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimes: [...formState.values.crimes, newValue],
      },
    }));
    setKeyCrime(!keyCrime);
  };

  const handleEditSubtype = crime => {
    setSubtypesSelected(crime.subtypesSelected);
    setSelectedCrime(crime);
    setEditingSubtypes(true);
  };

  const handleChangeCrime = () => {
    if (!selectedCrime) {
      return toast.warn('Selecione um crime para ser adicionado');
    }
    if (!editingSubtypes) {
      handleAddCrime();
    } else {
      changeSubtypesSelected();
    }
    setSelectedCrime(null);
    setSubtypesSelected([]);
    setEditingSubtypes(false);
  };

  const handleChangeSubtype = newValue => {
    setSubtypesSelected(newValue);
  };

  const changeSubtypesSelected = () => {
    const { crimes } = formState.values;

    const index = crimes.findIndex(crime => crime.id === selectedCrime.id);
    const subtypes = subtypesSelected?.map(sub => {
      return subtypeOptions.find(subtype => subtype.id === sub.id || subtype.id === sub);
    });

    if (index !== -1) {
      const updatedCrimes = [...crimes];

      updatedCrimes[index] = {
        ...updatedCrimes[index],
        subtypesSelected: subtypes || [],
      };

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          crimes: updatedCrimes,
        },
      }));
    }

    setSelectedCrime({ ...selectedCrime, subtypesSelected: subtypes });
  };

  useEffect(() => {
    setSubtypeOptions(selectedCrime?.subtypes);
  }, [selectedCrime]);

  const Typification = () => {
    return (
      <Box className={classes.containerTypification}>
        {raw.length > 0 ? (
          <Box className={classes.containerRaw}>
            <Typography variant="h6" className={classes.titleTypification}>
              Tipificação:
            </Typography>
            {raw.map((item, index) => (
              <Chip
                key={index}
                variant="outlined"
                size="small"
                label={item.typification}
                style={{ padding: 2, margin: 5 }}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="h6" className={classes.titleTypification}>
            Tipificação não informada
          </Typography>
        )}
      </Box>
    );
  };

  const UndefinedCrimeComponent = () => {
    return (
      <Box className={classes.containerTypification}>
        {undefinedCrimes.length > 0 && (
          <Box className={classes.containerRaw}>
            <Typography variant="h6" className={classes.titleTypification}>
              {undefinedCrimes.length > 1
                ? 'Tipificações do crime indefinido:'
                : 'Tipificação do crime indefinido:'}
            </Typography>
            {undefinedCrimes.map((item, index) => (
              <Chip
                key={index}
                variant="outlined"
                size="small"
                label={item.typification}
                style={{ padding: 2, margin: 5 }}
              />
            ))}
          </Box>
        )}
      </Box>
    );
  };

  useEffect(() => {
    getCrimes(inputSearchCrime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLaw]);

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={1.5}>
        <Box>
          <Typography className={classes.titulo}>
            {isInfraction(inquiryClassCode) ? 'Atos Infracionais' : 'Crimes'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {!editCrime ? (
            <Tooltip title="Alterar crimes" arrow placement="top">
              <IconButton className={classes.buttonEditar} size="small" onClick={handleEditeCrime}>
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Box style={{ marginRight: 10 }}>
                <Tooltip title="Salvar alterações" arrow placement="top">
                  <IconButton
                    className={classes.buttonEditar}
                    size="small"
                    onClick={handleUpdateCrime}
                    form="my-form-edit"
                    type="submit"
                  >
                    <SaveIcon style={{ color: '#1976d2' }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Cancelar" arrow placement="top">
                  <IconButton
                    className={classes.buttonEditar}
                    size="small"
                    onClick={handleEditeCrimeToFalse}
                  >
                    <ClearIcon style={{ color: '#e1296a' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <UndefinedCrimeComponent />
      <Typification />
      {!editCrime && (
        <Grid container spacing={1} className={classes.containerCrimeGrid}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Card className={classes.cardCrime}>
              {manipulateCrimes.length > 0 ? (
                manipulateCrimes.map(crime => (
                  <Chip
                    key={crime.id}
                    className={classes.chip}
                    size="small"
                    label={crime.taxonomy}
                  />
                ))
              ) : (
                <Chip className={classes.chip} size="small" label="Não informado" />
              )}
            </Card>
          </Grid>
        </Grid>
      )}
      {editCrime && (
        <Grid container spacing={1}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.containerLabelInput}>
              <Autocomplete
                onInputChange={(_, newInputValue) => updateValueLaws(newInputValue)}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={option => option.name}
                filterOptions={x => x}
                value={selectedLaw}
                fullWidth
                noOptionsText="Nada foi encontrado"
                loadingText="Buscando..."
                loading={loadingLaws}
                clearOnBlur={false}
                options={optionsLaw}
                onChange={handleChangeLaw}
                renderOption={option => (
                  <>
                    {option.name} - {option.number}
                  </>
                )}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Lei"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingLaws ? <CircularProgress color="primary" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: handleScrollLaw,
                }}
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.containerLabelInput}>
              <Autocomplete
                //value={null}
                key={keyCrime}
                onInputChange={(_, newInputValue) => updateValueCrimes(newInputValue)}
                getOptionSelected={(option, value) => option.taxonomy === value.taxonomy}
                getOptionLabel={option => option.taxonomy}
                filterOptions={x => x}
                fullWidth
                noOptionsText="Nada foi encontrado"
                loadingText="Buscando..."
                loading={loadingCrimes}
                clearOnBlur={false}
                options={crimesCometidos}
                onChange={handleChangeCrimeAutocomplete}
                value={selectedCrime}
                            disabled={editingSubtypes}
                renderOption={option => (
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography variant="body1">
                          {option.taxonomy && `Nome ${option.taxonomy || 'não informado'}`}
                        </Typography>
                        <Typography variant="body1">
                          {option.lawName && `Lei ${option.lawName || 'não informada'}`}
                        </Typography>
                        <Typography variant="body1">
                          {option.lawNumber && `Lei n° ${option.lawNumber || 'não informado'}`}
                        </Typography>
                        <Typography variant="body1">
                          {option.article && `Artigo ${option.article || 'não informado'}`}
                        </Typography>
                        <Typography variant="body1">
                          {option.paragraph &&
                            `Parágrafo n° ${option.paragraph || 'não informado'}`}
                        </Typography>
                        <Typography variant="body1">
                          {option.item && `Item ${option.item || 'não informado'}`}
                        </Typography>
                        <Typography variant="body1">
                          {option.point && `Ponto ${option.point || 'não informado'}`}
                        </Typography>
                        <Typography variant="body1">
                          {option?.subject?.hierarchy &&
                            `Hierarquia ${option.lawNumber || 'não informada'}`}
                        </Typography>
                      </React.Fragment>
                    }
                  >
                    <Typography>
                      {option.taxonomy} - Lei:{' '}
                      {option.lawNumber ? option.lawNumber : 'não informada'} - Artigo:{' '}
                      {option.article ? option.article : 'não informado'}{' '}
                      {option.paragraph ? '-' : ''} {option.paragraph ? option.paragraph : ''}{' '}
                      {option.item ? '-' : ''} {option.item ? option.item : ''}{' '}
                      {option.point ? '-' : ''} {option.point ? option.point : ''}
                    </Typography>
                  </HtmlTooltip>
                )}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label={`${
                      isInfraction(inquiryClassCode) ? 'Ato Infracional' : 'Crime'
                    } cometido *`}
                    fullWidth
                    onClick={() => {
                      setSubtypeOptions(null);
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingCrimes ? <CircularProgress color="primary" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: handleScrollCrimes,
                }}
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            {subtypeOptions?.length > 0 && (
              <div className={classes.containerLabelInput}>
                <ComboBoxMultiSelect
                  options={subtypeOptions}
                  label="Selecione os subtipos"
                  selectedIds={subtypesSelected?.map(subtype => subtype.id)}
                  onChange={handleChangeSubtype}
                />
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              paddingRight: 15,
            }}
          >
            <ButtonConsensus
              title={editingSubtypes ? 'Alterar' : 'Adicionar'}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              size="small"
              onClick={handleChangeCrime}
            />
          </Grid>
          <Grid item xs={12} sm={12} style={{ paddingLeft: 5 }}>
            <div className={classes.addCrimeContainer}>
              {isInfraction(inquiryClassCode) ? 'Ato(s) Infracional(is)' : 'Crime(s)'}:
              {formState.values.crimes.map(crime => (
                <div key={crime.id} className={classes.textoCrimeContainer}>
                  <Typography>
                    {crime.taxonomy} - Lei: {crime.lawNumber ? crime.lawNumber : 'não informada'} -
                    Artigo: {crime.article ? crime.article : 'não informado'}{' '}
                    {crime.paragraph ? '-' : ''} {crime.paragraph ? crime.paragraph : ''}{' '}
                    {crime.item ? '-' : ''} {crime.item ? crime.item : ''} {crime.point ? '-' : ''}{' '}
                    {crime.point ? crime.point : ''}{' '}
                    {crime.subtypesSelected?.length
                      ? `- Subtipos: ${crime.subtypesSelected.map(obj => obj.name).join(', ')}`
                      : ''}
                  </Typography>
                  <div className={classes.iconContainer}>
                    {crime.subtypes?.length > 0 && (
                      <EditIcon
                        fontSize="small"
                        onClick={() => handleEditSubtype(crime)}
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                    <DeleteIcon
                      fontSize="small"
                      onClick={event => handleRemoveCrime(event, crime.id)}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CrimesPendingInquiry;

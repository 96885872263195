import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  DialogContent,
  MenuItem,
  TextField,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { HeaderModal, ProsecutorOfficeAndProsecutorForm } from 'components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { changeNameStep } from 'utils/changeNameStep';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import ButtonConsensus from 'components/ButtonConsensus';
import agreementService from 'services/agreement/agreementService';
import hearingService from 'services/hearing/hearingService';
import InputMask from 'react-input-mask';
import { addHoursAndMinutesInDate } from 'utils/date-time.utils';
import { useSelector } from 'react-redux';
import { USER_TYPE } from 'constants/inquirys/userType';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    minHeight: 200,
    marginTop: theme.spacing(2),
  },
  action: {
    padding: theme.spacing(0, 3, 3, 0),
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 50,
    padding: theme.spacing(1.5),
  },
}));

export default function MaxWidthDialog(props) {
  const { open, handleClose, hearing, inquiryProsecutor, inquiryProsecutorOffice } = props;

  const customerConfig = useSelector(state => state.customerConfig);

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const OPTIONS_HEARING = {
    CANCEL_HEARING: `Cancelar audiência${enableHearingForJuvenileOffenders ? '/oitiva' : ''}`,
    CHANGE_DATE_HEARING: `Definir nova data para audiência${
      enableHearingForJuvenileOffenders ? '/oitiva' : ''
    } existente`,
    NEW_HEARING: `Marcar nova audiência${
      enableHearingForJuvenileOffenders ? '/oitiva' : ''
    } (intimar novamente)`,
  };

  const MenuOptions = [
    {
      name: OPTIONS_HEARING.CANCEL_HEARING,
      value: OPTIONS_HEARING.CANCEL_HEARING,
    },
    {
      name: OPTIONS_HEARING.CHANGE_DATE_HEARING,
      value: OPTIONS_HEARING.CHANGE_DATE_HEARING,
    },
    {
      name: OPTIONS_HEARING.NEW_HEARING,
      value: OPTIONS_HEARING.NEW_HEARING,
    },
  ];

  const classes = useStyles();

  const initialValues = {
    hearingDone: false,
    justification: '',
    optionSelected: '',
    newDateHearing: '',
    hourHearing: '',
  };

  const [formState, setFormState] = useState({
    values: { ...initialValues },
  });

  const [disabled, setDisabled] = useState(false);

  const groups = useSelector(state => state.logedUser.groups);
  const loggedUser = useSelector(state => state.logedUser);
  const { selectedIndexGroup } = useSelector(state => state.groupMenu);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const [agreementProsecutor, setProsecutor] = useState(inquiryProsecutor);
  const [agreementProsecutorOffice, setProsecutorOffice] = useState(inquiryProsecutorOffice);

  const handleChangeProsecutor = (_event, newValue) => {
    setProsecutor(newValue);
  };

  const handleChangeProsecutorOffice = (_event, newValue) => {
    setProsecutorOffice(newValue);
  };

  const handleSubmit = event => {
    event.preventDefault();

    const {
      hearingDone,
      justification,
      newDateHearing,
      optionSelected,
      hourHearing,
    } = formState.values;

    if (!optionSelected) {
      toast.warn(
        `Informe se deseja ou não remarcar a audiência${
          enableHearingForJuvenileOffenders ? '/oitiva' : ''
        }`,
      );
      return;
    }

    if (optionSelected === OPTIONS_HEARING.CHANGE_DATE_HEARING && !newDateHearing) {
      toast.warn(
        `Informe a nova data para a audiência${enableHearingForJuvenileOffenders ? '/oitiva' : ''}`,
      );
      return;
    }

    if (
      optionSelected === OPTIONS_HEARING.CHANGE_DATE_HEARING &&
      (!hourHearing || hourHearing.length !== 5)
    ) {
      toast.warn(
        `Informe o horário da audiência${enableHearingForJuvenileOffenders ? '/oitiva' : ''} 00:00`,
      );
      return;
    }

    if (!agreementProsecutor) {
      toast.warn('Informe um promotor');
      return;
    }

    if (!agreementProsecutorOffice) {
      toast.warn('Informe uma delegacia');
      return;
    }

    if (!justification) {
      toast.warn('Informe uma justificativa');
      return;
    }

    setDisabled(true);

    if (optionSelected === OPTIONS_HEARING.CHANGE_DATE_HEARING) {
      let hour = hourHearing.substring(0, 2);
      let min = hourHearing.substring(3, 5);

      hearingService
        .hearingChangeDateService(hearing.id, {
          date: addHoursAndMinutesInDate(newDateHearing, hour, min),
        })
        .then(() => {
          toast.success(
            `Data da audiência${
              enableHearingForJuvenileOffenders ? '/oitiva' : ''
            } alterada com sucesso!`,
          );
          setTimeout(() => {
            handleClose();
          }, 1000);
        })
        .finally(() => setDisabled(false));
    } else {
      agreementService
        .hearingCloseService(hearing.id, {
          hearingDone,
          scheduleNewHearing: optionSelected === OPTIONS_HEARING.NEW_HEARING,
          justification,
          prosecutor: agreementProsecutor,
          prosecutorOffice: agreementProsecutorOffice,
        })
        .then(response => {
          toast.success(
            `Inquérito enviado para a fase ${changeNameStep(response.data.step, customerConfig)}`,
          );
          setTimeout(() => {
            handleClose();
          }, 1000);
        })
        .finally(() => setDisabled(false));
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      <HeaderModal onClose={handleClose}>
        Audiência{enableHearingForJuvenileOffenders ? '/Oitiva' : ''} não realizada
      </HeaderModal>
      <DialogContent>
        <form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Paper elevation={1} className={classes.paper}>
                <Typography variant="body1">
                  Audiência{enableHearingForJuvenileOffenders ? '/Oitiva' : ''} selecionada:{' '}
                  <Moment format="DD/MM/YYYY" local>
                    {hearing.date}
                  </Moment>
                  {' às '}{' '}
                  <Moment format="HH:mm" local>
                    {hearing.date}
                  </Moment>
                </Typography>
              </Paper>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                select
                name="optionSelected"
                label={`Sobre esta audiência${enableHearingForJuvenileOffenders ? '/oitiva' : ''}`}
                value={formState.values.optionSelected}
                onChange={handleChange}
                variant="outlined"
                size="small"
              >
                {MenuOptions.map(item => (
                  <MenuItem key={item.name} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {formState.values.optionSelected === OPTIONS_HEARING.CHANGE_DATE_HEARING && (
              <>
                <Grid item xl={8} lg={8} md={8} sm={8} xs={12}>
                  <TextField
                    fullWidth
                    name="newDateHearing"
                    label={`Nova data da audiência${
                      enableHearingForJuvenileOffenders ? '/oitiva' : ''
                    }`}
                    value={formState.values.newDateHearing}
                    onChange={handleChange}
                    variant="outlined"
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                  />
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
                  <InputMask
                    onChange={handleChange}
                    value={formState.values.hourHearing || ''}
                    mask="99:99"
                    maskChar={null}
                  >
                    {inputProps => (
                      <TextField
                        {...inputProps}
                        label={`Hora da audiência${
                          enableHearingForJuvenileOffenders ? '/oitiva' : ''
                        }`}
                        name="hourHearing"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )}
                  </InputMask>
                </Grid>
              </>
            )}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <ProsecutorOfficeAndProsecutorForm
                prosecutor={agreementProsecutor}
                prosecutorOffice={agreementProsecutorOffice}
                onProsecutorChange={handleChangeProsecutor}
                onProsecutorOfficeChange={handleChangeProsecutorOffice}
                isProsecutor={loggedUser.type === USER_TYPE.Prosecutor}
                smaller={true}
                prosecutorOfficeGroup={groups[selectedIndexGroup]}
              />
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                multiline
                variant="outlined"
                fullWidth
                minRows={6}
                maxRows={10}
                label="Justificativa"
                name="justification"
                value={formState.values.justification}
                onChange={handleChange}
                size="small"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions className={classes.action}>
        <ButtonConsensus
          onClick={handleSubmit}
          color="#FFFFFF"
          variant="contained"
          disabled={disabled}
          title="Salvar"
        />
      </DialogActions>
    </Dialog>
  );
}

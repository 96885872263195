import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { ButtonConsensus, HeaderModal, InputConsensus } from 'components';
import { toast } from 'react-toastify';
import { colors } from 'utils/colorsSteps';
import { formatDateToISO8601, formatDateToISOString } from 'utils/date-time.utils';

const ModalChangeDeadline = props => {
  const { open, onClose, task, confirmUpdateDeadline, loading } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const initialValues = {
    date: formatDateToISO8601(task.task.date),
  };

  const [formState, setFormState] = useState({ ...initialValues });

  const handleConfirmTask = event => {
    event.preventDefault();

    if (!formState.date) {
      toast.warn('Informe uma data!');
      return;
    }

    let newTask = { ...task.task };
    newTask.date = formatDateToISOString(formState.date);
    confirmUpdateDeadline(newTask);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <HeaderModal onClose={onClose}>Alterar Prazo</HeaderModal>
        <DialogContent>
          <form onSubmit={handleConfirmTask}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <InputConsensus
                  label="Prazo"
                  name="date"
                  value={formState.date}
                  onChange={handleChange}
                  fullWidth
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Box display="flex" justifyContent="flex-end" mb={1}>
                  <ButtonConsensus
                    title="Confirmar"
                    backgroundColor={colors.primary}
                    hovercolor={colors.primary}
                    type="submit"
                    margin={0}
                    disabled={loading}
                  />
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalChangeDeadline;

import React from 'react';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import { CurrencyTextField, InputConsensus } from 'components';
import { getOnlyNumbers } from 'utils/currency';
import PjeJudClassSubAutoComplete from './components/PjeJudClassSubAutocomplete';
import InputMask from 'react-input-mask';
import { npuMask } from 'masks/masks';
import { applyMask } from 'utils/genericFunctions/applyMask';

const FormPreRegisterPJE = props => {
  const { formState, setFormState, handleChange, isInAnalysis, investigatedNPU } = props;

  const handleCauseValue = (event, value, maskedValue) => {
    event.persist();
    handleChange('causeValue', Number(getOnlyNumbers(maskedValue || event.target.value)) / 100);
  };

  return (
    <>
      <PjeJudClassSubAutoComplete
        formState={formState}
        setFormState={setFormState}
        isInAnalysis={isInAnalysis}
        handleChange={handleChange}
      />
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        {investigatedNPU ? (
          <InputConsensus value={applyMask(investigatedNPU, npuMask)} label="NPU" name="npu" fullWidth disabled />
        ) : (
          <InputMask
            value={formState.npu || ''}
            onChange={event => {
              handleChange('npu', event.target.value);
            }}
            mask={npuMask}
            maskChar={null}
          >
            {inputProps => <InputConsensus {...inputProps} label="Número de vínculo" name="npu" fullWidth />}
          </InputMask>
        )}
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <CurrencyTextField
          label="Valor da causa"
          name="causeValue"
          onChange={handleCauseValue}
          value={formState.causeValue || 0}
          fullWidth
          max={1000000000000}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
        <FormControlLabel
          style={{ marginRight: 0 }}
          control={
            <Checkbox
              checked={formState.freeJustice}
              onChange={(_, checked) => handleChange('freeJustice', checked)}
              name="freeJustice"
              color="primary"
              size="small"
            />
          }
          label={'Justiça gratuita'}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
        <FormControlLabel
          style={{ marginRight: 0 }}
          control={
            <Checkbox
              checked={formState.antecipationRequest}
              onChange={(_, checked) => handleChange('antecipationRequest', checked)}
              name="antecipationRequest"
              color="primary"
              size="small"
            />
          }
          label={'Pedido de liminar de antecipação de tutela'}
        />
      </Grid>
    </>
  );
};

export default FormPreRegisterPJE;

import React from 'react';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const ModalConfirm = props => {
  const { open, onClose, people, onClick } = props;

  const handleConfirm = () => {
    onClick();
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        //fullWidth={'sm'}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{people?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h5">
              Deseja excluir{' '}
              {people?.peopleType === 'victim' ? ' a vítima' : ' a pessoa investigada'}{' '}
              {people?.name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button onClick={onClose} size="small" variant="contained">
            Cancelar
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={() => handleConfirm()}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalConfirm;

import React from 'react';
import InputConsensus from 'components/InputConsensus';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import {
  IconButton,
  Tooltip,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  containerChip: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
}));

const AlternativeEmails = props => {
  const {
    alternativeEmails,
    editOrgan,
    showInputAlternativeEmails,
    handleChangeShowInputAlternativeEmails,
    handleRemoveAlternativeEmail,
    handleAddAlternativeEmail,
    handleChange,
    disabled,
    formState,
  } = props;

  const classes = useStyles();

  return (
    <Box>
      {!editOrgan ? (
        <form onSubmit={handleAddAlternativeEmail}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <InputConsensus
              onChange={handleChange}
              value={formState.values.alternativeEmails || ''}
              label="E-mail Alternativo"
              name="alternativeEmails"
              type="email"
              variant="standard"
              autoComplete="no"
              fullWidth
              disabled={disabled}
            />
            <Tooltip title="Adicionar e-mail alternativo" arrow placement="right">
              <IconButton color="primary" type="submit" aria-label="add email">
                <AddIcon style={{ cursor: 'pointer' }} />
              </IconButton>
            </Tooltip>
          </Box>
          <TableContainer component={Paper} style={{ marginTop: 10 }}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">E-mails alternativos</TableCell>
                  <TableCell align="center" width={150}>
                    <Box display="flex" position="relative" justifyContent="center">
                      Ações
                      {showInputAlternativeEmails && (
                        <Box position="absolute" right={0} top={-3}>
                          <IconButton size="small" onClick={handleChangeShowInputAlternativeEmails}>
                            <IndeterminateCheckBoxIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alternativeEmails.map((item, index) => (
                  <TableRow key={index} hover>
                    <TableCell component="th" scope="row" align="center">
                      {item}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleRemoveAlternativeEmail(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography align="left">E-mails alternativos</Typography>
            <Tooltip title="Alterar e-mails alternativos" arrow placement="right">
              <IconButton
                color="primary"
                size="small"
                onClick={handleChangeShowInputAlternativeEmails}
                disabled={disabled}
              >
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {!showInputAlternativeEmails &&
            (alternativeEmails.length > 0 ? (
              <Box className={classes.containerChip}>
                {alternativeEmails.map((alternativeEmail, i) => (
                  <Chip
                    label={alternativeEmail}
                    key={i}
                    color="primary"
                    size="small"
                    variant="outlined"
                  />
                ))}
              </Box>
            ) : (
              <Typography align="left" variant="h6" className={classes.form}>
                {'Sem e-mails alternativos cadastrados'}
              </Typography>
            ))}
        </>
      )}
    </Box>
  );
};

export default AlternativeEmails;

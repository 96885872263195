/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { InputConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import userService from 'services/user/userService';
import prosecutorOfficeService from 'services/prosecutor-office/prosecutorOfficeService';
import debounce from 'lodash.debounce';

const useStyles = makeStyles(() => ({
  prosecutorOfficeAndProsecutor: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 16,
  },
}));

const ProsecutorOfficeAndProsecutorForm = props => {
  const {
    edit = true,
    prosecutor,
    prosecutorOffice,
    onProsecutorChange,
    onProsecutorOfficeChange,
    prosecutorOfficeGroup,
    isProsecutor,
    smaller,
  } = props;

  const classes = useStyles();

  const [initialProsecutorOffice] = useState(prosecutorOffice);
  const [initialProsecutor] = useState(prosecutor);
  const [loadingProsecutors, setLoadingProsecutors] = useState(false);
  const [pageProsecutors, setPageProsecutors] = useState(1);
  const [lastPageProsecutors, setLastPageProsecutors] = useState(1);
  const [prosecutorOptions, setProsecutorOptions] = useState([]);

  const [loadingProsecutorOffices, setLoadingProsecutorOffices] = useState(false);
  const [inputSearchProsecutorOffices, setInputSearchProsecutorOffices] = useState('');
  const [pageProsecutorOffices, setPageProsecutorOffices] = useState(1);
  const [lastPageProsecutorOffices, setLastPageProsecutorOffices] = useState(1);
  const [prosecutorOfficeOptions, setProsecutorOfficeOptions] = useState([]);

  const getProsecutors = () => {
    setLoadingProsecutors(true);
    setPageProsecutors(1);

    const params = {
      page: 1,
      size: 20,
    };

    if (prosecutorOffice) {
      params.prosecutorOfficeId = prosecutorOffice.id;
    }

    userService
      .getProsecutorsService(params)
      .then(response => {
        if (initialProsecutorOffice && initialProsecutorOffice.id === prosecutorOffice?.id) {
          if (!response.data.data.find(obj => obj.id === initialProsecutor.id)) {
            response.data.data.push(initialProsecutor);
          }
        }

        setProsecutorOptions(response.data.data);
        setLastPageProsecutors(response.data.lastPage);
      })
      .finally(() => setLoadingProsecutors(false));
  };

  const loadMoreProsecutorResults = () => {
    const nextPage = pageProsecutors + 1;

    setPageProsecutors(nextPage);
    setLoadingProsecutors(true);

    const params = {
      page: nextPage,
      size: 20,
      prosecutorOfficeId: prosecutorOffice?.id,
    };

    userService
      .getProsecutorsService(params)
      .then(response => {
        setProsecutorOptions([...prosecutorOptions, ...response.data.data]);
        setLastPageProsecutors(response.data.lastPage);
      })
      .finally(() => setLoadingProsecutors(false));
  };

  const handleScrollProsecutors = event => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    const showLastItemPage = listboxNode.scrollHeight - position <= 1;
    const showAllProsecutors = pageProsecutors < lastPageProsecutors;

    if (showLastItemPage && showAllProsecutors && !loadingProsecutors) {
      loadMoreProsecutorResults();
    }
  };

  const handleChangeProsecutor = (_event, newValue) => {
    onProsecutorChange(_event, newValue);
  };

  const getProsecutorOffices = (input = '') => {
    setLoadingProsecutorOffices(true);
    setPageProsecutorOffices(1);

    const params = {
      page: 1,
      size: 20,
      prosecutorOfficeGroupId: prosecutorOfficeGroup?.id,
      name: input,
    };

    prosecutorOfficeService
      .getProsecutorOfficesService(params)
      .then(response => {
        if (!response.data.data.find(obj => obj.id === prosecutorOffice?.id)) {
          response.data.data.push(prosecutorOffice);
        }
        setProsecutorOfficeOptions(response.data.data);
        setLastPageProsecutorOffices(response.data.lastPage);
      })
      .finally(() => setLoadingProsecutorOffices(false));
  };

  const loadMoreProsecutorOfficeResults = () => {
    const nextPage = pageProsecutorOffices + 1;

    setPageProsecutorOffices(nextPage);
    setLoadingProsecutorOffices(true);

    const params = {
      page: nextPage,
      size: 20,
      prosecutorOfficeGroupId: prosecutorOfficeGroup?.id,
      name: inputSearchProsecutorOffices,
    };

    prosecutorOfficeService
      .getProsecutorOfficesService(params)
      .then(response => {
        setProsecutorOfficeOptions([...prosecutorOfficeOptions, ...response.data.data]);
        setLastPageProsecutorOffices(response.data.lastPage);
      })
      .finally(() => setLoadingProsecutorOffices(false));
  };

  const debouncedGetProsecutorOffices = useCallback(
    debounce(newValue => getProsecutorOffices(newValue), 500),
    [],
  );

  const updateValueProsecutorOffices = newValue => {
    const prosecutorOfficeOption = prosecutorOfficeOptions.find(
      prosecutorOffice => prosecutorOffice.name === newValue,
    );

    if (!prosecutorOfficeOption) {
      debouncedGetProsecutorOffices(newValue);
      setInputSearchProsecutorOffices(newValue);
    }
  };

  const handleScrollProsecutorOffices = event => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;
    const showLastItemPage = listboxNode.scrollHeight - position <= 1;
    const showAllProsecutorOffices = pageProsecutorOffices < lastPageProsecutorOffices;

    if (showLastItemPage && showAllProsecutorOffices && !loadingProsecutorOffices) {
      loadMoreProsecutorOfficeResults();
    }
  };

  const handleChangeProsecutorOffice = (_event, newValue) => {
    onProsecutorOfficeChange(_event, newValue);
    handleChangeProsecutor('', null);
  };

  useEffect(() => {
    if (!isProsecutor) {
      debouncedGetProsecutorOffices(inputSearchProsecutorOffices);
    }
  }, [isProsecutor]);

  useEffect(() => {
    if (prosecutorOffice) {
      getProsecutors();
    }
  }, [prosecutorOffice]);

  const gridSize = smaller ? 5 : 6;

  return (
    <>
      <Grid container spacing={2} classes={classes.prosecutorOfficeAndProsecutor}>
        <Grid
          item
          xs={gridSize * 2}
          sm={gridSize * 2}
          md={gridSize * 2}
          lg={gridSize * 2}
          xl={gridSize * 2}
        >
          <Box mt={3}>
            <Typography variant="h6">Responsável pela Movimentação:</Typography>
          </Box>
        </Grid>
        <Grid item xs={gridSize * 2} sm={gridSize} md={gridSize} lg={gridSize} xl={gridSize}>
          <Autocomplete
            value={prosecutorOffice}
            onInputChange={(_, newInputValue) => updateValueProsecutorOffices(newInputValue)}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            filterOptions={x => x}
            fullWidth
            noOptionsText="Nada foi encontrado"
            loadingText="Buscando..."
            loading={loadingProsecutorOffices}
            clearOnBlur={false}
            options={prosecutorOfficeOptions}
            onChange={handleChangeProsecutorOffice}
            renderOption={option => <>{option?.name}</>}
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Promotoria *"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingProsecutorOffices ? (
                        <CircularProgress color="primary" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: handleScrollProsecutorOffices,
            }}
            disabled={isProsecutor || !edit}
          />
        </Grid>

        <Grid item xs={gridSize * 2} sm={gridSize} md={gridSize} lg={gridSize} xl={gridSize}>
          <Autocomplete
            disabled={!prosecutorOffice || isProsecutor || !edit}
            value={prosecutor}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name}
            fullWidth
            noOptionsText="Nada foi encontrado"
            loadingText="Buscando..."
            loading={loadingProsecutors}
            clearOnBlur={false}
            options={prosecutorOptions}
            onChange={handleChangeProsecutor}
            renderOption={option => <>{option?.name}</>}
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Promotor *"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingProsecutors ? <CircularProgress color="primary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: handleScrollProsecutors,
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProsecutorOfficeAndProsecutorForm;

import _ from 'lodash';

const parcelsHoursSum = parcels => {
  const hours = parcels.map(parcel => parseInt(parcel.hours));
  const total = hours.reduce((accumulator, value) => accumulator + value, 0);
  return total;
};

const parcelsAmountSum = parcels => {
  const amount = parcels.map(parcel => parseInt(parcel.amount));
  const total = amount.reduce((accumulator, value) => accumulator + value, 0);
  return total;
};

export { parcelsHoursSum, parcelsAmountSum };

import http from '../httpService';

const PendingInquiryService = {
  getPendingInquiryService: id => http.get(`pending-inquiries/${id}`),

  getPendingInquiriesService: params => http.get('pending-inquiries', { params }),

  giveBackPendingInquiryService: (pendingInquiryId, params) =>
    http.post(`pending-inquiries/${pendingInquiryId}/devolution`, params),

  giveBackBatchPendingInquiryService: params =>
    http.post(`pending-inquiries/devolution/batch`, params),

  refreshPcpeInquiry: pendingInquiryId =>
    http.post(`pending-inquiries/${pendingInquiryId}/refresh`),

  cancelInquiryReturnation: (inquiryId, params) =>
    http.put(`pending-inquiries/${inquiryId}/cancel-devolution`, params),

  getConcatenatedFilesUrl: (pendingInquiryId, params) =>
    http.get(`pending-inquiries/${pendingInquiryId}/concatenated-files/url`, { params }),

  deletePendingInquiry: (pendingInquiryId, params) =>
    http.patch(`pending-inquiries/${pendingInquiryId}/delete`, params),
};

export default PendingInquiryService;

import { ButtonConsensus } from 'components';
import React from 'react';
import googleApiService from 'services/google-api/googleApiService';

const ButtonLoginGoogleApi = props => {
  const handleLoginGoogleApi = async () => {
    await googleApiService.getAuthUrlService().then(response => {
      openUrl(response.data.authUrl);
    });
  };

  const openUrl = url => {
    window.open(url);
  };

  return (
    <ButtonConsensus variant="contained" title="Logar com Google" onClick={handleLoginGoogleApi} />
  );
};

export default ButtonLoginGoogleApi;

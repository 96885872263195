import React from 'react';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import { ButtonConsensus, HeaderModal, Label } from 'components';
import { changeNameStep } from 'utils/changeNameStep';
import { redirectToInquiryIdStep } from 'utils/genericFunctions/redirectToInquiryIdStep';
import { colors } from 'utils/colorsSteps';
import RefreshIcon from '@material-ui/icons/Refresh';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    minWidth: 700,
  },
  container: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  containerPendings: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  label: {
    marginLeft: theme.spacing(1),
    fontSize: theme.spacing(1.6),
  },
  containerProceed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  containerRefreshButton: {
    marginBottom: theme.spacing(2),
  },
}));

const PedingCriminalAntecedentesSearche = props => {
  const { data = [], open, onClose, handleClickProceed, handleRefreshList, loading } = props;

  const customerConfig = useSelector(state => state.customerConfig);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
    >
      <HeaderModal onClose={onClose}>
        Investigados com pesquisa de antecedente criminal pendente
      </HeaderModal>
      <DialogContent style={{ marginBottom: 30 }}>
        <Box className={classes.containerRefreshButton}>
          <Tooltip arrow placement="right" title="Atualizar lista">
            <IconButton onClick={handleRefreshList} disabled={Boolean(loading)}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center">Investigado</TableCell>
                <TableCell align="center">N° inquérito</TableCell>
                <TableCell align="center">Fase</TableCell>
                <TableCell align="center">Pendências</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item, index) => (
                <Tooltip key={index} arrow placement="top" title="Ir à tela de detalhe">
                  <TableRow
                    hover
                    style={{ cursor: 'pointer' }}
                    onClick={() => redirectToInquiryIdStep(item.inquiryInvestigated)}
                  >
                    <TableCell component="th" scope="row">
                      <Typography variant="body2">
                        {item.inquiryInvestigated.investigated?.name}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Typography variant="body2">
                        {item.inquiryInvestigated.inquiry?.policeInquiryNumber}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Typography variant="body2">
                        {changeNameStep(item.inquiryInvestigated?.step, customerConfig)}
                      </Typography>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center" colSpan={3}>
                      <Box className={classes.containerPendings}>
                        {item.pendingOrgans?.map((organ, index) => (
                          <Label
                            key={index}
                            className={classes.label}
                            variant="outlined"
                            color={colors.navBar}
                          >
                            {organ}
                          </Label>
                        ))}
                      </Box>
                    </TableCell>
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Box className={classes.containerProceed}>
          <ButtonConsensus
            title="Prosseguir mesmo assim"
            size="medium"
            onClick={handleClickProceed}
            margin={0}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PedingCriminalAntecedentesSearche;

import React from 'react';

const CallVideoJitsu = props => {
  const { videoCallUrl } = props;

  return (
    <iframe
      allow="camera; microphone; fullscreen; display-capture; autoplay"
      src={videoCallUrl}
      style={{ height: '100%', width: '100%', border: 0, borderRadius: '.5rem' }}
      title="videoCall"
    />
  );
};

export default CallVideoJitsu;

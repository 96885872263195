import { UserAssociationRepresentative } from "./users";

export default [
  {
    "id": "34923ac9-bca1-484c-9722-57d3dfd21ab1",
    "name": "Instituição teste 1",
    "cnpj": null,
    "zipCode": null,
    "city": null,
    "address": null,
    "neighborhood": null,
    "number": null,
    "uf": null,
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [UserAssociationRepresentative]
  },
  {
    "id": "99884e28-dd1f-44fb-8a35-dcd29076f355",
    "name": "Instituição teste 2",
    "cnpj": null,
    "zipCode": null,
    "city": null,
    "address": null,
    "neighborhood": null,
    "number": null,
    "uf": null,
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [UserAssociationRepresentative]
  },
  {
    "id": "caa11545-e917-43a7-9c8f-ac56c56966a5",
    "name": "Instituição teste 3",
    "cnpj": "12.345.678/9123-45",
    "zipCode": "55660-000",
    "city": "Bezerros",
    "address": "Rua 01",
    "neighborhood": "centro",
    "number": "11",
    "uf": "PE",
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [UserAssociationRepresentative]
  },
  {
    "id": "48b42a6b-fe58-48c8-ae44-ea3267646479",
    "name": "Instituição teste 4",
    "cnpj": "12.345.612/3456-12",
    "zipCode": "55660-000",
    "city": "Recife",
    "address": "Rua 1",
    "neighborhood": "centro",
    "number": "1001",
    "uf": "PE",
    "complement": "",
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [UserAssociationRepresentative]
  },
  {
    "id": "f99079e1-c01f-492f-b659-2a6bfb780543",
    "name": "Instituição teste 5",
    "cnpj": "00.000.000/0000-00",
    "zipCode": "55555-555",
    "city": "CARUARU ",
    "address": "TESTE ",
    "neighborhood": "TESTE ",
    "number": "14",
    "uf": "DF",
    "complement": "204",
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [UserAssociationRepresentative]
  },
  {
    "id": "496d19ff-de1f-463c-b04f-da844f4801c2",
    "name": "teste 9",
    "cnpj": null,
    "zipCode": null,
    "city": null,
    "address": null,
    "neighborhood": null,
    "number": null,
    "uf": null,
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "777e85f2-a359-484b-965d-9ac83cbd7269",
        "type": "AssociationRepresentative",
        "name": "teste 9",
        "login": "teste99@gmail.com",
        "email": "teste99@gmail.com",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": "Atendente",
        "createdAt": "2023-03-08T19:25:57.032Z",
        "updatedAt": "2024-01-17T14:57:27.968Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "80ddf114-08a7-4c2a-b6a0-897a541b29fc",
    "name": "Inst Final",
    "cnpj": null,
    "zipCode": null,
    "city": null,
    "address": null,
    "neighborhood": null,
    "number": null,
    "uf": null,
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "8ded76fc-2939-47c8-a896-f693a3682b12",
        "type": "AssociationRepresentative",
        "name": "Inst Final",
        "login": "aninhafinal@bol.com.br",
        "email": "aninhafinal@bol.com.br",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": null,
        "createdAt": "2023-11-14T13:59:53.717Z",
        "updatedAt": "2024-01-03T18:00:48.499Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "68444d8c-ad06-4127-9c34-6674846ff3bd",
    "name": "Instituição final 03",
    "cnpj": null,
    "zipCode": null,
    "city": null,
    "address": null,
    "neighborhood": null,
    "number": null,
    "uf": null,
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "e1de9951-cef4-4071-bb59-8d2798233d58",
        "type": "AssociationRepresentative",
        "name": "Instituição final 03",
        "login": "instfinal@gmail.com",
        "email": "instfinal@gmail.com",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": null,
        "createdAt": "2023-11-23T17:15:02.968Z",
        "updatedAt": "2023-11-23T17:15:02.968Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "a503dc4c-2ace-4125-89ea-b3a92135e084",
    "name": "ÓRGÃO TAL",
    "cnpj": "55.555.555/5555-55",
    "zipCode": "50000-000",
    "city": "OLINDA",
    "address": "RUA TAL DE TAL",
    "neighborhood": "BULTRINS",
    "number": "2",
    "uf": "DF",
    "complement": "B",
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "a69c254e-39ad-4b27-b35e-070409276eff",
        "type": "AssociationRepresentative",
        "name": "ÓRGÃO TAL",
        "login": "orgao@gmail.com",
        "email": "orgao@gmail.com",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": "Atendente",
        "createdAt": "2022-05-05T19:03:00.426Z",
        "updatedAt": "2023-02-09T00:20:20.988Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "2571ebe7-0d9b-4d36-a6f8-f8d282ab3c36",
    "name": "Instituição 003",
    "cnpj": "11.111.111/1111-11",
    "zipCode": "11111-111",
    "city": "Recife",
    "address": "teste",
    "neighborhood": "bairro teste",
    "number": "123",
    "uf": "PE",
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "f84a1ca7-81e2-42a4-922c-aa8ca5d14af4",
        "type": "AssociationRepresentative",
        "name": "Instituição 003",
        "login": "inst03@gmail.com",
        "email": "inst03@gmail.com",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": null,
        "createdAt": "2023-12-26T18:11:16.618Z",
        "updatedAt": "2023-12-28T17:35:21.016Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "a32141e9-ad03-4445-bd1b-629f971e4153",
    "name": "Institutição teste 24",
    "cnpj": "11.111.111/1111-11",
    "zipCode": "99999-999",
    "city": "Recife",
    "address": "teste",
    "neighborhood": "bairro teste",
    "number": "111111",
    "uf": "PE",
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "a59e6982-6097-4257-870c-2ab9ea509b8f",
        "type": "AssociationRepresentative",
        "name": "Institutição teste 24",
        "login": "teste24@gmail.com",
        "email": "teste24@gmail.com",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": "Atendente",
        "createdAt": "2023-01-12T16:06:36.395Z",
        "updatedAt": "2023-03-27T18:22:07.368Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "1ce89fb6-82cf-48a9-a625-7b586ae128ba",
    "name": "Teste Inst Final",
    "cnpj": "33.333.333/3333-33",
    "zipCode": "32322-222",
    "city": "Recife",
    "address": "end",
    "neighborhood": "bairro",
    "number": "123",
    "uf": "PE",
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "e7b62a67-8608-4590-a274-ce9d6f69b03c",
        "type": "AssociationRepresentative",
        "name": "Teste Inst Final",
        "login": "anaeac@bol.com.br",
        "email": "anaeac@bol.com.br",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": "Atendente",
        "createdAt": "2023-03-07T13:22:09.743Z",
        "updatedAt": "2023-12-27T21:05:21.883Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "ccbeab21-fd59-4267-bf5d-83098c4cccf3",
    "name": "Nova Instituição",
    "cnpj": null,
    "zipCode": null,
    "city": null,
    "address": null,
    "neighborhood": null,
    "number": null,
    "uf": null,
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "79bfd372-dff0-4e10-a25a-ddca53502b3d",
        "type": "AssociationRepresentative",
        "name": "Nova Instituição",
        "login": "nova-instituicao@gmail.com",
        "email": "nova-instituicao@gmail.com",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": "Atendente",
        "createdAt": "2023-08-14T21:10:21.789Z",
        "updatedAt": "2023-08-14T21:10:21.789Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "cfe3e58c-e222-42a9-b987-008063cf6c29",
    "name": "Gustavo",
    "cnpj": null,
    "zipCode": null,
    "city": "Recife",
    "address": null,
    "neighborhood": null,
    "number": null,
    "uf": "PE",
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": [
      {
        "id": "a7046d67-f42d-4ad7-b6c1-b4cbf0504fc7",
        "type": "AssociationRepresentative",
        "name": "Gustavo",
        "login": "instdenisteste@teste.com",
        "email": "instdenisteste@teste.com",
        "registration": null,
        "syncArquimedes": false,
        "canBeResponsible": true,
        "admissionDate": null,
        "representativeType": "Atendente",
        "createdAt": "2023-05-05T03:33:02.765Z",
        "updatedAt": "2023-05-05T19:09:42.314Z",
        "permissions": null
      }
    ]
  },
  {
    "id": "351be041-4449-4f42-865f-7150a376453c",
    "name": "Teste Inst Final",
    "cnpj": null,
    "zipCode": null,
    "city": null,
    "address": null,
    "neighborhood": null,
    "number": null,
    "uf": null,
    "complement": null,
    "forward": false,
    "coordination": false,
    "situation": null,
    "corporateName": null,
    "openingHours": null,
    "observation": null,
    "reaccreditationJustification": null,
    "phones": null,
    "size": null,
    "pjfeisRegistration": null,
    "upToDateWithPjfeis": null,
    "hasAnOperatingLicense": null,
    "respondingToLegalProceedings": null,
    "hasRegisteredWithCivilRegistry": null,
    "activityDescription": null,
    "reasonForSuspension": null,
    "deletedAt": null,
    "reasonForUnaccredited": null,
    "type": null,
    "responsibleSector": null,
    "users": []
  }
];
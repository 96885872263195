import { combineReducers } from 'redux';
import sessionReducer from './sessionReducer';
import logedUserReducer from './logedUserReducer';
import policeInquiryNumberReducer from './policeInquiryNumberReducer';
import organOriginReducer from './organOriginReducer';
import factInquiryReducer from './factInquiryReducer';
import navBarReducer from './navBarReducer';
import uploadInquiryReducer from './uploadInquiryReducer';
import inquiryReducer from './inquiryReducer';
import crimesPendingInquiryReducer from './crimesPendingInquiryReducer';
import classesInquiryReducer from './classesInquiryReducer';
import GroupMenuReducer from './GroupMenuReducer';
import linkingInquiryReducert from './linkingInquiryReducert';
import tabsServicesMaterialsReducer from './tabsServicesMaterialsReducer';
import registerUserReducer from './pagination/registerUserReducer';
import registerOrganReducer from './pagination/registerOrganReducer';
import registerGroupOfficeReducer from './pagination/registerGroupOfficeReducer';
import customerConfigReducer from './customerConfigReducer';
import boundInstitutionReducer from './boundInstitutionReducer';
import userDistributionsOnlyReducer from './userDistributionsOnlyReducer';
import lifetimeReducer from './lifetimeReducer';
import chatAiReducer from './chatAiReducer';

const rootReducer = combineReducers({
  session: sessionReducer,
  logedUser: logedUserReducer,
  policeInquiryNumberReducer: policeInquiryNumberReducer,
  organOrigin: organOriginReducer,
  factInquiry: factInquiryReducer,
  chatAi: chatAiReducer,
  navbar: navBarReducer,
  uploadFileInquiry: uploadInquiryReducer,
  inquiry: inquiryReducer,
  classesInquiry: classesInquiryReducer,
  crimesPendingInquiry: crimesPendingInquiryReducer,
  groupMenu: GroupMenuReducer,
  linkingInquiry: linkingInquiryReducert,
  tabServiceMaterial: tabsServicesMaterialsReducer,
  registerUserPagination: registerUserReducer,
  registerGroupOfficePagination: registerGroupOfficeReducer,
  registerOrganPagination: registerOrganReducer,
  customerConfig: customerConfigReducer,
  boundInstitution: boundInstitutionReducer,
  userDistributionsOnly: userDistributionsOnlyReducer,
  lifetime: lifetimeReducer,
});

export default rootReducer;

import * as actionTypes from 'actions';

const initialState = {
  attachments: [],
};

const uploadInquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_FILE_INQUIRY: {
      return {
        ...state,
        attachments: action.payload,
      };
    }

    case actionTypes.CLEAR_UPLOAD_FILE_INQUIRY: {
      return {
        ...state,
        attachments: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default uploadInquiryReducer;

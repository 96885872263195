import React, { useMemo, useState, useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  useMediaQuery,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  DialogActions,
} from '@material-ui/core';
import {
  ButtonConsensus,
  HeaderModal,
  InputConsensus,
  ProsecutorOfficeAndProsecutorForm,
} from 'components';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { colors } from 'utils/colorsSteps';
import { useTheme } from '@material-ui/core/styles';
import agreementService from 'services/agreement/agreementService';
import inquiryService from 'services/inquiries/inquiryService';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { USER_TYPE } from 'constants/inquirys/userType';

const ACTION = {
  ANALISE: 'ANALISE',
  DEVOLVER: 'DEVOLVER',
  ARQUIVAR: 'ARQUIVAR',
};

const DealWith = props => {
  const { open, onClose, inquiry, refreshPage } = props;
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const loggedUser = useSelector(state => state.logedUser);
  const isProsecutor = loggedUser.type === USER_TYPE.Prosecutor;

  const [action, setAction] = useState(ACTION.ANALISE);
  const [justification, setJustification] = useState('');
  const [loading, setLoading] = useState(false);

  const initialValues = {
    prosecutor: isProsecutor
      ? { ...loggedUser, id: loggedUser.userId }
      : inquiry.inquiry.prosecutor,
    prosecutorOffice: inquiry.inquiry.prosecutorOffice,
  };
  const [formState, setFormState] = useState({ ...initialValues });

  const handleChangeAction = event => {
    setAction(event.target.value);
  };

  const renderButtonTitle = () => {
    switch (action) {
      case ACTION.ANALISE:
        return 'Analise';
      case ACTION.DEVOLVER:
        return 'Devolver';
      case ACTION.ARQUIVAR:
        return 'Arquivar';
      default:
        return '';
    }
  };

  const handleChangeProsecutor = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      prosecutor: newValue,
    }));
  };

  const handleChangeProsecutorOffice = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      prosecutorOffice: newValue,
    }));
  };

  const handleSubmit = useCallback(() => {
    const { prosecutor, prosecutorOffice } = formState;
    const params = {};
    let movementTask = {};
    switch (action) {
      case ACTION.ANALISE:
        if (!prosecutorOffice) {
          toast.warn('Verifique o campo promotoria');
          return;
        }
        if (!prosecutor) {
          toast.warn('Verifique o campo promotor');
          return;
        }
        movementTask = {
          prosecutorOfficeId: prosecutorOffice.id,
          prosecutorId: prosecutor.id,
          inquiryInvestigatedId: inquiry.id,
          type: 'ENCAMINHAR_DILIGENCIAS',
          subType: {
            name: 'Termo de declaração',
            code: '920009',
          },
        };
        setLoading(true);
        inquiryService
          .updateChangeStepService(inquiry.id, {
            step: 'ANALISE',
            movementTask,
          })
          .then(() => {
            toast.success('Inquérito enviado para análise');
            onClose();
            refreshPage();
          })
          .finally(() => setLoading(false));
        break;
      case ACTION.DEVOLVER:
        params.justification = justification;
        setLoading(true);
        agreementService
          .resetStatusAgreement(inquiry?.agreement?.id, params)
          .then(() => {
            toast.success('Inquérito devolvido');
            onClose();
            refreshPage();
          })
          .finally(() => setLoading(false));
        break;
      case ACTION.ARQUIVAR:
        setLoading(true);

        inquiryService
          .updateChangeStepService(inquiry.id, {
            step: 'ARQUIVAR',
            justification,
          })
          .then(() => {
            toast.success('Inquérito arquivado');
            onClose();
            refreshPage();
          })
          .finally(() => setLoading(false));

        break;
      default:
        break;
    }
  }, [action, formState, inquiry, justification, onClose, refreshPage]);

  return (
    <Box width="100%">
      <Dialog
        fullWidth
        maxWidth={'sm'}
        fullScreen={fullScreen}
        open={open}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        aria-labelledby="responsive-dialog-title"
      >
        <HeaderModal onClose={onClose}>Tramitar</HeaderModal>
        <DialogContent>
          <Box>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={action}
                onChange={handleChangeAction}
              >
                <FormControlLabel
                  value={ACTION.ANALISE}
                  control={<Radio />}
                  label="Enviar para análise"
                />
                <FormControlLabel value={ACTION.DEVOLVER} control={<Radio />} label="Devolver" />
                <FormControlLabel value={ACTION.ARQUIVAR} control={<Radio />} label="Arquivar" />
              </RadioGroup>
            </FormControl>
            {action === ACTION.ANALISE && (
              <Box mt={1}>
                <ProsecutorOfficeAndProsecutorForm
                  prosecutor={formState.prosecutor}
                  prosecutorOffice={formState.prosecutorOffice}
                  onProsecutorChange={handleChangeProsecutor}
                  onProsecutorOfficeChange={handleChangeProsecutorOffice}
                  isProsecutor={isProsecutor}
                  prosecutorOfficeGroup={inquiry.inquiry.prosecutorOfficeGroup}
                />
              </Box>
            )}
            {(action === ACTION.DEVOLVER || action === ACTION.ARQUIVAR) && (
              <Box mt={1}>
                <InputConsensus
                  multiline
                  minRows={4}
                  maxRows={4}
                  label={`Justificar ${
                    action === ACTION.DEVOLVER ? 'a devolução para a devolução' : 'o arquivamento'
                  } do cumprimento do acordo`}
                  variant="outlined"
                  fullWidth
                  name="justification"
                  value={justification}
                  onChange={event => setJustification(event.target.value)}
                />
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <ButtonConsensus
            title={renderButtonTitle()}
            backgroundColor={colors.navBar}
            hovercolor={colors.navBar}
            onClick={handleSubmit}
            disabled={loading}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DealWith;

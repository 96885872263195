import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Box, MenuItem, Grid, CircularProgress } from '@material-ui/core';
import inquiryService from 'services/inquiries/inquiryService';
import prosecutorOfficeGroupService from 'services/prosecutor-office-group/prosecutorOfficeGroupService';
import { Autocomplete } from '@material-ui/lab';
import { InputConsensus, ButtonConsensus } from 'components';
import debounce from 'lodash.debounce';
import Moment from 'react-moment';
import userService from 'services/user/userService';
import { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import history from 'utils/history';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';

const DISTRIBUTION_TYPE = {
  DIRECTED: 'directed',
  RANDOM: 'random',
};

const RedistributionSuccess = props => {
  const { inquiry, onClose, displayTargetOfficeGroup, displayDistributionType } = props;

  const prosecutorOfficeGroup = inquiry.inquiry?.prosecutorOfficeGroup;

  const initialValues = {
    officeGroup: prosecutorOfficeGroup || null,
    typeDistribution: '',
    prosecutor: null,
    prosecutorOffice: null,
  };

  const [formState, setFormState] = useState({ values: { ...initialValues } });

  const [groups, setGroups] = useState([]);
  const [loadingGroup, setLoadingGroup] = useState(false);
  const [pageGroup, setPageGroup] = useState(1);
  const [lastPageGroup, setLastPageGroup] = useState(1);
  const [showAutocomplet, setShowAutocomplet] = useState(false);
  const [prosecutors, setProsecutors] = useState([]);
  const [key, setKey] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleClickConfirm = () => {
    if (!formState.values.officeGroup && inquiry.step !== STEPS_INQUIRY.CUMPRIMENTO_ACORDO) {
      toast.warn('Selecione um Grupo Ofício!');
      return;
    }
    const params = {
      sameOfficeGroup: Boolean(formState.values.officeGroup?.id === prosecutorOfficeGroup?.id),
    };
    if (formState.values.officeGroup?.id !== prosecutorOfficeGroup?.id) {
      params.prosecutorOfficeGroupId = formState.values.officeGroup?.id;
    }

    if (
      formState.values.officeGroup?.id === prosecutorOfficeGroup?.id ||
      inquiry.step === STEPS_INQUIRY.CUMPRIMENTO_ACORDO
    ) {
      if (formState.values.typeDistribution === DISTRIBUTION_TYPE.DIRECTED) {
        if (!formState.values.prosecutor) {
          toast.warn('Selecione um promotor!');
          return;
        }

        if (!formState.values.prosecutorOffice) {
          toast.warn('Selecione uma promotoria!');
          return;
        }
        params.distributeToProsecutorId = formState.values.prosecutor.id;
        params.prosecutorDesignation = formState.values.prosecutorOffice.name;
      }
    }

    setDisabled(true);
    inquiryService
      .redistributionConfirmService(inquiry.id, params)
      .then(() => {
        toast.success('Redistribuição realizada com sucesso!');
        onClose();
        history.goBack();
      })
      .finally(() => {
        setDisabled(false);
        history.push('/tramitacao-e-controle', { statusInquiries: '' });
      });
  };

  const getProsecutors = () => {
    const params = {
      prosecutorOfficeGroupIds: formState.values.officeGroup?.id,
    };
    userService.getUsersProsecutorsService(params).then(response => {
      setProsecutors(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      setShowAutocomplet(true);
    });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  const handleChangeProsecutor = (_, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        prosecutor: newValue,
        prosecutorOffice: null,
      },
    }));
    setKey(!key);
  };

  const debouncedgetGroup = useCallback(
    debounce(newValue => getOfficeGroups(newValue), 500),
    [],
  );

  const updateValueGroup = newValue => {
    if (!groups.find(groups => groups.name === newValue)) {
      debouncedgetGroup(newValue);
    }
  };

  const getOfficeGroups = (_input = '') => {
    const params = {
      //judicialDistrictId: '',
      page: 1,
      // size: 20,
    };
    prosecutorOfficeGroupService.getOfficeGroupsService(params).then(response => {
      setGroups(response.data.data);
    });
  };

  const handleChangeGroup = (_, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        officeGroup: newValue,
      },
    }));
  };

  const loadMoreGroupResults = () => {
    const nextPage = pageGroup + 1;

    setPageGroup(nextPage);

    setLoadingGroup(true);

    let params = {
      //judicialDistrictId: '',
      page: 1,
      // size: 20,
    };

    prosecutorOfficeGroupService
      .getOfficeGroupsService(params)
      .then(response => {
        setGroups([...groups, ...response.data.data]);
        setLastPageGroup(response.data.lastPage);
      })
      .finally(() => setLoadingGroup(false));
  };

  const handleScrollGroup = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && pageGroup < lastPageGroup && !loadingGroup) {
      loadMoreGroupResults();
    }
  };

  useEffect(() => {
    debouncedgetGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { typeDistribution, prosecutor, prosecutorOffice } = formState.values;
    if (typeDistribution !== '') {
      setShowBtn(true);
    }
    if (typeDistribution === DISTRIBUTION_TYPE.RANDOM) {
      setDisabled(false);
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          prosecutor: null,
          prosecutorOffice: null,
        },
      }));
    }
    if (typeDistribution === DISTRIBUTION_TYPE.DIRECTED) {
      getProsecutors();
      if (prosecutor && prosecutorOffice) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formState.values.typeDistribution,
    formState.values.prosecutor,
    formState.values.prosecutorOffice,
  ]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        prosecutor: null,
        prosecutorOffice: null,
        typeDistribution: DISTRIBUTION_TYPE.RANDOM,
      },
    }));
  }, [formState.values.officeGroup]);
  return (
    <>
      {inquiry.step !== STEPS_INQUIRY.CUMPRIMENTO_ACORDO && displayTargetOfficeGroup ? (
        <>
          <Box mt={2} display="flex" justifyContent="center">
            <Typography variant="h5">Selecione o Grupo ofício.</Typography>
          </Box>
          <Autocomplete
            value={formState.values.officeGroup}
            onInputChange={(_, newInputValue) => updateValueGroup(newInputValue)}
            getOptionSelected={(option, value) => option?.name === value?.name}
            getOptionLabel={option => option.name}
            fullWidth
            noOptionsText="Nada foi encontrado"
            loadingText="Buscando..."
            loading={loadingGroup}
            clearOnBlur={false}
            options={groups}
            onChange={handleChangeGroup}
            renderOption={option => <>{option.name}</>}
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Grupo Ofício"
                fullWidth
                variant="standard"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingGroup ? <CircularProgress color="primary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: handleScrollGroup,
            }}
          />
        </>
      ) : null}
      {formState.values.officeGroup?.id !== prosecutorOfficeGroup?.id &&
      displayTargetOfficeGroup ? (
        <Typography variant="h5" align="center" style={{ color: '#FF0000' }}>
          O IP será enviado para Triagem do grupo selecionado.
        </Typography>
      ) : null}
      <Box my={2}>
        <Grid container spacing={2}>
          {(formState.values.officeGroup?.id === prosecutorOfficeGroup?.id ||
            inquiry.step === STEPS_INQUIRY.CUMPRIMENTO_ACORDO) &&
          displayDistributionType ? (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <InputConsensus
                label="Distribuição"
                name="typeDistribution"
                onChange={handleChange}
                value={formState.values.typeDistribution || ''}
                select
                fullWidth
              >
                <MenuItem value={DISTRIBUTION_TYPE.DIRECTED}>Específica</MenuItem>
                <MenuItem value={DISTRIBUTION_TYPE.RANDOM}>Aleatória</MenuItem>
              </InputConsensus>
            </Grid>
          ) : null}
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {showAutocomplet && formState.values.typeDistribution !== DISTRIBUTION_TYPE.RANDOM && (
              <Autocomplete
                options={prosecutors}
                fullWidth
                autoHighlight
                onChange={handleChangeProsecutor}
                key={key}
                noOptionsText="Não encontrado"
                value={prosecutors.find(value => value.id === formState.values.prosecutor?.id)}
                getOptionLabel={option => option.name || ''}
                renderOption={option => <div>{option.name}</div>}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    name="name"
                    label="Promotor"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            )}
          </Grid>
          {formState.values.prosecutor && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <InputConsensus
                select
                label="Promotoria"
                name="prosecutorOffice"
                value={formState.values.prosecutorOffice || ''}
                onChange={handleChange}
                variant="standard"
                size="small"
                fullWidth
              >
                {formState.values.prosecutor.designations.map(office => {
                  const prosecutorOfficeGroupId = inquiry.inquiry.prosecutorOfficeGroup.id;
                  const blocking = office?.blockings?.find(
                    blocking => blocking.prosecutorOfficeGroup.id === prosecutorOfficeGroupId,
                  );
                  return (
                    <MenuItem key={office.name} value={office} disabled={!!blocking}>
                      {blocking ? (
                        blocking?.startDate && blocking?.endDate ? (
                          <Box>
                            <span>Inativo no período: </span>
                            <Moment format="DD/MM/YYYY">{blocking.startDate}</Moment>
                            <span> até </span>
                            <Moment format="DD/MM/YYYY">{blocking.endDate}</Moment>
                            <Box>{office.name}</Box>
                          </Box>
                        ) : (
                          <Box>
                            <Box>Promotor Inativo</Box>
                            <Box>{office.name}</Box>
                          </Box>
                        )
                      ) : (
                        office.name
                      )}
                    </MenuItem>
                  );
                })}
              </InputConsensus>
            </Grid>
          )}
          {showBtn && (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box display="flex" justifyContent="flex-end">
                <ButtonConsensus
                  title="Confirmar"
                  backgroundColor={colors.primary}
                  hovercolor={colors.primary}
                  color={colors.greShadow}
                  onClick={handleClickConfirm}
                  disabled={disabled}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default RedistributionSuccess;

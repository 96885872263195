import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { renderRoutes } from 'react-router-config';
import routes from '../../routes';
import TermsOfUse from '../../views/TermsOfUse';
import history from 'utils/history';

const TermsOfUseGuard = () => {
  const loggedUser = useSelector(state => state.logedUser);
  const [termsOfUseSigned, setTermsOfUseSigned] = useState(
    !loggedUser.loged || history.location.pathname === '/' || !loggedUser.termsOfUse?.id,
  );
  useEffect(() => {
    setTermsOfUseSigned(
      !loggedUser.loged || history.location.pathname === '/' || !loggedUser.termsOfUse?.id,
    );
    if (loggedUser.loged && history.location.pathname !== '/' && loggedUser.termsOfUse?.id) {
      history.replace('termos-de-uso');
    }
  }, [loggedUser]);
  return termsOfUseSigned ? renderRoutes(routes) : <TermsOfUse />;
};

export default TermsOfUseGuard;

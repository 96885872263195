import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
  },
}));

const MsgReturnSearch = props => {
  const { step, sizeDataApi, total } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();
  const classes = useStyles();

  return (
    <Typography variant="body2" className={classes.root}>
      {step === 'details'
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) de investigados`
        : step === STEPS_INQUIRY.TRIAGEM
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Triagem`
        : step === STEPS_INQUIRY.PESQUISA
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Pesquisa e Distribuição`
        : step === STEPS_INQUIRY.ANALISE
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Análise`
        : step === STEPS_INQUIRY.INTIMACAO
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Intimação`
        : step === STEPS_INQUIRY.TRAMITACAO_CONTROLE
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Tramitação e Controle`
        : step === STEPS_INQUIRY.AUDIENCIA
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Audiência${
            enableHearingForJuvenileOffenders ? '/Oitiva' : ''
          }`
        : step === 'VINCULADOS'
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s)`
        : step === STEPS_INQUIRY.CUMPRIMENTO_ACORDO
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Cumprimento de Acordo`
        : step === STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Termo de encaminhamento`
        : step === STEPS_INQUIRY.MIGRACAO
        ? `Exibindo ${sizeDataApi} de ${total} resultado(s) em Migração`
        : ''}
    </Typography>
  );
};

export default MsgReturnSearch;

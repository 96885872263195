import http from '../httpService';

const LawService = {
  getLawService: params => http.get(`laws`, { params }),

  registerLawService: params => http.post('laws', params),

  updateLawService: (lawId, params) => http.patch(`laws/${lawId}`, params),
};

export default LawService;

import React from 'react';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import colorsSteps, { colors } from 'utils/colorsSteps';
import Moment from 'react-moment';

const ChatMessage = props => {
  const { message } = props;

  const useStyles = makeStyles(theme => ({
    container: {
      width: '100%',
    },
    message: {
      width: '70%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2, 1, 0, 1),
      background: message.remetente === 'bot' ? colors.greySecundary : colorsSteps.ANALISE,
      float: message.remetente === 'bot' ? 'left' : 'right',
    },
    textMessage: {
      color: message.remetente !== 'bot' ? '#fff' : '',
    },
    textHour: {
      color: message.remetente !== 'bot' ? '#fff' : '',
      fontSize: 10,
      textAlign: 'right',
      marginTop: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Card className={classes.message}>
        <div style={{ paddingRight: 24 }}>
          <Typography className={classes.textMessage}>
            {message.remetente === 'bot' ? 'MPPE' : 'Investigado'}
          </Typography>
          <Typography className={classes.textMessage}>
            {message.mensagem || 'não informado'}
          </Typography>
        </div>
        <Typography className={classes.textHour}>
          {message.data ? (
            <Moment format="DD/MM/YYYY HH:mm">{message.data}</Moment>
          ) : (
            'não informado'
          )}
        </Typography>
      </Card>
    </div>
  );
};

export default ChatMessage;

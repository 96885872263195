import React from 'react';
import { Typography, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { INSTALLMENT_TYPES } from 'constants/agreement/installmentTypes';

const useStyles = makeStyles({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '40px',
  },
  radioLabel: {
    fontSize: '0.7rem',
  },
});

const InstallmentTypeRadioGroup = props => {
  const { installmentType, setInstallmentType } = props;
  const classes = useStyles();
  return (
    <>
      <Typography align="left" variant="h6">
        Selecione o tipo de parcelamento
      </Typography>
      <RadioGroup
        name="installmentsType"
        onChange={event => setInstallmentType(event.target.value)}
        value={installmentType}
        className={classes.radioGroup}
      >
        <FormControlLabel
          control={<Radio color="primary" />}
          label="Fixo por período"
          value={INSTALLMENT_TYPES.FIXED_PER_PERIOD}
          classes={{ label: classes.radioLabel }}
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          label="Variável por período"
          value={INSTALLMENT_TYPES.VARIABLE_PER_PERIOD}
          classes={{ label: classes.radioLabel }}
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          label="A ser pago até uma determinada data"
          value={INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE}
          classes={{ label: classes.radioLabel }}
        />
      </RadioGroup>
    </>
  );
};

export default InstallmentTypeRadioGroup;

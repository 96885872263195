import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { StepTitle } from '../../../';
import Moment from 'react-moment';
import ViewListIcon from '@material-ui/icons/ViewList';
import pmService from 'services/PM/pmService';
import movementTaskService from 'services/movement-task/movementTaskService';
import { useUserTypes } from 'hooks/useUserTypes';
import { ModalListFiles } from 'components/ProcessingTasks/components';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ModalResponsible from '../ModalResponsible/ModalResponsible';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 150,
    maxHeight: 180,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputInquiry: {
    height: 35,
    width: '100%',
    border: `1px solid #747474`,
    padding: 10,
    backgroundColor: 'transparent',
    marginRight: 10,
    marginTop: 5,
  },
  btnSalvar: {
    color: '#ffffff',
    backgroundColor: '#3bc3bf',
    '&:hover': {
      backgroundColor: '#00597b',
    },
  },
  table: {
    width: '100%',
  },
}));

const TableModalChangeLevelForm = props => {
  const {
    setFormState,
    tasks,
    setRow,
    setHaveTask,
    handleRemoveTask,
    className,
    setFileInBase64,
    setUploadObject,
    ...rest
  } = props;

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const [files, setFiles] = useState(null);

  const [openModalListFiles, setOpenModalListFiles] = useState(false);

  const [openModalResponsible, setOpenModalResponsible] = useState(false);
  const [responsibleTask, setResponsibleTask] = useState(false);

  const insertFiles = response => {
    let listFiles = {
      requestDocument: response.data.requestDocuments,
      responseDocument: response.data.responseDocuments,
    };

    setFiles(listFiles);
  };

  const lastManifestationHasError = task => {
    if (task?.manifestations?.length > 0) {
      return (
        task.manifestations?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))?.[0]
          ?.integrationStatus === 'ERROR'
      );
    }
    return false;
  };

  const handleEditTask = task => {
    if (task.requestDocument !== null) {
      setFileInBase64([task.requestDocument]);
      setUploadObject([task.requestDocument]);
    }

    setFormState(formState => ({
      ...formState,
      justification: task.justification || '',
      type: task.type,
      name: task?.subType.name,
      place: task.place,
      date: task.date ? task.date.substring(0, 10) : '',
      subtype: {
        code: task?.subType.code,
        name: task?.subType.name,
      },
    }));

    setRow(task);
    setHaveTask(false);
  };

  const getFilesTasks = async taskId => {
    if (isPM) {
      await pmService.getFilesTasksPmService(taskId).then(response => {
        insertFiles(response);
      });
    } else {
      await movementTaskService.getFilesTasksService(taskId).then(response => {
        insertFiles(response);
      });
    }
  };

  const handleOpenModalListFiles = async task => {
    await getFilesTasks(task.id);
    setOpenModalListFiles(true);
  };

  const handleOpenModalResponsible = task => {
    setResponsibleTask(task);
    setOpenModalResponsible(true);
  };

  return (
    <Box {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2} className={classes.containerForm}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              {tasks.length < 1 && (
                <caption style={{ textAlign: 'center' }}>Não há tarefas adicionadas</caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell align="center">Tipo</TableCell>
                  <TableCell align="center">Data</TableCell>
                  <TableCell align="center">Arquivos</TableCell>
                  <TableCell align="center">Responsável</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task, index) => (
                  <TableRow key={index} hover>
                    <TableCell component="th" scope="row" align="center">
                      <StepTitle step={task?.subType.name} />
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Moment format="DD/MM/YYYY" local>
                        {task.createdAt}
                      </Moment>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Tooltip arrow placement="top" title="Visualizar arquivos">
                        <IconButton onClick={() => handleOpenModalListFiles(task)}>
                          <ViewListIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <IconButton onClick={() => handleOpenModalResponsible(task)}>
                        <AssignmentIndIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      {task.awaitingResponse && task.awaitingVerification ? (
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => handleEditTask(task)}
                        >
                          <EditIcon />
                        </IconButton>
                      ) : (
                        <Tooltip title="Tarefa em andamento" placement="top">
                          <span>
                            <IconButton color="primary" size="small" disabled>
                              <EditIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                      {task.awaitingResponse &&
                      task.awaitingVerification &&
                      !lastManifestationHasError(task) ? (
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => handleRemoveTask(task)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      ) : (
                        <Tooltip title="Tarefa em andamento" placement="top">
                          <span>
                            <IconButton color="primary" size="small" disabled>
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {openModalListFiles && (
        <ModalListFiles
          onClose={() => setOpenModalListFiles(false)}
          open={openModalListFiles}
          files={files}
        />
      )}
      {openModalResponsible && (
        <ModalResponsible
          handleClose={() => setOpenModalResponsible(false)}
          open={openModalResponsible}
        >
          <>
            <Typography variant="h6" color="black">
              Promotor: {responsibleTask?.prosecutor?.name}
            </Typography>
            <br />
            <Typography variant="h6" color="black">
              Promotoria: {responsibleTask?.prosecutorOffice?.name}
            </Typography>
          </>
        </ModalResponsible>
      )}
    </Box>
  );
};

export default TableModalChangeLevelForm;

import * as actionTypes from 'actions';

export const chatAiPossibleStates = {
  open: 'OPEN',
  closed: 'CLOSED',
  minimized: 'MINIMIZED',
};

const initialState = {
  messages: [],
  situation: chatAiPossibleStates.closed,
  lastFeature: undefined,
};

const chatAiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AI_MESSAGES:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    case actionTypes.CLEAR_AI_MESSAGES:
      return {
        ...state,
        messages: [],
        lastFeature: undefined,
      };
    
    case actionTypes.SET_CHAT_AI_SITUATION:
      return {
        ...state,
        situation: action.payload,
      };
    
    case actionTypes.SET_CHAT_AI_FEATURE:
      return {
        ...state,
        lastFeature: action.payload,
      };

    default:
      return state;
  }
};

export default chatAiReducer;

import React, { useState } from 'react';
import { Typography, IconButton, Box, Tooltip, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import { useUserTypes } from 'hooks/useUserTypes';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
    paddingLeft: 10,
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  containerJudicial: {
    marginTop: theme.spacing(1),
  },
  labelTitle: {
    color: '#00597b',
  },
  inputResult: {
    color: '#00597b',
    fontWeight: 'bold',
  },
  textAreaReady: {
    color: colors.primary,
    fontWeight: 'bold',
    marginTop: 5,
    maxWidth: '100%',
    minWidth: '100%',
    width: '100%',
    padding: 5,
    outline: 0,
    border: `1px solid transparent`,
    backgroundColor: 'transparent',
    borderRadius: 5,
    resize: 'none',
  },
  textArea: {
    marginTop: 5,
    maxWidth: '100%',
    minWidth: '100%',
    width: '100%',
    padding: 5,
    outline: 0,
    border: `1px solid #000`,
    backgroundColor: 'transparent',
    borderRadius: 5,
    resize: 'none',
  },
}));

const ObservationInquiryDetail = props => {
  const {
    inquiryId,
    observationInquiry,
    refreshPage,
    write = false,
    otherStep = false,
    canEdit = false,
  } = props;

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const initialValues = {
    observation: observationInquiry || '',
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const [editObservation, setEditObservation] = useState(false);

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  const handleEditObservation = () => {
    if (!write) {
      handleOpenSnackbar();
      return;
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
    setEditObservation(true);
  };

  const handleEditObservationToFalse = () => {
    setEditObservation(false);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleUpdateObservation = () => {
    const { observation } = formState.values;

    const params = {
      observation: !observation ? null : observation,
    };

    inquiryService.updateInquiryInvestigatedService(inquiryId, params).then(() => {
      toast.success('Dados alterados com sucesso!');
      setEditObservation(false);
      refreshPage();
    });
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.titulo}>Observação do investigado</Typography>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {!editObservation && ((!otherStep && !isPM) || canEdit) ? (
              <Tooltip title="Alterar Observação" arrow placement="top">
                <IconButton size="small" onClick={handleEditObservation}>
                  <CreateIcon style={{ color: '#00b1ac' }} />
                </IconButton>
              </Tooltip>
            ) : (
              editObservation && (
                <>
                  <Box style={{ marginRight: 10 }}>
                    <Tooltip title="Salvar alterações" arrow placement="top">
                      <IconButton
                        size="small"
                        onClick={handleUpdateObservation}
                        form="my-form-edit"
                        type="submit"
                      >
                        <SaveIcon style={{ color: '#1976d2' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title="Cancelar" arrow placement="top">
                      <IconButton size="small" onClick={handleEditObservationToFalse}>
                        <ClearIcon style={{ color: '#e1296a' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )
            )}
          </Box>
        </Box>
      </Box>
      <Box px={1.5} py={1}>
        {!editObservation ? (
          observationInquiry ? (
            <TextareaAutosize
              className={classes.textAreaReady}
              minRows={2}
              aria-label="maximum height"
              value={formState.values.observation || ''}
              readOnly
            />
          ) : (
            <Typography align="left" variant="h6" className={classes.labelTitle}>
              {observationInquiry || 'Não há observação'}
            </Typography>
          )
        ) : (
          <TextareaAutosize
            className={classes.textArea}
            minRows={2}
            maxRows={3}
            aria-label="maximum height"
            name="observation"
            onChange={handleChange}
            value={formState.values.observation || ''}
          />
        )}
      </Box>
    </Box>
  );
};

export default ObservationInquiryDetail;

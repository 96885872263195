/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-extra-boolean-cast */
import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Grid, IconButton, Box, Tooltip, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { InputConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import organService from 'services/organ/organService';
import debounce from 'lodash.debounce';
import { useUserTypes } from 'hooks/useUserTypes';
import { formatNpuMask } from 'masks/masksImask';
import { clearInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import PmService from 'services/PM/pmService';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  containerJudicial: {
    marginTop: theme.spacing(1),
  },
  boxLeft: {
    textAlign: 'left',
    paddingLeft: theme.spacing(1.5),
  },
  boxRight: {
    textAlign: 'left',
    paddingRight: theme.spacing(1.5),
  },
  labelTitle: {
    color: '#00597b',
  },
  inputResult: {
    color: '#00597b',
    fontWeight: 'bold',
  },
}));

const JudicialInformations = props => {
  const {
    inquiry,
    refreshPage,
    write = false,
    otherStep = false,
    setRefreshTasks,
    canEdit = false,
  } = props;

  const { isPM } = useUserTypes();

  const { isMPDFT } = useCustomerConfig();

  const classes = useStyles();

  const [editJudicial, setEditJudicial] = useState(false);

  const [loadingDelegacias, setLoadingDelegacias] = useState(false);
  const [inputSearchDelegacias, setInputSearchDelegacias] = useState('');
  const [pageDelegacias, setPageDelegacias] = useState(1);
  const [lastPageDelegacias, setLastPageDelegacias] = useState(1);
  const [options, setOptions] = useState([]);

  const initialValues = {
    npu: inquiry.npu,
    judgmentPlace: inquiry.judgmentPlace,
  };

  const [formState, setFormState] = useState({
    values: { ...initialValues },
  });

  const handleChange = event => {
    event.persist();
    const { target } = event;
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [target.name]: clearInquiryNumber(target.value).slice(0, 20),
      },
    }));
  };

  const getDelegacias = (input = '') => {
    setLoadingDelegacias(true);

    setPageDelegacias(1);

    let params = {
      page: 1,
      size: 20,
      search: input,
      category: 'JUDICIARY',
    };

    organService
      .getDelegaciasService(params)
      .then(response => {
        setOptions(response.data.data);
        setLastPageDelegacias(response.data.lastPage);
      })
      .finally(() => setLoadingDelegacias(false));
  };

  const loadMoreDelegaciasResults = () => {
    const nextPage = pageDelegacias + 1;

    setPageDelegacias(nextPage);

    setLoadingDelegacias(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchDelegacias,
      category: 'JUDICIARY',
    };

    organService
      .getDelegaciasService(params)
      .then(response => {
        setOptions([...options, ...response.data.data]);
        setLastPageDelegacias(response.data.lastPage);
      })
      .finally(() => setLoadingDelegacias(false));
  };

  const debouncedGetDelegacias = useCallback(
    debounce(newValue => getDelegacias(newValue), 500),
    [],
  );

  const updateValueDelegacias = newValue => {
    if (Boolean(!options.find(delegacia => delegacia.name === newValue))) {
      debouncedGetDelegacias(newValue);
      setInputSearchDelegacias(newValue);
    }
  };

  const handleScrollDelegacias = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageDelegacias < lastPageDelegacias &&
      !loadingDelegacias
    ) {
      loadMoreDelegaciasResults();
    }
  };

  const handleChangeJudgmentPlace = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        judgmentPlace: newValue,
      },
    }));
  };

  const editJuficialInformations = () => {
    const { npu, judgmentPlace } = formState.values;

    let params = {};

    if (npu && !isMPDFT) {
      if (npu.length < 20) {
        toast.warn('NPU inválido. Verifique se o NPU digitado contém 20 números');
        return;
      }

      params.npu = npu;
    } else {
      params.npu = null;
    }

    if (judgmentPlace) {
      params.judgmentPlaceId = judgmentPlace.id;
    } else {
      params.judgmentPlaceId = null;
    }
    if (isPM) {
      PmService.updateInquiryInvestigatedService(inquiry.id, params).then(() => {
        toast.success('Informações alteradas com sucesso');
        setEditJudicial(false);
        if (inquiry.step === 'TRAMITACAO_CONTROLE') {
          setRefreshTasks(true);
        }
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryInvestigatedService(inquiry.id, params).then(() => {
        toast.success('Informações alteradas com sucesso');
        setEditJudicial(false);
        if (inquiry.step === 'TRAMITACAO_CONTROLE') {
          setRefreshTasks(true);
        }
        refreshPage();
      });
    }
  };

  const handleEditJudicial = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        judgmentPlace: inquiry.judgmentPlace,
        npu: inquiry.npu,
      },
    }));
    setEditJudicial(true);
  };

  const handleEditJudicialToFalse = () => {
    setEditJudicial(false);
  };

  const handleUpdateJudicial = () => {
    editJuficialInformations();
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  useEffect(() => {
    if (editJudicial) {
      debouncedGetDelegacias(inquiry?.judgmentPlace ? inquiry.judgmentPlace.name : '');
    }
  }, [editJudicial]);

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={1.5}>
        <Box>
          <Typography className={classes.titulo}>Dados da Justiça</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {!editJudicial && ((!otherStep && !isPM) || canEdit) ? (
            <Tooltip title="Alterar dados" arrow placement="top">
              <IconButton
                size="small"
                onClick={() => (!write ? handleOpenSnackbar() : handleEditJudicial())}
              >
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            editJudicial && (
              <>
                <Box style={{ marginRight: 10 }}>
                  <Tooltip title="Salvar alterações" arrow placement="top">
                    <IconButton
                      size="small"
                      onClick={handleUpdateJudicial}
                      form="my-form-edit"
                      type="submit"
                    >
                      <SaveIcon style={{ color: '#1976d2' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="Cancelar" arrow placement="top">
                    <IconButton size="small" onClick={handleEditJudicialToFalse}>
                      <ClearIcon style={{ color: '#e1296a' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )
          )}
        </Box>
      </Box>
      <Grid container spacing={2} className={classes.containerJudicial}>
        {!isMPDFT && (
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <Box className={classes.boxLeft}>
              {!editJudicial ? (
                <>
                  <Typography className={classes.labelTitle} component="label" variant="body2">
                    NPU
                  </Typography>
                  <Typography className={classes.inputResult} variant="body1">
                    {inquiry.npu ? formatNpuMask(inquiry.npu) : 'não informado'}
                  </Typography>
                </>
              ) : (
                <InputConsensus
                  onChange={handleChange}
                  value={formState?.values?.npu ? formatNpuMask(formState.values.npu) : ''}
                  label="NPU"
                  name="npu"
                  variant="standard"
                  fullWidth
                />
              )}
            </Box>
          </Grid>
        )}
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box className={classes.boxRight}>
            {!editJudicial ? (
              <>
                <Typography className={classes.labelTitle} component="label" variant="body2">
                  Vara/Local
                </Typography>
                <Typography className={classes.inputResult} variant="body1">
                  {inquiry.judgmentPlace?.name || 'não informado'}
                </Typography>
              </>
            ) : (
              <Autocomplete
                value={formState.values.judgmentPlace}
                onInputChange={(_, newInputValue) => updateValueDelegacias(newInputValue)}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={option => option.name}
                filterOptions={x => x}
                fullWidth
                noOptionsText="Nada foi encontrado"
                loadingText="Buscando..."
                loading={loadingDelegacias}
                clearOnBlur={false}
                options={options}
                onChange={handleChangeJudgmentPlace}
                renderOption={option => <>{option.name}</>}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Vara/Local"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingDelegacias ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: handleScrollDelegacias,
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default JudicialInformations;

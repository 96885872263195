import { PENALTY_KINDS } from "constants/agreement/penaltyKinds";

const serviceParser = communityServices => {
  const newArrayService = [];
  communityServices.forEach(service => {
    const newService = {
      ...service,
      finalDate: service.finalDate?.substring(0, 10),
      hours: service.hours,
      inicialDate: service.inicialDate?.substring(0, 10),
      penalty: PENALTY_KINDS.SERVICES,
      service: service?.service,
      parcels: service?.installments?.map(i => ({
        ...i,
        hours: i?.value ?? 0,
        date: i.initialDate,
      })),
      relation: {
        hours: service.targetNecessity?.hours,
        active: service.targetNecessity?.active,
        association: service.targetNecessity?.association,
        finalDate: service.targetNecessity?.finalDate.substring(0, 10),
        inicialDate: service.targetNecessity?.inicialDate.substring(0, 10),
        id: service.targetNecessity?.id,
        description: service.targetNecessity?.description,
        communityService: service.targetNecessity?.communityService,
        project: service.project,
      },
    };
    newArrayService.push(newService);
  });
  return newArrayService;
};

export default serviceParser;

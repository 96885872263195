import React, { useState } from 'react';
import { IconButton, Typography, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Moment from 'react-moment';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { InputConsensus } from 'components';
import { toast } from 'react-toastify';
import { colors } from 'utils/colorsSteps';
import inquiryService from 'services/inquiries/inquiryService';
import { formatDateToISO8601, formatDateToISOString } from 'utils/date-time.utils';
import { useUserTypes } from 'hooks/useUserTypes';
import PmService from 'services/PM/pmService';

const useStyles = makeStyles(theme => ({
  titleConfidential: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  titleRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  containerTitleBtns: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  containerConfidential: {
    padding: theme.spacing(0, 1.5),
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2, 0),
  },
  containerGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerGridItem: {
    display: 'flex',
    justifyContent: 'start',
  },
  result: {
    color: colors.navBar,
    fontSize: 14,
    margin: theme.spacing(0.5, 0),
  },
  result2: {
    color: colors.navBar,
    fontSize: 14,
    fontWeight: 'bold',
    margin: theme.spacing(0.5, 0),
  },
  containerResult: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const DeadlineInquiry = props => {
  const { inquiryId, deadline, otherStep, write, refreshPage, canEdit = false } = props;

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const [editValues, setEditValues] = useState(false);

  const [formState, setFormState] = useState({
    deadline: deadline ? formatDateToISO8601(deadline) : '',
  });

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const handleEditValuesToTrue = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    setEditValues(true);
  };

  const handleEditValuesToFalse = () => {
    setFormState(formState => ({
      ...formState,
      deadline: deadline ? formatDateToISO8601(deadline) : '',
    }));
    setEditValues(false);
  };

  const changeValuesRequest = () => {
    if (!formState.deadline) {
      return toast.warn('Informe uma data para o prazo do inquérito');
    }

    let params = {
      deadline: formatDateToISOString(formState.deadline, true),
    };

    if (isPM) {
      PmService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        setEditValues(false);
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        setEditValues(false);
        refreshPage();
      });
    }
  };

  return (
    <div className={classes.containerConfidential}>
      <div className={classes.containerTitleBtns}>
        <Typography className={classes.titleConfidential}>Prazo do inquérito</Typography>
        <div className={classes.titleRight}>
          {!editValues && ((!otherStep && !isPM) || canEdit) ? (
            <Tooltip title="Alterar prazo do inquérito" arrow placement="top">
              <IconButton
                className={classes.buttonEditar}
                size="small"
                onClick={handleEditValuesToTrue}
              >
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            editValues && (
              <>
                <div style={{ marginRight: 10 }}>
                  <Tooltip title="Salvar alterações" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={changeValuesRequest}
                    >
                      <SaveIcon style={{ color: '#1976d2' }} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div>
                  <Tooltip title="Cancelar" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleEditValuesToFalse}
                    >
                      <ClearIcon style={{ color: '#e1296a' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            )
          )}
        </div>
      </div>
      <Grid container spacing={2} className={classes.containerGrid}>
        <Grid item xs={12} className={classes.containerGridItem}>
          {editValues ? (
            <InputConsensus
              type="date"
              name="deadline"
              fullWidth
              value={formState.deadline}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
            />
          ) : (
            <div className={classes.containerResult}>
              <Typography variant="body1" className={classes.result2}>
                {deadline ? (
                  <Moment utc format="DD/MM/YYYY">
                    {deadline}
                  </Moment>
                ) : (
                  'Sem prazo definido'
                )}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default DeadlineInquiry;

export const finalDate = () => {
  const newDate = new Date();

  let ano = newDate.getFullYear();

  let mes = newDate.getMonth() + 1;
  if (mes < 10) mes = `0${mes}`;

  let dia = newDate.getDate();
  if (dia < 10) {
    dia = `0${dia}`;
  }

  return `${ano}-${mes}-${dia}`;
};

export const initialDate = () => {
  const newDate = new Date();

  let ano = newDate.getFullYear();

  let mes = newDate.getMonth() + 1;
  if (mes < 10) mes = `0${mes}`;

  return `${ano}-${mes}-01`;
};

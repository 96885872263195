import * as actionTypes from 'actions';

const initialState = {
  userDistributionsOnly: true,
};

const userDistributionsOnlyReducer = (state = initialState, action) => {
  if (action.type === actionTypes.SET_USER_DISTRIBUTIONS_ONLY) {
    return {
      ...state,
      userDistributionsOnly: action.payload,
    };
  } else {
    return state;
  }
};

export default userDistributionsOnlyReducer;

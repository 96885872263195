import React from 'react';
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { ButtonConsensus, HeaderModal } from 'components';
import { colors } from 'utils/colorsSteps';

const ModalChangeGroup = props => {
  const { open, onClose, group, indexGroup, confirmChange } = props;

  const handleChange = () => {
    confirmChange(indexGroup);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <HeaderModal onClose={onClose}>{group.name}</HeaderModal>
        <DialogContent>
          <Typography variant="h5" id="alert-dialog-description">
            Deseja realmente mudar para este grupo ofício?
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonConsensus
            title="Cancelar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
          />
          <ButtonConsensus
            title="Confirmar"
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={handleChange}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalChangeGroup;

import React, { useState, useEffect } from 'react';
import { Dialog, Typography, Grid, DialogContent, Box, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import axios from '../../../../utils/axios';
import { toast } from 'react-toastify';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { HeaderModal, ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerModal: {
    padding: 15,
    minWidth: 500,
    maxWidth: 500,
    minHeight: 400,
    maxHeight: 450,
    width: '100%',
    overflow: 'auto',
    '@media (max-width:500px)': {
      minWidth: 300,
      minHeight: 350,
    },
    '@media (max-width:960px)': {
      minWidth: 300,
      minHeight: 350,
    },
  },
  header: {
    width: '100%',
    backgroundColor: '#00597b',
    height: 70,
    color: '#ffffff',
    paddingLeft: 30,
    fontSize: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropzone: {
    height: 100,
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  msgBody: {
    fontSize: 20,
    color: '#00597b',
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
  },
  dadosCriminais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 18,
  },
  containerDadosPessoais: {
    backgroundColor: '#ededed',
    marginTop: 5,
    padding: 10,
    borderRadius: 25,
  },
  labelDadoaPessoais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
  },
  labelCrime: {
    fontWeight: 'bold',
    color: '#000',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8cf5f',
    width: '60%',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  textArea: {
    width: '100%',
    padding: 5,
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
  },
  containerConfirm: {
    height: 380,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#787878',
    fontSize: 22,
    fontWeight: 'bold',
    padding: 100,
    '@media (max-width:690px)': {
      padding: 30,
    },
  },
  btnConfirm: {
    backgroundColor: '#00b1ac',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 40,
    height: 60,
    width: 170,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  labelForm: {
    display: 'flex',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 14,
  },
  inputForm: {
    display: 'block',
    height: 25,
    outline: 0,
    padding: 5,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerLabelInput: {
    padding: 5,
    marginBottom: 15,
  },
}));

const ModalAddFile = props => {
  const { open, onClose, inquiry, refreshPage } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [file, setFile] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const [formState, setFormState] = useState({
    organName: '',
    isOk: false,
    certificate: {
      base64: '',
    },
  });

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: false,
    onDrop: files => handleSetFile(files),
  });

  useEffect(() => {
    file.length > 0 && formState.organName.length > 0 ? setDisabled(false) : setDisabled(true);
  }, [file, formState.organName]);

  const handleSendFile = () => {
    setDisabled(true);

    let params = {
      organName: formState.organName,
      isOk: formState.isOk,
      certificate: {
        base64: formState.certificate.base64,
      },
    };

    axios
      .post(`criminal-antecedents/custom-organs-queries/${inquiry.id}`, params)
      .then(() => {
        toast.success('Órgão cadastrado com sucesso!');
        refreshPage();
        setFormState(_formState => ({
          organName: '',
          isOk: false,
          certificate: {
            base64: '',
          },
        }));
        setFile([]);
      })
      .finally(() => setDisabled(false));
  };

  const handleSetFile = fileAnexo => {
    setFile(fileAnexo);
    getBase64(fileAnexo[0]);
  };

  const getBase64 = fileAnexo => {
    var reader = new FileReader();
    reader.readAsDataURL(fileAnexo);
    reader.onload = function() {
      var result = reader.result;
      //console.log({ base64: result });

      setFormState(formState => ({
        ...formState,
        certificate: {
          ...formState.certificate,
          base64: result,
        },
      }));
    };
  };

  const handleRemoveFile = () => {
    setFile([]);
    setFormState(formState => ({
      ...formState,
      certificate: {
        ...formState.certificate,
        base64: '',
      },
    }));
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  const toggleChecked = () => {
    setFormState(formState => ({
      ...formState,
      isOk: !formState.isOk,
    }));
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="sm"
    >
      <HeaderModal
        onClose={onClose}
        backgroundColor={colors.navBar}
        colorIconTitle={colors.greySecundary}
      >
        Adicionar Órgão
      </HeaderModal>
      <DialogContent>
        <Grid container>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <form id="formFile">
              <div className={classes.containerLabelInput}>
                <Typography
                  style={{
                    fontSize: 16,
                    cursor: 'pointer',
                    width: '50%',
                  }}
                  onClick={() => toggleChecked()}
                >
                  Há antecedentes criminais?
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={formState.isOk} onChange={toggleChecked} />}
                  />
                </FormGroup>
              </div>
              <div className={classes.containerLabelInput}>
                <label htmlFor="organName" className={classes.labelForm}>
                  Nome do Órgão
                </label>
                <input
                  className={classes.inputForm}
                  type="text"
                  name="organName"
                  autoComplete="off"
                  value={formState.organName}
                  onChange={handleChange}
                />
              </div>
            </form>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
              <input {...getInputProps()} />
              <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
            </div>
            <Box mt={2}>
              <Typography>Arquivos</Typography>
              {file.map((file, i) => (
                <Box key={i} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <PictureAsPdfIcon style={{ marginRight: 5 }} />
                    <Typography variant="body1">{file.path}</Typography>
                  </Box>
                  <IconButton onClick={handleRemoveFile}>
                    <HighlightOffIcon style={{ color: '#e81224', cursor: 'pointer' }} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Box display="flex" justifyContent="center">
              <ButtonConsensus
                fullWidth
                title="Enviar"
                disabled={disabled}
                onClick={handleSendFile}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddFile;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  IconButton,
  Checkbox,
  TextareaAutosize,
  MenuItem,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import validate from 'validate.js';
import Tooltip from '@material-ui/core/Tooltip';
import { setFactInquiryReducer, setStatusInquiryReducer } from 'actions';
import { listUf } from 'utils/citysPE';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { InputConsensus, KeyboardDatePicker } from 'components';
import { formatDateToBrazilian } from 'utils/date-time.utils';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
    marginBottom: 20,
  },
  containerLabelInputCrime: {
    padding: 5,
  },
  containerLabelInput: {
    padding: 5,
  },
  selectForm: {
    height: 25,
    outline: 0,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: '#ededed',
  },
  form: {
    heigth: '100%',
    width: '100%',
  },
  labelForm: {
    display: 'flex',
    fontSize: 11,
  },
  labelFormCrime: {
    display: 'flex',
    fontSize: 11,
    color: '#00597b',
  },
  inputForm: {
    display: 'block',
    height: 25,
    outline: 0,
    padding: 5,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: 'transparent',
  },
  inputFormCrime: {
    display: 'block',
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: '#00597b',
    textAlign: 'left',
  },
  inputFormCrimeEdit: {
    display: 'block',
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontWeight: 'bold',
    color: '#00597b',
    borderBottom: '1px solid #000',
  },
  textArea: {
    marginTop: 5,
    maxWidth: '100%',
    minWidth: '100%',
    minHeight: 50,
    width: '100%',
    padding: 5,
    outline: 0,
    border: `1px solid #000`,
    backgroundColor: 'transparent',
    borderRadius: 5,
    resize: 'none',
  },
  textAreaRead: {
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    backgroundColor: 'transparent',
    borderRadius: 5,
  },
  inputMaterial: {
    width: '100%',
  },
  textAreaNoEdit: {
    textAlign: 'justify',
    color: '#00597b',
    maxWidth: '100%',
    minWidth: '100%',
    fontWeight: 'bold',
    width: '100%',
    padding: theme.spacing(0.5, 0.5, 0.5, 0),
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
}));

const FactPendingInquiry = props => {
  const { clearReducer, setClearReducer, fact = undefined, status = null, write } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  const factReducer = useSelector(state => state.factInquiry);
  const { status: statusInquiryReducer } = useSelector(state => state.inquiry);

  const initialState = {
    date: '',
    time: '',
    shift: '',
    uf: 'PE',
    judicialDistrict: 'RECIFE',
    locality: '',
    isFlagrant: false,
    report: '',
  };

  const [formState, setFormState] = useState({
    fact: fact ? { ...fact } : { ...initialState },
  });

  const [inquiryStatus, setInquiryStatus] = useState(status || '');

  const [editFact, setEditFact] = useState(false);
  const [checkReducer, setCheckReducer] = useState(false);

  useEffect(() => {
    const errors = validate(formState.fact);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.fact]);

  useEffect(() => {
    setFormState(_formState => ({
      fact: { ...fact },
    }));
    setClearReducer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearReducer]);

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      fact: {
        ...formState.fact,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  const handleChangeInquiryStatus = event => {
    event.persist();
    setInquiryStatus(event.target.value);
  };

  const listShift = [
    {
      name: 'Manhã',
      value: 'MANHÃ',
    },
    {
      name: 'Tarde',
      value: 'TARDE',
    },
    {
      name: 'Noite',
      value: 'NOITE',
    },
  ];

  const handleCancelEdit = () => {
    setFormState(formState => ({
      ...formState,
      fact: { ...factReducer },
    }));
    setInquiryStatus(statusInquiryReducer);
    setEditFact(false);
  };

  const handleEditFact = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    if (checkReducer) {
      setFormState(formState => ({
        ...formState,
        fact: { ...factReducer },
      }));
      setInquiryStatus(statusInquiryReducer);
    } else {
      setFormState(formState => ({
        ...formState,
        fact: { ...fact },
      }));
      setInquiryStatus(inquiryStatus);
    }
    setEditFact(true);
  };

  const handleUpdateFact = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    dispatch(setFactInquiryReducer(formState.fact));
    dispatch(setStatusInquiryReducer(inquiryStatus));
    setCheckReducer(true);
    setEditFact(false);
  };

  useEffect(() => {
    dispatch(setFactInquiryReducer(fact));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fact]);

  useEffect(() => {
    dispatch(setStatusInquiryReducer(status));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <Box className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box style={{ width: '50%' }}>
          <Typography className={classes.titulo}>Fato</Typography>
        </Box>
        <Box width="50%" display="flex" justifyContent="flex-end" alignItems="center">
          {!editFact ? (
            <Tooltip
              title="Alterar Fatos do inquérito"
              arrow
              placement="top"
              style={{ marginLeft: 10 }}
            >
              <IconButton className={classes.buttonEditar} size="small" onClick={handleEditFact}>
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Box style={{ marginRight: 10 }}>
                <Tooltip title="Salvar alterações" arrow placement="top">
                  <IconButton
                    className={classes.buttonEditar}
                    size="small"
                    onClick={handleUpdateFact}
                    form="my-form-edit"
                    type="submit"
                  >
                    <SaveIcon style={{ color: '#1976d2' }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Cancelar" arrow placement="top">
                  <IconButton
                    className={classes.buttonEditar}
                    size="small"
                    onClick={handleCancelEdit}
                  >
                    <ClearIcon style={{ color: '#e1296a' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </Box>
      {fact && !editFact ? (
        <form className={classes.form}>
          <Grid container spacing={1}>
            <Grid item xs={7} sm={3}>
              <Box className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>Data *</span>
                <Typography className={classes.inputFormCrime}>
                  {formState.fact.date
                    ? formatDateToBrazilian(formState.fact.date)
                    : 'Não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={5} sm={3}>
              <Box className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>Hora</span>
                <Typography className={classes.inputFormCrime}>
                  {formState.fact.time || 'Não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={4} sm={2}>
              <Box className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>Turno</span>
                <Typography className={classes.inputFormCrime}>
                  {formState.fact.shift || 'Não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={8} sm={4}>
              <Box className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>Localidade</span>
                <Typography className={classes.inputFormCrime}>
                  {formState.fact.locality || 'Não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={7} sm={4}>
              <Box className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>Comarca</span>
                <Typography className={classes.inputFormCrime}>
                  {formState.fact.judicialDistrict || 'Não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3} sm={2}>
              <Box className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>UF</span>
                <Typography className={classes.inputFormCrime}>
                  {formState.fact.uf || 'Não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2} sm={1} style={{ justifyContent: 'start', display: 'flex' }}>
              <Box className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>Flagrante</span>
                <Typography className={classes.inputFormCrime}>
                  {formState.fact.isFlagrant ? 'Sim' : 'Não'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>Observação</span>
                <Typography className={classes.textAreaNoEdit}>
                  {inquiryStatus || 'Não informado'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} style={{ marginBottom: 10 }}>
              <div className={classes.containerLabelInput}>
                <span className={classes.labelFormCrime}>Relato</span>
                <Typography className={classes.textAreaNoEdit}>
                  {formState.fact.report || 'Não informado'}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </form>
      ) : (
        <form className={classes.form}>
          <Grid container spacing={1}>
            <Grid item xs={7} sm={3}>
              <div className={classes.containerLabelInput}>
                <KeyboardDatePicker
                  className={classes.inputMaterial}
                  variant="inline"
                  name="date"
                  maxDate={new Date()}
                  label="Data *"
                  value={formState.fact.date || null}
                  onChange={newDate =>
                    setFormState(formState => ({
                      ...formState,
                      fact: {
                        ...formState.fact,
                        date: newDate,
                      },
                    }))
                  }
                />
              </div>
            </Grid>
            <Grid item xs={5} sm={3}>
              <div className={classes.containerLabelInput}>
                <InputConsensus
                  id="time"
                  label="Hora"
                  name="time"
                  type="time"
                  onChange={handleChange}
                  value={formState.fact.time}
                  className={classes.inputMaterial}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={4} sm={2}>
              <div className={classes.containerLabelInput}>
                <InputConsensus
                  className={classes.inputMaterial}
                  id="standard-select-currency-uf-turno"
                  select
                  label="Turno"
                  value={formState.fact.shift}
                  onChange={handleChange}
                  name="shift"
                >
                  {listShift.map((v, i) => (
                    <MenuItem key={i} value={v.value}>
                      {v.name}
                    </MenuItem>
                  ))}
                </InputConsensus>
              </div>
            </Grid>
            <Grid item xs={8} sm={4}>
              <div className={classes.containerLabelInput}>
                <InputConsensus
                  className={classes.inputMaterial}
                  id="custom-css-standard-input-locality"
                  label="Localidade"
                  value={formState.fact.locality}
                  onChange={handleChange}
                  name="locality"
                  type="text"
                />
              </div>
            </Grid>
            <Grid item xs={3} sm={2}>
              <div className={classes.containerLabelInput}>
                <InputConsensus
                  className={classes.inputMaterial}
                  id="standard-select-currency-uf-address"
                  select
                  label="UF"
                  name="uf"
                  value={formState.fact.uf}
                  onChange={handleChange}
                >
                  {listUf().map((v, i) => (
                    <MenuItem key={i} value={v.value}>
                      {v.name}
                    </MenuItem>
                  ))}
                </InputConsensus>
              </div>
            </Grid>
            <Grid item xs={7} sm={4}>
              <div className={classes.containerLabelInput}>
                <InputConsensus
                  className={classes.inputMaterial}
                  id="standard-select-currency-uf-judicialDistrict"
                  select
                  label="Comarca"
                  value={formState.fact?.judicialDistrict?.toUpperCase()}
                  onChange={handleChange}
                  name="judicialDistrict"
                >
                  <MenuItem value={'RECIFE'}>RECIFE</MenuItem>
                </InputConsensus>
              </div>
            </Grid>
            <Grid item xs={2} sm={1} style={{ justifyContent: 'start', display: 'flex' }}>
              <div className={classes.containerLabelInput}>
                <label htmlFor="isFlagrant" className={classes.labelForm}>
                  Flagrante
                </label>
                <Checkbox
                  checked={formState.fact.isFlagrant}
                  name="isFlagrant"
                  size="small"
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classes.containerLabelInput}>
                <label htmlFor="status" className={classes.labelForm}>
                  Observação
                </label>
                <TextareaAutosize
                  className={classes.textArea}
                  rows={1}
                  rowsMax={2}
                  aria-label="maximum height"
                  name="status"
                  autoComplete="no"
                  onChange={handleChangeInquiryStatus}
                  value={inquiryStatus}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <div className={classes.containerLabelInput}>
                <label htmlFor="report" className={classes.labelForm}>
                  Relato
                </label>
                <TextareaAutosize
                  className={classes.textArea}
                  rows={3}
                  rowsMax={4}
                  aria-label="maximum height"
                  name="report"
                  autoComplete="no"
                  onChange={handleChange}
                  value={formState.fact.report}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      )}
    </Box>
  );
};

export default FactPendingInquiry;

/**
 * Clear string received in function: `FULANO DA SILVA`.
 * @param {name} name - FULANO DA SILVA*+&%$#@.
 */
export const clearSpecialCharacterName = name => {
  if (typeof name !== 'string') {
    return name;
  }

  // eslint-disable-next-line no-useless-escape
  return name.replace(/[@*/|\\,`´^~.;:<>{}$_+='"¨!@#%&?\-\(\)\[\]]/g, '');
};

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const RegisterButton = props => {
  const { onClick } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ButtonConsensus
        title="Cadastrar"
        backgroundColor={colors.primary}
        hovercolor={colors.primary}
        onClick={onClick}
      >
        Cadastrar
      </ButtonConsensus>
    </div>
  );
};

RegisterButton.propTypes = {
  onClick: PropTypes.func,
};

export default RegisterButton;

import React, { useState } from 'react';
import { IconButton, Tooltip, MenuItem, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { InputConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import userService from 'services/user/userService';
import { useUserTypes } from 'hooks/useUserTypes';
import PmService from 'services/PM/pmService';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  containerBtnEdit: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const ProsecutorToInquiry = props => {
  const {
    inquiry,
    prosecutor,
    prosecutorOffice: prosecutorOfficeProp,
    write,
    handleOpenSnackbar,
    refreshPage,
    canEdit,
    visualizeOnly = false,
  } = props;

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const [prosecutorsIsLoading, setProsecutorsIsLoading] = useState(false);
  const [editProsecutor, setEditProsecutor] = useState(false);
  const [prosecutors, setProsecutors] = useState([]);
  const [newProsecutor, setnewProsecutor] = useState({
    ...prosecutor,
    prosecutorOffices: prosecutorOfficeProp ? [prosecutorOfficeProp] : [],
  });
  const [prosecutorOffice, setProsecutorOffice] = useState(prosecutorOfficeProp);

  const getProsecutors = () => {
    setProsecutorsIsLoading(true);

    let params = {
      page: 1,
      size: 100,
    };

    if (inquiry?.prosecutorOfficeGroup) {
      params.prosecutorOfficeGroupIds = inquiry.prosecutorOfficeGroup.id;
    }

    userService
      .getUsersProsecutorsService(params)
      .then(response => {
        //console.log(response.data.data);
        setProsecutors(response.data.data);
      })
      .finally(() => {
        setProsecutorsIsLoading(false);
      });
  };

  const handleEditProsecutor = () => {
    //setnewProsecutor(prosecutor);
    getProsecutors();
    setEditProsecutor(true);
  };

  const handleCancelEditProsecutor = () => {
    setEditProsecutor(false);
  };

  const handleChangeProsecutor = (event, newValue) => {
    setnewProsecutor(newValue);
    setProsecutorOffice(null);
  };

  const validateRequest = () => {
    if (!newProsecutor?.id && !prosecutorOffice?.id) {
      toast.warn('Informe um promotor e sua promotoria');
      return;
    }
    if (!newProsecutor?.id) {
      toast.warn('Informe um promotor');
      return;
    }
    if (!prosecutorOffice?.id) {
      toast.warn('Informe uma promotoria');
      return;
    }

    handleUpdateProsecutor();
  };

  const handleUpdateProsecutor = () => {
    let params = {
      prosecutorId: newProsecutor.id,
      prosecutorOfficeId: prosecutorOffice.id,
    };
    if (isPM) {
      PmService.updateInquiryService(inquiry.id, params).then(() => {
        toast.success('Alteração realizada com sucesso!');
        setEditProsecutor(false);
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryService(inquiry.id, params).then(() => {
        toast.success('Alteração realizada com sucesso!');
        setEditProsecutor(false);
        refreshPage();
      });
    }
  };

  const handleChangeProsecutorOffice = event => {
    event.persist();
    setProsecutorOffice(event.target.value);
    //console.log(event.target.value);
  };

  return (
    <div className={classes.root}>
      {!editProsecutor ? (
        <div className={classes.containerBtnEdit}>
          Promotor(a):{` ${prosecutor ? prosecutor?.name : 'não informado'}`}
          {(!isPM || canEdit) && !visualizeOnly && (
            <Tooltip title="Alterar promotor(a)" arrow placement="top" style={{ marginLeft: 10 }}>
              <IconButton
                size="small"
                disabled
                onClick={() => (!write ? handleOpenSnackbar() : handleEditProsecutor())}
              >
                <CreateIcon style={{ color: '#808080' }} />
                {/* Cor do botão habilitado acima: 00b1ac */}
              </IconButton>
            </Tooltip>
          )}
        </div>
      ) : (
        <Grid container spacing={1}>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
          >
            <div>
              <Tooltip title="Salvar alterações" arrow placement="top" style={{ marginLeft: 10 }}>
                <IconButton size="small" onClick={validateRequest}>
                  <SaveIcon style={{ color: '#1976d2' }} />
                </IconButton>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Cancelar" arrow placement="top">
                <IconButton size="small" onClick={handleCancelEditProsecutor}>
                  <ClearIcon style={{ color: '#e1296a' }} />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Autocomplete
              options={prosecutors}
              loading={prosecutorsIsLoading}
              fullWidth
              size="small"
              loadingText="Aguarde um momento..."
              noOptionsText="Nada foi encontrado"
              value={newProsecutor}
              onChange={handleChangeProsecutor}
              getOptionLabel={option => (option.name ? option.name : '')}
              filterOptions={x => x}
              includeInputInList
              renderInput={params => (
                <InputConsensus
                  {...params}
                  name="prosecutor"
                  label="Promotor"
                  variant="standard"
                  inputProps={{
                    ...params.inputProps,
                  }}
                />
              )}
            />
          </Grid>

          {newProsecutor?.prosecutorOffices?.length > 0 && (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <InputConsensus
                select
                label="Promotoria"
                name="prosecutorOffice"
                value={prosecutorOffice}
                onChange={handleChangeProsecutorOffice}
                variant="standard"
                size="small"
                fullWidth
              >
                {newProsecutor?.prosecutorOffices?.map(office => (
                  <MenuItem key={office.id} value={office}>
                    {office.name}
                  </MenuItem>
                ))}
              </InputConsensus>
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};

export default ProsecutorToInquiry;

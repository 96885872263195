import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Tooltip,
  Box,
} from '@material-ui/core';
import { colors } from 'utils/colorsSteps';
import { formatedInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { changeNameStep } from 'utils/changeNameStep';
import { redirectToInquiryIdStep } from 'utils/genericFunctions/redirectToInquiryIdStep';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  paper: {
    marginTop: '.5rem',
  },
  cliqueMe: {
    color: colors.navBar,
    cursor: 'pointer',
    '&:hover': {
      color: colors.greenLight,
    },
  },
}));

const ListSurveysWithTheSameClass = props => {
  const { foundInquiries } = props;

  const customerConfig = useSelector(state => state.customerConfig);

  const classes = useStyles();

  const [parsedObjcts, setParsedObjcts] = useState([]);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!foundInquiries) return;

    setParsedObjcts(
      foundInquiries.investigated.map(item => ({
        id: item.id,
        policeInquiryNumber: foundInquiries.policeInquiryNumber,
        investigatedName: item.investigated.name,
        internalSystemNumber: foundInquiries.internalSystemNumber,
        step: item.step,
      })),
    );
  }, [foundInquiries]);

  return (
    <Card className={classes.root}>
      <Typography variant="h6">
        Existe um ou mais inquéritos com o mesmo número e classe no Consensus.
        <Box onClick={() => setExpanded(expanded => !expanded)}>
          <b className={classes.cliqueMe}> Clique aqui </b>
        </Box>
        para verificar
      </Typography>
      <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: '100%' }}>
        <Paper elevation={2} className={classes.paper}>
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">N° inquérito</TableCell>
                  <TableCell align="center">Investigado</TableCell>
                  <TableCell align="center">Número Interno</TableCell>
                  <TableCell align="center">Fase</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parsedObjcts.map((item, index) => (
                  <Tooltip title="Ir à tela deste inquérito" arrow key={index}>
                    <TableRow
                      hover
                      style={{ cursor: 'pointer' }}
                      onClick={() => redirectToInquiryIdStep(item)}
                    >
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">
                          {formatedInquiryNumber(item.policeInquiryNumber)}
                        </Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">{item.investigatedName}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">{item.internalSystemNumber}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <Typography variant="body2">
                          {changeNameStep(item.step, customerConfig)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Collapse>
    </Card>
  );
};

export default ListSurveysWithTheSameClass;

import React, { useState, useEffect } from 'react';
import { Dialog, Grid, Typography, IconButton, Card, DialogContent, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import axios from '../../../../utils/axios';
import DescriptionIcon from '@material-ui/icons/Description';
import CircularProgress from '@material-ui/core/CircularProgress';
import HeaderModal from 'components/HeaderModal';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerModal: {
    //minWidth: 500,
    minHeight: 400,
    maxHeight: 450,
    width: 500,
    overflow: 'auto',
    '@media (max-width:500px)': {
      minWidth: 300,
      minHeight: 350,
    },
    '@media (max-width:960px)': {
      minWidth: 300,
      minHeight: 350,
    },
  },
  header: {
    width: '100%',
    backgroundColor: '#00597b',
    height: 70,
    color: '#ffffff',
    paddingLeft: 30,
    fontSize: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: 25,
    padding: 20,
    textAlign: 'center',
    width: 450,
    '@media (max-width:700px)': {
      width: 345,
    },
  },
  msgBody: {
    fontSize: 20,
    color: '#00597b',
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
  },
  dadosCriminais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 18,
  },
  containerDadosPessoais: {
    backgroundColor: '#ededed',
    marginTop: 5,
    padding: 10,
    borderRadius: 25,
  },
  labelDadoaPessoais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
  },
  labelCrime: {
    fontWeight: 'bold',
    color: '#000',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8cf5f',
    width: '60%',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  textArea: {
    width: '100%',
    padding: 5,
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
  },
  containerConfirm: {
    height: 380,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#787878',
    fontSize: 22,
    fontWeight: 'bold',
    padding: 100,
    '@media (max-width:690px)': {
      padding: 30,
    },
  },
  btnConfirm: {
    backgroundColor: '#00b1ac',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 40,
    height: 60,
    width: 170,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    marginBottom: 0,
    paddingLeft: 10,
    paddingTop: 5,
  },
  containerRow: {
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 50,
  },
}));

const ModalOthers = props => {
  const { open, inquiry, onClose } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const page = 1;
  const size = 20;

  const [customOrg, setCustomOrg] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCustomAntecedentes = async () => {
    setLoading(true);

    let params = {
      page,
      size,
    };

    axios
      .get(`criminal-antecedents/custom-organs-queries/${inquiry.id}`, params)
      .then(response => {
        //console.log({ response: response.data.data });
        setCustomOrg(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomAntecedentes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToFile = url => window.open(url, '_blank');
  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={onClose}
      open={open}
      aria-labelledby="responsive-dialog-title"
      className={classes.modal}
      maxWidth="sm"
      fullWidth
    >
      <HeaderModal
        onClose={onClose}
        backgroundColor={colors.navBar}
        colorIconTitle={colors.greySecundary}
      >
        Outros Órgãos
      </HeaderModal>
      <DialogContent>
        <Box>
          {loading ? (
            <Box display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          ) : (
            <Grid container spacing={0}>
              {customOrg.length > 0
                ? customOrg.map((v, i) => {
                    return v.organName !== 'IITB' &&
                      v.organName !== 'JFPE' &&
                      v.organName !== 'TJPE' &&
                      v.organName !== 'CONSENSUS' ? (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.containerRow}
                      >
                        <Card className={classes.row}>
                          <div style={{ paddingLeft: 25 }}>
                            <Typography style={{ fontSize: 16 }}>{v.organName}</Typography>
                          </div>
                          <div
                            style={{
                              paddingRight: 15,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <CheckCircleIcon
                              style={{
                                color:
                                  v.isOk === null
                                    ? '#f7d85e'
                                    : v.isOk === true
                                    ? '#00b1ac'
                                    : v.isOk === false
                                    ? '#e81224'
                                    : '',
                              }}
                            />
                            <IconButton style={{ marginLeft: 15 }}>
                              <DescriptionIcon onClick={() => handleGoToFile(v.certificateUrl)} />
                            </IconButton>
                          </div>
                        </Card>
                      </Grid>
                    ) : (
                      ''
                    );
                  })
                : 'Não há arquivos'}
            </Grid>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalOthers;

export const FACT_INQUIRY = 'FACT_INQUIRY';
export const CLEAR_FACT_INQUIRY = 'CLEAR_FACT_INQUIRY';

export const setFactInquiryReducer = fact => ({
  type: FACT_INQUIRY,
  payload: fact,
});

export const clearFactInquiryReducer = () => ({
  type: CLEAR_FACT_INQUIRY,
});

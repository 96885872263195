import React from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { colors } from 'utils/colorsSteps';

const HeaderModal = props => {
  const { children, backgroundColor, colorIconTitle, onClose, ...other } = props;

  const useStyles = makeStyles(theme => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: backgroundColor,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    colorIcon: {
      color: colorIconTitle,
    },
    textTitle: {
      color: colorIconTitle,
    },
  }));

  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography className={classes.textTitle} variant="h5" style={{ paddingLeft: 5 }}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon className={classes.colorIcon} fontSize="small" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

HeaderModal.propTypes = {
  onClose: PropTypes.func,
  backgroundColor: PropTypes.string,
  colorIconTitle: PropTypes.string,
};

HeaderModal.defaultProps = {
  backgroundColor: '#fff',
  colorIconTitle: colors.greyShadow,
};

export default HeaderModal;

export const SET_LINKING_INQUIRY_ID = 'SET_LINKING_INQUIRY_ID';
export const CLEAR_LINKING_INQUIRY_ID = 'CLEAR_LINKING_INQUIRY_ID';

export const SET_LINKING_INQUIRY_ID_IN_MODAL_LINKING = 'SET_LINKING_INQUIRY_ID_IN_MODAL_LINKING';
export const CLEAR_LINKING_INQUIRY_ID_IN_MODAL_LINKING =
  'CLEAR_LINKING_INQUIRY_ID_IN_MODAL_LINKING';

export const setLinkingInquiryId = boundInquiryId => ({
  type: SET_LINKING_INQUIRY_ID,
  payload: boundInquiryId,
});

export const clearLinkingInquiryId = () => ({
  type: CLEAR_LINKING_INQUIRY_ID,
});

export const setLinkingInquiryIdInModal = boundInquiryId => ({
  type: SET_LINKING_INQUIRY_ID_IN_MODAL_LINKING,
  payload: boundInquiryId,
});

export const clearLinkingInquiryIdInModal = () => ({
  type: CLEAR_LINKING_INQUIRY_ID_IN_MODAL_LINKING,
});

import * as actionTypes from 'actions';
import { CUSTOMER_CONFIG as CC } from 'constants/customerConfig';

const initialState = () => {
  let initialValues = {};
  for (const key in CC) {
    initialValues[CC[key]] = false;
  }
  return initialValues;
};

const customerConfig = (state = initialState(), action) => {
  switch (action.type) {
    case actionTypes.SET_CUSTOMER_CONFIG:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.CLEAR_CUSTOMER_CONFIG:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default customerConfig;

import React from 'react';
import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { formatDateToBrazilian, formatHourToISO8601 } from 'utils/date-time.utils';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles({
  root: {
    backgroundColor: '#ededed',
    borderRadius: 20,
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 200,
    boxShadow: 'none',
    border: '1px solid #707070',
  },
  title: {
    color: '#1c6b88',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    paddingLeft: 6,
  },
});

const PendingHearings = props => {
  const { inquiry, ...rest } = props;

  const classes = useStyles();

  const pendingHearingsList = inquiry.hearings.filter(hearing => hearing.status === 'CONFIRMED');

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  return (
    <Card {...rest} className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item container xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography className={classes.title}>
            {`Audiência${enableHearingForJuvenileOffenders ? '/Oitiva' : ''} Pendente`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: 15, paddingRight: 15 }}
        >
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              {pendingHearingsList?.length < 1 && (
                <caption style={{ textAlign: 'center' }}>Não há audiências pendentes</caption>
              )}
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <Typography variant="body1">Data</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Hora</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Modo</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Observação</Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="body1">Local</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {pendingHearingsList?.map(hearing => (
                  <TableRow>
                    <TableCell component="th" scope="row" align="center">
                      {formatDateToBrazilian(hearing.date)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {formatHourToISO8601(hearing.date)}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {hearing.isInPerson ? 'Presencial' : 'Remoto'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {hearing.observation || '-'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {hearing.place || '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  );
};

export default PendingHearings;

import React from 'react';
import { Box } from '@mui/material';
import { keyframes } from '@emotion/react';

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 
  40% {
    transform: scale(1);
  }
`;

const Dot = ({ delay }) => (
  <Box
    component="span"
    sx={{
      width: 8,
      height: 8,
      backgroundColor: 'grey.700',
      borderRadius: '50%',
      display: 'inline-block',
      margin: '0 4px',
      animation: `${bounce} 1.4s infinite ease-in-out both`,
      animationDelay: delay,
    }}
  />
);

const TypingIndicator = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Dot delay="0s" />
      <Dot delay="0.2s" />
      <Dot delay="0.4s" />
    </Box>
  );
};

export default TypingIndicator;

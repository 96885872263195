import React from 'react';
import {
  Grid,
  IconButton,
  Collapse,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { parcelsAmountSum, parcelsHoursSum, orderParcelsByInitialDate } from 'utils/parcels';
import { formatDateToBrazilian } from 'utils/date-time.utils';
import { getOnlyNumbers, currencyFormat } from 'utils/currency';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { INSTALLMENT_TYPES } from 'constants/agreement/installmentTypes';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  inputInquiry: {
    height: 35,
    width: '100%',
    border: `1px solid #747474`,
    padding: 10,
    backgroundColor: 'transparent',
    marginRight: 10,
    marginTop: 5,
  },
  btnSalvar: {
    color: '#ffffff',
    backgroundColor: '#3bc3bf',
    '&:hover': {
      backgroundColor: '#00597b',
    },
  },
  table: {
    width: '100%',
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const parcelsSum = parcels => {
  const numbers = parcels.map(parcel => parseInt(getOnlyNumbers(parcel.value)));
  const total = numbers.reduce((accumulator, value) => accumulator + value, 0);
  return currencyFormat(total / 100);
};

function Row(props) {
  const {
    handleConfirmDeletion,
    description,
    editPenalty,
    index,
    row,
    edit,
    disabledActions,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const { isMPDFT, enablePenaltyTypes, enableInstallmentTypes } = useCustomerConfig();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.penalty === PENALTY_KINDS.OTHER_PENALTIES && isMPDFT
            ? PENALTY_KINDS.OTHER_PENALTIES_MPDFT
            : row.penalty}
        </TableCell>
        {row.penalty === PENALTY_KINDS.PECUNIARY ? (
          row?.parcels ? (
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {enableInstallmentTypes &&
              [
                INSTALLMENT_TYPES.VARIABLE_PER_PERIOD,
                INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE,
              ].includes(row.installmentType)
                ? currencyFormat(Number(getOnlyNumbers(row.totalValue || row.value) / 100))
                : parcelsSum(row.parcels)}
            </TableCell>
          ) : (
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {currencyFormat(Number(getOnlyNumbers(row.value) / 100))}
            </TableCell>
          )
        ) : row.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM ||
          row.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT ? (
          row?.parcels ? (
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {parcelsSum(row.parcels)}
            </TableCell>
          ) : (
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {currencyFormat(Number(getOnlyNumbers(row.estimatedValue) / 100))}
            </TableCell>
          )
        ) : row.penalty === PENALTY_KINDS.MATERIALS ? (
          row?.parcels ? (
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {parcelsAmountSum(row.parcels)}
            </TableCell>
          ) : (
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {row?.amount} unidade(s)
            </TableCell>
          )
        ) : row.penalty === PENALTY_KINDS.SERVICES ? (
          row?.parcels ? (
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {parcelsHoursSum(row.parcels)}
            </TableCell>
          ) : (
            <TableCell align="center" style={{ fontWeight: 'bold' }}>
              {row?.hours} horas
            </TableCell>
          )
        ) : (
          <TableCell align="center" style={{ fontWeight: 'bold' }} />
        )}
        {edit ? (
          <TableCell>
            <Box
              align="center"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Button
                color="primary"
                size="small"
                disabled={disabledActions[index]?.edit}
                onClick={() => editPenalty(row, index, description)}
              >
                <EditIcon />
              </Button>
              <Button
                color="primary"
                size="small"
                disabled={disabledActions[index]?.delete}
                onClick={() => handleConfirmDeletion(row, index, description)}
              >
                <DeleteIcon />
              </Button>
            </Box>
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Detalhes
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {row.penalty === PENALTY_KINDS.PECUNIARY && (
                      <>
                        {enableInstallmentTypes && (
                          <TableCell align="center">Parcelamento</TableCell>
                        )}
                        {enablePenaltyTypes && <TableCell align="center">Tipo</TableCell>}
                        <TableCell align="center">Data do pagamento</TableCell>
                        {enableInstallmentTypes &&
                          row.installmentType === INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE && (
                            <TableCell align="center">Data inicial</TableCell>
                          )}
                        {enableInstallmentTypes &&
                          [INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE].includes(
                            row.installmentType,
                          ) && (
                            <>
                              <TableCell align="center">Valor mínimo mensal</TableCell>
                              <TableCell align="center">Valor máximo mensal</TableCell>
                            </>
                          )}
                        {enableInstallmentTypes &&
                          [INSTALLMENT_TYPES.VARIABLE_PER_PERIOD].includes(row.installmentType) && (
                            <>
                              <TableCell align="center">Valor mínimo</TableCell>
                              <TableCell align="center">Valor máximo</TableCell>
                            </>
                          )}
                        {row?.parcels &&
                          (!enableInstallmentTypes ||
                            (enableInstallmentTypes &&
                              row.installmentType === INSTALLMENT_TYPES.FIXED_PER_PERIOD)) && (
                            <TableCell align="center">Valor</TableCell>
                          )}
                        <TableCell align="center">Instituição</TableCell>
                        <TableCell align="center">Projeto</TableCell>
                      </>
                    )}
                    {(row.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM ||
                      row.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT) && (
                      <>
                        <TableCell align="center">Vítima</TableCell>
                        <TableCell align="center">Valor R$ estimado</TableCell>
                        <TableCell align="center">
                          {isMPDFT ? 'Data de Pagamento' : 'Pagamento pelo investigado'}
                        </TableCell>
                        <TableCell align="center">
                          {isMPDFT ? 'Data de Recebimento pela Vítima' : 'Recebimento pela vítima'}
                        </TableCell>
                        <TableCell align="center">Descrição</TableCell>
                      </>
                    )}
                    {row.penalty === PENALTY_KINDS.MATERIALS && (
                      <>
                        <TableCell align="center">Material</TableCell>
                        <TableCell align="center">Instituição</TableCell>
                        <TableCell align="center">Projeto</TableCell>
                        <TableCell align="center">Data da entrega</TableCell>
                        {row?.parcels && <TableCell align="center">Quantidade</TableCell>}
                      </>
                    )}
                    {row.penalty === PENALTY_KINDS.SERVICES && (
                      <>
                        <TableCell align="center">Serviço</TableCell>
                        <TableCell align="center">Instituição</TableCell>
                        <TableCell align="center">Projeto</TableCell>
                        <TableCell align="center">Data</TableCell>
                        {row?.parcels && <TableCell align="center">Horas</TableCell>}
                      </>
                    )}
                    {row.penalty === PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES && (
                      <>
                        <TableCell align="center">Data prevista para cumprimento</TableCell>
                        <TableCell align="center">Instituição</TableCell>
                        <TableCell align="center">Projeto</TableCell>
                        <TableCell align="center">Descrição</TableCell>
                        <TableCell align="center">Tipo</TableCell>
                      </>
                    )}
                    {[PENALTY_KINDS.OTHER_PENALTIES, PENALTY_KINDS.OTHER_PENALTIES_MPDFT].includes(
                      row.penalty,
                    ) && (
                      <>
                        <TableCell align="center">Instituição</TableCell>
                        <TableCell align="center">Projeto</TableCell>
                        <TableCell align="center">Tipo</TableCell>
                        <TableCell align="center">Descrição</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.penalty === PENALTY_KINDS.PECUNIARY && row?.parcels ? (
                    orderParcelsByInitialDate(row.parcels).map((item, index) => (
                      <TableRow key={index}>
                        {enableInstallmentTypes && (
                          <TableCell component="th" scope="row" width="10%" align="center">
                            {row?.installmentType}
                          </TableCell>
                        )}
                        {enablePenaltyTypes && (
                          <TableCell component="th" scope="row" width="10%" align="center">
                            {row?.type}
                          </TableCell>
                        )}
                        <TableCell component="th" scope="row" width="10%" align="center">
                          {item.date ? formatDateToBrazilian(item.date) : 'A definir'}
                        </TableCell>
                        {enableInstallmentTypes &&
                          row.installmentType === INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE && (
                            <TableCell component="th" scope="row" width="10%" align="center">
                              {item.initialDate
                                ? formatDateToBrazilian(item.initialDate)
                                : 'A definir'}
                            </TableCell>
                          )}
                        {enableInstallmentTypes &&
                          [
                            INSTALLMENT_TYPES.VARIABLE_PER_PERIOD,
                            INSTALLMENT_TYPES.PAID_BY_SPECIFIC_DATE,
                          ].includes(row.installmentType) && (
                            <>
                              <TableCell component="th" scope="row" width="10%" align="center">
                                {item.minimumValue
                                  ? currencyFormat(Number(getOnlyNumbers(item.minimumValue) / 100))
                                  : ''}
                              </TableCell>
                              <TableCell component="th" scope="row" width="10%" align="center">
                                {item.maximumValue
                                  ? currencyFormat(Number(getOnlyNumbers(item.maximumValue) / 100))
                                  : ''}
                              </TableCell>
                            </>
                          )}
                        {row?.parcels &&
                          (!enableInstallmentTypes ||
                            (enableInstallmentTypes &&
                              row.installmentType === INSTALLMENT_TYPES.FIXED_PER_PERIOD)) && (
                            <TableCell component="th" scope="row" align="center">
                              {currencyFormat(Number(getOnlyNumbers(item?.value) / 100))}
                            </TableCell>
                          )}
                        <TableCell component="th" scope="row" align="center">
                          {row?.association?.name ||
                            row?.relation?.association?.name ||
                            'A definir'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row?.project?.name || 'A definir'}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : row.penalty === PENALTY_KINDS.MATERIALS && row?.parcels ? (
                    orderParcelsByInitialDate(row.parcels).map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{row?.material?.name || 'A definir'}</TableCell>
                        <TableCell align="center">
                          {row?.association?.name ||
                            row?.relation?.association?.name ||
                            'A definir'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row?.project?.name || 'A definir'}
                        </TableCell>
                        <TableCell align="center" width="10%">
                          {item.date || row.deliveryDeadline
                            ? formatDateToBrazilian(item.date || row.deliveryDeadline)
                            : 'A definir'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {item?.amount}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : row.penalty === PENALTY_KINDS.SERVICES && row?.parcels ? (
                    orderParcelsByInitialDate(row.parcels).map((item, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{row?.service?.name || 'A definir'}</TableCell>
                        <TableCell align="center">
                          {row?.association?.name ||
                            row?.relation?.association?.name ||
                            'A definir'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {row?.project?.name || 'A definir'}
                        </TableCell>
                        <TableCell align="center" width="10%">
                          {item.date ? formatDateToBrazilian(item.date) : 'A definir'}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {item?.hours}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      {row.penalty === PENALTY_KINDS.PECUNIARY && (
                        <TableCell component="th" scope="row">
                          {row.paymentDeadline
                            ? formatDateToBrazilian(row.paymentDeadline)
                            : 'A definir'}
                        </TableCell>
                      )}
                      {(row.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM ||
                        row.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT) && (
                        <>
                          <TableCell align="center">
                            {row?.victim?.name || 'Não informado'}
                          </TableCell>
                          <TableCell align="center">
                            {currencyFormat((row?.estimatedValue || 0) / 100)}
                          </TableCell>
                          <TableCell align="center" width="10%">
                            {row?.deliveryDeadline
                              ? formatDateToBrazilian(row.deliveryDeadline)
                              : 'A definir'}
                          </TableCell>
                          <TableCell align="center" width="10%">
                            {row?.dateVictimCanReceive
                              ? formatDateToBrazilian(row.dateVictimCanReceive)
                              : 'A definir'}
                          </TableCell>
                        </>
                      )}
                      {row.penalty === PENALTY_KINDS.MATERIALS && (
                        <>
                          <TableCell align="center">{row?.material?.name || 'A definir'}</TableCell>
                          <TableCell align="center">
                            {row?.association?.name ||
                              row?.relation?.association?.name ||
                              'A definir'}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row?.project?.name || 'A definir'}
                          </TableCell>
                          <TableCell align="center">
                            {row.deliveryDeadline
                              ? formatDateToBrazilian(row.deliveryDeadline)
                              : 'A definir'}
                          </TableCell>
                        </>
                      )}
                      {row.penalty === PENALTY_KINDS.SERVICES && (
                        <>
                          <TableCell align="center">{row?.service?.name || 'A definir'}</TableCell>
                          <TableCell align="center">
                            {row?.association?.name ||
                              row?.relation?.association?.name ||
                              'A definir'}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row?.project?.name || 'A definir'}
                          </TableCell>
                          <TableCell align="center">
                            {row.inicialDate ? formatDateToBrazilian(row.inicialDate) : 'A definir'}
                          </TableCell>
                        </>
                      )}
                      {row.penalty === PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES && (
                        <>
                          <TableCell align="center">
                            {formatDateToBrazilian(row?.expectedDate)}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row?.association?.name ||
                              row?.relation?.association?.name ||
                              'A definir'}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {row?.project?.name || 'A definir'}
                          </TableCell>
                          <Tooltip title={row?.description} placement="top">
                            <TableCell component="th" scope="row" align="center">
                              {row?.description?.substring(0, 35) + '...'}
                            </TableCell>
                          </Tooltip>
                          {enablePenaltyTypes && <TableCell align="center">{row?.type}</TableCell>}
                        </>
                      )}
                      {[
                        PENALTY_KINDS.OTHER_PENALTIES,
                        PENALTY_KINDS.OTHER_PENALTIES_MPDFT,
                      ].includes(row.penalty) && (
                        <>
                          <TableCell align="center">
                            {row.association?.name || 'A definir'}
                          </TableCell>
                          <TableCell align="center">{row.project?.name || 'A definir'}</TableCell>
                          <TableCell align="center">{row.type}</TableCell>
                        </>
                      )}
                      {(row.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM ||
                        row.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT) &&
                      (row?.description || '').length <= 35 ? (
                        <TableCell component="th" scope="row" align="center">
                          {row?.description}
                        </TableCell>
                      ) : (
                        row.penalty !== PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES && (
                          <Tooltip title={row?.description} placement="top" align="center">
                            <TableCell component="th" scope="row">
                              {row?.description?.substring(0, 35) + '...'}
                            </TableCell>
                          </Tooltip>
                        )
                      )}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Penalties = props => {
  const {
    penalties,
    description,
    handleConfirmDeletion = {},
    editPenalty = {},
    edit = true,
    disabledActions = {},
  } = props;
  const classes = useStyles();

  const { isMPDFT } = useCustomerConfig();

  return (
    <Grid container spacing={2} className={classes.containerForm}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="penalties">
            {penalties.length < 1 && (
              <caption style={{ textAlign: 'center' }}>Não há dados adicionados</caption>
            )}
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell align="center">{isMPDFT ? 'Medida' : 'Penalidade'}</TableCell>
                <TableCell align="center">Qtd/Valor/Horas</TableCell>
                {edit ? <TableCell align="center">Ações</TableCell> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {penalties.map((item, index) => (
                <Row
                  index={index}
                  row={item}
                  edit={edit}
                  handleConfirmDeletion={handleConfirmDeletion}
                  editPenalty={editPenalty}
                  description={description}
                  disabledActions={disabledActions}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default Penalties;

export const SET_BOUND_INSTITUTION = 'SET_BOUND_INSTITUTION';
export const CLEAR_BOUND_INSTITUTION = 'CLEAR_BOUND_INSTITUTION';

export const setBoundInstitutionReducer = boundInstitution => ({
  type: SET_BOUND_INSTITUTION,
  payload: boundInstitution,
});

export const clearBoundInstitution = () => ({
  type: CLEAR_BOUND_INSTITUTION,
});

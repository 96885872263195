export const AI_MESSAGES = 'AI_MESSAGES';
export const CLEAR_AI_MESSAGES = 'CLEAR_AI_MESSAGES';
export const SET_CHAT_AI_SITUATION = 'SET_CHAT_AI_SITUATION';
export const SET_CHAT_AI_FEATURE = 'SET_CHAT_AI_FEATURE';

export const setAiMessages = messages => ({
  type: AI_MESSAGES,
  payload: messages,
});

export const clearAiMessages = () => ({
  type: CLEAR_AI_MESSAGES,
  payload: null,
});

export const setChatAiSituation = newSituation => ({
  type: SET_CHAT_AI_SITUATION,
  payload: newSituation,
});

export const setChatAiFeature = newFeature => ({
  type: SET_CHAT_AI_FEATURE,
  payload: newFeature,
});

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { colors } from 'utils/colorsSteps';

const ButtonConsensus = props => {
  const {
    color,
    size,
    margin,
    backgroundColor,
    hovercolor,
    title,
    onClick,
    className,
    width,
    ...rest
  } = props;

  const useStyles = makeStyles(theme => ({
    root: {
      backgroundColor: backgroundColor,
      '&:hover': {
        backgroundColor: hovercolor,
      },
      color: color,
      borderRadius: 25,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    // TODO
    container: {
      //margin: theme.spacing(1),
      margin: margin,
      width: width || undefined,
      [theme.breakpoints.down('xs')]: {
        margin: theme.spacing(0.3),
        width: '100%',
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Button
        {...rest}
        className={clsx(classes.root, className)}
        variant="contained"
        onClick={onClick}
        size={size}
      >
        {title}
        {/* {children ? children : null} */}
      </Button>
    </div>
  );
};

ButtonConsensus.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  hovercolor: PropTypes.string,
  color: PropTypes.string,
  margin: PropTypes.number,
  size: PropTypes.string,
  width: PropTypes.number,
};

ButtonConsensus.defaultProps = {
  backgroundColor: colors.navBar,
  hovercolor: colors.navBar,
  color: '#ffffff',
  margin: 8,
  size: 'large',
};

export default ButtonConsensus;

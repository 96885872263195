import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {
    color: colors.navBar,
  },
  result: {
    color: colors.navBar,
    fontWeight: 'bold',
  },
  resultReport: {
    color: colors.navBar,
    fontWeight: 'bold',
    textAlign: 'justify',
  },
}));

const JusticeDataCompany = props => {
  const { inquiry } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3" className={classes.title}>
            Dados da Justiça
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Typography variant="body1" className={classes.title}>
            NPU
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.npu || 'não informado'}
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Typography variant="body1" className={classes.title}>
            Vara/Local
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.judgmentPlace?.name || 'não informado'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default JusticeDataCompany;

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Typography, Grid, Card } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { HeaderModal } from 'components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {},
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textTitle: {},
  children: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerDropzone: {
    minHeight: 150,
    padding: theme.spacing(1, 2),
  },
  containerListFiles: {
    padding: theme.spacing(1, 2),
  },
  cardFileContainer: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerModal: {
    padding: theme.spacing(1, 0),
  },
}));

const ModalAddFileGeneric = props => {
  const {
    open,
    onClose,
    className,
    fileInBase64,
    setFileInBase64,
    uploadObject,
    setUploadObject,
    multiple,
    children,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [showFiles, setShowFiles] = useState(true);

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: multiple,
    onDrop: files => filterFiles(files),
  });

  //Verifica se o arquivo já existe no state uploadObject.
  const filterFiles = files => {
    files.forEach(v => {
      var index = uploadObject.findIndex(upload => upload.name === v.name);
      if (index === -1) {
        if (multiple) {
          setUploadObject([...uploadObject, v]);
        } else {
          setUploadObject([v]);
        }
        getBase64(v);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    });
  };

  //Converter arquivo para Base64
  const getBase64 = file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      var result = reader.result;

      //Verifica se o arquivo já existe no state.
      var index = fileInBase64.findIndex(upload => upload.name === file.name);

      if (index === -1) {
        if (multiple) {
          setFileInBase64([
            ...fileInBase64,
            {
              name: file.name,
              base64: result,
              //mimetype: v.type
            },
          ]);
        } else {
          setFileInBase64([
            {
              name: file.name,
              base64: result,
              //mimetype: v.type
            },
          ]);
        }
      } else {
        toast.warn('Arquivo já anexado.');
      }
    };
    reader.onerror = function(_error) {
      //console.log('Error: ', error);
    };
  };

  useEffect(() => {
    setShowFiles(true);
  }, [showFiles]);

  const handleRemoveFile = (fileANex, i) => {
    setShowFiles(false);
    uploadObject.splice(i, 1);
    const newList = uploadObject.filter(file => file.name !== fileANex.name);
    setUploadObject(newList);
    setFileInBase64(newList);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <HeaderModal onClose={onClose}>Anexar arquivo</HeaderModal>
        <div className={classes.containerModal}>
          <Grid container style={{ height: '100%', width: '100%' }}>
            {children ? (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.containerDropzone}
                >
                  <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                    <input {...getInputProps()} />
                    <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.containerListFiles}
                >
                  <div>
                    <Typography variant="body1">Arquivos {uploadObject.length}</Typography>

                    {uploadObject.map((file, i) => (
                      <div key={i}>
                        {showFiles && (
                          <Card className={classes.cardFileContainer}>
                            <Typography
                              style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}
                            >
                              <PictureAsPdfIcon style={{ marginRight: 5 }} />
                              {file !== null ? file.name : ''} {/*- {file.size} bytes*/}
                            </Typography>
                            <HighlightOffIcon
                              onClick={() => handleRemoveFile(file, i)}
                              style={{ color: '#e81224', cursor: 'pointer', marginLeft: 5 }}
                            />
                          </Card>
                        )}
                      </div>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.children}>
                  {children}
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.containerDropzone}
                >
                  <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                    <input {...getInputProps()} />
                    <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.containerListFiles}
                >
                  <aside>
                    <Typography variant="body1">Arquivos {uploadObject.length}</Typography>

                    {uploadObject.map((file, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          {showFiles && (
                            <>
                              <Typography
                                style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}
                              >
                                <PictureAsPdfIcon style={{ marginRight: 5 }} />
                                {file !== null ? file.name : ''} {/*- {file.size} bytes*/}
                              </Typography>
                              <HighlightOffIcon
                                onClick={() => handleRemoveFile(file, i)}
                                style={{ color: '#e81224', cursor: 'pointer', marginLeft: 5 }}
                              />
                            </>
                          )}
                        </div>
                      );
                    })}
                  </aside>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

ModalAddFileGeneric.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  fileInBase64: PropTypes.array,
  setFileInBase64: PropTypes.func.isRequired,
  uploadObject: PropTypes.array,
  setUploadObject: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

ModalAddFileGeneric.defaultProps = {
  multiple: false,
};

export default ModalAddFileGeneric;

import React, { useState, useEffect } from 'react';
import {
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { HeaderModal, ButtonConsensus } from 'components';
import { useTheme } from '@material-ui/core/styles';
import { colors } from 'utils/colorsSteps';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { ModalListFIles } from '../../views/DetailPendingInquiry/components';
import { toast } from 'react-toastify';
import history from 'utils/history';
import inquiryService from 'services/inquiries/inquiryService';
import organService from 'services/organ/organService';
import inquiryReturnationService from 'services/inquiry-returnation/inquiryReturnationService';
import PendingInquiryService from 'services/pending-inquiries/pendingInquiryService';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      flex: 0.8,
    },
  },
  containerTextArea: {
    margin: theme.spacing(2, 0),
  },
  action: {
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      alignItems: 'flex-start',
      padding: theme.spacing(0, 2.5),
    },
  },
}));

const GiveBackGenericInquiryModal = props => {
  const {
    open,
    onClose,
    inquiry,
    otherInvestigateds,
    isBatch,
    inquiryList,
    batchSourceOrgan,
    ...rest
  } = props;

  const classes = useStyles();
  const sourceOrgan = batchSourceOrgan || inquiry?.inquiry?.sourceOrgan;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const initialState = {
    typeDevolution: '',
    description: '',
    email: sourceOrgan?.email || '',
    requireResponse: '',
  };

  const [formState, setFormState] = useState({
    values: { ...initialState },
  });

  const emails = sourceOrgan?.email
    ? [sourceOrgan?.email, ...(sourceOrgan?.alternativeEmails || [])]
    : [...(sourceOrgan?.alternativeEmails || [])];

  const [files, setFiles] = useState([]);
  const [manipulateChecks, setManipulateChecks] = useState([]);
  const [openModalListFile, setOpenModalListFIle] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const [textMode, setTextMode] = useState(emails.length === 0);

  const [returnationTypes, setReturnationTypes] = useState([]);
  const [focused, setFocused] = useState(false);

  const [ccEmails, setCcEmails] = useState([]);

  const handleCloseModalListFiles = () => {
    setOpenModalListFIle(false);
  };

  const handleClose = () => {
    onClose();
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeReturnationType = event => {
    event.persist();
    if (!isBatch) {
      const envolvedInvestigateds = otherInvestigateds?.map(e => e.name);
      envolvedInvestigateds.push(inquiry.investigated.name);
      const victims = inquiry.inquiry.victims.map(victim => victim.name);
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          typeDevolution: event.target.value,
          description: `${event.target.value?.description ||
            ''}\n\nDelegacia de origem: ${sourceOrgan?.name ||
            ''}\nNúmero do procedimento: ${inquiry.inquiry?.policeInquiryNumber ||
            ''}\nInvestigados: ${envolvedInvestigateds.toString() ||
            ''}\nVítimas: ${victims.toString()}`,
        },
      }));
    } else {
      let description = event.target.value?.description || '';
      inquiryList.forEach(({ inquiryData }) => {
        const investigateds = inquiryData.investigated.map(e => e.name);
        const victims = inquiryData.victims.map(e => e.name);
        description += `\n\nDelegacia de origem: ${sourceOrgan?.name ||
          ''}\nNúmero dos procedimento: ${inquiryData.policeInquiryNumber ||
          ''}\nInvestigados: ${investigateds.toString() || ''}\nVítimas: ${victims.toString()}`;
      });
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          typeDevolution: event.target.value,
          description,
        },
      }));
    }
  };

  const handleConfirm = event => {
    event.preventDefault();

    const { description, typeDevolution, email, requireResponse } = formState.values;

    let errors = [];

    if (!typeDevolution?.id) {
      errors.push('Tipo de Devolução');
    }

    if (requireResponse === '') {
      errors.push('Devolução com Retorno');
    }

    if (email === '') {
      errors.push('E-mail');
    }

    if (description === '') {
      errors.push('Descrição');
    }

    if (errors.length > 0) {
      errors.forEach(error => {
        toast.warn(`Campo ${error} obrigatório`);
      });
      return;
    }

    const params = {
      email,
      returnationTypeId: typeDevolution.id,
      requireResponse,
    };

    if (ccEmails.length > 0) {
      params.cc = ccEmails;
    }

    if (!sourceOrgan?.email && !isBatch) {
      params.id = sourceOrgan?.id;
      registerEmail(params);
    } else {
      params.justification = description;
      giveBackInquiry(params);
    }
  };

  const giveBackInquiry = params => {
    setDisabled(true);

    if (!isBatch) {
      inquiryService
        .giveBackInquiryService(inquiry.inquiry.id, params)
        .then(() => {
          toast.success('Devolução realizada com sucesso');

          handleClose();

          setFormState(formState => ({
            ...formState,
            values: { ...initialState },
          }));

          setTimeout(() => {
            history.push('/Dashboard');
          }, 2000);
        })
        .finally(() => setDisabled(false));
    } else {
      PendingInquiryService.giveBackBatchPendingInquiryService({
        ...params,
        inquiryIds: inquiryList.map(i => i.id),
      })
        .then(() => {
          toast.success('Devolução realizada com sucesso');

          handleClose();

          setFormState(formState => ({
            ...formState,
            values: { ...initialState },
          }));

          setTimeout(() => {
            history.push('/Dashboard');
          }, 2000);
        })
        .finally(() => setDisabled(false));
    }
  };

  const registerEmail = params => {
    setDisabled(true);

    let localParams = {
      email: params.email,
    };
    organService
      .organUpdateEmailService(params.id, localParams)
      .then(() => {
        let paramsGiveBackInquiry = {
          justification: formState.values.description,
          email: params.email,
        };
        giveBackInquiry(paramsGiveBackInquiry);
      })
      .catch(() => {
        setDisabled(false);
      });
  };

  const getFiles = () => {
    inquiryService.getFilesInquiryService(inquiry.inquiry.id).then(response => {
      setFiles(response.data.data);
    });
  };

  const getReturnationTypes = async () => {
    inquiryReturnationService.getAllInquiryReturnationTypes().then(response => {
      setReturnationTypes(response.data);
    });
  };

  const handleChangeEmailMode = () => {
    if (textMode) {
      formState.values.email = sourceOrgan?.email;
      setFormState(formState);
      setTextMode(false);
    } else {
      formState.values.email = '';
      setFormState(formState);
      setTextMode(true);
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!isBatch) {
        getFiles();
      }
      getReturnationTypes();
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Dialog
        {...rest}
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        aria-labelledby="modal-give-back-inquiry"
      >
        <HeaderModal onClose={handleClose}>Devolução de inquérito</HeaderModal>
        <form onSubmit={handleConfirm}>
          <DialogContent className={classes.container}>
            <Grid container alignItems="center" spacing={2}>
              {fullScreen ? (
                <>
                  {!isBatch && (
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      sm={4}
                      xs={12}
                      style={{ display: 'flex', justifyContent: 'flex-end' }}
                    >
                      <ButtonConsensus
                        title="Ver Inquérito"
                        margin={0}
                        backgroundColor={colors.greenLight}
                        hovercolor={colors.greenLight}
                        startIcon={<AssignmentIcon />}
                        onClick={() => setOpenModalListFIle(true)}
                        size="medium"
                      />
                    </Grid>
                  )}
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      select
                      label="Tipo de Devolução"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="typeDevolution"
                      value={formState.values.typeDevolution}
                      onChange={handleChangeReturnationType}
                    >
                      {returnationTypes.map(item => (
                        <MenuItem key={item.id} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      select
                      label="Devolução com Retorno"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="requireResponse"
                      value={formState.values.requireResponse}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>{'Sim'}</MenuItem>
                      <MenuItem value={false}>{'Não'}</MenuItem>
                    </TextField>
                  </Grid>
                  {textMode ? (
                    <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                      <TextField
                        label="E-mail"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="email"
                        name="email"
                        value={formState.values.email || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                        <TextField
                          select
                          label="E-mail"
                          variant="outlined"
                          size="small"
                          fullWidth
                          type="email"
                          name="email"
                          value={formState.values.email || ''}
                          onChange={handleChange}
                        >
                          {emails?.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  )}
                  {emails.length > 0 ? (
                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                      <ButtonConsensus
                        title={textMode ? 'Selecionar E-mail' : 'Digitar E-mail'}
                        margin={0}
                        onClick={handleChangeEmailMode}
                        size="medium"
                        fullWidth
                      />
                    </Grid>
                  ) : null}
                  {isBatch && (
                    <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                      <TextField
                        label="Órgão de origem"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="organ-name"
                        value={sourceOrgan?.name || 'Não informado'}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <Typography>{'Cc (Após digitar cada email, aperte enter)'}</Typography>
                    <ReactMultiEmail
                      placeholder="Cc"
                      emails={ccEmails}
                      onChange={_emails => {
                        setCcEmails(_emails);
                      }}
                      autoFocus={true}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      select
                      label="Tipo de Devolução"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="typeDevolution"
                      value={formState.values.typeDevolution}
                      onChange={handleChangeReturnationType}
                    >
                      {returnationTypes.map(item => (
                        <MenuItem key={item.id} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      select
                      label="Devolução com Retorno"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="requireResponse"
                      value={formState.values.requireResponse}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>{'Sim'}</MenuItem>
                      <MenuItem value={false}>{'Não'}</MenuItem>
                    </TextField>
                  </Grid>
                  {!isBatch && (
                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                      <ButtonConsensus
                        title="Ver Inquérito"
                        margin={0}
                        backgroundColor={colors.greenLight}
                        hovercolor={colors.greenLight}
                        startIcon={<AssignmentIcon />}
                        onClick={() => setOpenModalListFIle(true)}
                        size="medium"
                        fullWidth
                      />
                    </Grid>
                  )}
                  {textMode ? (
                    <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                      <TextField
                        label="E-mail"
                        variant="outlined"
                        size="small"
                        type="email"
                        fullWidth
                        name="email"
                        value={formState.values.email || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                        <TextField
                          select
                          label="E-mail"
                          variant="outlined"
                          size="small"
                          type="email"
                          fullWidth
                          name="email"
                          value={formState.values.email || ''}
                          onChange={handleChange}
                        >
                          {emails?.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  )}
                  {emails.length > 0 ? (
                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                      <ButtonConsensus
                        title={textMode ? 'Selecionar E-mail' : 'Digitar E-mail'}
                        margin={0}
                        onClick={handleChangeEmailMode}
                        size="medium"
                        fullWidth
                      />
                    </Grid>
                  ) : null}
                  {isBatch && (
                    <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                      <TextField
                        label="Órgão de origem"
                        variant="outlined"
                        size="small"
                        fullWidth
                        name="organ-name"
                        value={sourceOrgan?.name || 'Não informado'}
                        inputProps={{ readOnly: true }}
                      />
                    </Grid>
                  )}
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <Typography>{'Cc (Após digitar cada email, aperte Enter)'}</Typography>
                    <ReactMultiEmail
                      placeholder="Cc"
                      emails={ccEmails}
                      onChange={_emails => {
                        setCcEmails(_emails);
                      }}
                      autoFocus={true}
                      onFocus={() => setFocused(true)}
                      onBlur={() => setFocused(false)}
                      getLabel={(email, index, removeEmail) => {
                        return (
                          <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                              ×
                            </span>
                          </div>
                        );
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <div className={classes.containerTextArea}>
              <TextField
                multiline
                minRows={10}
                maxRows={10}
                label="Descrição"
                variant="outlined"
                fullWidth
                name="description"
                value={formState.values.description}
                onChange={handleChange}
              />
            </div>
          </DialogContent>
          <DialogActions className={classes.action}>
            <ButtonConsensus
              title="CONFIRMAR"
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              color="#fff"
              type="submit"
              disabled={disabled}
            />
          </DialogActions>
        </form>
      </Dialog>
      {openModalListFile && (
        <ModalListFIles
          investigated={inquiry.inquiry.investigated}
          open={openModalListFile}
          onClose={handleCloseModalListFiles}
          files={files}
          manipulateChecks={manipulateChecks}
          setManipulateChecks={setManipulateChecks}
        />
      )}
    </div>
  );
};

export default GiveBackGenericInquiryModal;

import projectService from 'services/project/projectService';

export const getProjects = ({
  setLoading,
  setPage,
  setLastPage,
  setProjects,
  input = '',
  associationId = null,
}) => {
  setLoading(true);

  setPage(1);

  let params = {
    page: 1,
    size: 20,
    name: input,
    associationId,
    onlyActiveProjects: true,
  };

  projectService
    .getProjectsService(params)
    .then(response => {
      setProjects(response.data.data);
      setLastPage(response.data.lastPage);
    })
    .finally(() => setLoading(false));
};

import IMask from 'imask';

export const INPUT_MASKS = {
  npuMask: '0000000-00.0000.0.00.0000',
};

export const npuMask = IMask.createMask({
  mask: INPUT_MASKS.npuMask,
});

/**
 * Returns the NPU formatted, using this model: `0000000-00.0000.0.00.0000`.
 * @npuNumber npu - String
 */
export const formatNpuMask = (npu = '') => npuMask.resolve(npu);

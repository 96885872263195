import * as actionTypes from 'actions';
import { EnvTypes } from 'constants/envTypes';

const initialState = {
  currentEnviromentType: EnvTypes.PRD
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
  case actionTypes.SET_CURRENT_ENVIROMENT_TYPE:
      return {
        ...state,
        currentEnviromentType: action.payload,
      };
  default:
    return state;
  }
};

export default sessionReducer;

export const citysPE = () => {
  let listCitys = [
    {
      name: 'Água Preta',
      value: 'Água Preta',
    },
    {
      name: 'Águas Belas',
      value: 'Águas Belas',
    },
    {
      name: 'Recife',
      value: 'Recife',
    },
    {
      name: 'Abreu e Lima',
      value: 'Abreu e Lima',
    },
    {
      name: 'Afogados da Ingazeira',
      value: 'Afogados da Ingazeira',
    },
    {
      name: 'Afrânio',
      value: 'Afrânio',
    },
    {
      name: 'Agrestina',
      value: 'Agrestina',
    },
    {
      name: 'Alagoinha',
      value: 'Alagoinha',
    },
    {
      name: 'Aliança',
      value: 'Aliança',
    },
    {
      name: 'Altinho',
      value: 'Altinho',
    },
    {
      name: 'Amaraji',
      value: 'Amaraji',
    },
    {
      name: 'Angelim',
      value: 'Angelim',
    },
    {
      name: 'Araripina',
      value: 'Araripina',
    },
    {
      name: 'Arcoverde',
      value: 'Arcoverde',
    },
    {
      name: 'Barreiros',
      value: 'Barreiros',
    },
    {
      name: 'Belém de Maria',
      value: 'Belém de Maria',
    },
    {
      name: 'Belém do São Francisco',
      value: 'Belém do São Francisco',
    },
    {
      name: 'Belo Jardim',
      value: 'Belo Jardim',
    },
    {
      name: 'Betânia',
      value: 'Betânia',
    },
    {
      name: 'Bezerros',
      value: 'Bezerros',
    },
    {
      name: 'Bodocó',
      value: 'Bodocó',
    },
    {
      name: 'Bom Conselho',
      value: 'Bom Conselho',
    },
    {
      name: 'Bom Jardim',
      value: 'Bom Jardim',
    },
    {
      name: 'Bonito',
      value: 'Bonito',
    },
    {
      name: 'Brejo da Madre de Deus',
      value: 'Brejo da Madre de Deus',
    },
    {
      name: 'Buíque',
      value: 'Buíque',
    },
    {
      name: 'Cabo de Santo Agostinho',
      value: 'Cabo de Santo Agostinho',
    },
    {
      name: 'Cabrobó',
      value: 'Cabrobó',
    },
    {
      name: 'Cachoeirinha',
      value: 'Cachoeirinha',
    },
    {
      name: 'Calçado',
      value: 'Calçado',
    },
    {
      name: 'Camaragibe',
      value: 'Camaragibe',
    },
    {
      name: 'Camocim de São Félix',
      value: 'Camocim de São Félix',
    },
    {
      name: 'Canhotinho',
      value: 'Canhotinho',
    },
    {
      name: 'Capoeiras',
      value: 'Capoeiras',
    },
    {
      name: 'Carnaíba',
      value: 'Carnaíba',
    },
    {
      name: 'Carpina',
      value: 'Carpina',
    },
    {
      name: 'Caruaru',
      value: 'Caruaru',
    },
    {
      name: 'Catende',
      value: 'Catende',
    },
    {
      name: 'Chã Grande',
      value: 'Chã Grande',
    },
    {
      name: 'Condado',
      value: 'Condado',
    },
    {
      name: 'Correntes',
      value: 'Correntes',
    },
    {
      name: 'Cumaru',
      value: 'Cumaru',
    },
    {
      name: 'Cupira',
      value: 'Cupira',
    },
    {
      name: 'Custódia',
      value: 'Custódia',
    },
    {
      name: 'Escada',
      value: 'Escada',
    },
    {
      name: 'Exu',
      value: 'Exu',
    },
    {
      name: 'Feira Nova',
      value: 'Feira Nova',
    },
    {
      name: 'Ferreiros',
      value: 'Ferreiros',
    },
    {
      name: 'Flores',
      value: 'Flores',
    },
    {
      name: 'Floresta',
      value: 'Floresta',
    },
    {
      name: 'Gameleira',
      value: 'Gameleira',
    },
    {
      name: 'Garanhuns',
      value: 'Garanhuns',
    },
    {
      name: 'Glória do Goitá',
      value: 'Glória do Goitá',
    },
    {
      name: 'Goiana',
      value: 'Goiana',
    },
    {
      name: 'Gravatá',
      value: 'Gravatá',
    },
    {
      name: 'Ibimirim',
      value: 'Ibimirim',
    },
    {
      name: 'Ibirajuba',
      value: 'Ibirajuba',
    },
    {
      name: 'Igarassu',
      value: 'Igarassu',
    },
    {
      name: 'Inajá',
      value: 'Inajá',
    },
    {
      name: 'Ipojuca',
      value: 'Ipojuca',
    },
    {
      name: 'Ipubi',
      value: 'Ipubi',
    },
    {
      name: 'Itaíba',
      value: 'Itaíba',
    },
    {
      name: 'Ilha de Itamaracá',
      value: 'Ilha de Itamaraca',
    },
    {
      name: 'Itambé',
      value: 'Itambé',
    },
    {
      name: 'Itapetim',
      value: 'Itapetim',
    },
    {
      name: 'Itapissuma',
      value: 'Itapissuma',
    },
    {
      name: 'Jaboatão dos Guararapes',
      value: 'Jaboatão dos Guararapes',
    },
    {
      name: 'Jataúba',
      value: 'Jataúba',
    },
    {
      name: 'João Alfredo',
      value: 'João Alfredo',
    },
    {
      name: 'Joaquim Nabuco',
      value: 'Joaquim Nabuco',
    },
    {
      name: 'Jupi',
      value: 'Jupi',
    },
    {
      name: 'Jurema',
      value: 'Jurema',
    },
    {
      name: 'Lagoa de Itaenga',
      value: 'Lagoa de Itaenga',
    },
    {
      name: 'Lagoa do Ouro',
      value: 'Lagoa do Ouro',
    },
    {
      name: 'Lagoa dos Gatos',
      value: 'Lagoa dos Gatos',
    },
    {
      name: 'Lajedo',
      value: 'Lajedo',
    },
    {
      name: 'Limoeiro',
      value: 'Limoeiro',
    },
    {
      name: 'Macaparana',
      value: 'Macaparana',
    },
    {
      name: 'Maraial',
      value: 'Maraial',
    },
    {
      name: 'Mirandiba',
      value: 'Mirandiba',
    },
    {
      name: 'Moreilândia',
      value: 'Moreilandia',
    },
    {
      name: 'Moreno',
      value: 'Moreno',
    },
    {
      name: 'Nazaré da Mata',
      value: 'Nazaré da Mata',
    },
    {
      name: 'Olinda',
      value: 'Olinda',
    },
    {
      name: 'Orobó',
      value: 'Orobó',
    },
    {
      name: 'Ouricuri',
      value: 'Ouricuri',
    },
    {
      name: 'Palmares',
      value: 'Palmares',
    },
    {
      name: 'Palmeirina',
      value: 'Palmeirina',
    },
    {
      name: 'Panelas',
      value: 'Panelas',
    },
    {
      name: 'Parnamirim',
      value: 'Parnamirim',
    },
    {
      name: 'Passira',
      value: 'Passira',
    },
    {
      name: 'Paudalho',
      value: 'Paudalho',
    },
    {
      name: 'Paulista',
      value: 'Paulista',
    },
    {
      name: 'Pedra',
      value: 'Pedra',
    },
    {
      name: 'Pesqueira',
      value: 'Pesqueira',
    },
    {
      name: 'Petrolândia',
      value: 'Petrolândia',
    },
    {
      name: 'Petrolina',
      value: 'Petrolina',
    },
    {
      name: 'Poção',
      value: 'Poção',
    },
    {
      name: 'Primavera',
      value: 'Primavera',
    },
    {
      name: 'Quipapá',
      value: 'Quipapá',
    },
    {
      name: 'Riacho das Almas',
      value: 'Riacho das Almas',
    },
    {
      name: 'Ribeirão',
      value: 'Ribeirão',
    },
    {
      name: 'Rio Formoso',
      value: 'Rio Formoso',
    },
    {
      name: 'Salgueiro',
      value: 'Salgueiro',
    },
    {
      name: 'Saloá',
      value: 'Saloá',
    },
    {
      name: 'Sanharó',
      value: 'Sanharó',
    },
    {
      name: 'Santa Cruz do Capibaribe',
      value: 'Santa Cruz do Capibaribe',
    },
    {
      name: 'Santa Maria da Boa Vista',
      value: 'Santa Maria da Boa Vista',
    },
    {
      name: 'Santa Maria do Cambucá',
      value: 'Santa Maria do Cambucá',
    },
    {
      name: 'São Bento do Una',
      value: 'São Bento do Una',
    },
    {
      name: 'São Caitano',
      value: 'São Caitano',
    },
    {
      name: 'São João',
      value: 'São João',
    },
    {
      name: 'São Joaquim do Monte',
      value: 'São Joaquim do Monte',
    },
    {
      name: 'São José da Coroa Grande',
      value: 'São José da Coroa Grande',
    },
    {
      name: 'São José do Belmonte',
      value: 'São José do Belmonte',
    },
    {
      name: 'São José do Egito',
      value: 'São José do Egito',
    },
    {
      name: 'São Lourenço da Mata',
      value: 'São Lourenço da Mata',
    },
    {
      name: 'São Vicente Ferrer',
      value: 'São Vicente Ferrer',
    },
    {
      name: 'Serra Talhada',
      value: 'Serra Talhada',
    },
    {
      name: 'Serrita',
      value: 'Serrita',
    },
    {
      name: 'Sertânia',
      value: 'Sertânia',
    },
    {
      name: 'Sirinhaém',
      value: 'Sirinhaém',
    },
    {
      name: 'Surubim',
      value: 'Surubim',
    },
    {
      name: 'Tabira',
      value: 'Tabira',
    },
    {
      name: 'Tacaratu',
      value: 'Tacaratu',
    },
    {
      name: 'Taquaritinga do Norte',
      value: 'Taquaritinga do Norte',
    },
    {
      name: 'Terra Nova',
      value: 'Terra Nova',
    },
    {
      name: 'Timbaúba',
      value: 'Timbaúba',
    },
    {
      name: 'Toritama',
      value: 'Toritama',
    },
    {
      name: 'Trindade',
      value: 'Trindade',
    },
    {
      name: 'Triunfo',
      value: 'Triunfo',
    },
    {
      name: 'Tuparetama',
      value: 'Tuparetama',
    },
    {
      name: 'Venturosa',
      value: 'Venturosa',
    },
    {
      name: 'Verdejante',
      value: 'Verdejante',
    },
    {
      name: 'Vertentes',
      value: 'Vertentes',
    },
    {
      name: 'Vicência',
      value: 'Vicência',
    },
    {
      name: 'Vitória de Santo Antão',
      value: 'Vitória de Santo Antão',
    },
    {
      name: 'Brejão',
      value: 'Brejão',
    },
    {
      name: 'Buenos Aires',
      value: 'Buenos Aires',
    },
    {
      name: 'Caetés',
      value: 'Caetés',
    },
    {
      name: 'Cortês',
      value: 'Cortês',
    },
    {
      name: 'Iati',
      value: 'Iati',
    },
    {
      name: 'Itaquitinga',
      value: 'Itaquitinga',
    },
    {
      name: 'Pombos',
      value: 'Pombos',
    },
    {
      name: 'Sairé',
      value: 'Sairé',
    },
    {
      name: 'Tacaimbó',
      value: 'Tacaimbó',
    },
    {
      name: 'Tracunhaém',
      value: 'Tracunhaém',
    },
    {
      name: 'Fernando de Noronha',
      value: 'Fernando de Noronha',
    },
    {
      name: 'Tamandaré',
      value: 'Tamandaré',
    },
    {
      name: 'Xexéu',
      value: 'Xexéu',
    },
    {
      name: 'Orocó',
      value: 'Orocó',
    },
    {
      name: 'Lagoa do Carro',
      value: 'Lagoa do Carro',
    },
    {
      name: 'Paranatama',
      value: 'Paranatama',
    },
    {
      name: 'Santa Cruz da Baixa Verde',
      value: 'Santa Cruz da Baixa Verde',
    },
    {
      name: 'Vertente do Lério',
      value: 'Vertente do Lério',
    },
    {
      name: 'Brejinho',
      value: 'Brejinho',
    },
    {
      name: 'Lagoa Grande',
      value: 'Lagoa Grande',
    },
    {
      name: 'Araçoiaba',
      value: 'Araçoiaba',
    },
    {
      name: 'Jaqueira',
      value: 'Jaqueira',
    },
    {
      name: 'Terezinha',
      value: 'Terezinha',
    },
    {
      name: 'Salgadinho',
      value: 'Salgadinho',
    },
    {
      name: 'Vitória',
      value: 'Vitória',
    },

    /*Mogi das Cruzes - SP
        Bradão - Paraíba
        Maceió - AL*/
  ];

  listCitys.sort(function(a, b) {
    return a.name.localeCompare(b.name);
  });

  return listCitys;
};

export const listUf = () => {
  const listUf = [
    {
      name: 'AC',
      value: 'AC',
    },
    {
      name: 'AL',
      value: 'AL',
    },
    {
      name: 'AP',
      value: 'AP',
    },
    {
      name: 'AM',
      value: 'AM',
    },
    {
      name: 'BA',
      value: 'BA',
    },
    {
      name: 'CE',
      value: 'CE',
    },
    {
      name: 'DF',
      value: 'DF',
    },
    {
      name: 'ES',
      value: 'ES',
    },
    {
      name: 'GO',
      value: 'GO',
    },
    {
      name: 'MA',
      value: 'MA',
    },
    {
      name: 'MT',
      value: 'MT',
    },
    {
      name: 'MS',
      value: 'MS',
    },
    {
      name: 'MG',
      value: 'MG',
    },
    {
      name: 'PA',
      value: 'PA',
    },
    {
      name: 'PB',
      value: 'PB',
    },
    {
      name: 'PR',
      value: 'PR',
    },
    {
      name: 'PE',
      value: 'PE',
    },
    {
      name: 'PI',
      value: 'PI',
    },
    {
      name: 'RJ',
      value: 'RJ',
    },
    {
      name: 'RN',
      value: 'RN',
    },
    {
      name: 'RS',
      value: 'RS',
    },
    {
      name: 'RO',
      value: 'RO',
    },
    {
      name: 'RR',
      value: 'RR',
    },
    {
      name: 'SC',
      value: 'SC',
    },
    {
      name: 'SP',
      value: 'SP',
    },
    {
      name: 'SE',
      value: 'SE',
    },
    {
      name: 'TO',
      value: 'TO',
    },
  ];
  return listUf;
};

export const listOrganOrigin = () => {
  let organOrigin = [
    {
      value: '21403',
      name: '1ª CIRCUNSCRICAO POLICIAL - RIO BRANCO',
    },
    {
      value: '21404',
      name: '2ª CIRCUNSCRICAO POLICIAL - BOA VISTA',
    },
    {
      value: '21405',
      name: '3ª CIRCUNSCRICAO POLICIAL - JOANA BEZERRA',
    },
    {
      value: '21406',
      name: '4ª CIRCUNSCRICAO POLICIAL - ESPINHEIRO',
    },
    {
      value: '21407',
      name: '5ª CIRCUNSCRICAO POLICIAL - CASA AMARELA',
    },
    {
      value: '21408',
      name: '6ª CIRCUNSCRICAO POLICIAL - CORDEIRO',
    },
    {
      value: '21409',
      name: '7ª CIRCUNSCRICAO POLICIAL - BOA VIAGEM',
    },
    {
      value: '21410',
      name: '8ª CIRCUNSCRICAO POLICIAL - JORDAO',
    },
    {
      value: '21411',
      name: '9ª CIRCUNSCRIÇÃO POLICIAL - IPSEP',
    },
    {
      value: '21412',
      name: '10ª CIRCUNSCRICAO POLICIAL - IBURA',
    },
    {
      value: '21413',
      name: '11ª CIRCUNSCRICAO POLICIAL - AFOGADOS',
    },
    {
      value: '21414',
      name: '12ª CIRCUNSCRICAO POLICIAL - JARDIM SÃO PAULO',
    },
    {
      value: '21415',
      name: '13ª CIRCUNSCRICAO POLICIAL - MUSTARDINHA',
    },
    {
      value: '21416',
      name: '14ª CIRCUNSCRICAO POLICIAL - VARZEA',
    },
    {
      value: '21417',
      name: '15ª CIRCUNSCRICAO POLICIAL - ALTO DO PASCOAL',
    },
    {
      value: '21418',
      name: '16ª CIRCUNSCRICAO POLICIAL - AGUA FRIA',
    },
    {
      value: '21419',
      name: '17ª CIRCUNSCRICAO POLICIAL - VASCO DA GAMA',
    },
    {
      value: '21420',
      name: '18ª CIRCUNSCRICAO POLICIAL - MACAXEIRA',
    },
    {
      value: '137631',
      name: '3ª Delegacia Seccional - Boa Viagem',
    },
    {
      value: '108842',
      name: '4ª Delegacia Seccional - Várzea',
    },
    {
      value: '108937',
      name: '5ª Delegacia Seccional - Apipucos',
    },
    {
      value: '119732',
      name: 'DIRETORIA INTEGRADA METROPOLITANA',
    },
    {
      value: '130091',
      name: 'Delegacia de Polícia de Repressão aos crimes Cibernéticos',
    },
    {
      value: '109626',
      name: 'Delegacia de Polícia de Crimes Contra a Ordem Tributária - DECOOT',
    },
    {
      value: '20026',
      name: 'DELEGACIA DE POLÍCIA INTERESTADUAL E CAPTURAS',
    },
    {
      value: '108385',
      name: 'Grupo de Operações Especiais da Polícia Civil de Pernambuco- GOE/PE',
    },
    {
      value: '138451',
      name: 'DELEGACIA DE POLICIA DE REPRESSÃO AO CRIME ORGANIZADO- 1ª DPRCO',
    },
    {
      value: '138431',
      name: 'DEPARTAMENTO DE REPRESSÃO AO CRIME ORGANIZADO - DRACO',
    },
    {
      value: '20019',
      name: '1ª DELEGACIA POLICIAL DA MULHER',
    },
    {
      value: '110330',
      name: '2ª Delegacia de Polícia de Repressão ao Narcotráfico',
    },
    {
      value: '135291',
      name: 'Departamento de Repressão ao Narcotráfico - DENARC',
    },
    {
      value: '20023',
      name: 'DELEGACIA DE POLÍCIA DE DELITOS DE TRÂNSITO',
    },
    {
      value: '130852',
      name: 'Delegacia de Policia do Turista - DPTUR',
    },
    {
      value: '110127',
      name: 'Delegacia de Polícia de Repressão ao Estelionato DPRE',
    },
    {
      value: '112055',
      name: 'Delegacia de Polícia de Roubos e Furtos de Cargas',
    },
    {
      value: '107464',
      name: 'Delegacia Policial de Repressão ao Roubo e Furto de Veículos - DPRFV',
    },
    {
      value: '20936',
      name: 'DELEGACIA DE POLÍCIA DE ROUBOS E FURTOS',
    },
    {
      value: '119591',
      name: 'DEPATRI',
    },
    {
      value: '103663',
      name: 'Delegacia do Idoso do Estado de PE',
    },
    {
      value: '107112',
      name: 'DELEGACIA DE DESAPARECIDOS E DE PROTEÇÃO À PESSOA',
    },
    {
      value: '107037',
      name: 'DHPP - 1ª Delegacia de Polícia de Homicídios',
    },
    {
      value: '107038',
      name: 'DHPP - 2ª Delegacia de Polícia de Homicídios',
    },
    {
      value: '107039',
      name: 'DHPP - 3ª Delegacia de Polícia de Homicídios',
    },
    {
      value: '107040',
      name: 'DHPP - 4ª Delegacia de Polícia de Homicídios',
    },
    {
      value: '107041',
      name: 'DHPP - 5ª Delegacia de Polícia de Homicídios',
    },
    {
      value: '124831',
      name: 'DHPP - Departamento de Homicídios e de Proteção a Pessoa',
    },
    {
      value: '20491',
      name: 'Delegacia Policial de Apuração de Crimes Praticados Contra Crianças e Adolescent',
    },
    {
      value: '105552',
      name: 'DPCA - Unidade de Prevenção e Repressão aos Atos Infracionais',
    },
    {
      value: '107403',
      name: 'Delegacia de Meio Ambiente e Infrações Penais de Menor Potencial Ofensivo',
    },
    {
      value: '20031',
      name: 'DECON - DELEGACIA DE POLÍCIA DE PROTEÇÃO AO CONSUMIDOR',
    },
    {
      value: '136171',
      name: 'DELEGACIA DE REPRESSAO A INTOLERANCIA ESPORTIVA - DPRIE',
    },
    {
      value: '20006',
      name: 'COORDENAÇÃO DOS PROCEDIMENTOS POLICIAIS - COORDPPOL',
    },
    {
      value: '20008',
      name: 'DIRETORIA EXECUTIVA DE POLÍCIA ESPECIALIZADA',
    },
    {
      value: '00001',
      name: '1ª Delegacia Seccional - Santo Amaro',
    },
    {
      value: '000002',
      name: '2ª Delegacia Seccional - Espinheiro',
    },
    {
      value: '000003',
      name: 'DELEGACIA DE POLICIA DE REPRESSÃO AO CRIME ORGANIZADO- 2ª DPRCO',
    },
    {
      value: '000004',
      name: 'DEPARTAMENTO DE POLÍCIA DA MULHER',
    },
    {
      value: '000005',
      name: '1ª Delegacia de Polícia de Repressão ao Narcotráfico',
    },
    {
      value: '000006',
      name: '3ª Delegacia de Polícia de Repressão ao Narcotráfico',
    },
    {
      value: '000007',
      name: 'DEPARTAMENTO DE POLÍCIA DA CRIANÇA E DO ADOLESCENTE',
    },
  ];

  organOrigin.sort(function(a, b) {
    return a.name.localeCompare(b.name);
  });

  return organOrigin;
};

import { sortBy } from 'lodash';

const getFileNameWithoutPart = name => name.toLowerCase().split(' parte ')[0];

const getFilePart = name => {
  const extractPartRegex = / parte (\d+)/gi;
  const part = extractPartRegex.exec(name.toLowerCase())?.[1];
  if (part) {
    return parseInt(part);
  }
  return 0;
};

export const sortFilesByNameAndPart = (files, getFilename) =>
  sortBy(files, [
    file => getFileNameWithoutPart(getFilename(file)),
    file => getFilePart(getFilename(file)),
  ]);

import http from '../httpPmService';

const PmService = {
  loginPm: params => http.post('auth/login', params),

  getDelegaciasPmService: params => http.get('organs', { params }),

  getJudicialDistrictsPmService: params => http.get(`judicial-districts`, { params }),

  getInquiriesPmService: params => http.get('inquiries', { params }),

  getZipCodePmService: zipCode => http.get(`persons/adresses/${zipCode}`),

  getPersonInArquimedesPmService: params => http.get('persons/arquimedes', { params }),

  getCrimesPmService: params => http.get('crimes', { params }),

  getLawsPmService: params => http.get('laws', { params }),

  getSdsDataPmService: params => http.get(`persons/sds-personal-data`, { params }),

  getCheckPoliceInquiryNumberPmService: (newInquiryNumber, params) =>
    http.get(`inquiries/check-police-inquiry-number/${newInquiryNumber}`, { params }),

  registerInquiryPmService: params => http.post('inquiry-registration-users/inquiries', params),

  getReturnedInquiriesService: params =>
    http.get('inquiry-registration-users/inquiries/returned', { params }),

  getInquiriesPmSummaryService: params =>
    http.get('inquiry-registration-users/inquiries/summary', { params }),

  getInquiriesInvestigatedPmService: params =>
    http.get('inquiry-registration-users/inquiries/investigated', { params }),

  getInquiryInvestigatedDetailPmService: inquiryInvestigatedId =>
    http.get(`inquiry-registration-users/inquiries/investigated/${inquiryInvestigatedId}`),

  getFilesTasksPmService: taskId => http.get(`inquiry-registration-users/movement-tasks/${taskId}`),

  getMovementTasksPmService: params =>
    http.get('inquiry-registration-users/movement-tasks', { params }),

  sendMovementTasksPmService: taskId =>
    http.post(`inquiry-registration-users/movement-tasks/${taskId}/response`),

  uploadDocumentsInTasksPmService: (taskId, params) =>
    http.post(`inquiry-registration-users/movement-tasks/${taskId}/response-documents`, params),

  addFileInquiryPmService: (inquiryId, params) =>
    http.post(`inquiry-registration-users/inquiries/${inquiryId}/attachments`, params),

  getFilesInquiryPmService: (inquiryId, params) =>
    http.get(`inquiry-registration-users/inquiries/${inquiryId}/attachments`, { params }),

  getUsersPmService: params => http.get('inquiry-registration-users/users', { params }),

  registerUsersPmService: params => http.post('inquiry-registration-users/users', params),

  updateUsersPmService: (userId, params) =>
    http.patch(`inquiry-registration-users/users/${userId}`, params),

  deleteUserPmService: userId => http.delete(`inquiry-registration-users/users/${userId}`),

  getHistoryInquiryPmService: inquiryId => http.get(`inquiries/investigated/${inquiryId}/history`),

  getInstitutionsRegistrationPmByClass: (classCode, params) =>
    http.get(`inquiry-registration-user-institutions/${classCode}`, { params }),

  getFileTypesPmService: params => http.get('inquiry-registration-users/file-type', { params }),

  updateInquiryService: (inquiryId, params) =>
    http.patch(`inquiry-registration-users/inquiries/${inquiryId}`, params),

  updateInquiryInvestigatedService: (inquiryId, params) =>
    http.patch(`inquiry-registration-users/inquiries/investigated/${inquiryId}`, params),

  addNewInvestigatedInToInquiryService: (inquiryId, params) =>
    http.post(`inquiry-registration-users/inquiries/${inquiryId}/investigated`, params),

  updateInvestigatedLegalRepresentative: (inquiryInvestigateId, params) =>
    http.patch(
      `inquiry-registration-users/inquiries/investigated/${inquiryInvestigateId}/legal-representative`,
      params,
    ),

  updateVictimInquiry: (inquiryId, params) =>
    http.patch(`inquiry-registration-users/inquiries/investigated/victims/${inquiryId}`, params),

  returnToMPService: idInquiry =>
    http.put(`inquiry-registration-users/investigated/${idInquiry}/return-to-mp`),

  getUserByIdPmService: userId => http.get(`inquiry-registration-users/user/${userId}`),
};

export default PmService;

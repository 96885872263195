import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  right: {
    float: 'right',
  },
}));

const TableToolbar = props => {
  const classes = useStyles();
  const { title, numSelected, action, onDelete, rows } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="h6">
          {numSelected} {numSelected > 1 ? 'selecionados' : 'selecionado'}
        </Typography>
      ) : (
        <>
          <Typography className={classes.title} variant="h6">
            {title}
          </Typography>
        </>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Apagar">
            <IconButton aria-label="delete" onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          {rows.length && null}
        </>
      ) : (
        action
      )}
    </Toolbar>
  );
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  exportContent: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.func)),
  title: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  rows: PropTypes.array,
  headers: PropTypes.array,
};

TableToolbar.defaultProps = {
  rows: [],
  headers: [],
};

export default TableToolbar;

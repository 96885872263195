import React from 'react';
import { Dialog, DialogActions, DialogContent, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { HeaderModal, ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import history from 'utils/history';

const ModalConfirm = props => {
  const {
    title,
    children,
    open,
    dontShowConfirm,
    step = '',
    tramitionOk,
    handleClose,
    onClick,
    disabledConfirm,
    noCloseOnConfirm = false,
    hideCloseX = false,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleConfirm = () => {
    onClick();
    if (noCloseOnConfirm) {
      return;
    }

    if (step !== 'analise') {
      handleClose();
    }
  };

  const handleCloseConfirm = () => {
    history.goBack();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {hideCloseX ? (
          <HeaderModal>{title}</HeaderModal>
        ) : (
          <HeaderModal onClose={handleClose}>{title}</HeaderModal>
        )}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {tramitionOk && (
            <ButtonConsensus
              title="Fechar"
              backgroundColor="#fff"
              hovercolor={colors.greySecundary}
              color={colors.greyShadow}
              onClick={handleCloseConfirm}
            />
          )}
          {!tramitionOk && (
            <>
              <ButtonConsensus
                title="Cancelar"
                backgroundColor="#fff"
                hovercolor={colors.greySecundary}
                color={colors.greyShadow}
                onClick={handleClose}
              />
              {!dontShowConfirm && (
                <ButtonConsensus
                  title="Confirmar"
                  backgroundColor={colors.primary}
                  hovercolor={colors.primary}
                  onClick={handleConfirm}
                  disabled={disabledConfirm}
                />
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalConfirm;

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Typography, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ButtonCustom from '../../../Button';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { clearUploadFilesInquiry } from '../../../../actions';
import { toast } from 'react-toastify';
import intimationService from 'services/intimation/intimationService';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerModal: {
    minWidth: 500,
    minHeight: 350,
    //height: '100%',
    //width: '100%',
    '@media (max-width:500px)': {
      minWidth: 300,
      minHeight: 350,
    },
    '@media (max-width:960px)': {
      minWidth: 300,
      minHeight: 350,
    },
  },
  header: {
    width: '100%',
    backgroundColor: '#00597b',
    height: 70,
    color: '#ffffff',
    paddingLeft: 30,
    fontSize: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    marginTop: 10,
    borderRadius: 25,
    padding: 20,
    height: 250,
    width: 450,
    '@media (max-width:700px)': {
      width: 355,
    },
  },
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  msgBody: {
    fontSize: 20,
    color: '#00597b',
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
  },
  dadosCriminais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 18,
  },
  containerDadosPessoais: {
    backgroundColor: '#ededed',
    marginTop: 5,
    padding: 10,
    borderRadius: 25,
  },
  labelDadoaPessoais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
  },
  labelCrime: {
    fontWeight: 'bold',
    color: '#000',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8cf5f',
    width: '60%',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  textArea: {
    width: '100%',
    padding: 5,
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
  },
  containerConfirm: {
    height: 380,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#787878',
    fontSize: 22,
    fontWeight: 'bold',
    padding: 100,
    '@media (max-width:690px)': {
      padding: 30,
    },
  },
  btnConfirm: {
    backgroundColor: '#00b1ac',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 40,
    height: 60,
    width: 170,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
}));

const ModalAddFile = props => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [file, setFile] = useState([]);
  const [uploadObject, setUploadObject] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [showFiles, setShowFiles] = useState(true);

  const { open, onClose, className, intimation, ...rest } = props;

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: false,
    onDrop: files => filterFiles(files),
  });

  useEffect(() => {
    //console.log(intimation);
    dispatch(clearUploadFilesInquiry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowFiles(true);
  }, [showFiles]);

  const handleSendFile = () => {
    setDisabled(true);
    getBase64(file);
    //setFile([]);
    //verificar se irá alterar ou salvar aqui e fazer requisição
  };

  const sendFilesUpdate = async file => {
    intimationService
      .confirmManualIntimation(intimation.id, { base64File: file.base64 })
      .then(() => {
        toast.success(`Arquivo(s) anexado(s) com sucesso!`);
      });
  };

  const handleRemoveFile = (fileANex, i) => {
    setShowFiles(false);
    file.splice(i, 1);
    const newList = uploadObject.filter(file => file.name !== fileANex.name);
    setUploadObject(newList);
  };

  //Verifica se o arquivo já existe no state File.
  const filterFiles = files => {
    if (files.length > 0) {
      files.forEach(v => {
        var index = file.findIndex(upload => upload.name === v.name);
        if (index === -1) {
          setFile(file => [...file, v]);
          setDisabled(false);
        } else {
          toast.warn('Arquivo já anexado.');
        }
      });
    } else {
      var index = file.findIndex(upload => upload.name === files[0].name);
      if (index === -1) {
        setFile(file => [...file, files[0]]);
        setDisabled(false);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    }
  };
  //

  //Converter arquivo para Base64
  const getBase64 = files => {
    if (files.length > 0) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function() {
        var result = reader.result;
        //console.log(result);
        //Verifica se o arquivo já existe no Reducer.

        var index = uploadObject.findIndex(upload => upload.name === files[0].name);
        if (index === -1) {
          setUploadObject(uploadObject => [
            {
              ...uploadObject,
              name: files[0].name,
              base64: result,
              //mimetype: files[0].type
            },
          ]);
          let fileToSend = {
            name: files[0].name,
            base64: result,
          };
          //requisição da alteração aqui
          sendFilesUpdate(fileToSend);
          //setDisabled(false);
        } else {
          toast.warn('Arquivo já anexado.');
          setDisabled(true);
        }
        //
      };
      reader.onerror = function(_error) {
        toast.warn('Erro ao converter arquivo. Tente novamente.');
      };

      dispatch(clearUploadFilesInquiry());
      setUploadObject([]);
      setFile([]);
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        maxWidth="md"
      >
        <div className={classes.containerModal}>
          <div className={classes.header}>
            <div>Adicionar arquivo</div>
            <div style={{ paddingRight: 10 }}>
              <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
            </div>
          </div>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ minHeight: 150, padding: 20 }}
            >
              <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                <input {...getInputProps()} />
                <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: 20, height: 200 }}>
              <aside>
                <h4>Arquivos {file.length}</h4>
                {file.length > 0 && (
                  <>
                    {file.map((file, i) => {
                      return (
                        <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                          {showFiles && (
                            <Typography
                              style={{ marginTop: 5, display: 'flex', alignItems: 'center' }}
                            >
                              <PictureAsPdfIcon /> {file.path} {/*- {file.size} bytes*/}
                              <HighlightOffIcon
                                onClick={() => handleRemoveFile(file, i)}
                                style={{ color: '#e81224', cursor: 'pointer' }}
                              />
                            </Typography>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </aside>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              <ButtonCustom
                backgroundColor="#00b1ac"
                hovercolor="#0dd1cc"
                borderColor="#7d7d7d"
                color="#ffffff"
                tittle="Anexar"
                height="55px"
                width="160px"
                mediaWidth="90%"
                disabled={disabled}
                onClick={handleSendFile}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalAddFile;

import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Collapse,
  Typography,
  List,
  ListItem,
  ListItemText,
  Dialog,
  Divider,
  Box,
  ListItemIcon,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import ListIcon from '@material-ui/icons/List';
import CloseIcon from '@material-ui/icons/Close';
import inquiryService from 'services/inquiries/inquiryService';
import { toast } from 'react-toastify';
import ClearIcon from '@material-ui/icons/Clear';
import { ModalConfirm } from 'components';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const ModalListFiles = props => {
  const { onClose, open, files, editable } = props;

  const classes = useStyles();

  const [openAttachment, setOpenAttachment] = useState(true);

  const [selectedIndexAttachment, setSelectedIndexAttachment] = useState(null);
  const [filesArray, setFilesArray] = useState(files);
  const [openModalConfirmDelete, setOpenModalConfirmDelete] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const handleClose = () => {
    onClose();
  };

  const handleOpenRequest = () => {
    setOpenAttachment(!openAttachment);
  };

  const handleClearSelectedIndex = () => {
    setSelectedIndexAttachment(null);
  };

  const handleDeleteFile = () => {
    inquiryService.deleteFilesInquiryService(fileToDelete);
    setOpenModalConfirmDelete(false);
    const fileIndex = filesArray.attachments.findIndex(e => e.id === fileToDelete);
    const firstPart = filesArray.attachments.slice(0, fileIndex);
    const secondPart = filesArray.attachments.slice(fileIndex + 1);
    setFilesArray({
      attachments: firstPart.concat(secondPart),
    });
    toast.success('Arquivo excluído com sucesso!');
  };

  const openModalDelete = (victimIntimationFileId, event) => {
    event.stopPropagation();
    setFileToDelete(victimIntimationFileId);
    setOpenModalConfirmDelete(true);
  };

  const handleClick = (_event, file, index, list) => {
    switch (list) {
      case '1':
        setSelectedIndexAttachment(index);
        break;
      case '2':
        setSelectedIndexAttachment(null);
        break;
      default:
        break;
    }
    window.open(file.url, '_blank');
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle onClose={handleClose} id="simple-dialog-title">
        Arquivos anexados
      </DialogTitle>
      <Divider />
      <List component="nav">
        <ListItem button onClick={handleOpenRequest}>
          <ListItemIcon>
            <ListIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Arquivo da Intimação de Vítima" />
          {openAttachment ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openAttachment} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {filesArray.attachments.length > 0 ? (
              filesArray.attachments.map((file, index) => (
                <Box key={file.name}>
                  <ListItem
                    selected={selectedIndexAttachment === index}
                    button
                    className={classes.nested}
                    onClick={event => handleClick(event, file, index, '1')}
                  >
                    <ListItemIcon>
                      <PictureAsPdfIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={file.name} />
                    {editable && (
                      <ListItemIcon>
                        <IconButton onClick={event => openModalDelete(file.id, event)}>
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </ListItemIcon>
                    )}
                  </ListItem>
                  <Divider />
                </Box>
              ))
            ) : (
              <Box>
                <ListItem button className={classes.nested} onClick={handleClearSelectedIndex}>
                  <ListItemText primary="Não há arquivo" />
                </ListItem>
                <Divider />
              </Box>
            )}
          </List>
        </Collapse>
      </List>
      {openModalConfirmDelete && (
        <ModalConfirm
          title="Confirmação"
          open={openModalConfirmDelete}
          handleClose={() => setOpenModalConfirmDelete(false)}
          onClick={handleDeleteFile}
          noCloseOnConfirm={true}
        >
          <Typography variant="h5">Deseja excluir esse arquivo?</Typography>
        </ModalConfirm>
      )}
    </Dialog>
  );
};

export default ModalListFiles;

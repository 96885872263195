import React from 'react';
import { Dialog, Typography, DialogContent, Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/styles';
import { HeaderModal, ButtonConsensus } from 'components';
import { useTheme } from '@material-ui/core/styles';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(theme => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2, 1, 2, 1),
  },
  btn: {
    marginRight: theme.spacing(2),
  },
  text: {
    padding: theme.spacing(2, 2, 2, 2),
  },
}));

const ModalConfirm = props => {
  const {
    open,
    onClose,
    confirm,
    disabledBtnModalConfirm,
    setDisabledBtnModalConfirm,
    boundInquiryId,
    checkedPoliceInquiryNumberClass,
    manipulateChecks,
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleConfirmRequest = () => {
    setDisabledBtnModalConfirm(true);
    confirm();
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <HeaderModal onClose={onClose}>Alerta</HeaderModal>
        <DialogContent>
          <Box className={classes.text}>
            {checkedPoliceInquiryNumberClass.length > 0 && (
              <Typography variant="h5">
                Existe um inquérito com o mesmo número IP e com a mesma classe.
                <br />
                Ao confirmar, os arquivos deste inquérito pendente serão anexados ao inquérito já
                existente.
              </Typography>
            )}
            {manipulateChecks.length > 2 && (
              <Typography variant="h5">Três ou mais arquivos serão importados.</Typography>
            )}
            {!boundInquiryId && (
              <Typography variant="h5">Você não vinculou este inquérito a nenhum outro.</Typography>
            )}
            <Typography variant="h5">Deseja continuar?</Typography>
          </Box>
          <Box className={classes.containerBtn}>
            <ButtonConsensus
              title="Cancelar"
              backgroundColor="#fff"
              hovercolor={colors.greySecundary}
              color={colors.greyShadow}
              onClick={onClose}
            />
            <ButtonConsensus
              title="Confirmar"
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              disabled={disabledBtnModalConfirm}
              onClick={handleConfirmRequest}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalConfirm;

/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import ViewCardPendingInquiries from '../ViewCardPendingInquiries';
import {
  Grid,
  Card,
  Typography,
  Button,
  FormControlLabel,
  Checkbox,
  IconButton,
  Box,
  Tooltip,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useSelector } from 'react-redux';
import InputMask from 'react-input-mask';
import { MsgReturnSearch } from './components';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import clsx from 'clsx';
import { clearInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { genericInquiry, npuGenericInquiry, pcpeGenericInquiry } from 'masks/masks';
import { useFilterInquiry } from 'hooks/useFilterInquiry';
import ModalClasses from 'components/DivNumeroRequerimento/components/ModalClasses';
import CloseIcon from '@material-ui/icons/Close';
import pendingInquiryService from 'services/pending-inquiries/pendingInquiryService';
import {
  addHoursMinutesSecondsToFinalDatesFilter,
  addHoursMinutesSecondsToStartDatesFilter,
  formatDateToISOString,
} from 'utils/date-time.utils';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const FilterInquiryPending = props => {
  const { className, hasProblems, isResponseOfSolicitation, withoutGroup, ...rest } = props;

  const {
    policeInquiryPendingNumberContext,
    setPoliceInquiryPendingNumberContext,
    startDatePendingContext,
    setStartDatePendingContext,
    endDatePendingContext,
    setEndDatePendingContext,
    isArrestedPendingContext,
    setIsArrestedPendingContext,
    returnedPendingContext,
    setReturnedPendingContext,
    judicialDistrictPendingContext,
    setJudicialDistrictPendingContext,
    classInquiryPendingContext,
    setClassInquiryPendingContext,
    isFlagrantContext,
    setIsFlagrantContext,
    pageContext,
    setPageContext,
  } = useFilterInquiry();

  const [showProgress, setShowProgress] = useState(false);

  const useStyles = makeStyles(theme => ({
    divCheck: {
      display: 'block',
    },
    checkBox: {
      marginRight: 5,
      marginTop: 5,
      height: 15,
      width: 20,
      cursor: 'pointer',
    },
    labelBox: {
      marginTop: 5,
      paddingRight: 10,
      fontSize: 15,
      cursor: 'pointer',
    },
    inputInquiry: {
      height: 35,
      width: '100%',
      border: `1px solid #747474`,
      padding: 10,
      backgroundColor: 'transparent',
      marginRight: 10,
      marginTop: 5,
    },
    selectInput: {
      height: 35,
      width: '100%',
      border: `1px solid #747474`,
      paddingLeft: 10,
      backgroundColor: 'transparent',
      marginRight: 10,
      marginTop: 5,
    },
    divContainerInputs: {
      marginTop: 5,
      width: '100%',
      height: '100%',
      boxShadow: 'none',
    },
    teste: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    calendar: {
      backgroundColor: '#3bc3bf',
      color: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 8,
      marginTop: 5,
      borderRadius: '50%',
      padding: 3,
    },
    resumoBusca: {
      height: 25,
      fontSize: 12,
      paddingLeft: 5,
      margin: 5,
      '@media (max-width:600px)': {
        marginBottom: 20,
      },
    },
    divInputs: {
      padding: 5,
    },
    btnSearch: {
      marginTop: 4,
      backgroundColor: '#3bc3bf',
      border: `1px solid #ccc`,
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      height: 37,
      width: '100%',
      borderRadius: 5,
      '&:hover': {
        backgroundColor: '#00597b',
      },
    },
    classeList: {
      overflow: 'auto',
      minHeight: '45vh',
      boxShadow: 'none',
    },
    labelsimple: {
      color: '#2c7692',
    },
    divPagination: {
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      marginTop: 15,
    },
    labelAnterior: {
      color: '#a7a9aa',
      cursor: 'pointer',
    },
    spanPaginacao: {
      color: '#2c7692',
    },
    spanProximo: {
      color: '#2c7692',
    },
    autoContainer: {
      marginTop: -220,
      '@media (max-width:600px)': {
        marginTop: -210,
        Height: 150,
        maxHeight: 175,
        minHeight: 175,
        maxWidth: '85%',
        minWidth: '87%',
        position: 'relative',
      },
      position: 'fixed',
      zIndex: 5,
      Height: 175,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 1,
      },
      backgroundColor: '#00597b',
      borderRadius: 10,
      padding: 5,
      border: '1px solid #999',
    },
    containerCrime: {
      position: 'relative',
      maxHeight: 175,
      minHeight: 175,
      maxWidth: 155,
      minWidth: 155,
    },
    optionCrime: {
      padding: 5,
      borderRadius: 2,
      borderBottom: '1px solid #999',
      '&:hover': {
        backgroundColor: '#3bc3bf',
      },
    },
    itemOptionCrime: {
      cursor: 'pointer',
      color: '#ffffff',
    },
    pagination: {
      '& > *': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 5,
    },
    circularProgress: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 5,
      marginBottom: -170,
      marginTop: 100,
    },
  }));

  const classes = useStyles();

  const [stateSearch, setStateSearch] = useState(false);

  const [inquiries, setInquiries] = useState([]);
  const [page, setPage] = useState(pageContext);
  const size = 20;
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [isResult, setIsResult] = useState(false);
  const [sizeDataApi, setSizeDataApi] = useState(0);
  // const [showComarcas, setShowComarcas] = useState(false);
  // const [flagGetInquiries, setFlagGetInquiries] = useState(false);
  const [openModalClasse, setOpenModalClasse] = useState(false);

  const { selectedIndexGroup } = useSelector(state => state.groupMenu);
  const { groups } = useSelector(state => state.logedUser);
  const { allOriginNumbersAreNpu } = useCustomerConfig();

  //const [comarcas, setComarcas] = useState([]);

  const initialValues = {
    policeInquiryNumber: policeInquiryPendingNumberContext || '',
    startDate: startDatePendingContext || '',
    endDate: endDatePendingContext || '',
    someArrested: isArrestedPendingContext || '',
    returned: returnedPendingContext || false,
    judicialDistrict: judicialDistrictPendingContext || null,
    classInquiry: classInquiryPendingContext || null,
    classDescription: classInquiryPendingContext ? classInquiryPendingContext.description : '',
    isFlagrant: isFlagrantContext || '',
  };

  const [mask, setMask] = useState(
    allOriginNumbersAreNpu
      ? npuGenericInquiry
      : policeInquiryPendingNumberContext?.match(/\d/g)?.length === 16
      ? pcpeGenericInquiry
      : genericInquiry,
  );

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const handleOpenModalClasse = () => {
    setOpenModalClasse(true);
  };

  const handleCloseModalClasse = () => {
    setOpenModalClasse(false);
  };

  const handleAddClasse = (event, classe) => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        classInquiry: classe,
        classDescription: classe.description,
      },
    }));
  };

  const handleClearClasses = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        classInquiry: null,
        classDescription: '',
      },
    }));
  };

  const mapListViewCards = event => {
    event.preventDefault();
    setStateSearch(false);
    getPendingInquiries(page, size);
  };

  const getPendingInquiries = (page, size) => {
    const {
      policeInquiryNumber,
      returned,
      startDate,
      endDate,
      someArrested,
      judicialDistrict,
      classInquiry,
      isFlagrant,
    } = formState.values;

    let params;
    if (withoutGroup) {
      params = {
        page,
        size,
        withoutGroup: true,
        returned,
        judicialDistrictOfProsecutorOfficeGroupId: groups[selectedIndexGroup]?.id,
      };
    } else {
      params = {
        page,
        size,
        isResponseOfSolicitation: isResponseOfSolicitation ? true : false,
        returned,
        prosecutorOfficeGroupId: groups[selectedIndexGroup]?.id,
      };

      if (!isResponseOfSolicitation) {
        params.hasProblems = hasProblems ? true : false;
      }
    }

    if (startDate) {
      params.startDate = formatDateToISOString(
        addHoursMinutesSecondsToStartDatesFilter(startDate),
        true,
      );
    }

    if (endDate) {
      params.endDate = formatDateToISOString(
        addHoursMinutesSecondsToFinalDatesFilter(endDate),
        true,
      );
    }

    if (policeInquiryNumber) params.policeInquiryNumber = clearInquiryNumber(policeInquiryNumber);

    if (someArrested !== '') params.someArrested = someArrested;

    if (isFlagrant !== '') {
      params.isFlagrant = isFlagrant;
    }

    if (classInquiry) {
      if (classInquiry.classes) {
        params.classe = classInquiry.classes.map(c => c.code).join(',');
      } else {
        params.classe = classInquiry.code;
      }
    }

    setShowProgress(true);

    pendingInquiryService
      .getPendingInquiriesService(params)
      .then(response => {
        setInquiries(
          response.data.data.sort((a, b) => {
            if (a.step > b.step) {
              return 1;
            }
            if (a.step < b.step) {
              return -1;
            }
            return 0;
          }),
        );
        setTotalPages(response.data.lastPage);
        setTotal(response.data.total);
        setStateSearch(true);
        setIsResult(true);
        setPage(page);
        setPageContext(page);
        setSizeDataApi(response.data.data.length);

        setPoliceInquiryPendingNumberContext(policeInquiryNumber);
        setStartDatePendingContext(startDate);
        setEndDatePendingContext(endDate);
        setIsArrestedPendingContext(someArrested);
        setReturnedPendingContext(returned);
        setJudicialDistrictPendingContext(judicialDistrict);
        setClassInquiryPendingContext(classInquiry);
        setIsFlagrantContext(isFlagrant);
      })
      .finally(() => {
        //setShowComarcas(true);
        setShowProgress(false);
      });
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePageChange = (event, value) => {
    if (page !== value) {
      setStateSearch(false);
      setPage(value);
      getPendingInquiries(value, size);
    }
  };

  const handlePoliceInquiryNumberChange = event => {
    event.persist();
    const inputValue = event.target.value;

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    if (!allOriginNumbersAreNpu) {
      const newMask = inputValue.match(/\d/g)?.length === 16 ? pcpeGenericInquiry : genericInquiry;
      setMask(newMask);
    }
  };

  const verifyMask = event => {
    const inputValue = event.target.value;
    if (inputValue.match(/\d/g)?.length === 16 && !allOriginNumbersAreNpu) {
      setMask(genericInquiry);
    }
  };

  const verifyOnPaste = event => {
    setMask(event.target.value?.length === 16 ? pcpeGenericInquiry : genericInquiry);
  };

  // const handleChangeJudicialDistrict = (event, newValue) => {
  //   setFormState(formState => ({
  //     ...formState,
  //     values: {
  //       ...formState.values,
  //       judicialDistrict: newValue,
  //     },
  //   }));
  // };

  // useEffect(() => {
  //   getJudicialDisctrictMatchContext();
  // }, []);

  // useEffect(() => {
  //   if (flagGetInquiries) getPendingInquiries(page, size);
  // }, [flagGetInquiries, selectedIndexGroup]);

  /*Remover codigo abaixo quando for inserir novamente filtro de comarca*/
  useEffect(() => {
    getPendingInquiries(page, size);
  }, []);

  return (
    <Card {...rest} className={clsx(classes.divContainerInputs, className)}>
      <form onSubmit={mapListViewCards}>
        <Grid container spacing={1}>
          {/* <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            {showComarcas ? (
              <Autocomplete
                options={comarcas}
                fullWidth
                autoHighlight
                value={comarcas.find(
                  comarca => comarca.name === formState.values.judicialDistrict?.name,
                )}
                onChange={handleChangeJudicialDistrict}
                noOptionsText="Não encontrado"
                getOptionLabel={option => option.name}
                renderOption={option => <>{option.name}</>}
                renderInput={params => (
                  <div ref={params.InputProps.ref}>
                    <input
                      {...params.inputProps}
                      className={classes.inputInquiry}
                      placeholder="Comarca"
                    />
                  </div>
                )}
              />
            ) : (
              <p>buscando...</p>
            )}
          </Grid> */}
          <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
            <InputMask
              className={classes.inputInquiry}
              type="text"
              placeholder="Número do Inquérito"
              name="policeInquiryNumber"
              onChange={handlePoliceInquiryNumberChange}
              value={formState.values.policeInquiryNumber}
              mask={mask}
              maskChar={null}
              onBeforeInput={verifyMask}
              onPaste={event => {
                if (!allOriginNumbersAreNpu) {
                  verifyOnPaste(event);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
            <input
              className={classes.inputInquiry}
              type="date"
              name="startDate"
              onChange={handleChange}
              value={formState.values.startDate}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
            <input
              className={classes.inputInquiry}
              type="date"
              name="endDate"
              onChange={handleChange}
              value={formState.values.endDate}
            />
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
            <select
              className={classes.inputInquiry}
              name="someArrested"
              onChange={handleChange}
              value={formState.values.someArrested}
            >
              <option value="">Está Preso?</option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </Grid>
          <Grid item xs={12} sm={3} md={2} lg={2} xl={1}>
            <select
              className={classes.selectInput}
              onChange={handleChange}
              name="isFlagrant"
              value={formState.values.isFlagrant}
            >
              <option value="">É Flagrante?</option>
              <option value={true}>Sim</option>
              <option value={false}>Não</option>
            </select>
          </Grid>
          <Grid item xs={12} sm={4} md={5} lg={3} xl={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
              width="100%"
            >
              <input
                className={classes.inputInquiry}
                style={{
                  marginRight: 0,
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  paddingRight: '2rem',
                }}
                name="classInquiry"
                value={formState.values.classDescription}
                onClick={handleOpenModalClasse}
                placeholder="Classe do inquérito"
              />
              <Tooltip title="Limpar Classe" placement="top" arrow>
                <IconButton
                  size="small"
                  onClick={handleClearClasses}
                  style={{
                    position: 'absolute',
                    right: 3,
                    top: 8,
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
            <FormControlLabel
              style={{ width: '100%' }}
              control={
                <Checkbox
                  checked={formState.values.returned}
                  onChange={handleChange}
                  name="returned"
                  color="primary"
                />
              }
              label="Inquéritos devolvidos"
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
            <Button className={classes.btnSearch} type="submit" hovercolor="#00b1ac">
              <SearchIcon />
            </Button>
          </Grid>
        </Grid>
      </form>
      {showProgress && (
        <div className={classes.circularProgress}>
          <CircularProgress style={{ height: 70, width: 70, color: '#00597b' }} />
        </div>
      )}
      {stateSearch ? (
        <>
          <MsgReturnSearch sizeDataApi={sizeDataApi} page={page} size={size} totalPages={total} />
          <Card className={classes.classeList}>
            {isResult && <ViewCardPendingInquiries inquiries={inquiries} />}
          </Card>
        </>
      ) : (
        <>
          <Typography className={classes.resumoBusca}></Typography>
          <Typography className={classes.classeList}></Typography>
        </>
      )}

      <ModalClasses
        open={openModalClasse}
        onClose={handleCloseModalClasse}
        onClick={handleAddClasse}
      />

      <div className={classes.pagination}>
        <Pagination
          size="small"
          count={totalPages}
          page={page}
          color="primary"
          onChange={handlePageChange}
        />
      </div>
    </Card>
  );
};

export default FilterInquiryPending;

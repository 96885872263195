import React, { useState, useEffect } from 'react';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Paper,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { useTheme } from '@material-ui/core/styles';
import { ReOrderableItem, ReOrderableList } from 'react-reorderable-list';
import { HeaderModal, ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { makeStyles } from '@material-ui/styles';
import { sortFilesByNameAndPart } from 'utils/genericFunctions/sortFilesByNameAndPart';

const useStyles = makeStyles(theme => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerItem: {
    flex: 11,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dragHandleIcon: {
    cursor: 'grab',
  },
  containerFile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    minHeight: 60,
    padding: theme.spacing(1),
  },
  containerGridItem: {
    minHeight: 80,
    padding: '10px 0',
    textAlign: 'center',
  },
  containerGrid: {
    height: '100%',
    width: '100%',
  },
}));

const ModalSelectAndOrderFilesToConcat = props => {
  const { open, onClose, onConfirm, files, getFormattedFilename } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const classes = useStyles();

  const [filesSelection, setFilesSelection] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSelectAll(true);
    setFilesSelection(
      sortFilesByNameAndPart(files, getFormattedFilename).map(file => ({ file, checked: true })),
    );
  }, [files, getFormattedFilename]);

  const handleConfirm = async () => {
    try {
      setLoading(true);
      await onConfirm(
        filesSelection
          .filter(fileSelection => fileSelection.checked)
          .map(fileSelection => fileSelection.file),
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSelectAllFiles = event => {
    const checked = event.target.checked;
    setSelectAll(checked);
    setFilesSelection(filesSelection.map(selection => ({ ...selection, checked })));
  };

  const handleListUpdate = newList => {
    setFilesSelection(newList);
  };

  const handleClickFile = file => {
    window.open(file.url, '_blank');
  };

  const handleChangeCheckbox = ({ target }) => {
    const newFilesSelection = filesSelection.map(fileSelection => {
      if (fileSelection.file.id === target.value) {
        return {
          ...fileSelection,
          checked: target.checked,
        };
      } else {
        return fileSelection;
      }
    });
    setFilesSelection(newFilesSelection);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
      >
        <HeaderModal onClose={onClose}>Gerar Arquivo Unificado</HeaderModal>
        <DialogContent>
          <Grid container className={classes.containerGrid}>
            {filesSelection.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value={selectAll}
                      onChange={handleSelectAllFiles}
                      checked={selectAll}
                    />
                  }
                  label={selectAll ? 'Desmarcar todos' : 'Marcar todos'}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.containerGridItem}
            >
              {filesSelection.length > 0 ? (
                <ReOrderableList onListUpdate={handleListUpdate} list={filesSelection}>
                  {filesSelection.map((selection, index) => (
                    <ReOrderableItem key={index}>
                      <Paper key={index} elevation={3} className={classes.containerFile}>
                        <Box style={{ flex: 1 }}>
                          <Checkbox
                            value={selection.file.id}
                            onChange={handleChangeCheckbox}
                            key={index}
                            checked={selection.checked}
                            color="primary"
                          />
                        </Box>
                        <Box
                          className={classes.containerItem}
                          onClick={() => handleClickFile(selection.file)}
                        >
                          <DescriptionIcon fontSize="small" />
                          <Typography variant="body1">
                            {getFormattedFilename(selection.file)}
                          </Typography>
                        </Box>
                        <Box className={classes.dragHandleIcon}>
                          <DragHandleIcon />
                        </Box>
                      </Paper>
                    </ReOrderableItem>
                  ))}
                </ReOrderableList>
              ) : (
                <Typography style={{ paddingTop: 25, fontSize: 16 }}>Não há arquivos</Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonConsensus
            title="Cancelar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
          />
          {filesSelection.some(({ checked }) => checked) ? (
            <ButtonConsensus
              title="Unificar"
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              onClick={handleConfirm}
              disabled={loading}
            />
          ) : (
            <Tooltip title="Nenhum arquivo selecionado">
              <span>
                <ButtonConsensus
                  title="Unificar"
                  backgroundColor={colors.primary}
                  hovercolor={colors.primary}
                  disabled={true}
                />
              </span>
            </Tooltip>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalSelectAndOrderFilesToConcat;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
// import { colors } from 'utils/colorsSteps';
import { Grid, Checkbox, Paper, Typography, Divider } from '@material-ui/core';
import { InvestigatedDetail } from './components';
// import { ButtonConsensus } from '../../components';
import { verifyMaskInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
// import { singleDischarge } from 'services/inquiries';
// import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  container: {
    width: '100%',
  },
  containerItem: {
    width: '100%',
  },
  paper: {
    width: '100%',
    position: 'relative',
    minHeight: '3rem',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerCheckbox: {
    left: '1rem',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      left: '.5rem',
    },
  },
  containerClasses: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '.5rem',
    },
  },
  containerInvestigatedsDetail: {
    margin: theme.spacing(2, 0, 0, 0),
  },
}));

const CardBatchInquiry = props => {
  const { inquiries, selectInquiries, setSelectInquiries } = props;

  const classes = useStyles();

  const { allOriginNumbersAreNpu } = useCustomerConfig();

  const handleChangeSelectInquiries = ({ target }) => {
    if (target.checked) {
      setSelectInquiries([...selectInquiries, target.value]);
    } else {
      setSelectInquiries(selectInquiries.filter(inquiryId => inquiryId !== target.value));
    }
  };

  // const handleDischarge = inquiryId => {
  //   try {
  //     singleDischarge(inquiryId);
  //     toast.success('Operação realizada');
  //     refresh();
  //   } catch (error) {
  //     toast.error(error);
  //   }
  // };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        {inquiries
          .filter(item => item.investigated)
          .map(item => (
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              className={classes.containerItem}
              key={item.id}
            >
              <Paper elevation={3} className={classes.paper}>
                <div className={classes.header}>
                  <Typography variant="body1">
                    {verifyMaskInquiryNumber(
                      item.policeInquiryNumber,
                      item?.classeDescription,
                      allOriginNumbersAreNpu,
                    )}
                  </Typography>
                  <div className={classes.containerCheckbox}>
                    <Checkbox
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      value={item.id}
                      onChange={handleChangeSelectInquiries}
                      id={item.id}
                      checked={selectInquiries.includes(item.id)}
                    />
                  </div>
                  {/* <div style={{ position: 'absolute', right: '1rem' }}>
                    <ButtonConsensus
                      size="small"
                      title="Baixa"
                      color="#FFF"
                      margin={0}
                      backgroundColor={colors.greenLight}
                      hovercolor={colors.greenLight}
                      onClick={() => handleDischarge(item.id)}
                    />
                  </div> */}
                </div>
                <Divider style={{ margin: '1rem 0' }} />
                <Grid container spacing={2}>
                  <Grid xl={6} lg={6} md={6} sm={6} xs={12} style={{ paddingLeft: '.5rem' }}>
                    <div style={{ display: 'flex' }}>
                      <Typography style={{ marginRight: '.5rem' }}>
                        {item.internalSystemNumber}
                      </Typography>
                      <Typography>{item.arquimedesAuto}</Typography>
                    </div>
                  </Grid>
                  <Grid xl={6} lg={6} md={6} sm={6} xs={12} className={classes.containerClasses}>
                    <Typography>Classe: {item.classeDescription}</Typography>
                  </Grid>
                </Grid>
                <Divider style={{ margin: '.8rem 0' }} />
                <div className={classes.containerInvestigatedsDetail}>
                  <InvestigatedDetail inquiry={item} />
                </div>
              </Paper>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default CardBatchInquiry;

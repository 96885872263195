import React, { useState } from 'react';
import { Grid, Typography, Box, Paper, IconButton, Tooltip, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import { listUf } from 'utils/citysPE';
import { InputConsensus } from 'components';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  labelValue: {
    display: 'flex',
    width: '100%',
  },
  resultValue: {
    paddingLeft: 5,
    wordWrap: 'break-word',
    minWidth: '25%',
  },
  title: {
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const Lawyer = props => {
  const { lawyer, setLawyer, action, externalEdit, disabled = false, canChangeLawyer = true } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    values: { ...lawyer },
  });

  const [editLawyer, setEditLawyer] = useState(false);

  const handleChange = event => {
    event.persist();
    setFormState(formState => {
      const newFormState = {
        ...formState,
        values: {
          ...formState.values,
          [event.target.name]:
            event.target.type === 'checkbox' ? event.target.checked : event.target.value,
        },
      };

      if (action === 'respectExternalEdit') {
        setLawyer(newFormState.values);
      }

      return newFormState;
    });
  };

  const handleEditLawyer = () => {
    setEditLawyer(true);
  };

  const handleCancelEditLawyer = () => {
    setEditLawyer(false);
    setFormState(formState => ({
      ...formState,
      values: { ...lawyer },
    }));
  };

  const handleSaveLawyer = () => {
    const { name, licenseNumber, licenseUf } = formState.values;

    if (
      !name ||
      name === '' ||
      !licenseNumber ||
      licenseNumber === '' ||
      !licenseUf ||
      licenseUf === ''
    ) {
      toast.warn('Preencha todos campos');
      return;
    }

    setLawyer({ ...formState.values });
    setEditLawyer(false);
  };

  return (
    <Paper className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xl={11} lg={11} md={11} sm={11} xs={10}>
          <Typography className={classes.heading}>
            Dados do(a) advogado(a) ou defensor(a) público
          </Typography>
        </Grid>

        {!externalEdit && action != 'respectExternalEdit' && canChangeLawyer && (
          <Grid item xl={1} lg={1} md={1} sm={1} xs={2}>
            <Box
              style={{
                display: 'flex',
                justifyContent: !editLawyer ? 'flex-end' : 'space-between',
              }}
            >
              {!editLawyer ? (
                <Tooltip title="Atualizar" arrow placement="top">
                  <IconButton size="small" onClick={handleEditLawyer}>
                    <CreateIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <>
                  <Box>
                    <Tooltip title="Salvar" arrow placement="top">
                      <IconButton size="small" onClick={handleSaveLawyer}>
                        <SaveIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title="Cancelar" arrow placement="top">
                      <IconButton size="small" onClick={handleCancelEditLawyer}>
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
        )}

        {!editLawyer && (!externalEdit || action != 'respectExternalEdit' || !canChangeLawyer) ? (
          <>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Box className={classes.labelValue}>
                <Typography variant="h6">Nome: </Typography>
                <Typography variant="body2" className={classes.resultValue}>
                  {lawyer?.name || 'não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={4} xs={8}>
              <Box className={classes.labelValue}>
                <Typography variant="h6">N° OAB: </Typography>
                <Typography variant="body2" className={classes.resultValue}>
                  {lawyer?.licenseNumber || 'não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={2} xs={4}>
              <Box className={classes.labelValue}>
                <Typography variant="h6">UF: </Typography>
                <Typography variant="body2" className={classes.resultValue}>
                  {lawyer?.licenseUf || 'não informado'}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={4}>
              <Box className={classes.labelValue}>
                <Typography variant="h6">E-mail: </Typography>
                <Typography variant="body2" className={classes.resultValue}>
                  {lawyer?.email || 'não informado'}
                </Typography>
              </Box>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <InputConsensus
                label="Nome"
                name="name"
                onChange={handleChange}
                value={formState.values.name || ''}
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={4} xs={8}>
              <InputConsensus
                label="N° OAB"
                name="licenseNumber"
                onChange={handleChange}
                value={formState.values.licenseNumber || ''}
                fullWidth
                disabled={disabled}
              />
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={2} xs={4}>
              <InputConsensus
                label="UF OAB"
                name="licenseUf"
                onChange={handleChange}
                value={formState.values.licenseUf || ''}
                fullWidth
                select
                disabled={disabled}
              >
                {listUf().map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </InputConsensus>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
              <InputConsensus
                label="E-mail"
                name="email"
                variant="standard"
                onChange={handleChange}
                value={formState.values.email || ''}
                type="email"
                fullWidth
                disabled={disabled}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default Lawyer;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
    minWidth: 700,
  },
  container: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const TableAssociationAmount = props => {
  const { tableAssociationAmountData } = props;

  const classes = useStyles();

  return (
    <>
      <Box style={{ marginBottom: 30 }} width="100%">
        <Box my={1}>
          {tableAssociationAmountData && (
            <TableContainer component={Paper} className={classes.container}>
              <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={2}>
                      Saldo restante por instituição
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableAssociationAmountData.map((association, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }} align="left" colSpan={3}>
                          {association.associationName}
                        </TableCell>
                      </TableRow>
                      {association.materials.length ? (
                        <>
                          <TableRow>
                            <TableCell style={{ width: 48 }} />
                            <TableCell>Materiais</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">Quantidade</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {association.materials.map((material, materialIndex) => (
                                    <TableRow key={materialIndex} hover>
                                      <TableCell align="center" style={{ width: 500 }}>
                                        {material.materialName}
                                      </TableCell>
                                      <TableCell align="center" style={{ width: 500 }}>
                                        {material.materialQuantity}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : null}
                      {association.services.length ? (
                        <>
                          <TableRow>
                            <TableCell style={{ width: 48 }} />
                            <TableCell>Serviços</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell colSpan={2}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">Nome</TableCell>
                                    <TableCell align="center">Horas</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {association.services.map((service, serviceIndex) => (
                                    <TableRow key={serviceIndex} hover>
                                      <TableCell align="center" style={{ width: 500 }}>
                                        {service.serviceName}{' '}
                                      </TableCell>
                                      <TableCell align="center" style={{ width: 500 }}>
                                        {service.serviceHour}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : null}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TableAssociationAmount;

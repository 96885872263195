import * as actionTypes from 'actions';

const initialState = {
  crimes: [],
};

const crimesPendingInquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CRIMES_INQUIRY:
      return {
        ...state,
        crimes: action.payload,
      };

    default:
      return state;
  }
};

export default crimesPendingInquiryReducer;

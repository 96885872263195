/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Box,
  TextareaAutosize,
  Typography,
  IconButton,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Skeleton from '@material-ui/lab/Skeleton';
import { Autocomplete } from '@material-ui/lab';
import axios from 'utils/axios';
import { InputConsensus, ButtonConsensus, KeyboardDatePicker, ModalConfirm } from 'components';
import { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import { formatDateToISO8601 } from 'utils/date-time.utils';
import moment from 'moment';
import { parcelsAmountSum } from 'views/Agreement/util';
import associationService from 'services/association/associationService';
import debounce from 'lodash.debounce';
import { orderParcelsByInitialDate } from 'utils/parcels';
import { getProjects } from '../utils';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { PARTNER_SITUATIONS } from 'constants/partner-situations';
import { initialDate } from 'utils/DatesInMonth';
import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';

const useStyles = makeStyles(theme => ({
  containerForm: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    margin: theme.spacing(2, 0, 2, 0),
    width: '100%',
  },
  containerCheck: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerParcels: {
    padding: theme.spacing(2),
    backgroundColor: '#ececec',
    borderRadius: '0.7rem',
    margin: theme.spacing(0, 0.8, 1, 0.8),
  },
}));

const MaterialsForAssociations = props => {
  const {
    setDisabledActionsPenaltyTable = {},
    selectedIndex = null,
    setSelectedIndex = {},
    materials,
    setMaterials,
    description,
    setDescription,
    institutionType,
    institutionSelected,
  } = props;

  const { isMPDFT, viewModalBalances } = useCustomerConfig();

  const classes = useStyles();

  const [row, setRow] = useState(null);
  const [key, setKey] = useState(false);
  const [key2, setKey2] = useState(false);
  const [showMaterials, setShowMaterials] = useState(false);
  const [manipulateMaterials, setManipulateMaterials] = useState([]);
  const [companys, setCompanys] = useState([]);

  const [listProjects, setListProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingAssociations, setLoadingAssociations] = useState(false);
  const [inputSearchProjects, setInputSearchProjects] = useState('');
  const [pageProjects, setPageProjects] = useState(1);
  const [lastPageProjects, setLastPageProjects] = useState(1);

  const [openDeleteParcelModalConfirm, setOpenDeleteParcelModalConfirm] = useState(false);
  const [parcelToDelete, setParcelToDelete] = useState(null);

  const initialValuesCompareAmount = {
    amount: 0,
    attendedAmount: 0,
    remainingAmount: 0,
  };

  const [compareAmount, setCompareAmount] = useState({ ...initialValuesCompareAmount });

  const initialValues = {
    relation: null,
    material: null,
    amount: '',
    penalty: PENALTY_KINDS.MATERIALS,
    description: '',
    deliveryDeadline: '',
    descriptionLocal: isMPDFT ? '' : description,
    parcels: [],
    numberOfParcels: 1,
    project: '',
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values:
        selectedIndex !== null
          ? {
              ...materials[selectedIndex.index],
              descriptionLocal: isMPDFT ? materials[selectedIndex.index].description : description,
            }
          : { ...initialValues },
    }));
  }, [selectedIndex]);

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        descriptionLocal: description,
      },
    }));
  }, [description]);

  useEffect(() => {
    if (formState.values.material !== null) {
      getCompanys();
    }
  }, [formState.values.material]);

  useEffect(() => {
    getManipulateMaterials();
    getCompanys();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: null,
        relation: null,
      },
      touched: {
        ...formState.touched,
        relation: true,
      },
    }));
  }, [institutionSelected]);

  const getManipulateMaterials = async () => {
    let params = {
      hasDemand: true,
    };
    await axios.get('material', { params }).then(response => {
      setManipulateMaterials(
        response.data.data.sort(function(a, b) {
          return a.name.localeCompare(b.name);
        }),
      );
      setShowMaterials(true);
    });
  };

  const getCompanys = async () => {
    try {
      setLoadingAssociations(true);

      const params = {
        active: true,
        forward: false,
        includeAssociationsWithNullSituation: true,
      };

      if (isMPDFT) {
        delete params.includeAssociationsWithNullSituation;
        params.situation = PARTNER_SITUATIONS.ACCREDITED;
      }

      if (formState.values.material?.id) params.materialId = formState.values.material.id;

      await axios
        .get('association-material', { params })
        .then(response => setCompanys(response.data.data));
    } catch (error) {
      toast.error('Erro ao buscar instituições');
    } finally {
      setLoadingAssociations(false);
    }
  };

  const handleInputKeyPress = event => {
    if (event.key === ',' || event.key === '.') {
      event.preventDefault();
    }
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    if (event.target.name === 'descriptionLocal') {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          description: event.target.value,
        },
      }));
    }
  };

  const handleChangeAssociation = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: null,
        relation: newValue,
      },
      touched: {
        ...formState.touched,
        relation: true,
      },
    }));

    setCompareAmount(compare => ({
      ...compare,
      amount: newValue ? newValue.amount : 0,
      attendedAmount: newValue ? newValue.attendedAmount : 0,
      remainingAmount: newValue ? newValue.remainingAmount : 0,
    }));

    setKey(!key);
  };

  const handleChangeMaterial = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        material: newValue,
        project: null,
        relation: {
          ...formState.values.relation,
          association: null,
        },
      },
      touched: {
        ...formState.touched,
        material: true,
      },
    }));
    setKey2(!key2);
  };

  const handleClearFormStat = () => {
    setRow(null);
    if (selectedIndex !== null) {
      setDisabledActionsPenaltyTable(prevState => ({
        ...prevState,
        [selectedIndex.penaltiesIndex]: { ...prevState[selectedIndex.penaltiesIndex], edit: false },
      }));
      setSelectedIndex(null);
    }
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
  };

  useEffect(() => {
    if (row) {
      setFormState(formState => ({
        ...formState,
        values: { ...row, descriptionLocal: isMPDFT ? row.description : description },
      }));
      setKey2(!key2);
      setKey(!key);
    }
  }, [row]);

  const handleSubmit = event => {
    event.preventDefault();

    const { relation, amount, descriptionLocal, description, parcels } = formState.values;

    if (
      parcels.length === 0 ||
      parcels.some(parcel => !parcel.amount) ||
      (isMPDFT ? description === '' : descriptionLocal === '') ||
      (institutionType === 'final' && !relation?.association)
    ) {
      toast.warn('Preencha todos os campos');
      return;
    }

    let intAmount = parseInt(amount);

    if (selectedIndex !== null) {
      let newArray = [...materials];
      let newMaterials = formState.values;
      setDescription(newMaterials.descriptionLocal);
      delete newMaterials.descriptionLocal;
      newArray[selectedIndex.index] = newMaterials;
      setMaterials(newArray);
    } else {
      let newMaterials = formState.values;
      setDescription(newMaterials.descriptionLocal);
      delete newMaterials.descriptionLocal;
      setMaterials([...materials, newMaterials]);
    }

    handleClearFormStat();
  };

  const handleToRead = () => {
    handleClearFormStat();
  };

  const handleAddParcel = () => {
    const { deliveryDeadline, amount, numberOfParcels } = formState.values;

    if (!amount) {
      toast.warn('Insira um valor');
      return;
    }
    if (!numberOfParcels) {
      toast.warn('Insira a quantidade de parcelas');
      return;
    }
    const newParcels = [];
    let date = deliveryDeadline && moment(deliveryDeadline);

    for (let i = 0; i < numberOfParcels; i++) {
      newParcels.push({
        date: date && formatDateToISO8601(date.clone().add(i, 'months')),
        amount: amount,
      });
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        parcels: newParcels,
        deliveryDeadline: '',
        amount: '',
      },
    }));
  };

  const loadMoreProjectsResults = () => {
    const nextPage = pageProjects + 1;

    setPageProjects(nextPage);

    setLoadingProjects(true);

    const params = {
      page: nextPage,
      size: 20,
      name: inputSearchProjects,
    };

    if (formState.values.relation?.association) {
      params.associationId = formState.values.relation?.association?.id;
    }

    associationService
      .getAssociationsService(params)
      .then(response => {
        setListProjects([...listProjects, ...response.data.data]);
        setLastPageProjects(response.data.lastPage);
      })
      .finally(() => setLoadingProjects(false));
  };

  const handleScrollProjects = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageProjects < lastPageProjects &&
      !loadingProjects
    ) {
      loadMoreProjectsResults();
    }
  };

  const debouncedGetProjects = useCallback(
    debounce(
      (newValue, associationId) =>
        getProjects({
          setLoading: setLoadingProjects,
          setPage: setPageProjects,
          setLastPage: setLastPageProjects,
          setProjects: setListProjects,
          input: newValue,
          associationId,
        }),
      500,
    ),
    [],
  );

  const updateValueProject = newValue => {
    if (!listProjects.find(inst => inst.name === newValue)) {
      debouncedGetProjects(newValue, formState.values.relation?.association?.id);
      setInputSearchProjects(newValue);
    }
  };

  const handleChangeProject = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        project: newValue,
      },
    }));
  };

  const handleParcelDateChange = (index, newDate) => {
    const updatedParcels = formState.values.parcels.map((parcel, idx) =>
      idx === index ? { ...parcel, date: newDate } : parcel,
    );
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        parcels: updatedParcels,
      },
    });
  };

  const handleParcelAmountChange = (index, amount) => {
    const updatedParcels = formState.values.parcels.map((parcel, idx) =>
      idx === index ? { ...parcel, amount } : parcel,
    );
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        parcels: updatedParcels,
      },
    });
  };

  const handleOpenDeleteParcelModalConfirm = parcel => {
    setOpenDeleteParcelModalConfirm(true);
    setParcelToDelete(parcel);
  };

  const handleCloseDeleteParcelModalConfirm = () => {
    setOpenDeleteParcelModalConfirm(false);
    setParcelToDelete(null);
  };

  const deleteParcel = () => {
    const parcel = parcelToDelete;
    const index = formState.values.parcels.findIndex(item => item.date === parcel.date);
    if (index !== -1) {
      const newParcels = formState.values.parcels.filter(
        (value, _index, _arr) => value.date !== parcel.date,
      );
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          parcels: newParcels,
        },
      }));
    }
  };

  useEffect(() => {
    setFormState(formState => {
      return {
        ...formState,
        values: {
          ...formState.values,
          project: formState.values.project,
        },
      };
    });
    debouncedGetProjects('', formState.values.relation?.association?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.values.relation]);

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} className={classes.containerForm}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            {showMaterials ? (
              <Autocomplete
                options={manipulateMaterials}
                fullWidth
                autoHighlight
                onChange={handleChangeMaterial}
                key={key2} //clear label on change value
                noOptionsText="Não encontrado"
                value={formState.values.material}
                getOptionLabel={option => option.name}
                renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    name="materialId"
                    label="Material"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            ) : (
              <Skeleton variant="rect" animation="pulse" />
            )}
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            {formState.values.material && institutionType === 'final' && !loadingAssociations ? (
              <Autocomplete
                options={companys}
                fullWidth
                autoHighlight
                onChange={handleChangeAssociation}
                key={key} //clear label on change value
                noOptionsText="Não encontrado"
                value={formState.values.relation}
                getOptionLabel={option => option?.association?.name}
                renderOption={option => (
                  <React.Fragment>{option?.association?.name}</React.Fragment>
                )}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    name="company"
                    label="Instituição"
                    variant="standard"
                    inputProps={{
                      ...params.inputProps,
                    }}
                  />
                )}
              />
            ) : (
              institutionType === 'final' &&
              formState.values.material && <Skeleton variant="rect" animation="pulse" />
            )}
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            {formState.values.relation && institutionType === 'final' && (
              <Box marginBottom={3}>
                <Autocomplete
                  value={formState.values.project}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={option => option.name}
                  filterOptions={x => x}
                  onInputChange={(_, newInputValue) => updateValueProject(newInputValue)}
                  fullWidth
                  noOptionsText="Nada foi encontrado"
                  loadingText="Buscando..."
                  loading={loadingProjects}
                  clearOnBlur={false}
                  options={listProjects}
                  onChange={handleChangeProject}
                  renderOption={option => <>{option.name}</>}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      name="project"
                      label="Projeto"
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingProjects ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  ListboxProps={{
                    onScroll: handleScrollProjects,
                  }}
                />
              </Box>
            )}
          </Grid>

          <Grid
            item
            container
            spacing={2}
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.containerParcels}
          >
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography align="left" variant="h5" className={classes.form}>
                Parcelas
              </Typography>
            </Grid>

            {formState.values.parcels.length === 0 && (
              <>
                <Grid item xl={5} lg={5} md={5} sm={5} xs={12}>
                  <KeyboardDatePicker
                    variant="inline"
                    name="deliveryDeadline"
                    label="Data inicial"
                    value={formState.values.deliveryDeadline || null}
                    minDate={!selectedIndex ? initialDate() : undefined}
                    onChange={newDate =>
                      setFormState(formState => ({
                        ...formState,
                        values: {
                          ...formState.values,
                          deliveryDeadline: newDate,
                        },
                      }))
                    }
                  />
                </Grid>
                <Grid item xl={5} lg={5} md={5} sm={6} xs={12}>
                  <InputConsensus
                    label="Materiais por mês*"
                    name="amount"
                    variant="standard"
                    type="number"
                    inputProps={{
                      min: 0,
                    }}
                    onChange={handleChange}
                    onKeyPress={handleInputKeyPress}
                    value={formState.values.amount || ''}
                    fullWidth
                  />
                </Grid>
              </>
            )}

            {formState.values.parcels && formState.values.parcels.length === 0 && (
              <>
                <Grid item xl={2} lg={2} md={2} sm={2} xs={12}>
                  <InputConsensus
                    label="Número de meses*"
                    name="numberOfParcels"
                    variant="standard"
                    type="number"
                    inputProps={{
                      min: 1,
                    }}
                    onChange={handleChange}
                    onKeyPress={handleInputKeyPress}
                    value={formState.values.numberOfParcels || 1}
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <ButtonConsensus
                    title="Gerar Parcelas"
                    backgroundColor={colors.primary}
                    hovercolor={colors.primary}
                    onClick={() => handleAddParcel()}
                    size="medium"
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Data</TableCell>
                    <TableCell align="center">Quantidade</TableCell>
                    <TableCell align="center" width={150}>
                      <Box display="flex" position="relative" justifyContent="center">
                        Ações
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orderParcelsByInitialDate(formState.values.parcels).map((item, index) => (
                    <TableRow key={index} hover>
                      <TableCell component="th" scope="row" align="center">
                        <KeyboardDatePicker
                          variant="inline"
                          name="deliveryDeadline"
                          value={item.date || null}
                          onChange={newDate => handleParcelDateChange(index, newDate)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" align="center">
                        <InputConsensus
                          name="amount"
                          variant="standard"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          onKeyPress={handleInputKeyPress}
                          onChange={e => handleParcelAmountChange(index, e.target.value)}
                          value={item.amount || ''}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          color="primary"
                          size="small"
                          onClick={() => handleOpenDeleteParcelModalConfirm(item)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell align="left" colSpan={3} style={{ fontWeight: 'bold' }}>
                      Total: {parcelsAmountSum(formState.values.parcels)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12} />
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextareaAutosize
              style={{ width: '100%', padding: 5, resize: 'none' }}
              maxRows={4}
              minRows={4}
              aria-label="maximum height"
              placeholder="Descrever como será cumprido"
              onChange={handleChange}
              name="descriptionLocal"
              value={isMPDFT ? formState.values.description : formState.values.descriptionLocal}
            />
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Alterar' : 'Incluir'} ${
                isMPDFT ? 'Medida' : 'Penalidade'
              }`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              type="submit"
              size="medium"
            />
            <ButtonConsensus
              title={`${selectedIndex !== null ? 'Cancelar' : 'Limpar'}`}
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              onClick={() => handleToRead()}
              size="medium"
              style={{ marginLeft: '-6px' }}
            />
          </Grid>
        </Grid>
      </form>
      {openDeleteParcelModalConfirm && (
        <ModalConfirm
          open={openDeleteParcelModalConfirm}
          handleClose={handleCloseDeleteParcelModalConfirm}
          onClick={deleteParcel}
          title="Excluir parcela"
        />
      )}
    </Box>
  );
};

export default MaterialsForAssociations;

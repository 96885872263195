import React from 'react';
import { Dialog, DialogContent, useMediaQuery, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { HeaderModal, ChatMessage } from 'components';

export default function ChatHistory(props) {
  const { open, handleClose, messages } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Dialog fullScreen={fullScreen} fullWidth maxWidth="md" open={open} onClose={handleClose}>
      <HeaderModal onClose={handleClose}>Histórico da conversa</HeaderModal>
      <DialogContent style={{ marginBottom: 30 }}>
        {!messages.length ? (
          <Typography variant="h5">Não há mensagens</Typography>
        ) : (
          messages.map(message => <ChatMessage key={message.id} message={message} />)
        )}
      </DialogContent>
      {/* <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions> */}
    </Dialog>
  );
}

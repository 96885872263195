import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';

const ModalDeleteConfirm = props => {
  const { open, onClose, victimIntimation, confirmDelete } = props;

  const handleDelete = () => {
    confirmDelete(victimIntimation);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle id="alert-dialog-title">Excluir Intimação da Vítima</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {console.log('victimIntimation: ' + victimIntimation)}
            {console.log('victim: ' + victimIntimation?.victim)}
            Deseja realmente excluir a intimação da vítima {victimIntimation.victim.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonConsensus
            title="CANCELAR"
            size="medium"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
          />
          <ButtonConsensus
            title="EXCLUIR"
            size="medium"
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={handleDelete}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalDeleteConfirm;

import React from 'react';
import { Card, Collapse, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Info } from '../';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    backgroundColor: '#f8f8f8',
    padding: theme.spacing(1),
  },
}));

const InvestigatedInfo = props => {
  const { inquiry, expanded } = props;

  const classes = useStyles();

  return (
    <>
      {inquiry.investigated.length > 1 ? (
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid container spacing={1}>
            {inquiry.investigated.map(investigated => (
              <Grid key={investigated.id} item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card className={classes.cardContainer}>
                  <Info
                    redirect={{ id: investigated.id, step: investigated.step }}
                    investigated={investigated}
                    showDivider
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </Collapse>
      ) : (
        <Card className={classes.cardContainer}>
          {inquiry.investigated.map(investigated => (
            <Info
              redirect={{ id: investigated.id, step: investigated.step }}
              investigated={investigated}
              key={investigated.id}
            />
          ))}
        </Card>
      )}
    </>
  );
};

export default InvestigatedInfo;

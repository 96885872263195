import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  Typography,
  Grid,
  Tooltip,
  Paper,
  DialogContent,
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { HeaderModal } from 'components';
import { Refresh } from '@material-ui/icons';
import pendingInquiryService from 'services/pending-inquiries/pendingInquiryService';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  msgBody: {
    fontSize: 20,
    color: '#00597b',
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
    paddingTop: '20%',
  },
  btnFile: {
    backgroundColor: '#00b1ac',
    marginLeft: 5,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
  },
  containerItem: {
    flex: 11,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  containerFile: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    minHeight: 60,
    padding: theme.spacing(1),
  },
  containerGridItem: {
    minHeight: 80,
    padding: '10px 0',
    textAlign: 'center',
  },
  containerGrid: {
    height: '100%',
    width: '100%',
  },
}));

const ModalListFIles = props => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const {
    files = [],
    setFiles,
    open,
    onClose,
    className,
    manipulateChecks,
    setManipulateChecks,
    pendingInquiryId,
    ...rest
  } = props;

  const [selectAll, setSelectAll] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleGoToWindowRequeriment = ({ url }) => {
    window.open(url, '_blank');
  };

  const handleChange = ({ target }) => {
    if (target.checked) {
      setManipulateChecks([...manipulateChecks, target.value]);
    } else {
      setManipulateChecks(manipulateChecks.filter(check => check !== target.value));
    }
  };

  const handleSelectAllFiles = event => {
    if (event.target.checked) {
      setSelectAll(true);
      setManipulateChecks(files.map(file => file.id));
    } else {
      setSelectAll(false);
      setManipulateChecks([]);
    }
  };

  const refreshInquiryData = async () => {
    setIsRefreshing(true);
    toast.info('Carregando novos dados da base de integração');
    await pendingInquiryService
      .refreshPcpeInquiry(pendingInquiryId)
      .then(response => {
        toast.success('Dados atualizados com sucesso');
        setFiles(response.data.inquiryData.files);
      })
      .finally(() => {
        setIsRefreshing(false);
      });
  };

  useEffect(() => {
    if (!manipulateChecks?.length) {
      setSelectAll(false);
      return;
    }

    if (manipulateChecks?.length === files?.length) {
      setSelectAll(true);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manipulateChecks]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        maxWidth="sm"
        fullWidth
      >
        <HeaderModal onClose={onClose}>
          Arquivos do Inquérito
          <Tooltip title="Refresh">
            <IconButton color="primary" disabled={isRefreshing} onClick={refreshInquiryData}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </HeaderModal>
        <DialogContent>
          <Grid container className={classes.containerGrid}>
            {files.length > 0 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      value={selectAll}
                      onChange={handleSelectAllFiles}
                      checked={selectAll}
                    />
                  }
                  label={selectAll ? 'Desmarcar todos' : 'Marcar todos'}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classes.containerGridItem}
            >
              {files.length > 0 ? (
                files.map((file, index) => (
                  <Paper key={index} elevation={3} className={classes.containerFile}>
                    <Box style={{ flex: 1 }}>
                      <Checkbox
                        value={file.id}
                        onChange={handleChange}
                        id={String(index)}
                        checked={manipulateChecks.includes(file.id)}
                        color="primary"
                      />
                    </Box>
                    <Tooltip title="Visualizar arquivo" arrow placement="top-start">
                      <Box
                        className={classes.containerItem}
                        onClick={() => handleGoToWindowRequeriment(file)}
                      >
                        <DescriptionIcon fontSize="small" />
                        <Typography variant="body1">
                          {file.pieceType || file.name}. Data: {file.date}
                        </Typography>
                      </Box>
                    </Tooltip>
                  </Paper>
                ))
              ) : (
                <Typography style={{ paddingTop: 25, fontSize: 16 }}>Não há arquivos</Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalListFIles;

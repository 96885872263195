import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, TextareaAutosize, Grid, Typography, Button } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import ButtonCustom from '../Button';
import axios from '../../utils/axios';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import history from 'utils/history';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';

const ModalResponsive = props => {
  const { open, onClose, className, inquiry, title, otherStep, persecucao, ...rest } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [confirm, setConfirm] = useState(false);
  const [nextLevel, setNextLevel] = useState(false);
  const [textMsg, setTextMsg] = useState('');
  const [widthModal, setWidthModal] = useState('sm');
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [nextLevelConfirm, setNextLevelConfirm] = useState(false);

  useEffect(() => {
    if (nextLevel) {
      setWidthModal('sm');
    } else {
      setWidthModal('md');
    }
  }, [nextLevel]);

  const [formState, setFormState] = useState({
    justification: '',
  });

  const useStyles = makeStyles(() => ({
    root: {
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    containerModal: {
      //height: nextLevel ? 400 : 600,
      minHeight: nextLevelConfirm ? 220 : 550,
      maxHeight: 900,
      overflow: 'auto',
      maxWidth: 800,
      display: 'flex',
      justifyContent: 'center',
    },
    containerConfirm: {
      //height: 380,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      color: '#787878',
      fontSize: 16,
      fontWeight: 'bold',
      paddingLeft: 5,
      paddingRight: 5,
      marginTop: 30,
      marginBottom: 50,
      '@media (max-width:690px)': {
        padding: 30,
      },
    },
    header: {
      width: '100%',
      backgroundColor: '#00597b',
      height: 70,
      color: '#ffffff',
      paddingLeft: 30,
      fontSize: 16,
      display: 'flex',
      alignItems: 'center',
    },
    body: {
      paddingTop: 10,
      paddingLeft: 30,
      paddingRight: 30,
    },
    label: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 15,
    },
    dadosCriminais: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 16,
    },
    containerDadosPessoais: {
      backgroundColor: '#ededed',
      marginTop: 5,
      marginBottom: 5,
      padding: 10,
      borderRadius: 25,
    },
    labelDadoaPessoais: {
      color: '#00597b',
      fontWeight: 'bold',
      fontSize: 12,
    },
    labelCrime: {
      fontWeight: 'bold',
      color: '#000',
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f8cf5f',
      width: '60%',
      '@media (max-width:960px)': {
        width: '100%',
      },
    },
    textArea: {
      width: '100%',
      padding: 5,
    },
    containerBtn: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerBtnArea: {
      padding: 10,
    },
    btnConfirm: {
      backgroundColor: '#00b1ac',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      borderRadius: 25,
      height: 50,
      width: 150,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: '#00597b',
      },
      '@media (max-width:600px)': {
        width: '100%',
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    setFormState(formState => ({
      ...formState,
    }));
    //console.log(formState.justification);
  }, [formState.justification]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      justification: event.target.value,
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleClearFormState = () => {
    setFormState(formState => ({
      ...formState,
      justification: '',
    }));
  };

  const handleCancel = () => {
    setConfirm(false);
    onClose();
    handleClearFormState();
    history.goBack();
  };

  const handleBack = () => {
    setConfirm(false);
    onClose();
    handleClearFormState();
  };

  const checkTitle = () => {
    if (title === 'PROSSEGUIR PARA PRÓXIMA FASE') {
      setNextLevel(true);
      setTextMsg('Tem certeza que deseja prosseguir para a próxima fase?');
    } else {
      setNextLevel(false);
      setNextLevelConfirm(true);
      setTextMsg('Atividade realizada com sucesso');
    }
  };

  const handleSendForm = e => {
    e.preventDefault();

    if (formState.justification !== '') {
      setDisabledBtn(true);
      if (title === 'PERSECUÇÃO') {
        fetchNextLevel(inquiry.id, 'PERSECUCAO', formState.justification);
      } else if (title === 'ARQUIVAR') {
        fetchNextLevel(inquiry.id, 'ARQUIVAR', formState.justification);
      } else if (title === 'SOLICITAR DILIGÊNCIA') {
        fetchNextLevel(inquiry.id, 'ENCAMINHAR_DILIGENCIAS', formState.justification);
      } else if (title === 'DECLINAR') {
        fetchNextLevel(inquiry.id, 'DECLINAR', formState.justification);
      } else if (title === 'DENÚNCIA') {
        fetchNextLevel(inquiry.id, 'DENUNCIA', formState.justification);
      }
      setConfirm(true);
    } else {
      toast.warn('Informe uma justificativa!');
    }
  };

  const fetchNextLevel = async (idInquiry, step, justification = 'proxima_fase') => {
    await axios
      .put('inquiries/investigated/' + idInquiry + '/step', { step, justification })
      .then(async response => {
        //console.log(response);
        setConfirm(true);
        setNextLevelConfirm(true);

        if (response.data.step === STEPS_INQUIRY.TRIAGEM) {
          setTextMsg('Inquérito enviado para a fase de Triagem Não Persecução');
        } else if (response.data.step === STEPS_INQUIRY.PESQUISA) {
          setTextMsg('Inquérito enviado para a fase de Pesquisa e Distribuição');
        } else if (response.data.step === STEPS_INQUIRY.ANALISE) {
          setTextMsg('Inquérito enviado para a fase de Análise');
        } else if (response.data.step === STEPS_INQUIRY.INTIMACAO) {
          setTextMsg('Inquérito enviado para a fase de Intimação');
        }

        setTextMsg('Fase alterada com sucesso!');
        toast.success('Fase alterada com sucesso!');
        setNextLevel(false);
      })
      .finally(() => {
        setDisabledBtn(false);
      });
  };

  const handleNextLevel = () => {
    setDisabledBtn(true);
    if (inquiry.step === STEPS_INQUIRY.TRIAGEM) {
      fetchNextLevel(inquiry.id, STEPS_INQUIRY.PESQUISA);
    } else if (inquiry.step === STEPS_INQUIRY.PESQUISA) {
      fetchNextLevel(inquiry.id, STEPS_INQUIRY.ANALISE);
    } else if (inquiry.step === STEPS_INQUIRY.ANALISE) {
      fetchNextLevel(inquiry.id, STEPS_INQUIRY.INTIMACAO);
    } else if (inquiry.step === STEPS_INQUIRY.INTIMACAO) {
      //console.log('Próxima fase: Não sei');
    } else if (otherStep === true && !persecucao) {
      fetchNextLevel(inquiry.id, STEPS_INQUIRY.ANALISE);
    } else if (otherStep === true && persecucao) {
      fetchNextLevel(inquiry.id, STEPS_INQUIRY.TRIAGEM);
    }
  };

  useEffect(() => {
    checkTitle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.root}
        maxWidth={widthModal}
      >
        <div className={classes.header}>{title}</div>
        <div className={classes.containerModal}>
          <div className={classes.body}>
            {!confirm && !nextLevel ? (
              <>
                <Grid container spacing={0} className={classes.dadosCriminais}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className={classes.label}>Dados Criminais</Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    {inquiry.inquiry.policeInquiryNumber}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{ textAlign: 'end', paddingRight: 30 }}
                  >
                    {inquiry.inquiry.policeReportNumber}
                  </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.containerDadosPessoais}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className={classes.label}>Dados Pessoais</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <Typography className={classes.labelDadoaPessoais}>
                      {inquiry.investigated.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={3} lg={3} xl={3}>
                    <Typography className={classes.labelDadoaPessoais}>
                      D/N:{' '}
                      <Moment format="DD/MM/YYYY" local>
                        {inquiry.investigated.birthDate}
                      </Moment>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <Typography className={classes.labelDadoaPessoais}>
                      CPF: {inquiry.investigated.taxpayerRegistry}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <Typography className={classes.labelDadoaPessoais}>
                      RG: {inquiry.investigated.rg?.code}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <Typography className={classes.labelDadoaPessoais}>
                      Org. Emissor: {inquiry.investigated.rg?.issuingBody}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                    <Typography className={classes.labelDadoaPessoais}>
                      Telefone: {inquiry.investigated.phone}
                    </Typography>
                  </Grid>
                  {/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <Typography className={classes.labelDadoaPessoais}>{inquiry.escolaridade}</Typography>
                                    </Grid>*/}
                  <Grid item>
                    <Typography className={classes.labelDadoaPessoais}>
                      Endereço: {inquiry.investigated.address?.street},{' '}
                      {inquiry.investigated.address?.number},{' '}
                      {inquiry.investigated.address?.neighborhood},{' '}
                      {inquiry.investigated.address?.city} - {inquiry.investigated.address?.uf}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Typography className={classes.labelDadoaPessoais}>
                      E-mail: {inquiry.investigated.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography className={classes.labelDadoaPessoais}>
                      Mãe: {inquiry.investigated.motherName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Typography className={classes.labelCrime}>
                      {inquiry.crimes.length > 0
                        ? inquiry.crimes.map(crime =>
                            inquiry.crimes.length === 1 ? crime.taxonomy : crime.taxonomy + '| ',
                          )
                        : ''}
                    </Typography>
                  </Grid>
                </Grid>
                <form id="form-justification" onSubmit={handleSendForm}>
                  <Grid container spacing={0} className={classes.dadosCriminais}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Typography className={classes.label}>Justificativa</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TextareaAutosize
                        className={classes.textArea}
                        rows={7}
                        aria-label="maximum height"
                        value={formState.justification}
                        onChange={handleChange}
                        required
                      />
                    </Grid>
                  </Grid>
                </form>
              </>
            ) : (
              <></>
            )}

            {confirm || nextLevel ? <div className={classes.containerConfirm}>{textMsg}</div> : ''}
            <Grid container spacing={1} className={classes.containerBtnArea}>
              {!confirm || nextLevel ? (
                <>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.containerBtn}>
                    <ButtonCustom
                      borderColor="#7d7d7d"
                      color="#7d7d7d"
                      tittle="CANCELAR"
                      height="50px"
                      width="150px"
                      mediaWidth="100%"
                      onClick={handleBack}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.containerBtn}>
                    {nextLevel ? (
                      <Button
                        className={classes.btnConfirm}
                        //type="submit"
                        onClick={handleNextLevel}
                        disabled={disabledBtn}
                      >
                        {disabledBtn ? 'Enviando...' : 'confirmar'}
                      </Button>
                    ) : (
                      <Button
                        className={classes.btnConfirm}
                        type="submit"
                        form="form-justification"
                        disabled={disabledBtn}
                      >
                        {disabledBtn ? 'Enviando...' : 'confirmar'}
                      </Button>
                    )}
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.containerBtn}
                  >
                    <ButtonCustom
                      borderColor="#2c7691"
                      color="#2c7691"
                      tittle="VOLTAR"
                      height="50px"
                      width="150px"
                      mediaWidth="100%"
                      onClick={handleCancel}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalResponsive;

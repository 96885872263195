export const UserAssociationRepresentative = {
  "id": "4f8591e2-7138-413f-8443-5cde4bf5062e",
  "type": "AssociationRepresentative",
  "name": "Atentente 1",
  "login": "atendente1",
  "email": "atendente1@email.com",
  "registration": null,
  "syncArquimedes": false,
  "canBeResponsible": true,
  "admissionDate": null,
  "representativeType": "Atendente",
  "createdAt": "2023-02-27T17:18:24.460Z",
  "updatedAt": "2023-02-27T17:22:09.645Z",
  "permissions": null
};

export default [
  {
    "id": "0001",
    "type": "Prosecutor",
    "name": "Usuário Teste",
    "login": "usuario.test",
    "email": "usuario.test@email.com",
    "passwordHash": "$2b$12$h39NxwGbTqHbufpmw0bYiudbsjKeA48zljE9IXwkMzuAKRjJKOD6m",
    "registration": null,
    "syncArquimedes": false,
    "canBeResponsible": true,
    "admissionDate": null,
    "representativeType": null,
    "createdAt": "2021-08-03T22:25:58.678Z",
    "updatedAt": "2021-08-03T22:26:19.820Z",
    "roles": [],
    "groups": [],
    "association": null,
    "prosecutorOffices": [],
    "permissions": [],
  }
];
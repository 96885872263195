export const getInitials = (name = '') => {
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map(v => v && v[0].toUpperCase())
    .join('');
};

export const firstLastName = (name = '') => {
  const fullName = name.split(' ');
  return fullName.length > 1 ? `${fullName[0]} ${fullName[fullName.length - 1]}` : `${fullName[0]}`;
};

import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';

const CustonSkeleton = props => {
  const { variant, width, height, animation, padding } = props;
  //Variants: text, circle, rect
  return (
    <div style={{ padding: padding || 10 }}>
      <Skeleton
        style={{ borderRadius: 5 }}
        variant={variant}
        width={width}
        height={height}
        animation={animation}
      />
    </div>
  );
};

CustonSkeleton.propTypes = {
  variant: PropTypes.string,
  width: PropTypes.string || PropTypes.number,
  height: PropTypes.number.isRequired,
  animation: PropTypes.string,
};

CustonSkeleton.defaultProps = {
  variant: 'text',
  width: '100%',
  height: 30,
  animation: 'wave',
};

export default CustonSkeleton;

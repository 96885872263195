import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(() => ({
  root: {},
}));

const StepTitle = props => {
  const { step, variant, uperCase, className, ...rest } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const classes = useStyles();

  const [manipuleStep, setManipuleStep] = useState(step);

  useEffect(() => {
    if (step) {
      switch (step) {
        case STEPS_INQUIRY.DENUNCIA:
          setManipuleStep('Denúncia');
          break;
        case STEPS_INQUIRY.DECLINAR:
          setManipuleStep('Declinação');
          break;
        case STEPS_INQUIRY.ARQUIVAR:
          setManipuleStep('Arquivamento');
          break;
        case STEPS_INQUIRY.PERSECUCAO:
          setManipuleStep('Persecução');
          break;
        case STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS:
          setManipuleStep('Despacho');
          break;
        case STEPS_INQUIRY.TRIAGEM:
          setManipuleStep('Triagem');
          break;
        case STEPS_INQUIRY.PESQUISA:
          setManipuleStep('Pesquisa e Distribuição');
          break;
        case STEPS_INQUIRY.ANALISE:
          setManipuleStep('Análise');
          break;
        case null:
          setManipuleStep('Análise');
          break;
        case STEPS_INQUIRY.INTIMACAO:
          setManipuleStep('Intimação');
          break;
        case STEPS_INQUIRY.TRAMITACAO_CONTROLE:
          setManipuleStep('Tramitação e Controle');
          break;
        case STEPS_INQUIRY.AUDIENCIA:
          setManipuleStep(`Audiência${enableHearingForJuvenileOffenders ? '/Oitiva' : ''}`);
          break;
        case STEPS_INQUIRY.CUMPRIMENTO_ACORDO:
          setManipuleStep('Cumprimento de Acordo');
          break;
        case STEPS_INQUIRY.DEVOLVIDO:
          setManipuleStep('Devolvido');
          break;
        case STEPS_INQUIRY.REQUISITORIO:
          setManipuleStep('Requisitório');
          break;
        case STEPS_INQUIRY.REMETIDO_AO_JUDICIARIO:
          setManipuleStep('Remetido ao Judiciário');
          break;
        case STEPS_INQUIRY.REMETIDO_AO_SISTEMA_INTERNO:
          setManipuleStep('Remetido ao Sistema Interno');
          break;
        case STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO:
          setManipuleStep('Termo de Encaminhamento');
          break;
        case STEPS_INQUIRY.NAO_IDENTIFICADO:
          setManipuleStep('Não Identificado');
          break;
        case STEPS_INQUIRY.MIGRACAO:
          setManipuleStep('Migração');
          break;
        default:
          break;
      }
    }
  }, [enableHearingForJuvenileOffenders, step]);

  return (
    <Typography {...rest} variant={variant} className={clsx(classes.root, className)}>
      {uperCase ? manipuleStep.toUpperCase() : manipuleStep}
    </Typography>
  );
};

StepTitle.propTypes = {
  className: PropTypes.string,
  step: PropTypes.string,
  uperCase: PropTypes.bool,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'srOnly',
    'inherit',
  ]),
};

StepTitle.defaultProps = {
  variant: 'body1',
  uperCase: false,
  step: 'Não informado',
};

export default StepTitle;

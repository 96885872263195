import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';
import { getOnlyNumbers } from 'utils/currency';

const ressarcimentVictimParser = (compensationToVictims, isMPDFT = false) => {
  const newArrayRessarcimentVictim = [];
  compensationToVictims.forEach(compensation => {
    let formatedValue = '';

    if (compensation.estimatedValue) {
      const compensationValue = Number(getOnlyNumbers(`${compensation.estimatedValue}`));
      formatedValue = compensationValue;
    }

    const newRessarciment = {
      ...compensation,
      dateVictimCanReceive: compensation.dateVictimCanReceive?.substring(0, 10),
      deliveryDeadline: compensation.deliveryDeadline?.substring(0, 10),
      description: compensation.description,
      notification: {
        email: false,
        letter: false,
        whatsapp: true,
      },
      penalty: isMPDFT
        ? PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT
        : PENALTY_KINDS.COMPENSATION_TO_VICTIM,
      victim: compensation.victim,
    };

    newRessarciment.estimatedValue = compensation.estimatedValue ? formatedValue : '0';

    newArrayRessarcimentVictim.push(newRessarciment);
  });
  return newArrayRessarcimentVictim;
};

export default ressarcimentVictimParser;

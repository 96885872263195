import http from '../httpService';

const AssociationService = {
  getFilesInquiryAssociationService: inquiryId =>
    http.get(`association/inquiries/${inquiryId}/attachments`),

  getInvestigatedRecordingsAssociationService: inquiryInvestigatedId =>
    http.get(`association/inquiries/investigated/${inquiryInvestigatedId}/recordings`),

  getHistoryInquiryUserAssociationService: inquiryInvestigatedId =>
    http.get(`association/inquiries/investigated/${inquiryInvestigatedId}/history`),

  getAssociationsService: params => http.get('association', { params }),

  getRelatedAssociationsService: params => http.get(`association/related/${params.associationId}`),

  getInvestigatedAssociationService: params =>
    http.get('association/inquiries/investigated', { params }),

  getInvestigatedByIdAssociationService: inquiryInvestigatedId =>
    http.get(`association/inquiries/investigated/${inquiryInvestigatedId}`),

  registerAssociationService: params => http.post('association', params),

  registerPartnerAssociationService: params => http.post('association/partner', params),

  registerSemaCemaAssociationService: params => http.post('association/sema-cema', params),

  updateAssociationService: (associationId, params) =>
    http.patch(`association/${associationId}`, params),

  updatePartnerAssociationService: (associationId, params) =>
    http.patch(`association/partner/${associationId}`, params),

  updateSemaCemaAssociationService: (associationId, params) =>
    http.patch(`association/sema-cema/${associationId}`, params),

  deleteAssociationService: associationId => http.delete(`association/${associationId}`),

  deleteSemaCemaAssociationService: associationId =>
    http.delete(`association/sema-cema/${associationId}`),

  getAssociationTypesService: params => http.get('association/types', { params }),

  getAssociationNaturesService: params => http.get('association/nature', { params }),

  getAssociationPhysicalSpacesService: params => http.get('association/physical-space', { params }),

  getAssociationMeasuresService: params => http.get('association/measures', { params }),

  getAssociationActivitiesService: params => http.get('association/activities', { params }),

  getAssociationByIdService: associationId => http.get(`association/${associationId}`),

  updateAssociationSituationService: (associationId, params) =>
    http.patch(`association/situation/${associationId}`, params),

  deletePartnerAssociationService: associationId =>
    http.patch(`association/partner/${associationId}/delete`),

  checkAssociationBalances: params => http.post('association/balances', params),
};

export default AssociationService;

import { colors as colorsMaterial } from '@material-ui/core';

export default {
  TRIAGEM_NAO_PERSECUCAO: '#2D8FCC',
  PESQUISA_DISTRIBUICAO: '#0064B0',
  ANALISE: '#00B56A',
  INTIMACAO: '#E88D48',
  REDISTRIBUIR: '#00008B',
  AUDIENCIA: '#007947',
  TRAMITACAO_CONTROLE: '#ff6e00',
  ENCAMINHAR_DILIGENCIAS: '#FFB800',
  PERSECUCAO: '#90948D',
  ARQUIVAR: '#90948D',
  DESARQUIVAR: '#00008B',
  DECLINAR: '#90948D',
  DENUNCIA: '#90948D',
  REMETIDO_AO_SISTEMA_INTERNO: '#90948D',
  CUMPRIMENTO_ACORDO: '#a50e96',
  TERMO_DE_ENCAMINHAMENTO: '#e817d3',
  NAO_IDENTIFICADO: '#90948D',
  DEVOLVIDO: '#90948D',
  REQUISITORIO: '#90948D',
  REMETIDO_AO_JUDICIARIO: '#90948D',
  MIGRACAO: '#90948D',
};

export const colors = {
  navBar: '#00597b',
  greenLight: '#00b1ac',
  primary: '#00597b',
  greyPrimary: colorsMaterial.grey[500],
  greySecundary: colorsMaterial.grey[300],
  greyShadow: colorsMaterial.grey[900],
};

import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import '../../font.css';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    paddingTop: 5,
    fontFamily: 'roboto-regular',
  },
}));

const TitleInquiryData = () => {
  const classes = useStyles();

  return <Typography className={classes.titulo}>Dados do Auto</Typography>;
};

export default TitleInquiryData;

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  Typography,
  Grid,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { HeaderModal, ButtonConsensus } from 'components';
import { toast } from 'react-toastify';
import agreementService from 'services/agreement/agreementService';

const useStyles = makeStyles(theme => ({
  root: {},
  containerModal: {
    padding: theme.spacing(1),
  },
  containerDropZone: {
    padding: theme.spacing(1),
  },
  dropzone: {
    height: 100,
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  containerBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
  },
  containerSignedAgreement: {
    padding: theme.spacing(1),
    textAlign: 'center',
  },
  containerBtnStep: {
    margin: theme.spacing(2),
  },
}));

const ModalAddFile = props => {
  const {
    open,
    onClose,
    className,
    files,
    setFiles,
    uploadFiles,
    setUploadFiles,
    handleSendFile,
    disabled,
    signedAgreement,
    forwardingTerm = false,
    agreementId,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [urlFile, setUrlFile] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: false,
    onDrop: files => filterFiles(files),
  });

  const filterFiles = newFiles => {
    newFiles.forEach(v => {
      var index = files.findIndex(upload => upload.name === v.name);
      if (index === -1) {
        setFiles(file => [...file, v]);
        getBase64(v);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    });
  };

  const getBase64 = fileAnexo => {
    var reader = new FileReader();
    reader.readAsDataURL(fileAnexo);
    reader.onload = function() {
      var result = reader.result;

      var index = files.findIndex(upload => upload.name === fileAnexo.name);

      if (index === -1) {
        setUploadFiles(uploadFile => [
          ...uploadFile,
          {
            name: fileAnexo.name,
            base64: result,
          },
        ]);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    };
    reader.onerror = function(_error) {
      //console.log('Error: ', error);
    };
  };

  const handleRemoveFile = (file, oldIndex) => {
    setFiles(files.filter((uploadFile, index) => index !== oldIndex));
    setUploadFiles(uploadFiles.filter(uploadFile => uploadFile.name !== file.name));
  };

  const onSendFile = () => {
    if (!uploadFiles.length) {
      toast.warn('Insira um arquivo para continuar');
      return;
    }
    handleSendFile();
  };

  useEffect(() => {
    if (signedAgreement) {
      agreementService
        .getSignedAgreementService(agreementId)
        .then(response => setUrlFile(response?.data[0]?.url ?? null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedAgreement]);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <HeaderModal onClose={onClose}>
          {forwardingTerm ? 'Termo de Encaminhamento' : 'Termo do Acordo'}
        </HeaderModal>

        {signedAgreement ? (
          <Grid container className={classes.containerModal}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.containerDropZone}
            >
              <List>
                <Tooltip arrow title="Ver arquivo" placement="top">
                  <ListItem button onClick={() => window.open(urlFile, '_blank')}>
                    <ListItemText primary={signedAgreement.name} />
                  </ListItem>
                </Tooltip>
              </List>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.containerModal}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.containerDropZone}
            >
              <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                <input {...getInputProps()} />
                <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
              </div>
              <aside style={{ marginTop: 15 }}>
                {files.length > 0 && (
                  <>
                    <Typography variant="h5">Arquivos {files.length}</Typography>
                    {files.map((file, i) => {
                      return (
                        <Box
                          key={i}
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography
                            style={{ marginTop: 10, display: 'flex', alignItems: 'center' }}
                          >
                            <PictureAsPdfIcon style={{ marginRight: 5 }} />
                            {file.path} {/*- {file.size} bytes*/}
                          </Typography>
                          <IconButton size="small" onClick={() => handleRemoveFile(file, i)}>
                            <HighlightOffIcon style={{ color: '#e81224', cursor: 'pointer' }} />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </>
                )}
              </aside>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.containerBtn}>
              <ButtonConsensus title="Enviar" disabled={disabled} onClick={onSendFile} />
            </Grid>
          </Grid>
        )}
      </Dialog>
    </div>
  );
};

export default ModalAddFile;

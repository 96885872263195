import * as actionTypes from 'actions';

const initialState = {
  id: '',
  name: '',
  createdAt: '',
  documentClasses: null,
  updatedAt: '',
  groups: null,
};

const boundInstitutionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_BOUND_INSTITUTION:
      return {
        ...state,
        id: action.payload.id,
        name: action.payload.name,
        documentClasses: action.payload.documentClasses,
        groups: action.payload.groups,
        createdAt: action.payload.createdAt,
        updatedAt: action.payload.updatedAt,
      };

    case actionTypes.CLEAR_BOUND_INSTITUTION:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default boundInstitutionReducer;

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Typography,
  Grid,
  Box,
  DialogContent,
  IconButton,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import DescriptionIcon from '@material-ui/icons/Description';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import HeaderModal from 'components/HeaderModal';
import { colors } from 'utils/colorsSteps';
import ButtonConsensus from 'components/ButtonConsensus';
import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/HelpOutline';
import { PJE_FILE_MAX_SIZE_BY_TYPE, parseFileProcessStatus } from './utils';

const useStyles = makeStyles(theme => ({
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  containerModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularProgress: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    zIndex: 5,
    height: '100%',
  },
}));

const TableFilesAllowed = props => {
  return (
    <div style={{ backgroundColor: 'white' }}>
      <Typography style={{ color: 'darkred' }}>{props.message}</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Tipos de arquivos permitidos</TableCell>
            <TableCell>Tamanho máximo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(PJE_FILE_MAX_SIZE_BY_TYPE).map((type, index) => (
            <TableRow key={index}>
              <TableCell>{type}</TableCell>
              <TableCell>{PJE_FILE_MAX_SIZE_BY_TYPE[type] / (1024 * 1024)}MB</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const ModalAddFile = props => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [filesInfo, setFilesInfo] = useState({});

  const {
    open,
    onClose,
    pjeFileOptions,
    setPjeFileOptions,
    movementFiles,
    setMovementFiles,
    disableFiles,
  } = props;

  const [loading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleSelectFile = ({ target }) => {
    const fileIndex = parseInt(target.id);
    if (fileIndex === -1) {
      return;
    }
    const file = pjeFileOptions[fileIndex];
    if (file.disabledReprocess) {
      return;
    }
    file.sendToPJE = target.checked;
    setPjeFileOptions([...pjeFileOptions]);
  };

  const handleRemoveFile = (item, index) => {
    setMovementFiles(files => files.filter((_item, fileIndex) => fileIndex !== index));
    setPjeFileOptions(files => files.filter(_item => _item.id !== item.id));
  }

  const hasInfoError = (fileName = '') => filesInfo[fileName]?.type === 'error';

  const validateFiles = files => {
    let info = filesInfo;
    files.forEach(file => {
      const fileExtension = file.name
      .split('.')
      .pop()
      .toUpperCase();

      if (!PJE_FILE_MAX_SIZE_BY_TYPE[fileExtension]) {
        info = {
          ...info,
          [file.name]: {
            message: 'Tipo de arquivo não permitido. Será ignorado.',
            type: 'error',
          },
        }
      }
      if (PJE_FILE_MAX_SIZE_BY_TYPE[fileExtension] < file.size) {
        if (fileExtension === 'PDF') {
          info = {
            ...info,
            [file.name]: {
              message: 'O arquivo excede o tamanho máximo permitido. Será divido em partes menores.',
              type: 'warning',
            },
          };
        } else {
          info = {
            ...info,
            [file.name]: {
              message: 'O arquivo excede o tamanho máximo permitido. Será ignorado',
              type: 'error',
            },
          };
        }
      }
      setFilesInfo(info);
    });
  };

  useEffect(() => {
    validateFiles(movementFiles);
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: true,
    onDrop: files => validateFiles(getBase64(files)),
  });

  //Converter arquivo para Base64
  const getBase64 = files => {
    files.forEach(file => {
      const { size } = file;
      const fileExtension = file.name
        .split('.')
        .pop()
        .toUpperCase();
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result;
        setMovementFiles(files => [
          ...files,
          {
            name: file.name,
            base64,
            ignore: !PJE_FILE_MAX_SIZE_BY_TYPE[fileExtension] ||
            (fileExtension !== 'PDF' && size > PJE_FILE_MAX_SIZE_BY_TYPE[fileExtension]),
            size,
          },
        ]);
      };
      reader.onerror = function(error) {
        toast.warn(error);
      };
    });
    return files;
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      className={classes.modal}
      maxWidth="sm"
      fullWidth
    >
      <HeaderModal
        onClose={handleClose}
        backgroundColor={colors.navBar}
        colorIconTitle={colors.greySecundary}
      >
        Anexar Arquivos
      </HeaderModal>
      <DialogContent>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box mt={2}>
            <Typography variant="h5">Arquivos selecionados</Typography>
          </Box>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: 150 }}>
            <Box {...getRootProps({ className: 'dropzone' })} className={classes.dropzone} mt={2}>
              <input {...getInputProps()} />
              <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box mt={2}>
              <Typography variant="h5" style={{ display: 'flex' }}>
                Arquivos selecionados
                <span style={{ marginLeft: 5 }}>
                  <Tooltip
                    arrow
                    placement="right"
                    title={<TableFilesAllowed />}
                    style={{ backgroundColor: 'white' }}
                  >
                    <HelpIcon style={{ color: 'darkgray', fontSize: '15px', marginTop: '3px' }} />
                  </Tooltip>
                </span>
              </Typography>
              {movementFiles?.length > 0 &&
                movementFiles
                  .filter(f => f)
                  .map((file, index) => (
                    <Box key={index} display="flex" justifyContent="space-between"> 
                      <Box display="flex" alignItems="center" mt={1}>
                        <DescriptionIcon
                          style={{
                            marginRight: 5,
                            color: hasInfoError(file.name) && 'darkgray',
                          }}
                        />
                        <Typography variant="body1" display="flex">
                          {hasInfoError(file.name) ? (
                            <s>{file.pieceType || file.name}</s>
                          ) : (
                            (file.pieceType || file.name) +
                            (file?.fileType?.description === 'Arquivos de requisitório'
                              ? ' (Arquivo de requisitório)'
                              : '')
                          )}
                          {filesInfo[file.name] && (
                            <span style={{ marginLeft: 5 }}>
                              <Tooltip title={filesInfo[file.name].message} arrow placement="right">
                                <InfoIcon
                                  style={{
                                    color: hasInfoError(file.name) ? 'darkred' : 'orange',
                                    fontSize: '15px',
                                  }}
                                />
                              </Tooltip>
                            </span>
                          )}
                        </Typography>
                      </Box>
                      <IconButton
                        onClick={() => handleRemoveFile(file, index)}
                      >
                        <HighlightOffIcon style={{ color: '#e81224' }} />
                      </IconButton>
                    </Box>
                  ))}
            </Box>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" my={2}>
          <ButtonConsensus
            title="Enviar arquivos"
            onClick={onClose}
            disabled={loading || !movementFiles?.length && pjeFileOptions?.length === 0}
          />
        </Box>
        {!disableFiles && pjeFileOptions?.length > 0 ? (
          <>
            <Typography variant="h5">Arquivos a enviar para o PJE</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Enviar</TableCell>
                  <TableCell>Nome do arquivo</TableCell>
                  <TableCell>Status de processamento</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {pjeFileOptions.map((file, index) => {
                  const extensionNotAllowed = !PJE_FILE_MAX_SIZE_BY_TYPE[file.name.split('.').pop().toUpperCase()]
                  return (
                  <TableRow key={index}>
                    <TableCell>
                      <Checkbox
                        value={file.id}
                        onChange={handleSelectFile}
                        id={String(index)}
                        checked={file.sendToPJE}
                        disabled={file.disabledReprocess || extensionNotAllowed}
                        color="primary"
                      />
                    </TableCell>
                    <TableCell>
                      {extensionNotAllowed ? <s>{file.name}</s> : file?.name}
                      {extensionNotAllowed && (
                        <span style={{ marginLeft: 5 }}>
                          <Tooltip title="Tipo de arquivo não permitido." arrow placement="right">
                            <InfoIcon
                              style={{
                                color: 'darkred',
                                fontSize: '15px',
                              }}
                            />
                          </Tooltip>
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      {parseFileProcessStatus(file.fileProcess?.processingStatus)}
                    </TableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </>
        ) : (
          <Typography>Não há arquivos no inquérito para o PJE</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddFile;

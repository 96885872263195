import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Typography, Grid, Card, CircularProgress } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import history from 'utils/history';
import intimationService from 'services/intimation/intimationService';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerModal: {
    minWidth: 500,
    minHeight: 400,
    maxHeight: 450,
    width: '100%',
    overflow: 'auto',
    '@media (max-width:500px)': {
      minWidth: 300,
      minHeight: 350,
    },
    '@media (max-width:960px)': {
      minWidth: 300,
      minHeight: 350,
    },
  },
  header: {
    width: '100%',
    backgroundColor: '#00597b',
    height: 70,
    color: '#ffffff',
    paddingLeft: 30,
    fontSize: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    marginTop: 10,
    borderRadius: 25,
    padding: 20,
    height: 250,
    width: 450,
    '@media (max-width:700px)': {
      width: 355,
    },
  },
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  msgBody: {
    fontSize: 20,
    color: '#00597b',
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
  },
  dadosCriminais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 18,
  },
  containerDadosPessoais: {
    backgroundColor: '#ededed',
    marginTop: 5,
    padding: 10,
    borderRadius: 25,
  },
  labelDadoaPessoais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
  },
  labelCrime: {
    fontWeight: 'bold',
    color: '#000',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8cf5f',
    width: '60%',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  textArea: {
    width: '100%',
    padding: 5,
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
  },
  containerConfirm: {
    height: 380,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#787878',
    fontSize: 22,
    fontWeight: 'bold',
    padding: 100,
    '@media (max-width:690px)': {
      padding: 30,
    },
  },
  btnConfirm: {
    backgroundColor: '#00b1ac',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 40,
    height: 60,
    width: 170,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  circularProgress: {
    display: 'flex',
    /*'& > * + *': {
            marginLeft: theme.spacing(2),
        },*/
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 5,
    paddingTop: '20%',
  },
}));

const ModalListFIles = props => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [files, setFiles] = useState([]);
  const [showFiles, setShowFiles] = useState(false);

  const { open, onClose, className, intimation, ...rest } = props;

  useEffect(() => {
    if (intimation) {
      getFiles(intimation.id);
    } else {
      toast.warn('Não há intimações!');
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFiles = async (intimationId, _mode) => {
    await intimationService.getIntimationConfirmationCertificates(intimationId).then(response => {
      //console.log(response.data.certificates);
      setFiles(response.data.certificates);
      setShowFiles(true);
    });
  };

  const handleGoToWindowRequeriment = file => {
    history.push('/intimacao/arquivo-confirmacao', file.url);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        maxWidth="md"
      >
        <div className={classes.header}>
          <div>Arquivos da Intimação</div>
          <div style={{ paddingRight: 10 }}>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
          </div>
        </div>
        <div className={classes.containerModal}>
          {!showFiles ? (
            <div className={classes.circularProgress}>
              <CircularProgress style={{ height: 40, width: 40, color: '#00597b' }} />
            </div>
          ) : (
            <Grid container style={{ height: '100%', width: '100%' }}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                style={{ minHeight: 80, padding: 10, textAlign: 'center' }}
              >
                {files.length > 0 ? (
                  files.map((file, i) => {
                    return (
                      <Card
                        key={i}
                        onClick={() => handleGoToWindowRequeriment(file)}
                        style={{
                          width: '100%',
                          cursor: 'pointer',
                          display: 'inline-block',
                          justifyContent: 'center',
                          alignItems: 'center',
                          marginBottom: 10,
                          height: 80,
                        }}
                      >
                        <DescriptionIcon fontSize="large" />
                        <Typography>Tipo do Certificado: {file.intimationCertificateType}</Typography>
                        <Typography>
                          data da confirmação:{' '}
                          <Moment format="DD/MM/YYYY HH:mm" local>
                            {file.confirmedAt}
                          </Moment>
                        </Typography>
                      </Card>
                    );
                  })
                ) : (
                  <Typography style={{ paddingTop: 50, fontSize: 16 }}>Não há arquivos</Typography>
                )}
              </Grid>
            </Grid>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default ModalListFIles;

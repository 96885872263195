import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';
import { FormPersonalData, InputConsensus } from 'components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DvrIcon from '@material-ui/icons/Dvr';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import InputMask from 'react-input-mask';
import axios from 'utils/axios';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import { verifyMaskInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { colors } from 'utils/colorsSteps';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import organService from 'services/organ/organService';
import { isInfraction } from 'utils/isInfraction';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  TituloParagraph: {
    marginBottom: theme.spacing(1),
  },
  labelValue: {
    display: 'flex',
    width: '100%',
  },
  resultValue: {
    paddingLeft: 5,
    wordWrap: 'break-word',
    minWidth: '25%',
  },
  chip: {
    margin: 2,
    backgroundColor: '#00b1ac',
    color: '#ffffff',
  },
  assignmentButton: {
    '&:hover, &.Mui-focusVisible': { backgroundColor: colors.greenLight },
    backgroundColor: colors.greenLight,
    color: 'white',
    marginLeft: 4,
  },
}));

const InvestigatedInfo = props => {
  const { inquiry, showInvestigated, setOpenModalListFiles, peoples, refreshPage } = props;

  const classes = useStyles();

  const { allOriginNumbersAreNpu } = useCustomerConfig();

  const [key, setKey] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);

  const initialValues = {
    npu: inquiry.npu,
    judgmentPlace: inquiry.judgmentPlace,
  };

  const [formState, setFormState] = useState({
    values: { ...initialValues },
  });

  const [editJudicial, setEditJudicial] = useState(false);

  const handleEditJudicial = () => {
    setFormState(formState => ({
      ...formState,
      values: { ...initialValues },
    }));
    setEditJudicial(true);
  };

  const handleEditJudicialToFalse = () => {
    setEditJudicial(false);
  };

  const handleUpdateJudicial = () => {
    editJuficialInformations();
  };

  const handleGoToCertificate = () => {
    const url = `/certidao/${inquiry.id}`;
    window.open(process.env?.PUBLIC_URL ? process.env.PUBLIC_URL + url : url, '_blank');
  };

  const permissionsReducer = useSelector(state => state.logedUser.permissions);

  const handleChange = event => {
    event.persist();
    const { target } = event;
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [target.name]: target.type === 'checkbox' ? target.checked : target.value,
      },
    }));
  };

  const editJuficialInformations = async () => {
    const { npu, judgmentPlace } = formState.values;

    if (!npu || !judgmentPlace) {
      toast.warn('Verifique os campos NPU e Vara e tente novamente');
      return;
    }

    if (npu.length < 25) {
      toast.warn('NPU inválido. Verifique se o NPU digitado contém 20 números');
      return;
    }

    let params = {
      npu: npu,
      judgmentPlaceId: judgmentPlace.id,
    };

    await axios.patch(`inquiries/investigated/${inquiry.id}`, params).then(() => {
      toast.success('Informações alteradas com sucesso');
      setEditJudicial(false);
      refreshPage();
    });
  };

  const handleChangeJudgmentPlace = (event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        judgmentPlace: newValue ? newValue : null,
      },
    }));

    setKey(!key);
  };

  const getDelegacias = async (input = '') => {
    let params = {
      page: 1,
      size: 10,
      search: input,
      category: 'JUDICIARY',
    };

    await organService.getDelegaciasService(params).then(response => {
      //console.log({ comarcas: response.data.data });
      setOptions(response.data.data);
    });
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let active = true;

    if (inputValue === '') {
      setOptions(formState.values.judgmentPlace ? [formState.values.judgmentPlace] : []);
      return undefined;
    }

    getDelegacias(inputValue);

    return () => {
      active = false;
    };
  }, [formState.values.judgmentPlace, inputValue]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item container xl={10} lg={10} md={10} sm={10} xs={12}>
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>Dados do inquérito</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  <Grid item container spacing={1} xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.TituloParagraph}>
                        <Typography variant="h5">INVESTIGADO</Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.labelValue}>
                        <Typography variant="h6">Nome: </Typography>
                        <Typography variant="body2" className={classes.resultValue}>
                          {showInvestigated ? inquiry.investigated.name : <Skeleton width="100%" />}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.labelValue}>
                        <Typography variant="h6">Endereço: </Typography>
                        <Typography variant="body2" className={classes.resultValue}>
                          {showInvestigated ? (
                            JSON.stringify(inquiry.investigated.address) !== '{}' ? (
                              <>
                                {inquiry.investigated.address?.street
                                  ? inquiry.investigated.address?.street
                                  : ''}
                                {inquiry.investigated.address?.street
                                  ? ` Nº ${inquiry.investigated.address?.number}`
                                  : ''}
                                {inquiry.investigated.address?.zipCode
                                  ? ` ${inquiry.investigated.address?.complement}`
                                  : ''}
                                {inquiry.investigated.address?.street
                                  ? ` ${inquiry.investigated.address?.neighborhood}`
                                  : ''}
                                {inquiry.investigated.address?.zipCode
                                  ? ` ${inquiry.investigated.address?.zipCode}`
                                  : ''}
                                {inquiry.investigated.address?.street
                                  ? ` ${inquiry.investigated.address?.city}`
                                  : ''}
                                {inquiry.investigated.address?.street
                                  ? ` ${inquiry.investigated.address?.uf}`
                                  : ''}
                              </>
                            ) : (
                              'Não informado'
                            )
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                      <Box style={{ display: 'flex' }}>
                        <Typography variant="h6">CPF: </Typography>
                        <Typography variant="body2" style={{ marginLeft: 5, minWidth: '50%' }}>
                          {showInvestigated ? (
                            inquiry.investigated?.taxpayerRegistry
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                      <Box style={{ display: 'flex' }}>
                        <Typography variant="h6">E-mail: </Typography>
                        <Typography variant="body2" style={{ marginLeft: 5 }}>
                          {showInvestigated ? (
                            inquiry.investigated?.email
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                      <Box style={{ display: 'flex' }}>
                        <Typography variant="h6">Tel: </Typography>
                        <Typography variant="body2" style={{ marginLeft: 5, minWidth: '50%' }}>
                          {showInvestigated ? (
                            inquiry.investigated?.phone
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Box style={{ width: '50%' }}>
                          <Box className={classes.TituloParagraph}>
                            <Typography variant="h5" style={{ marginTop: 10 }}>
                              DADOS DA JUSTIÇA
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          style={{
                            width: '50%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                          }}
                        >
                          {!editJudicial ? (
                            <Tooltip title="Alterar dados" arrow placement="top">
                              <IconButton size="small" onClick={handleEditJudicial}>
                                <CreateIcon />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            editJudicial && (
                              <>
                                <Box style={{ marginRight: 10 }}>
                                  <Tooltip title="Salvar alterações" arrow placement="top">
                                    <IconButton size="small" onClick={handleUpdateJudicial}>
                                      <SaveIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                <Box>
                                  <Tooltip title="Cancelar" arrow placement="top">
                                    <IconButton size="small" onClick={handleEditJudicialToFalse}>
                                      <ClearIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                              </>
                            )
                          )}
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Box className={classes.boxLeft}>
                        {!editJudicial ? (
                          <>
                            <Typography
                              className={classes.labelTitle}
                              component="label"
                              variant="h6"
                            >
                              NPU
                            </Typography>
                            <Typography className={classes.inputResult} variant="body1">
                              {inquiry.npu || 'não informado'}
                            </Typography>
                          </>
                        ) : (
                          <InputMask
                            onChange={handleChange}
                            value={formState.values.npu || ''}
                            mask="9999999-99.9999.9.99.9999"
                            maskChar={null}
                          >
                            {inputProps => (
                              <InputConsensus
                                {...inputProps}
                                label="NPU"
                                name="npu"
                                variant="standard"
                                fullWidth
                              />
                            )}
                          </InputMask>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <Box className={classes.boxRight}>
                        {!editJudicial ? (
                          <>
                            <Typography
                              className={classes.labelTitle}
                              component="label"
                              variant="h6"
                            >
                              Local do julgamento
                            </Typography>
                            <Typography className={classes.inputResult} variant="body1">
                              {inquiry.judgmentPlace?.name || 'não informado'}
                            </Typography>
                          </>
                        ) : (
                          <Autocomplete
                            options={options}
                            fullWidth
                            autoHighlight
                            onChange={handleChangeJudgmentPlace}
                            value={formState.values.judgmentPlace || ''}
                            filterOptions={x => x}
                            key={key}
                            includeInputInList
                            filterSelectedOptions
                            noOptionsText="Não encontrado"
                            onInputChange={(event, newInputValue) => {
                              setInputValue(newInputValue);
                            }}
                            getOptionLabel={option => option.name}
                            renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
                            renderInput={params => (
                              <InputConsensus
                                {...params}
                                name="judgmentPlace"
                                label="Local do julgamento"
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        )}
                      </Box>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.TituloParagraph}>
                        <Typography variant="h5" style={{ marginTop: 10 }}>
                          INQUÉRITO
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.labelValue}>
                        <Typography variant="h6">Número de origem: </Typography>
                        <Typography variant="body2" className={classes.resultValue}>
                          {showInvestigated ? (
                            verifyMaskInquiryNumber(
                              inquiry.inquiry.policeInquiryNumber,
                              inquiry.inquiry.classeDescription,
                              allOriginNumbersAreNpu,
                            )
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.labelValue}>
                        <Typography variant="h6">
                          {isInfraction(inquiry.inquiry.classe)
                            ? 'Ato(s) Infracional(is)'
                            : 'Crime(s)'}
                          :{' '}
                        </Typography>
                        <Typography variant="body2" className={classes.resultValue}>
                          {showInvestigated ? (
                            inquiry.crimes.length > 0 ? (
                              inquiry.crimes.map((crime, index) => (
                                <span key={index}>{crime.taxonomy + '| '}</span>
                              ))
                            ) : (
                              ''
                            )
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.TituloParagraph}>
                        <Typography variant="h5" style={{ marginTop: 10 }}>
                          DESCRIÇÃO DO OCORRIDO
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.labelValue}>
                        <Typography
                          variant="body2"
                          style={{ paddingLeft: 0 }}
                          className={classes.resultValue}
                        >
                          {showInvestigated ? (
                            inquiry.inquiry.fact.report || 'Não informado'
                          ) : (
                            <Skeleton width="100%" />
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <Box className={classes.TituloParagraph}>
                        <Typography variant="h5" style={{ marginTop: 10 }}>
                          VÍTIMA(s)
                        </Typography>
                      </Box>
                    </Grid>
                    {showInvestigated ? (
                      inquiry.inquiry.victims.length > 0 ? (
                        inquiry.inquiry.victims.map((victim, index) => (
                          <Grid container key={index}>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <Box className={classes.labelValue}>
                                <Typography variant="h6">Nome: </Typography>
                                <Typography variant="body2" className={classes.resultValue}>
                                  {victim.name}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                              <Box style={{ display: 'flex' }}>
                                <Typography variant="h6">CPF: </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: 5, minWidth: '50%' }}
                                >
                                  {victim.taxpayerRegistry}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                              <Box style={{ display: 'flex' }}>
                                <Typography variant="h6">Tel: </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: 5, minWidth: '50%' }}
                                >
                                  {victim.phone}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        ))
                      ) : (
                        <Typography variant="body2">Não informado</Typography>
                      )
                    ) : (
                      <Skeleton width="100%" />
                    )}
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormPersonalData
                      inquiryClasse={inquiry.inquiry.classe}
                      peoples={peoples}
                      refreshPage={refreshPage}
                      read={permissionsReducer.inquiriesTriagem.read}
                      write={permissionsReducer.inquiriesTriagem.write}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
        <Grid
          item
          container
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Box style={{ width: '30%' }}>
            <Tooltip
              title="Consultar certidão"
              placement="top"
              arrow
              onClick={handleGoToCertificate}
            >
              <IconButton>
                <DvrIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
          <Box style={{ width: '30%' }}>
            <Tooltip
              title="Consultar arquivos do inquérito"
              placement="top"
              arrow
              onClick={() => setOpenModalListFiles(true)}
            >
              <IconButton className={classes.assignmentButton}>
                <AssignmentIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default InvestigatedInfo;

import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Typography,
  Dialog,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import prosecutorOfficeGroupService from 'services/prosecutor-office-group/prosecutorOfficeGroupService';
import InputConsensus from 'components/InputConsensus';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h3">{children}</Typography>
      {onClose && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles({
  judicialDistrictSpace: {
    padding: '8px 16px',
    marginBottom:'24px'
  },
});

const ModalListOfficeGroups = ({ onClose, open, onClick }) => {
  const classes = useStyles();

  const [selectedGroup, setSelectedGroup] = useState(null);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const debouncedFetchGroups = useCallback(
    debounce(value => fetchGroups(value, 1), 500),
    []
  );

  useEffect(() => {
    debouncedFetchGroups('');
  }, [debouncedFetchGroups]);

  const fetchGroups = (input = '', pageNumber = 1) => {
    setLoading(true);

    prosecutorOfficeGroupService
      .getOfficeGroupsService({ page: pageNumber, size: 20, name: input })
      .then(response => {
        const data = response.data.data;
        setOptions(prevOptions => (pageNumber === 1 ? data : [...prevOptions, ...data]));
        setLastPage(response.data.lastPage);
      })
      .finally(() => setLoading(false));
  };

  const handleInputChange = (_, newValue) => {
    setInputValue(newValue);
    debouncedFetchGroups(newValue);
  };

  const handleChange = (_, group) => {
    setSelectedGroup(group);
    onClick(group);
  };

  const handleScroll = event => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (
      scrollHeight - (scrollTop + clientHeight) <= 1 &&
      page < lastPage &&
      !loading
    ) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchGroups(inputValue, nextPage);
    }
  };

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="sm">
      <DialogTitle onClose={onClose}>Selecione um Grupo Ofício</DialogTitle>
      <Divider />
      <Autocomplete
        className={classes.judicialDistrictSpace}
        value={selectedGroup}
        onInputChange={handleInputChange}
        onChange={handleChange}
        getOptionSelected={(option, value) => option.name === value.name}
        getOptionLabel={option => option.name || ''}
        options={options}
        loading={loading}
        noOptionsText="Nada foi encontrado"
        loadingText="Buscando..."
        clearOnBlur
        renderOption={option => <>{option.name}</>}
        renderInput={params => (
          <InputConsensus
            {...params}
            label="Grupo Ofício"
            variant="outlined"
            size="small"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color="primary" size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        ListboxProps={{ onScroll: handleScroll }}
      />
    </Dialog>
  );
};

export default ModalListOfficeGroups;

import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';
import { currencyFormat, getOnlyNumbers } from 'utils/currency';

const pecuniaryParser = pecuniaryPenalties => {
  const newArrayPecuniary = [];
  pecuniaryPenalties.forEach(pecuniaryApi => {
    const pecuniaryValue = Number(getOnlyNumbers(`${pecuniaryApi.value}`));
    const formatedValue = currencyFormat(pecuniaryValue / 100);

    const newPecuniary = {
      ...pecuniaryApi,
      paymentDeadline: pecuniaryApi?.paymentDeadline?.substring(0, 10) || '',
      value: formatedValue,
      penalty: PENALTY_KINDS.PECUNIARY,
      parcels: pecuniaryApi?.installments?.map(i => ({
        ...i,
        value: String(i?.value),
        date: i.finalDate,
        initialDate: i.initialDate,
        minimumValue: i?.minimumValue ? String(i?.minimumValue) : null,
        maximumValue: i?.maximumValue ? String(i?.maximumValue) : null,
      })),
      project: pecuniaryApi?.project,
      installmentType: pecuniaryApi?.installmentType,
      totalValue: pecuniaryValue,
    };

    newArrayPecuniary.push(newPecuniary);
  });
  return newArrayPecuniary;
};

export default pecuniaryParser;

import React from 'react';
import { Dialog, DialogContent, List, ListItem, Typography } from '@material-ui/core';
import { HeaderModal } from 'components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Moment from 'react-moment';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

export default function ModalSelectHearing(props) {
  const { open, handleClose, hearings, setselectedHearing, onSelectHearing } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClick = item => {
    setselectedHearing(item);
    onSelectHearing();
    handleClose();
    //console.log(item);
  };

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="sm"
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <HeaderModal onClose={handleClose}>
          Selecione uma audiência{enableHearingForJuvenileOffenders ? '/oitiva' : ''}
        </HeaderModal>
        <DialogContent>
          <List component="nav">
            {hearings.map(item => (
              <ListItem button key={item.id} onClick={() => handleClick(item)}>
                <Typography variant="body2">
                  Audiência{enableHearingForJuvenileOffenders ? '/Oitiva' : ''} de{' '}
                  <Moment format="DD/MM/YYYY" local>
                    {item.date}
                  </Moment>
                  {' às'}
                  <Moment format="HH:mm" local>
                    {item.date}
                  </Moment>
                </Typography>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
}

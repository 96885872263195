import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Tooltip, TextField } from '@material-ui/core';
import GroupIcon from '@material-ui/icons/Group';
import { Autocomplete } from '@material-ui/lab';
import { setGroupReducer, setSelectedIndexGroup } from '../../../../../../actions';
import { ModalChangeGroup } from './components';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(() => ({
  grupoOficioStyle: {
    fontFamily: 'Roboto-Regular',
    color: '#ffffff',
    textTransform: 'capitalize',
    fontSize: 12,
    fontWeight: 'normal',
  },
  autocompleteStyle: {
    width: 250,
    backgroundColor: '#fff',
    borderRadius: 6,
  },
  inputRoot: {
    padding: '2px 4px !important',
    backgroundColor: colors.primary,
    border: 'none',
    cursor: 'pointer',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  input: {
    backgroundColor: colors.primary,
    color: '#fff',
    padding: 0,
    border: 'none',
    cursor: 'pointer',
    '&::placeholder': {
      color: '#fff',
      opacity: 1,
    },
  },
  endAdornment: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
  },
}));

export default function GroupsMenu() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [openChangeGroup, setOpenChangeGroup] = useState(false);

  const groups = useSelector(state => state.logedUser.groups);
  const { selectedIndexGroup } = useSelector(state => state.groupMenu);

  const handleGroupChange = (event, newValue) => {
    if (newValue && newValue.id !== groups[selectedIndexGroup]?.id) {
      setSelectedGroup(newValue);
      setOpenChangeGroup(true);
    }
  };

  const confirmChange = () => {
    const index = groups.findIndex(group => group.id === selectedGroup.id);
    dispatch(setGroupReducer(selectedGroup));
    dispatch(setSelectedIndexGroup(index));
    setSelectedGroup(null);
    setOpenChangeGroup(false);
  };

  return (
    <div>
      <Box style={{ marginRight: 10 }}>
        <Tooltip arrow title="Grupo ofício">
          <Autocomplete
            options={groups}
            getOptionLabel={option => option.name}
            className={classes.autocompleteStyle}
            classes={{
              inputRoot: classes.inputRoot,
              input: classes.input,
            }}
            disableClearable
            openOnFocus
            blurOnSelect
            renderInput={params => (
              <TextField
                {...params}
                placeholder="Grupo Ofício"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <div className={classes.endAdornment}>
                      <GroupIcon style={{ marginRight: 5 }} color="white" />
                      {params.InputProps.endAdornment}
                    </div>
                  ),
                }}
              />
            )}
            value={groups[selectedIndexGroup] || null}
            onChange={handleGroupChange}
          />
        </Tooltip>
      </Box>
      {openChangeGroup && (
        <ModalChangeGroup
          open={openChangeGroup}
          onClose={() => setOpenChangeGroup(false)}
          group={selectedGroup}
          confirmChange={confirmChange}
        />
      )}
    </div>
  );
}

import { axiosMock } from 'utils/axios';
import associations from './data/associations';

axiosMock.onGet('/api/associations').reply(200, {
  currentPage: 1,
  lastPage: 1,
  pageSize: associations.length,
  total: associations.length,
  associations,
});

export const PJE_FILE_MAX_SIZE_BY_TYPE = {
  PDF: 3 * 1024 * 1024,
  MPEG: 5 * 1024 * 1024,
  OGG: 10 * 1024 * 1024,
  PNG: 3 * 1024 * 1024,
  MP4: 10 * 1024 * 1024,
  MOV: 10 * 1024 * 1024,
};

export const parseFileProcessStatus = processingStatus => {
  if (!processingStatus) {
    return '';
  }
  if (processingStatus === 'PROCESSING') {
    return 'Em processamento';
  } else if (processingStatus === 'WAITING') {
    return 'Aguardando';
  } else if (processingStatus === 'DONE') {
    return 'Finalizado';
  } else if (processingStatus === 'ERROR') {
    return 'Erro ao processar arquivo';
  } else if (processingStatus === 'CANCELED') {
    return 'Cancelado';
  }
  return 'Desconhecido';
};
import * as actionTypes from 'actions';

const initialState = {
  filters: {
    prosecutor: null,
    dateFrom: null,
    dateUntil: null,
  },
  displayOldResults: false,
};

const lifetimeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LIFETIME_FILTERS: {
      return { ...state, filters: action.payload };
    }

    case actionTypes.LOAD_OLD_LIFETIME_RESULTS: {
      return { ...state, displayOldResults: action.payload };
    }

    default:
      return state;
  }
};

export default lifetimeReducer;

import React, { useState, useEffect } from 'react';
import {
  TextField,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  useMediaQuery,
  Grid,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { HeaderModal, ButtonConsensus } from 'components';
import { useTheme } from '@material-ui/core/styles';
import useStyles from './styles';
import { colors } from 'utils/colorsSteps';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { toast } from 'react-toastify';
import history from 'utils/history';
import organService from 'services/organ/organService';
import pendingInquiryService from 'services/pending-inquiries/pendingInquiryService';
import inquiryReturnationService from 'services/inquiry-returnation/inquiryReturnationService';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';

const ModalGiveBackInquiry = props => {
  const {
    open,
    onClose,
    setOpenModalListFIle,
    pendingInquiryId,
    inquiry,
    people,
    organEmails,
    ...rest
  } = props;

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const organOriginReducer = useSelector(state => state.organOrigin);

  const initialState = {
    typeDevolution: '',
    description: '',
    email: organOriginReducer?.email || '',
    requireResponse: '',
  };

  const [formState, setFormState] = useState({
    values: { ...initialState },
  });

  const emails =
    organEmails || organOriginReducer.email
      ? [organOriginReducer.email, ...(organOriginReducer.alternativeEmails || [])]
      : [...(organOriginReducer.alternativeEmails || [])];

  const [disabled, setDisabled] = useState(false);

  const [textMode, setTextMode] = useState(emails.length === 0);

  const [returnationTypes, setReturnationTypes] = useState([]);
  const [focused, setFocused] = useState(false);
  const [ccEmails, setCcEmails] = useState([]);

  const handleClose = () => {
    onClose();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeReturnationType = event => {
    event.persist();
    const envolvedInvestigateds = people
      .filter(person => person.peopleType === 'investigated')
      .map(person => person.name);
    const victims = people
      .filter(person => person.peopleType === 'victim')
      .map(person => person.name);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        typeDevolution: event.target.value,
        description: `${event.target.value?.description || ''}\n\nDelegacia de origem: ${inquiry
          ?.sourceOrgan?.name || ''}\nNúmero do procedimento: ${inquiry?.policeInquiryNumber ||
          ''}\nInvestigados: ${envolvedInvestigateds.toString() ||
          ''}\nVítimas: ${victims.toString()}`,
      },
    }));
  };

  const handleChangeEmailMode = () => {
    if (textMode) {
      formState.values.email = organOriginReducer?.email;
      setFormState(formState);
      setTextMode(false);
    } else {
      formState.values.email = '';
      setFormState(formState);
      setTextMode(true);
    }
  };

  const handleConfirm = event => {
    event.preventDefault();

    const { description, typeDevolution, email, requireResponse } = formState.values;

    let errors = [];

    if (!typeDevolution?.id) {
      errors.push('Tipo de Devolução');
    }

    if (requireResponse === '') {
      errors.push('Devolução com Retorno');
    }

    if (email === 'não cadastrado' || !email) {
      errors.push('E-mail');
    }

    if (description === '') {
      errors.push('Descrição');
    }

    if (errors.length > 0) {
      toast.warn(`Preencha ${errors.join(errors.length > 2 ? ', ' : ' e ')}, para confirmar`);
      return;
    }

    let params = {
      email,
      returnationTypeId: typeDevolution.id,
      requireResponse,
    };

    if (ccEmails.length > 0) {
      params.cc = ccEmails;
    }

    if (!organOriginReducer?.email) {
      params.id = organOriginReducer?.id;
      registerEmail(params);
    } else {
      params.justification = description;
      giveBackInquiry(params);
    }
  };

  const giveBackInquiry = async params => {
    setDisabled(true);

    pendingInquiryService
      .giveBackPendingInquiryService(pendingInquiryId, params)
      .then(() => {
        toast.success('Devolução realizada com sucesso');

        handleClose();

        setFormState(formState => ({
          ...formState,
          values: { ...initialState },
        }));

        setTimeout(() => {
          history.push('/Dashboard');
        }, 2000);
      })
      .finally(() => setDisabled(false));
  };

  const registerEmail = async params => {
    setDisabled(true);
    await organService
      .organUpdateEmailService(params.id, { email: params.email })
      .then(() => {
        let paramsGiveBackInquiry = {
          justification: formState.values.description,
          email: params.email,
        };
        giveBackInquiry(paramsGiveBackInquiry);
      })
      .catch(() => {
        setDisabled(false);
      });
  };

  const getReturnationTypes = async () => {
    inquiryReturnationService.getAllInquiryReturnationTypes().then(response => {
      setReturnationTypes(response.data);
    });
  };

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getReturnationTypes();
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Dialog
        {...rest}
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
        aria-labelledby="modal-give-back-inquiry"
      >
        <HeaderModal onClose={handleClose}>Devolução de inquérito</HeaderModal>
        <form onSubmit={handleConfirm}>
          <DialogContent>
            <Grid container alignItems="center" spacing={2}>
              {fullScreen ? (
                <>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={3}
                    sm={4}
                    xs={12}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <ButtonConsensus
                      title="Ver Inquérito"
                      margin={0}
                      backgroundColor={colors.greenLight}
                      hovercolor={colors.greenLight}
                      startIcon={<AssignmentIcon />}
                      onClick={() => setOpenModalListFIle(true)}
                      size="medium"
                    />
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      select
                      label="Tipo de Devolução"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="typeDevolution"
                      value={formState.values.typeDevolution}
                      onChange={handleChangeReturnationType}
                    >
                      {returnationTypes.map(item => (
                        <MenuItem key={item.id} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      select
                      label="Devolução com Retorno"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="requireResponse"
                      value={formState.values.requireResponse}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>{'Sim'}</MenuItem>
                      <MenuItem value={false}>{'Não'}</MenuItem>
                    </TextField>
                  </Grid>
                  {textMode ? (
                    <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                      <TextField
                        label="E-mail"
                        variant="outlined"
                        size="small"
                        fullWidth
                        type="email"
                        name="email"
                        value={formState.values.email || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                        <TextField
                          select
                          label="E-mail"
                          variant="outlined"
                          size="small"
                          type="email"
                          fullWidth
                          name="email"
                          value={formState.values.email || ''}
                          onChange={handleChange}
                        >
                          {emails?.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  )}
                  {emails.length ? (
                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                      <ButtonConsensus
                        title={textMode ? 'Selecionar E-mail' : 'Digitar E-mail'}
                        margin={0}
                        onClick={handleChangeEmailMode}
                        size="medium"
                        fullWidth
                      />
                    </Grid>
                  ) : null}
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      label="Órgão de origem"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="organ-name"
                      value={organOriginReducer?.name || 'Não informado'}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      select
                      label="Tipo de Devolução"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="typeDevolution"
                      value={formState.values.typeDevolution}
                      onChange={handleChangeReturnationType}
                    >
                      {returnationTypes.map(item => (
                        <MenuItem key={item.id} value={item}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      select
                      label="Devolução com Retorno"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="requireResponse"
                      value={formState.values.requireResponse}
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>{'Sim'}</MenuItem>
                      <MenuItem value={false}>{'Não'}</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                    <ButtonConsensus
                      title="Ver Inquérito"
                      margin={0}
                      backgroundColor={colors.greenLight}
                      hovercolor={colors.greenLight}
                      startIcon={<AssignmentIcon />}
                      onClick={() => setOpenModalListFIle(true)}
                      size="medium"
                      fullWidth
                    />
                  </Grid>
                  {textMode ? (
                    <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                      <TextField
                        label="E-mail"
                        variant="outlined"
                        size="small"
                        type="email"
                        fullWidth
                        name="email"
                        value={formState.values.email || ''}
                        onChange={handleChange}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                        <TextField
                          select
                          label="E-mail"
                          variant="outlined"
                          size="small"
                          type="email"
                          fullWidth
                          name="email"
                          value={formState.values.email || ''}
                          onChange={handleChange}
                        >
                          {emails?.map(item => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    </>
                  )}
                  {emails.length ? (
                    <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                      <ButtonConsensus
                        title={textMode ? 'Selecionar E-mail' : 'Digitar E-mail'}
                        margin={0}
                        onClick={handleChangeEmailMode}
                        size="medium"
                        fullWidth
                      />
                    </Grid>
                  ) : null}
                  <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
                    <TextField
                      label="Órgão de origem"
                      variant="outlined"
                      size="small"
                      fullWidth
                      name="organ-name"
                      value={organOriginReducer?.name || 'Não informado'}
                      inputProps={{ readOnly: true }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xl={9} lg={9} md={9} sm={8} xs={12}>
              <Typography style={{ marginTop: 2 }}>
                {'Cc (Após digitar cada email, aperte enter)'}
              </Typography>
              <ReactMultiEmail
                placeholder="Cc"
                emails={ccEmails}
                onChange={_emails => {
                  setCcEmails(_emails);
                }}
                autoFocus={true}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      <div data-tag-item>{email}</div>
                      <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                      </span>
                    </div>
                  );
                }}
              />
            </Grid>
            <div className={classes.containerTextArea}>
              <TextField
                multiline
                minRows={10}
                maxRows={10}
                label="Descrição"
                variant="outlined"
                fullWidth
                name="description"
                value={formState.values.description}
                onChange={handleChange}
              />
            </div>
          </DialogContent>
          <DialogActions className={classes.action}>
            <ButtonConsensus
              title="CONFIRMAR"
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              color="#fff"
              type="submit"
              disabled={disabled}
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ModalGiveBackInquiry;

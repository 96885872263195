import React from 'react';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { HeaderModal } from 'components';
import { useTheme } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = makeStyles(() => ({
  container: {},
}));

const ModalListProblems = props => {
  const { open, onClose, problems, ...rest } = props;

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    onClose();
  };

  const problemToPt = problemENGL => {
    switch (problemENGL) {
      case 'MISSING_INVESTIGATED_RG':
        return 'INVESTIGADO SEM RG';
      case 'MISSING_INVESTIGATED_NAME':
        return 'INVESTIGADO SEM NOME';
      case 'MISSING_CRIMES':
        return 'NENHUM CRIME FOI INFORMADO';
      case 'MISSING_FILES':
        return 'NENHUM ARQUIVO FOI ADICIONADO';
      case 'INACCESSIBLE_FILE':
        return 'ARQUIVO INACESSÍVEL';
      case 'DUPLICATED_FILE_NAME':
        return 'ARQUIVOS COM NOMES DUPLICADOS';
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        {...rest}
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        fullScreen={fullScreen}
        aria-labelledby="modal-give-back-inquiry"
      >
        <HeaderModal onClose={handleClose}>Problemas deste inquérito</HeaderModal>
        <DialogContent className={classes.container}>
          <List component="nav" aria-label="main mailbox folders">
            {problems.map(problem => (
              <Box key={problem.id}>
                <ListItem button>
                  <ListItemIcon>
                    <ErrorOutlineIcon />
                  </ListItemIcon>
                  <ListItemText primary={problemToPt(problem.type)} />
                </ListItem>
                <Divider />
              </Box>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalListProblems;

export const SET_TAB_INDEX_SERVICES_MATERIALS = 'SET_TAB_INDEX_SERVICES_MATERIALS';
export const SET_TAB_INDEX_SERVICES_MATERIALS_SOLICITATION =
  'SET_TAB_INDEX_SERVICES_MATERIALS_SOLICITATION';

export const setSelectedTabIndexMaterialService = (index = 0) => ({
  type: SET_TAB_INDEX_SERVICES_MATERIALS,
  payload: index,
});

export const setSelectedTabIndexMaterialServiceSolicitation = (index = 0) => ({
  type: SET_TAB_INDEX_SERVICES_MATERIALS_SOLICITATION,
  payload: index,
});

/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Home from '@material-ui/icons/Home';
import AddIcon from '@material-ui/icons/Add';
import ViewListIcon from '@material-ui/icons/ViewList';
import SettingsIcon from '@material-ui/icons/Settings';

export default [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Home',
        href: '/pm/dashboard',
        icon: Home,
      },
      {
        title: 'Inquéritos',
        href: '/pm/inqueritos',
        icon: ViewListIcon,
      },
      {
        title: 'Configurações',
        href: '#',
        icon: SettingsIcon,
        resource: '',
        children: [
          {
            title: 'Senha Consensus',
            href: '/pm/configuracao-consensus',
          },
        ],
      },
      {
        title: 'Cadastros',
        href: '#',
        icon: AddIcon,
        children: [
          {
            title: 'Cadastro de Inquéritos',
            href: '/pm/cadastro/inquerito',
          },
          // {
          //   title: 'Cadastro de Usuário',
          //   href: '/pm/cadastro/usuario',
          // }
        ],
      },
      {
        title: 'Sair',
        href: '/',
        icon: ExitToAppIcon,
      },
    ],
  },
];

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { KeyboardDatePicker as Picker } from '@material-ui/pickers';
import InputConsensus from 'components/InputConsensus';
import { formatDateToBrazilian, formatDateToISO8601 } from 'utils/date-time.utils';

const KeyboardDatePicker = props => {
  const {
    onlyMonthYear,
    disableToolbar,
    placeholder,
    margin,
    autoOk,
    label,
    format,
    value,
    onChange,
    minDate,
    minDateMessage = minDate && `Data mínima permitida: ${formatDateToBrazilian(minDate)}`,
    maxDate,
    maxDateMessage = maxDate && `Data máxima permitida: ${formatDateToBrazilian(maxDate)}`,
    name,
    shrink,
    fullWidth,
    ariaLabel,
    invalidDateMessage,
    invalidLabel,
    inputVariant,
    component: InputComponent,
    views = onlyMonthYear ? ['year', 'month'] : ['year', 'month', 'date'],
    ...rest
  } = props;

  const customReturnedValue = useCallback(
    value => {
      if (value) {
        return views.includes('date') ? formatDateToISO8601(value) : value;
      }

      return null;
    },
    [views],
  );

  return (
    <Picker
      {...rest}
      autoOk={autoOk}
      disableToolbar={disableToolbar}
      format={format}
      margin={margin}
      name={name}
      InputLabelProps={{ shrink }}
      fullWidth={fullWidth}
      placeholder={placeholder}
      minDate={minDate}
      minDateMessage={minDateMessage}
      maxDate={maxDate}
      maxDateMessage={maxDateMessage}
      label={label}
      value={value}
      onChange={value => onChange(customReturnedValue(value))}
      KeyboardButtonProps={{
        'aria-label': ariaLabel,
      }}
      invalidDateMessage={invalidDateMessage}
      invalidLabel={invalidLabel}
      TextFieldComponent={InputComponent}
      inputVariant={inputVariant}
      views={views}
    />
  );
};

KeyboardDatePicker.propTypes = {
  autoOk: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, null]).isRequired,
  onChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['dialog', 'inline', 'static']),
  format: PropTypes.oneOf(['DD/MM/yyyy', 'MM/dd/yyyy', 'MM/yyyy']),
  label: PropTypes.string.isRequired,
  margin: PropTypes.oneOf(['normal', 'none', 'dense']),
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  minDate: PropTypes.any,
  minDateMessage: PropTypes.string,
  maxDate: PropTypes.any,
  maxDateMessage: PropTypes.string,
  ariaLabel: PropTypes.oneOf(['change date', 'change time']),
  invalidDateMessage: PropTypes.string,
  invalidLabel: PropTypes.oneOfType([PropTypes.string, null]),
  component: PropTypes.any,
  disableToolbar: PropTypes.bool,
  shrink: PropTypes.bool,
  inputVariant: PropTypes.oneOf(['filled', 'outlined', 'standard']),
  views: PropTypes.arrayOf(PropTypes.string),
  onlyMonthYear: PropTypes.bool,
};

KeyboardDatePicker.defaultProps = {
  autoOk: true,
  onChange: () => null,
  format: 'DD/MM/yyyy',
  margin: 'none',
  placeholder: 'dd/mm/aaaa',
  fullWidth: true,
  ariaLabel: 'change date',
  invalidDateMessage: 'Data inválida',
  invalidLabel: null,
  component: InputConsensus,
  disableToolbar: true,
  shrink: true,
  minDateMessage: 'A data não deve ser anterior à data mínima',
  inputVariant: 'standard',
  onlyMonthYear: false,
};

export default KeyboardDatePicker;

export const USER_TYPE = {
  User: 'User',
  Prosecutor: 'Prosecutor',
  AssociationRepresentative: 'AssociationRepresentative',
  InquiryRegistrationUser: 'InquiryRegistrationUser',
};

export const getUserTypeNameToLayoud = (type) => {
  switch (type) {
    case 'User':
      return 'Usuário';
    case 'Prosecutor':
      return 'Promotor';
    case 'AssociationRepresentative':
      return 'Instituição';
    case 'InquiryRegistrationUser':
      return 'PM/Bombeiro/Casa Civil';
    default:
      return type;
  }
};

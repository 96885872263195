import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, IconButton, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { FileTypes } from 'constants/file-types';
import { AiOutlineDownload } from 'react-icons/ai';

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none',
    height: '100%',
    backgroundColor: 'transparent',
    overflow: 'hidden',
    padding: 20,
  },
  imgMppe: {
    height: '70%',
    width: '5%',
    '@media (max-width: 1000px)': {
      width: '10%',
    },
    '@media (max-width: 600px)': {
      width: '20%',
      height: '100%',
    },
  },
  containerimg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  containerTitle: {
    textAlign: 'center',
    fontSize: 25,
    marginBottom: 30,
  },
  containerText: {
    fontSize: 15,
    wordWrap: 'break-word',
    textAlign: 'justify',
  },
  actionBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 10,
    border: '1px solid #e1e1e1',
    backgroundColor: '#fefefe',
  },
}));

const Viewer = props => {
  const { className, url, type, readMode = false, ...rest } = props;
  const classes = useStyles();

  const handleDownloadFile = () => {
    window.open(url, '_blank');
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      {[FileTypes.DOC, FileTypes.DOCX].includes(type) && !readMode && (
        <div className={classes.actionBar}>
          <Tooltip arrow placement="top" title="Baixar arquivo editável">
            <IconButton onClick={handleDownloadFile}>
              <AiOutlineDownload size={20} />
            </IconButton>
          </Tooltip>
        </div>
      )}
      <iframe
        id="myIframe"
        src={
          type === FileTypes.DOC || type === FileTypes.DOCX
            ? `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`
            : url
        }
        width="100%"
        height="100%"
        style={{ border: 'none', overflow: 'hidden' }}
        title="document"
      />
    </Card>
  );
};

export default Viewer;

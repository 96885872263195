import _ from 'lodash';

const calculateValuesForProjects = (data, property) => {
  const projectGroups = {};

  for (const item of data) {
    const projectId = item.projectId;
    if (!projectGroups[projectId]) {
      projectGroups[projectId] = [];
    }
    projectGroups[projectId].push(item);
  }

  const result = [];
  for (const projectId in projectGroups) {
    const group = projectGroups[projectId];
    const totalValue = group.reduce((acc, item) => {
      return acc + item.installments.reduce((sum, installment) => sum + installment.value, 0);
    }, 0);
    result.push({
      projectId,
      [property]: totalValue,
    });
  }

  return result;
};

const calculateValuesForAssociations = (data, keyName, valueKey) => {
  const result = {};

  data.forEach(item => {
    const chave = `${item.associationId}-${item[keyName]}`;
    if (!result[chave]) {
      result[chave] = {
        associationId: item.associationId,
        associationName: item.associationName,
        [keyName]: item[keyName],
        [valueKey]: 0,
      };
    }
    result[chave][valueKey] += item.installments.reduce(
      (acc, installment) => acc + installment.value,
      0,
    );
  });

  return Object.values(result);
};

const parsedDataForProjects = projects => {
  const grouped = {};

  projects.forEach(project => {
    const { projectId } = project;

    if (!grouped[projectId]) {
      grouped[projectId] = [];
    }

    grouped[projectId].push(project);
  });

  return Object.values(grouped).map(group => Object.assign({}, ...group));
};

const parsedDataForAssociations = associations => {
  const grouped = {};

  associations.forEach(association => {
    const { associationId, associationName } = association;

    if (!grouped[associationId]) {
      grouped[associationId] = {
        associationId,
        associationName,
        materials: [],
        services: [],
      };
    }
    if (association.materialName) {
      grouped[associationId].materials.push({
        materialName: association.materialName,
        materialQuantity: association.materialQuantity,
      });
    } else {
      grouped[associationId].services.push({
        serviceName: association.serviceName,
        serviceHour: association.serviceHour,
      });
    }
  });

  return Object.values(grouped);
};

export {
  calculateValuesForProjects,
  calculateValuesForAssociations,
  parsedDataForProjects,
  parsedDataForAssociations,
};

import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import history from 'utils/history';

/**
 * Redirect user to screen detail of the inquiry.
 * @param {Object} { id, step } - to have id and step with attribute.
 */
export const redirectToDetailInquiryStep = inquiry => {
  let url = '';
  switch (inquiry.step) {
    case STEPS_INQUIRY.TRIAGEM:
      url = `/detalhe/triagem/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.PESQUISA:
      url = `/detalhe/pesquisa-e-distribuicao/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.ANALISE:
      url = `/detalhe/analise/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.TRAMITACAO_CONTROLE:
      url = `/detalhe/tramitacao/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.INTIMACAO:
      url = `/detalhe/intimacao/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.AUDIENCIA:
      url = `/detalhe/audiencia/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.CUMPRIMENTO_ACORDO:
      url = `/detalhe/acordo/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO:
      url = `/detalhe/termo-de-encaminhamento/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.MIGRACAO:
      url = `/detalhe/migracao/${inquiry.id}`;
      break;
    case STEPS_INQUIRY.PERSECUCAO:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
    case STEPS_INQUIRY.DENUNCIA:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
    case STEPS_INQUIRY.ARQUIVAR:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
    case STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
    case STEPS_INQUIRY.DECLINAR:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
    case STEPS_INQUIRY.NAO_IDENTIFICADO:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
    case STEPS_INQUIRY.REMETIDO_AO_SISTEMA_INTERNO:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
    case STEPS_INQUIRY.DEVOLVIDO:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
    default:
      url = `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`;
      break;
  }
  return history.replace(url);
};

import React from 'react';
import { Dialog, DialogContent, TextField, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { HeaderModal } from 'components';
import { makeStyles, withStyles } from '@material-ui/styles';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const CssTextField = withStyles(() => ({
  root: {
    backgroundColor: '#fff',
    borderRadius: 5,
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#fff',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#000',
      },
      '&:hover fieldset': {
        borderColor: '#000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#000',
      },
    },
  },
}))(TextField);

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  containerIframe: {
    height: '65vh',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    minWidth: 500,
    [theme.breakpoints.down('sm')]: {
      height: '80vh',
    },
  },
  iframe: {
    borderWidth: 0,
    height: '100%',
    width: '100%',
  },
  containerGrid: {
    marginBottom: theme.spacing(0.5),
  },
  containerInput: {
    padding: theme.spacing(0.5),
  },
}));

const ModalSchedule = props => {
  const { open, onClose, url, formState, setFormState } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  return (
    <div>
      <Dialog fullWidth maxWidth={'lg'} fullScreen={fullScreen} open={open} onClose={onClose}>
        <HeaderModal onClose={onClose}>Agenda do Promotor</HeaderModal>
        <DialogContent className={classes.root}>
          <Grid container spacing={1} className={classes.containerGrid}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h4">
                Informe a data e hora da audiência
                {enableHearingForJuvenileOffenders ? '/oitiva' : ''}
              </Typography>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={6}
              xs={12}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className={classes.containerInput}>
                <CssTextField
                  //fullWidth
                  size="small"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  /*inputProps={{
                                        step: 300, // 5 min
                                    }}*/
                  label={`Data da audiência${enableHearingForJuvenileOffenders ? '/oitiva' : ''}`}
                  name="hearingDate"
                  onChange={handleChange}
                  value={formState.values.hearingDate || ''}
                />
              </div>
              <div className={classes.containerInput}>
                <CssTextField
                  label="Hora"
                  name="hourHearing"
                  variant="outlined"
                  size="small"
                  type="time"
                  value={formState.values.hourHearing || ''}
                  onChange={handleChange}
                  //fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </div>
            </Grid>
          </Grid>
          <div className={classes.containerIframe}>
            <iframe src={url} className={classes.iframe} title="schedule" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalSchedule;

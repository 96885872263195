import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { string, func, number, bool, shape, node } from 'prop-types';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import formatCurrency from './format-currency';

const InputConsensus = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiInput-underline:hover:before': {
      borderBottom: '2px solid #000',
    },
    '& .MuiInput-input': {
      color: '#000',
      fontSize: 14,
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& label.MuiFormLabel-root': {
      color: '#000',
      fontSize: 12,
    },
    '& .Mui-error': {
      fontSize: 10,
      color: 'red',
    },
  },
})(TextField);

const defaultConfig = {
  locale: 'pt-BR',
  formats: {
    number: {
      BRL: {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const IntlCurrencyInput = ({
  // eslint-disable-next-line no-unused-vars
  component: InputComponent,
  value,
  defaultValue,
  allowNullValue,
  config,
  currency,
  max,
  autoFocus,
  autoSelect,
  autoReset,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  disabledInput,
  disabled,
  // eslint-disable-next-line no-unused-vars
  customCurrencyLabel,
  ...otherProps
}) => {
  const inputRef = useCallback(
    node => {
      const isActive = node === document.activeElement;

      if (node && autoFocus && !isActive) {
        node.focus();
      }
    },
    [autoFocus],
  );

  const [maskedValue, setMaskedValue] = useState(allowNullValue ? '' : '0');

  // to prevent a malformed config object
  const safeConfig = useMemo(
    () => () => {
      const {
        formats: {
          number: {
            [currency]: { maximumFractionDigits },
          },
        },
      } = config;

      const finalConfig = {
        ...defaultConfig,
        ...config,
      };

      // at the moment this prevents problems when converting numbers
      // with zeroes in-between, otherwise 205 would convert to 25.
      finalConfig.formats.number[currency].minimumFractionDigits = maximumFractionDigits;

      return finalConfig;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultConfig, config],
  );

  const clean = number => {
    if (typeof number === 'number') {
      return number;
    }

    // strips everything that is not a number (positive or negative)
    return Number(number.toString().replace(/[^0-9-]/g, ''));
  };

  const normalizeValue = number => {
    const {
      formats: {
        number: {
          [currency]: { maximumFractionDigits },
        },
      },
    } = safeConfig();
    let safeNumber = number;

    if (typeof number === 'string') {
      safeNumber = clean(number);

      if (safeNumber % 1 !== 0) {
        safeNumber = safeNumber.toFixed(maximumFractionDigits);
      }
    } else {
      // all input numbers must be a float point (for the cents portion). This is a fallback in case of integer ones.
      safeNumber = Number.isInteger(number)
        ? Number(number) * 10 ** maximumFractionDigits
        : number.toFixed(maximumFractionDigits);
    }

    // divide it by 10 power the maximum fraction digits.
    return clean(safeNumber) / 10 ** maximumFractionDigits;
  };

  const calculateValues = inputFieldValue => {

    if (allowNullValue && (inputFieldValue === '' || inputFieldValue == null)) {
      return [null, ''];
    }

    const value = normalizeValue(inputFieldValue);
    const maskedValue = formatCurrency(value, safeConfig(), currency);

    return [value, maskedValue];
  };

  const updateValues = value => {

    if (allowNullValue && (value === '' || value == null)) {
      setMaskedValue('');
      return [null, ''];
    }

    const [calculatedValue, calculatedMaskedValue] = calculateValues(value);

    if (!max || calculatedValue <= max) {
      setMaskedValue(calculatedMaskedValue);

      return [calculatedValue, calculatedMaskedValue];
    } else {
      return [normalizeValue(maskedValue), maskedValue];
    }
  };

  const handleChange = event => {
    event.preventDefault();

    const [value, maskedValue] = updateValues(event.target.value);

    if (maskedValue) {
      onChange(event, value, maskedValue);
    }
  };

  const handleBlur = event => {
    const [value, maskedValue] = updateValues(event.target.value);

    if (autoReset) {
      calculateValues(0);
    }

    if (maskedValue) {
      onBlur(event, value, maskedValue);
    }
  };

  const handleFocus = event => {
    if (autoSelect) {
      event.target.select();
    }

    const [value, maskedValue] = updateValues(event.target.value);

    if (maskedValue) {
      onFocus(event, value, maskedValue);
    }
  };

  const handleKeyUp = event => onKeyPress(event, event.key, event.keyCode);

  useEffect(() => {
    if (allowNullValue && (value == null || value === '')) {
      setMaskedValue('');
    } else {
      const currentValue = value || defaultValue || 0;
      const [, maskedValue] = calculateValues(currentValue);

      setMaskedValue(maskedValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
  }, [currency, value, defaultValue, config]);

  return (
    <InputConsensus
      {...otherProps}
      ref={inputRef}
      value={maskedValue}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onKeyUp={handleKeyUp}
      disabled={disabledInput || disabled}
    // style={{
    //     height: 8
    // }}
    />
  );
};

IntlCurrencyInput.propTypes = {
  defaultValue: number,
  value: number,
  max: number,
  allowNullValue: bool,
  component: node.isRequired,
  currency: string.isRequired,
  config: shape().isRequired,
  autoFocus: bool.isRequired,
  autoSelect: bool.isRequired,
  autoReset: bool.isRequired,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  onKeyPress: func.isRequired,
};

IntlCurrencyInput.defaultProps = {
  component: 'input',
  currency: 'BRL',
  value: 0,
  config: defaultConfig,
  autoFocus: false,
  autoSelect: false,
  autoReset: false,
  allowNullValue: false,
  onChange: f => f,
  onBlur: f => f,
  onFocus: f => f,
  onKeyPress: f => f,
};

export default IntlCurrencyInput;

import React, { useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Box, Tooltip } from '@material-ui/core';
import {
  ButtonConsensus,
  ButtonShowTemplateAgreement,
  ButtonUploadAgreement,
  CustomerDisplayValidator,
} from 'components';
import { redirectToDetailInquiryStep } from 'utils/genericFunctions/redirectToDetailInquiryStep';
import { toast } from 'react-toastify';
import colorsStep, { colors } from 'utils/colorsSteps';
import history from 'utils/history';
import { CUSTOMER_CONFIG } from 'constants/customerConfig';
import { CustomerDontDisplayValidator } from 'components/CustomerDisplayValidator/CustomerDisplayValidator';
import { handlePermissionWrite } from 'utils/permission.utils';
import agreementService from 'services/agreement/agreementService';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1.5),
    backgroundColor: '#ededed',
    borderRadius: 20,
    border: '1px solid #707070',
  },
  containerBtns: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    //padding: theme.spacing(1),
  },
  containerTitle: {
    height: '100%',
  },
  title: {
    color: colors.navBar,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  containerEncaminhar: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(2.5),
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(2),
    },
  },
}));

const ClosingActionType = {
  HOMOLOGATION: 'HOMOLOGATION',
  FORWARDING: 'FORWARDING',
};

const ComponentDetailAgreement = props => {
  const {
    inquiry,
    agreementId,
    hearingIdAgreement,
    signedAgreement,
    files,
    setFiles,
    uploadFiles,
    setUploadFiles,
    refreshPage,
    allowToEdit = true,
  } = props;

  const classes = useStyles();

  const [loadingAction, setLoadingAction] = useState(false);

  const { wasApproved, wasForwarded } = useMemo(() => {
    return {
      wasApproved: Boolean(inquiry.agreement.wasApproved),
      wasForwarded: Boolean(inquiry.agreement.wasForwarded),
    };
  }, [inquiry]);

  const movementAgreement = action => {
    if (!inquiry.agreement?.prosecutorOffice) {
      toast.warn('Acordo sem promotoria');
      return;
    }
    if (!inquiry.agreement?.prosecutor) {
      toast.warn('Acordo sem promotor');
      return;
    }

    const params = {
      hearingDone: true,
      agreementDone: true,
      scheduleNewHearing: false,
      action,
      prosecutor: inquiry.agreement.prosecutor,
      prosecutorOffice: inquiry.agreement.prosecutorOffice,
    };

    setLoadingAction(true);

    agreementService
      .hearingCloseService(hearingIdAgreement, params)
      .then(response => {
        toast.success(`Inquérito enviado para a fase ${response.data.step}`);
        setTimeout(() => {
          history.replace(
            redirectToDetailInquiryStep({ id: response.data.id, step: response.data.step }),
          );
          history.goBack();
        }, 2000);
      })
      .finally(() => {
        setLoadingAction(false);
        refreshPage();
      });
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs>
          <Box className={classes.containerTitle}>
            <Typography variant="h4" className={classes.title}>
              Informações do Acordo
            </Typography>
          </Box>
        </Grid>
        <Grid item xs component={Box} display="flex" justifyContent="flex-end" alignItems="center">
          <CustomerDontDisplayValidator customerConfig={CUSTOMER_CONFIG.ENABLE_FORWARD_BUTTON}>
            {!signedAgreement || wasApproved ? (
              <Tooltip
                title={
                  wasApproved
                    ? 'Acordo Homologado'
                    : 'Anexe o termo do acordo, para remeter para homologação'
                }
                arrow
                placement="top"
              >
                <ButtonConsensus
                  title="Remeter para homologação"
                  margin={-25}
                  disabled
                  width={310}
                />
              </Tooltip>
            ) : (
              <ButtonConsensus
                title="Remeter para homologação"
                onClick={() =>
                  handlePermissionWrite(
                    () => movementAgreement(ClosingActionType.HOMOLOGATION),
                    allowToEdit,
                  )
                }
                width={310}
                margin={-25}
                disabled={loadingAction}
              />
            )}
          </CustomerDontDisplayValidator>
          <CustomerDisplayValidator customerConfig={CUSTOMER_CONFIG.ENABLE_FORWARD_BUTTON}>
            {!signedAgreement || wasApproved ? (
              <Tooltip
                title={
                  wasApproved
                    ? 'Acordo Homologado'
                    : 'Anexe o termo do acordo, para remeter para homologação'
                }
                arrow
                placement="top"
              >
                <ButtonConsensus
                  title="Remeter para homologação"
                  margin={-25}
                  disabled
                  width={335}
                />
              </Tooltip>
            ) : (
              <ButtonConsensus
                title="Remeter para homologação"
                onClick={() =>
                  handlePermissionWrite(
                    () => movementAgreement(ClosingActionType.HOMOLOGATION),
                    allowToEdit,
                  )
                }
                width={335}
                margin={-25}
                disabled={loadingAction}
              />
            )}
            {!signedAgreement || wasForwarded ? (
              <Tooltip
                title={
                  wasForwarded
                    ? 'Acordo Encaminhado'
                    : 'Anexe o termo do acordo, para finalizar termo de acordo'
                }
                arrow
                placement="top"
              >
                <ButtonConsensus
                  title="FINALIZAR TERMO DE ACORDO"
                  disabled
                  width={290}
                  margin={-25}
                />
              </Tooltip>
            ) : (
              <ButtonConsensus
                title="FINALIZAR TERMO DE ACORDO"
                onClick={() =>
                  handlePermissionWrite(
                    () => movementAgreement(ClosingActionType.FORWARDING),
                    allowToEdit,
                  )
                }
                backgroundColor={colorsStep.CUMPRIMENTO_ACORDO}
                hovercolor={colorsStep.CUMPRIMENTO_ACORDO}
                disabled={loadingAction}
                width={290}
                margin={-25}
              />
            )}
          </CustomerDisplayValidator>
          <ButtonShowTemplateAgreement agreementId={agreementId} />
          <ButtonUploadAgreement
            inquiryId={inquiry.id}
            agreementId={agreementId}
            hearingIdAgreement={hearingIdAgreement}
            signedAgreement={signedAgreement}
            files={files}
            setFiles={setFiles}
            uploadFiles={uploadFiles}
            setUploadFiles={setUploadFiles}
            refreshPage={refreshPage}
            allowToEdit={allowToEdit}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ComponentDetailAgreement;

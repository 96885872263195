import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CUSTOMER_CONFIG as CC } from 'constants/customerConfig';

const CustomerDisplayValidator = props => {
  const { customerConfig = '', children } = props;
  const allCustomerConfigs = useSelector(state => state.customerConfig);

  return !allCustomerConfigs[customerConfig] ? null : <React.Fragment>{children}</React.Fragment>;
};

export const CustomerDontDisplayValidator = props => {
  const { customerConfig = '', children } = props;
  const allCustomerConfigs = useSelector(state => state.customerConfig);

  return !allCustomerConfigs[customerConfig] ? <React.Fragment>{children}</React.Fragment> : null;
};

CustomerDisplayValidator.propTypes = {
  customerConfig: PropTypes.oneOf([CC.INQUIRY_MANUAL_REGISTRATION, CC.INQUIRY_IMPORT]).isRequired,
};

export default CustomerDisplayValidator;

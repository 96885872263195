import React, { useState, useEffect } from 'react';
import {
  Dialog,
  Typography,
  Grid,
  Box,
  IconButton,
  DialogContent,
  MenuItem,
  DialogActions,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import HeaderModal from 'components/HeaderModal';
import { colors } from 'utils/colorsSteps';
import ButtonConsensus from 'components/ButtonConsensus';
import InputConsensus from 'components/InputConsensus';
import history from 'utils/history';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import intimationService from 'services/intimation/intimationService';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(() => ({
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
}));

const ModalAddFile = props => {
  const { open, onClose, intimation } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  //const dispatch = useDispatch();

  const [file, setFile] = useState([]);
  const [uploadObject, setUploadObject] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [modes, setModes] = useState([]);
  const [selectedMode, setSelectedMode] = useState(null);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: files => filterFiles(files),
  });

  const handleSendFile = () => {
    setDisabled(true);
    getBase64(file);
  };

  const getIntimationsModes = () => {
    intimationService.getAllIntimationModes().then(response => {
      setModes(response.data);
    });
  };

  const sendFilesUpdate = file => {
    let params = {
      base64File: file.base64,
      intimationMode: selectedMode,
    };

    intimationService
      .confirmManualIntimation(intimation.id, params)
      .then(() => {
        setUploadObject([]);
        setFile([]);
        toast.success('Arquivo(s) anexado(s) com sucesso!');
        onClose();
        setTimeout(() => {
          history.goBack();
        }, 2000);
      })
      .finally(() => setDisabled(false));
  };

  const handleRemoveFile = (fileANex, i) => {
    file.splice(i, 1);
    const newList = uploadObject.filter(file => file.name !== fileANex.name);
    setUploadObject(newList);
  };

  //Verifica se o arquivo já existe no state File.
  const filterFiles = files => {
    if (file.length > 0) {
      toast.warn('Você já anexou um arquivo');
      return;
    } else {
      setFile(file => [...file, files[0]]);
      setDisabled(false);
    }
  };

  //Converter arquivo para Base64
  const getBase64 = files => {
    if (files.length > 0) {
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = function() {
        var result = reader.result;

        var index = uploadObject.findIndex(upload => upload.name === files[0].name);
        if (index === -1) {
          setUploadObject(uploadObject => [
            {
              ...uploadObject,
              name: files[0].name,
              base64: result,
            },
          ]);

          let fileToSend = {
            name: files[0].name,
            base64: result,
          };

          sendFilesUpdate(fileToSend);
        } else {
          toast.warn('Arquivo já anexado.');
          setDisabled(true);
        }
      };
      reader.onerror = function(_) {
        toast.warn('Erro ao converter arquivo. Tente novamente.');
      };

      //dispatch(clearUploadFilesInquiry());
      //setUploadObject([]);
      //setFile([]);
    }
  };

  useEffect(() => {
    //dispatch(clearUploadFilesInquiry());
    getIntimationsModes();
  }, []);

  return (
    <Dialog
      fullScreen={fullScreen}
      onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <HeaderModal backgroundColor={colors.navBar} colorIconTitle="#FFF" onClose={onClose}>
        Confirmação de intimação
      </HeaderModal>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box mt={1}>
              <InputConsensus
                select
                label="Meio de Intimação *"
                variant="outlined"
                fullWidth
                size="small"
                onChange={event => setSelectedMode(event.target.value)}
              >
                {modes.map(mode => (
                  <MenuItem key={mode} value={mode}>
                    {mode}
                  </MenuItem>
                ))}
              </InputConsensus>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box height={150}>
              <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                <input {...getInputProps()} />
                <Box my={1}>
                  <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
                </Box>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Typography variant="h5">Arquivo</Typography>
              {file.map((file, i) => (
                <Box key={i} display="flex" alignItems="center" justifyContent="space-between">
                  <Box display="flex">
                    <PictureAsPdfIcon />
                    <Typography>{file.path}</Typography>
                  </Box>
                  <IconButton onClick={() => handleRemoveFile(file, i)}>
                    <HighlightOffIcon style={{ color: '#e81224', cursor: 'pointer' }} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box display="flex" alignItems="center" justifyContent="center">
              <ButtonConsensus
                title="Anexar"
                backgroundColor={colors.primary}
                hovercolor={colors.primary}
                disabled={disabled || !selectedMode}
                onClick={handleSendFile}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box pl={2} display="flex" width="100%">
          <Typography variant="h6">
            Ao anexar a confirmação, o inquérito irá prosseguir para a fase de{' '}
            {intimation.type === 'HEARING'
              ? `Audiência${enableHearingForJuvenileOffenders ? '/Oitiva' : ''}`
              : 'Acordo'}{' '}
            *
          </Typography>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ModalAddFile;

import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import { TOKENS_AUTHENTICATION } from 'constants/tokens-authentication';
import { toast } from 'react-toastify';
import history from './history';

//TODO Refreshtoken
//let isRefreshing = false;
//let failedRequestsQueue = [];

const instance = axios.create({
  //baseURL: `https://consensus-api.dev.gcp.pitang.com/api/`
  baseURL: process.env.REACT_APP_BASE_URL_API + 'api/',
});

instance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem(TOKENS_AUTHENTICATION.user_and_prosecutor);
    if (!token) {
      return config;
    }
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem(TOKENS_AUTHENTICATION.user_and_prosecutor);
      toast.error(error.response?.data?.message || 'Necessário realizar login novamente');

      //TODO REFRESH TOKEN HERE
      // const originalConfig = error.config;

      // if (!isRefreshing) {
      //   isRefreshing = true;

      //   instance
      //     .post('/refresh-token')
      //     .then(response => {
      //       const refreshToken = response.data.refreshToken;
      //       instance.defaults.headers['Authorization'] = `Bearer ${refreshToken}`;
      //       localStorage.setItem(TOKENS_AUTHENTICATION.user_and_prosecutor, refreshToken);

      //       failedRequestsQueue.forEach(request => request.onSuccess(refreshToken));
      //       failedRequestsQueue = [];
      //     })
      //     .catch(error => {
      //       failedRequestsQueue.forEach(request => request.onFailure(error));
      //       failedRequestsQueue = [];
      //     })
      //     .finally(() => {
      //       isRefreshing = false;
      //     });
      // }

      // return new Promise((resolve, reject) => {
      //   failedRequestsQueue.push({
      //     onSuccess: newToken => {
      //       originalConfig.headers['Authorization'] = `Bearer ${newToken}`;

      //       resolve(instance(originalConfig));
      //     },
      //     onFailure: error => {
      //       reject(error);
      //     },
      //   });
      // });

      //Não precisará das linhas abaixos até a 80, ao implementar o refreshtoken, pois o return da linha 62 já irá retornar uma promise com success ou reject
      setTimeout(() => {
        history.push('/');
      }, 3000);
      return Promise.reject(error);
    }
    toast.error(error.response?.data?.message || 'Algo deu errado...');
    return Promise.reject(error);
  },
);

const mockInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API + 'api/',
});

export const axiosMock = new AxiosMockAdapter(mockInstance, { delayResponse: 0 });

export default process.env.NODE_ENV == 'test' ? mockInstance : instance;

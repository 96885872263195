import http from '../httpService';

const HearingService = {
  hearingChangeDateService: (hearingId, params) => http.patch(`hearings/${hearingId}`, params),

  hearingRecordingsService: hearingId => http.get(`hearings/${hearingId}/recordings`),

  getHearingByIdService: hearingId => http.get(`hearings/${hearingId}`),

  uploadChildInterviewTerm: (hearingId, params) =>
    http.post(`hearings/${hearingId}/child-interview-term`, params),

  getChildInterviewTerm: hearingId => http.get(`hearings/${hearingId}/child-interview-term`),

  completeAndSentToProcessing: (hearingId, params) =>
    http.post(`hearings/complete-and-sent-to-processing/${hearingId}`, params),

  createNewHearing: (inquiryId, params) =>
    http.post(`hearings/new-hearing/${inquiryId}`, params),
};

export default HearingService;

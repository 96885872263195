import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card, Typography, Button, FormControlLabel, Checkbox, Box } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import InputMask from 'react-input-mask';
import axios from '../../utils/axios';
import { MsgReturnSearch } from './components';
import validate from 'validate.js';
import {
  CardBatchInquiry,
  ModalNextLevel,
  ButtonConsensus,
  PedingCriminalAntecedentesSearche,
} from 'components';
import Pagination from '@material-ui/lab/Pagination';
import CircularProgress from '@material-ui/core/CircularProgress';
import { clearInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { genericInquiry, npuGenericInquiry, pcpeGenericInquiry } from 'masks/masks';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import inquiryService from 'services/inquiries/inquiryService';

const FilterBatchInquiry = () => {
  const { groups } = useSelector(state => state.logedUser);
  const { selectedIndexGroup } = useSelector(state => state.groupMenu);
  const { batchDistribution: permission } = useSelector(state => state.logedUser.permissions);
  const { allOriginNumbersAreNpu } = useCustomerConfig();

  const [showProgress, setShowProgress] = useState(false);

  const useStyles = makeStyles(theme => ({
    divCheck: {
      display: 'block',
    },
    checkBox: {
      marginRight: 5,
      marginTop: 5,
      height: 15,
      width: 20,
      cursor: 'pointer',
    },
    labelBox: {
      marginTop: 5,
      paddingRight: 10,
      fontSize: 15,
      cursor: 'pointer',
    },
    inputInquiry: {
      height: 35,
      width: '100%',
      border: `1px solid #747474`,
      padding: 10,
      backgroundColor: 'transparent',
      marginRight: 10,
      marginTop: 5,
    },
    selectInput: {
      height: 35,
      width: '100%',
      border: `1px solid #747474`,
      paddingLeft: 10,
      backgroundColor: 'transparent',
      marginRight: 10,
      marginTop: 5,
    },
    inputInquiryDataUp: {
      width: '100%',
      height: 35,
      border: `1px solid #747474`,
      padding: 10,
      backgroundColor: 'transparent',
      marginRight: 8,
      marginTop: 5,
      '@media (max-width:600px)': {
        marginTop: 5,
      },
    },
    inputInquiryDataDown: {
      width: '100%',
      height: 35,
      border: `1px solid #747474`,
      padding: 10,
      backgroundColor: 'transparent',
      marginRight: 8,
      marginTop: 5,
    },
    teste: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    calendar: {
      backgroundColor: '#3bc3bf',
      color: '#ffffff',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 8,
      marginTop: 5,
      borderRadius: '50%',
      padding: 3,
    },
    resumoBusca: {
      height: 25,
      fontSize: 12,
      paddingLeft: 5,
      margin: 5,
      '@media (max-width:600px)': {
        marginBottom: 20,
      },
    },
    divInputs: {
      padding: 5,
    },
    btnSearch: {
      marginTop: 4,
      backgroundColor: '#3bc3bf',
      border: `1px solid #ccc`,
      justifyContent: 'center',
      alignItems: 'center',
      color: '#ffffff',
      height: 37,
      width: '100%',
      borderRadius: 5,
      '&:hover': {
        backgroundColor: '#00597b',
      },
    },
    classeList: {
      overflow: 'auto',
      minHeight: '45vh',
      boxShadow: 'none',
    },
    labelsimple: {
      color: '#2c7692',
    },
    divPagination: {
      height: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 12,
      marginTop: 15,
    },
    labelAnterior: {
      color: '#a7a9aa',
      cursor: 'pointer',
    },
    spanPaginacao: {
      color: '#2c7692',
    },
    spanProximo: {
      color: '#2c7692',
    },
    autoContainer: {
      marginTop: -220,
      '@media (max-width:600px)': {
        marginTop: -210,
        Height: 150,
        maxHeight: 175,
        minHeight: 175,
        maxWidth: '85%',
        minWidth: '87%',
        position: 'relative',
      },
      position: 'fixed',
      zIndex: 5,
      Height: 175,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 1,
      },
      backgroundColor: '#00597b',
      borderRadius: 10,
      padding: 5,
      border: '1px solid #999',
    },
    containerCrime: {
      position: 'relative',
      maxHeight: 175,
      minHeight: 175,
      maxWidth: 155,
      minWidth: 155,
    },
    optionCrime: {
      padding: 5,
      borderRadius: 2,
      borderBottom: '1px solid #999',
      '&:hover': {
        backgroundColor: '#3bc3bf',
      },
    },
    itemOptionCrime: {
      cursor: 'pointer',
      color: '#ffffff',
    },
    pagination: {
      '& > *': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
      },
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 5,
    },
  }));

  const classes = useStyles();

  const [stateSearch, setStateSearch] = useState(false);

  const [selectInquiries, setSelectInquiries] = useState([]);
  const [inquiries, setInquiries] = useState([]);
  const [page, setPage] = useState(1);
  const size = 20;
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [isResult, setIsResult] = useState(false);
  const [sizeDataApi, setSizeDataApi] = useState(0);

  const [criminalAntecedentsHasProblems, setCriminalAntecedentsHasProblems] = useState([]);
  const [openAntecedentsHasProblemas, setOpenAntecedentsHasProblemas] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const initialValues = {
    policeInquiryNumber: '',
    internalSystemNumber: '',
    arquimedesAuto: '',
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const [mask, setMask] = useState(allOriginNumbersAreNpu ? npuGenericInquiry : genericInquiry);

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    toast.warn(message);
  };

  const handleSelectAllFiles = event => {
    if (event.target.checked) {
      setSelectAll(true);
      setSelectInquiries(inquiries.map(inquiry => inquiry.id));
    } else {
      setSelectAll(false);
      setSelectInquiries([]);
    }
  };

  const handleOpenAntecedentsHasProblemas = () => setOpenAntecedentsHasProblemas(true);

  const handleCloseAntecedentsHasProblemas = () => setOpenAntecedentsHasProblemas(false);

  const [openModalToDistribution, setOpenModalToDistribution] = useState(false);

  const [loadingRefreshCriminalAntecedents, setLoadingRefreshCriminalAntecedents] = useState(false);

  const handleCloseModalToDistribution = () => setOpenModalToDistribution(false);

  const handleOpenModalToDistribution = () => {
    setOpenModalToDistribution(true);
    handleCloseAntecedentsHasProblemas();
  };

  const getPendingCriminalAntecedentsSearchesRequest = async () => {
    if (!selectInquiries.length) {
      toast.warn('Selecione pelo menos um inquérito');
      return;
    }

    setLoadingRefreshCriminalAntecedents(true);

    try {
      const { data } = await inquiryService.getPendingCriminalAntecedentsSearches(selectInquiries);

      setCriminalAntecedentsHasProblems(data.pendingCriminalAntecedentSearches);

      return data.pendingCriminalAntecedentSearches;
    } catch (error) {
      return null;
    } finally {
      setLoadingRefreshCriminalAntecedents(false);
    }
  };

  const handleDistribution = async () => {
    if (!permission?.write) {
      handleOpenSnackbar();
      return;
    }

    const arrayData = await getPendingCriminalAntecedentsSearchesRequest();

    if (!arrayData) {
      return;
    }

    if (!arrayData.length) {
      handleOpenModalToDistribution();
      return;
    }

    handleOpenAntecedentsHasProblemas();
  };

  const refreshPage = () => {
    getPendingInquiries(page, size);
    setSelectInquiries([]);
  };

  const mapListViewCards = event => {
    event.preventDefault();
    setStateSearch(false);
    getPendingInquiries(page, size);
  };

  const getPendingInquiries = async (page, size) => {
    const { policeInquiryNumber, internalSystemNumber, arquimedesAuto } = formState.values;

    let params = {
      page,
      size,
      prosecutorOfficeGroupIds: groups[selectedIndexGroup]?.id,
    };

    if (internalSystemNumber) params.internalSystemNumber = internalSystemNumber;

    if (policeInquiryNumber) params.policeInquiryNumber = clearInquiryNumber(policeInquiryNumber);

    if (arquimedesAuto) params.arquimedesAuto = arquimedesAuto;

    setShowProgress(true);

    await axios
      .get('inquiries/inquiries-to-distribute', { params })
      .then(response => {
        setInquiries(response.data.data);
        setTotalPages(response.data.lastPage);
        setTotal(response.data.total);
        setStateSearch(true);
        setIsResult(true);
        setPage(page);
        setSizeDataApi(response.data?.data?.length);
      })
      .finally(() => {
        setShowProgress(false);
      });
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handlePageChange = (event, value) => {
    if (page !== value) {
      setStateSearch(false);
      setPage(value);
      getPendingInquiries(value, size);
    }
  };

  useEffect(() => {
    const errors = validate(formState.values);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      getPendingInquiries(page, size);
    }

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndexGroup]);

  const handlePoliceInquiryNumberChange = event => {
    event.persist();
    const inputValue = event.target.value;

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    if (!allOriginNumbersAreNpu) {
      const newMask = inputValue.match(/\d/g)?.length === 16 ? pcpeGenericInquiry : genericInquiry;
      setMask(newMask);
    }
  };

  const verifyMask = event => {
    const inputValue = event.target.value;
    if (inputValue.match(/\d/g)?.length === 16 && !allOriginNumbersAreNpu) {
      setMask(genericInquiry);
    }
  };

  const verifyOnPaste = event => {
    setMask(event.target.value?.length === 16 ? pcpeGenericInquiry : genericInquiry);
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <form onSubmit={mapListViewCards}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
              <InputMask
                className={classes.inputInquiry}
                type="text"
                placeholder="Número do Inquérito"
                name="policeInquiryNumber"
                onChange={handlePoliceInquiryNumberChange}
                value={formState.values.policeInquiryNumber}
                mask={mask}
                maskChar={null}
                onBeforeInput={verifyMask}
                onPaste={event => {
                  if (!allOriginNumbersAreNpu) {
                    verifyOnPaste(event);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <InputMask
                className={classes.inputInquiry}
                type="text"
                placeholder="Número interno"
                name="internalSystemNumber"
                mask={'999999999999999'}
                maskChar={null}
                onChange={handleChange}
                value={formState.values.internalSystemNumber}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <InputMask
                className={classes.inputInquiry}
                type="text"
                placeholder="Número arquimedes"
                name="arquimedesAuto"
                mask={'9999/999999999999999'}
                maskChar={null}
                onChange={handleChange}
                value={formState.values.arquimedesAuto}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2} lg={2} xl={2}>
              <Button className={classes.btnSearch} type="submit" hovercolor="#00b1ac">
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
        </form>

        {showProgress ? (
          <Box width="100%" display="flex" justifyContent="center" my={4}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            <MsgReturnSearch sizeDataApi={sizeDataApi} page={page} size={size} totalPages={total} />
            <ButtonConsensus title="Distribuir Inquéritos" onClick={handleDistribution} />
            {stateSearch ? (
              <Card className={classes.classeList}>
                {inquiries.length > 0 && (
                  <Box mx={1} display="flex" justifyContent="flex-end">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="secondary"
                          value={selectAll}
                          onChange={handleSelectAllFiles}
                          checked={selectAll}
                        />
                      }
                      label={selectAll ? 'Desmarcar todos' : 'Marcar todos'}
                    />
                  </Box>
                )}
                {isResult && (
                  <CardBatchInquiry
                    inquiries={inquiries}
                    selectInquiries={selectInquiries}
                    setSelectInquiries={setSelectInquiries}
                  />
                )}
              </Card>
            ) : (
              <>
                <Typography className={classes.resumoBusca} />
                <Typography className={classes.classeList} />
              </>
            )}

            <div className={classes.pagination}>
              <Pagination
                size="small"
                count={totalPages}
                page={page}
                color="primary"
                onChange={handlePageChange}
              />
            </div>
          </>
        )}
      </Box>
      {openModalToDistribution && (
        <ModalNextLevel
          batchDistribution
          open={openModalToDistribution}
          onClose={handleCloseModalToDistribution}
          inquiriesIds={selectInquiries}
          refreshBatchDistributionInquiries={refreshPage}
        />
      )}

      {openAntecedentsHasProblemas && (
        <PedingCriminalAntecedentesSearche
          data={criminalAntecedentsHasProblems}
          open={openAntecedentsHasProblemas}
          onClose={handleCloseAntecedentsHasProblemas}
          handleClickProceed={handleOpenModalToDistribution}
          handleRefreshList={getPendingCriminalAntecedentsSearchesRequest}
          loading={loadingRefreshCriminalAntecedents}
        />
      )}
    </>
  );
};

export default FilterBatchInquiry;

import * as actionTypes from 'actions';

const initialState = {
  page: 1,
  name: '',
  judicialDistrict: '',
};

const registerGroupOfficeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGINATION_REGISTER_GROUP_OFFICE: {
      return { ...action.payload };
    }

    case actionTypes.CLEAR_PAGINATION_REGISTER_GROUP_OFFICE: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default registerGroupOfficeReducer;

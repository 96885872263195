import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

const Btn = props => {
  const {
    children,
    className,
    color,
    tittle,
    backgroundColor,
    borderColor,
    width,
    mediaWidth,
    hovercolor,
    height,
    onClick,
    disabled,
    ...rest
  } = props;

  const useStyles = makeStyles(theme => ({
    root: {
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      color: color,
      backgroundColor: backgroundColor,
      border: `1px solid ${borderColor}`,
      outline: 0,
      height: height,
      borderRadius: 40,
      cursor: 'pointer',
      width: width,
      '&:hover': {
        backgroundColor: hovercolor,
      },
      '@media (max-width:600px)': {
        width: mediaWidth,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Button
      {...rest}
      className={clsx(classes.root, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {tittle}
      {children}
    </Button>
  );
};

export default Btn;

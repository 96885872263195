import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { InvestigatedInfo } from '../';

const useStyles = makeStyles(theme => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  headerPadding: {
    margin: theme.spacing(1, 0),
  },
}));

const InvestigatedDetail = props => {
  const { inquiry } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => setExpanded(!expanded);

  return (
    <>
      <div
        className={clsx(classes.header, {
          [classes.headerPadding]: inquiry.investigated.length > 1 ? false : true,
        })}
      >
        {inquiry.investigated.length < 1 ? (
          <Typography variant="body1">Investigado não informado</Typography>
        ) : (
          <Typography variant="body1">
            Investigado{inquiry.investigated.length > 1 ? 's' : ''}
          </Typography>
        )}
        {inquiry.investigated.length > 1 && (
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        )}
      </div>
      <InvestigatedInfo inquiry={inquiry} expanded={expanded} />
    </>
  );
};

export default InvestigatedDetail;

import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  containerTextArea: {
    margin: theme.spacing(2, 0),
  },
  action: {
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      alignItems: 'flex-start',
      padding: theme.spacing(0, 2.5),
    },
  },
}));

import React from 'react';
import clsx from 'clsx';
import { Dialog, Typography, Grid, DialogContent, Box, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { HeaderModal } from 'components';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textTitle: {},
}));

const ModalAddFile = props => {
  const {
    open,
    onClose,
    className,
    fileInBase64,
    setFileInBase64,
    uploadObject,
    setUploadObject,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: true,
    onDrop: files => filterFiles(files),
  });

  const filterFiles = files => {
    files.forEach(v => {
      var index = uploadObject.findIndex(upload => upload.name === v.name);
      if (index === -1) {
        setUploadObject(file => [...file, v]);
        getBase64(v);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    });
  };

  const getBase64 = file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      var result = reader.result;

      var index = fileInBase64.findIndex(upload => upload.name === file.name);

      if (index === -1) {
        setFileInBase64(setFileInBase64 => [
          ...setFileInBase64,
          {
            name: file.name,
            base64: result,
          },
        ]);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    };
  };

  const handleRemoveFile = (fileANex, i) => {
    uploadObject.splice(i, 1);
    const newList = uploadObject.filter(file => file.name !== fileANex.name);
    setUploadObject(newList);
    const newListReducer = uploadObject.filter(file => file.name !== fileANex.name);
    setFileInBase64(newListReducer);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <HeaderModal onClose={onClose}>Anexar arquivo</HeaderModal>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ minHeight: 150, padding: 20 }}
            >
              <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                <input {...getInputProps()} />
                <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: 20, marginBottom: 15 }}
            >
              <Typography variant="body1">Arquivos {uploadObject.length}</Typography>

              {uploadObject.map((file, i) => (
                <Box display="flex" alignItems="center" justifyContent="space-between" key={i}>
                  <Box display="flex">
                    <PictureAsPdfIcon style={{ marginRight: 5 }} />
                    <Typography variant="body1">{file?.name || ''}</Typography>
                  </Box>
                  <IconButton onClick={() => handleRemoveFile(file, i)}>
                    <HighlightOffIcon style={{ color: '#e81224' }} />
                  </IconButton>
                </Box>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalAddFile;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CardAgreement } from 'components';
import history from 'utils/history';
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

const ContainerCardAgreement = props => {
  const { inquirys, className, rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={2}>
        {inquirys.map(inquiry => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={inquiry.id}>
            <CardAgreement
              inquiry={inquiry}
              onClick={() => history.push(`/company/agreements/${inquiry.id}`)}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

ContainerCardAgreement.propTypes = {
  className: PropTypes.string,
  inquirys: PropTypes.array.isRequired,
};

ContainerCardAgreement.defaultProps = {
  inquirys: [],
};

export default ContainerCardAgreement;

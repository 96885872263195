import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Tooltip } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TimelineIcon from '@material-ui/icons/Timeline';
import { HeaderModal, ButtonConsensus } from 'components';
import { Timeline as CustomTimeline } from './components';
import colorsSteps from 'utils/colorsSteps';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import { changeNameStep } from 'utils/changeNameStep';
import { useUserTypes } from 'hooks/useUserTypes';
import pmService from 'services/PM/pmService';
import associationService from 'services/association/associationService';
import inquiryService from 'services/inquiries/inquiryService';
import { USER_TYPE } from 'constants/inquirys/userType';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(0, 0, 1, 0),
  },
  timelineItem: {
    '&:before': {
      content: 'none',
    },
  },
}));

const ButtonTimeline = props => {
  const { transitions, inquiry } = props;

  const classes = useStyles();

  const customerConfig = useSelector(state => state.customerConfig);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const { isPM, userType } = useUserTypes();

  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);

  const fixedTransition = {
    createdAt: inquiry.createdAt,
    toStep: 'Criação do inquérito',
  };

  const handleOpen = () => {
    if (isPM) {
      pmService.getHistoryInquiryPmService(inquiry.id).then(response => {
        setEvents(response.data);
      });
    } else {
      if (userType === USER_TYPE.AssociationRepresentative) {
        associationService.getHistoryInquiryUserAssociationService(inquiry.id).then(response => {
          setEvents(response.data);
        });
      } else {
        inquiryService.getHistoryInquiryService(inquiry.id).then(response => {
          setEvents(response.data);
        });
      }
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
        <ButtonConsensus
          title="Histórico"
          endIcon={<TimelineIcon style={{ color: '#ffffff' }} />}
          backgroundColor={`${colorsSteps[inquiry.step]}`}
          hovercolor={`${colorsSteps[inquiry.step]}`}
          onClick={handleOpen}
        />
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={handleClose}
        scroll="body"
        aria-labelledby="modal-timeline"
      >
        <HeaderModal onClose={handleClose}>Histórico do inquérito</HeaderModal>
        <DialogContent>
          <DialogContentText>
            <Timeline>
              {transitions.length > 0 ? (
                transitions.map((transition, index) => (
                  <TimelineItem className={classes.timelineItem} key={index}>
                    <TimelineSeparator>
                      <Tooltip
                        title={`${changeNameStep(transition.toStep, customerConfig)}`}
                        arrow
                        placement="top"
                      >
                        <TimelineDot
                          style={{ backgroundColor: `${colorsSteps[transition.toStep]}` }}
                        >
                          <LaptopMacIcon fontSize="small" style={{ color: 'transparent' }} />
                        </TimelineDot>
                      </Tooltip>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <CustomTimeline
                        events={events[index] && events[index].events}
                        transition={transition}
                      />
                    </TimelineContent>
                  </TimelineItem>
                ))
              ) : (
                <TimelineItem className={classes.timelineItem}>
                  <TimelineSeparator>
                    <Tooltip title={`Criação do inquérito`} arrow placement="top">
                      <TimelineDot style={{ backgroundColor: `${colorsSteps.NAO_IDENTIFICADO}` }}>
                        <LaptopMacIcon fontSize="small" style={{ color: 'transparent' }} />
                      </TimelineDot>
                    </Tooltip>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <CustomTimeline transition={fixedTransition} />
                  </TimelineContent>
                </TimelineItem>
              )}
            </Timeline>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ButtonTimeline;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  FormGroup,
  FormControlLabel,
  Switch,
  Grid,
  Typography,
  Tooltip,
  MenuItem,
  DialogContent,
  IconButton,
  Divider,
  Paper,
  Box,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { InputConsensus, ButtonConsensus, ProsecutorOfficeAndProsecutorForm } from 'components';
import { Autocomplete } from '@material-ui/lab';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux';
import { changeNameStep } from 'utils/changeNameStep';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { ModalAddFile } from './components';
import colorsSteps, { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import history from 'utils/history';
import Moment from 'react-moment';
import inquiryService from 'services/inquiries/inquiryService';
import userService from 'services/user/userService';
import HeaderModal from 'components/HeaderModal';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import { USER_TYPE } from 'constants/inquirys/userType';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerConfirm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#787878',
    fontSize: 16,
    fontWeight: 'bold',
    paddingLeft: 5,
    paddingRight: 5,
    marginBottom: 10,
  },
  body: {
    padding: theme.spacing(2),
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnConfirm: {
    backgroundColor: '#00b1ac',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 25,
    height: 50,
    width: 150,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  divSwitch: {
    flex: 5,
  },
  btnAnexoFile: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  containerSwitch: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  spanBlocking: {
    margin: theme.spacing(0, 0.5),
  },
}));

const ModalNextLevel = props => {
  const {
    open,
    onClose,
    className,
    inquiry,
    otherStep,
    persecucao,
    batchDistribution,
    inquiriesIds = [],
    processing = false,
    noChange = false,
    refreshBatchDistributionInquiries,
    sendToRequisitorio = false,
    sendToIntimation = false,
    sendToForwardingTerm = false,
    ...rest
  } = props;

  const customerConfig = useSelector(state => state.customerConfig);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  const [confirm, setConfirm] = useState(false);
  const [msg, setMsg] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [questDistribution, setQuestDistribution] = useState(true);
  const [key, setKey] = useState(false);
  const [prosecutors, setProsecutors] = useState([]);
  const [showAutocomplet, setShowAutocomplet] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [showAlertStep, setShowAlertStep] = useState(false);
  const [showAlertToStepRemetidoAoJudiciario, setShowAlertToStepRemetidoAoJudiciario] = useState(
    false,
  );

  const { selectedIndexGroup } = useSelector(state => state.groupMenu);
  const { groups } = useSelector(state => state.logedUser);

  const loggedUser = useSelector(state => state.logedUser);
  const isProsecutor = loggedUser.type === USER_TYPE.Prosecutor;

  const initialValues = {
    typeDistribution: '',
    prosecutor: null,
    despacho: false,
    prosecutorOffice: null,
    responsibleProsecutor: isProsecutor
      ? { ...loggedUser, id: loggedUser.userId }
      : inquiry?.inquiry?.prosecutor,
    responsibleProsecutorOffice: inquiry?.inquiry?.prosecutorOffice,
    //justification: '',
  };

  const [formState, setFormState] = useState({
    isValid: false,
    investigated: [],
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const [uploadObject, setUploadObject] = useState([]);
  const [fileInBase64, setFileInBase64] = useState([]);
  const [openModalAddFile, setOpenModalAddFile] = useState(false);

  useEffect(() => {
    const { typeDistribution, prosecutor, prosecutorOffice } = formState.values;
    if (typeDistribution !== '') {
      setShowBtn(true);
    }
    if (typeDistribution === 'random') {
      setDisabled(false);
      setQuestDistribution(true);
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          prosecutor: null,
          prosecutorOffice: null,
        },
      }));
    }

    if (typeDistribution === 'directed') {
      getProsecutors();
      setQuestDistribution(false);
      if (prosecutor && prosecutorOffice) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setQuestDistribution(true);
    }
  }, [
    formState.values.typeDistribution,
    formState.values.prosecutor,
    formState.values.prosecutorOffice,
  ]);

  const getProsecutors = () => {
    const params = {
      prosecutorOfficeGroupIds: groups[selectedIndexGroup]?.id,
    };
    userService.getUsersProsecutorsService(params).then(response => {
      setProsecutors(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      setShowAutocomplet(true);
    });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
    }));
  };

  const handleChangeProsecutor = (_, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        prosecutor: newValue ? newValue : null,
        prosecutorOffice: null,
      },
      touched: {
        ...formState.touched,
        prosecutor: true,
      },
    }));
    setKey(!key);
  };

  const handleChangeResponsibleProsecutorOffice = (_, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        responsibleProsecutorOffice: newValue,
      },
    }));
  };

  const handleChangeResponsibleProsecutor = (_, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        responsibleProsecutor: newValue,
      },
    }));
  };

  useEffect(() => {
    if (!inquiry && batchDistribution) {
      setShowAlertStep(true);
      setMsg('A distribuição deve ser específica ou aleatória?');
      return;
    }

    if (inquiry?.step === STEPS_INQUIRY.TRAMITACAO_CONTROLE) {
      setShowAlertToStepRemetidoAoJudiciario(sendToRequisitorio);

      if (sendToIntimation) {
        setMsg('Prosseguir para a fase intimação?');
      } else if (sendToForwardingTerm) {
        setMsg('Prosseguir para a fase termo de encaminhamento para aguardar homologação?');
      } else {
        setMsg(
          `Prosseguir para a fase 
                    ${changeNameStep(
                      sendToRequisitorio
                        ? STEPS_INQUIRY.REMETIDO_AO_JUDICIARIO
                        : inquiry?.goingToStep,
                      customerConfig,
                    )}?`,
        );
      }

      setShowBtn(true);

      return;
    }

    if (noChange && !processing && inquiry?.step === STEPS_INQUIRY.ANALISE) {
      setMsg('Tarefas insuficientes para realizar a tramitação');
    } else if (!noChange && processing) {
      setMsg('Prosseguir para a fase de Tramitação e Controle?');
    } else if (!noChange && !processing && inquiry?.step === STEPS_INQUIRY.PESQUISA) {
      setShowAlertStep(true);
      if (!inquiry?.inquiry?.prosecutor) {
        setMsg('A distribuição deve ser específica ou aleatória?');
        return;
      }
      if (inquiry?.inquiry?.prosecutor) {
        setMsg('Tem certeza que deseja prosseguir para a próxima fase?');
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            typeDistribution: 'random',
          },
        }));
      }
    } else if (
      inquiry?.step !== STEPS_INQUIRY.PESQUISA &&
      inquiry?.step !== STEPS_INQUIRY.TRAMITACAO_CONTROLE
    ) {
      setMsg('Tem certeza que deseja prosseguir para a próxima fase?');
      setShowBtn(true);
    }
  }, [noChange, processing]);

  const handleCancel = () => {
    onClose();
    setConfirm(false);
  };

  const handleBack = () => {
    onClose();
    history.goBack();
    setTimeout(() => {
      setConfirm(false);
    }, 1000);
  };

  //Change step inquiry
  const fetchNextLevel = (idInquiry, step, justification = '', status = '') => {
    let params = {
      step,
      justification,
    };

    const {
      prosecutor,
      prosecutorOffice,
      responsibleProsecutor,
      responsibleProsecutorOffice,
    } = formState.values;

    if (prosecutor) {
      params.distributeToProsecutorId = prosecutor.id;
      params.distributeToProsecutorDesignation = prosecutorOffice.name;
    }

    if (status) {
      params.status = status;
    }

    if (formState.values.despacho) {
      if (!responsibleProsecutorOffice) {
        toast.warn('Verifique o campo promotoria');
        return;
      }
      if (!responsibleProsecutor) {
        toast.warn('Verifique o campo promotor');
        return;
      }
      params.movementTask = {
        prosecutorId: responsibleProsecutor.id,
        prosecutorOfficeId: responsibleProsecutorOffice.id,
        inquiryInvestigatedId: idInquiry,
        type: 'ENCAMINHAR_DILIGENCIAS',
        subType: {
          name: 'Despacho',
          code: '920253',
        },
        shouldMoveInquiry: true,
        requestDocuments: fileInBase64,
        justification: formState.values.justification,
      };
    }

    inquiryService
      .updateChangeStepService(idInquiry, params)
      .then(response => {
        if (response.data?.step) {
          setShowAlertStep(false);
          setShowAlertToStepRemetidoAoJudiciario(false);
          setMsg(
            `Inquérito enviado para a fase de ${changeNameStep(
              response.data.step,
              customerConfig,
            )}`,
          );
        }

        setConfirm(true);
        toast.success('Fase alterada com sucesso!');
      })
      .finally(() => setDisabled(false));
  };

  const handleConfirmNextLevel = () => {
    setDisabled(true);

    if (batchDistribution) {
      let params = {
        inquiriesIds,
      };

      const { prosecutor, prosecutorOffice } = formState.values;

      if (prosecutor) {
        params.distributeToProsecutorId = prosecutor.id;
        params.prosecutorDesignation = prosecutorOffice.name;
      }

      handleBatchDistributionRequest(params);
    } else {
      if (formState.values.despacho) {
        if (!uploadObject.length) {
          toast.warn('Insira o arquivo de despacho');
          setDisabled(false);
          return;
        }
      }
      handleNextLevel();
    }
  };

  //Batch Distribution
  const handleBatchDistributionRequest = params => {
    inquiryService
      .batchDistributionService(params)
      .then(() => {
        toast.success('Distribuição realizada com sucesso');
        refreshBatchDistributionInquiries();
        onClose();
      })
      .finally(() => setDisabled(false));
  };

  const handleNextLevel = () => {
    if (inquiry?.step === STEPS_INQUIRY.TRIAGEM) {
      fetchNextLevel(inquiry?.id, STEPS_INQUIRY.PESQUISA);
    } else if (inquiry?.step === STEPS_INQUIRY.PESQUISA) {
      fetchNextLevel(inquiry?.id, STEPS_INQUIRY.ANALISE);
    } else if (inquiry?.step === STEPS_INQUIRY.ANALISE) {
      switch (processing) {
        case true:
          fetchNextLevel(inquiry?.id, STEPS_INQUIRY.TRAMITACAO_CONTROLE);
          break;
        case false:
          fetchNextLevel(inquiry?.id, STEPS_INQUIRY.INTIMACAO);
          break;
        default:
          break;
      }
    } else if (inquiry?.step === STEPS_INQUIRY.INTIMACAO) {
      return;
    } else if (otherStep === true && !persecucao) {
      fetchNextLevel(inquiry?.id, STEPS_INQUIRY.ANALISE);
    } else if (otherStep === true && persecucao) {
      fetchNextLevel(inquiry?.id, STEPS_INQUIRY.TRIAGEM);
    } else if (inquiry?.step === STEPS_INQUIRY.TRAMITACAO_CONTROLE) {
      if (sendToIntimation) {
        fetchNextLevel(inquiry?.id, STEPS_INQUIRY.INTIMACAO);
      } else if (sendToForwardingTerm) {
        fetchNextLevel(inquiry?.id, STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO, '', 'processing');
      } else {
        if (inquiry?.goingToStep === STEPS_INQUIRY.ARQUIVAR) {
          if (inquiry?.inquiry?.classe === '280' && !inquiry.inquiry?.deadline?.fulfilledAt) {
            return toast.error('Apenas flagrantes vinculados a inquéritos policiais podem ser arquivados');
          }
        }
        fetchNextLevel(
          inquiry?.id,
          showAlertToStepRemetidoAoJudiciario
            ? STEPS_INQUIRY.REMETIDO_AO_JUDICIARIO
            : inquiry?.goingToStep || STEPS_INQUIRY.ANALISE,
        );
      }
    }
  };

  const handleChangeDespacho = event => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.checked,
      },
    });
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        open={open}
        aria-labelledby="responsive-dialog-nexLevel"
        className={classes.root}
        maxWidth="sm"
      >
        <HeaderModal onClose={onClose}>Prosseguir para próxima fase</HeaderModal>
        <DialogContent className={classes.body}>
          <div className={classes.containerConfirm}>
            {showAlertStep && (
              <Paper
                elevation={2}
                style={{
                  width: '100%',
                  padding: 8,
                  background: colorsSteps.PESQUISA_DISTRIBUICAO,
                }}
              >
                <Typography variant="h5" style={{ color: '#fff' }}>
                  Ao prosseguir a supercertidão será enviada/salva.
                  <br />
                  Caso precise revisar algum item, feche esta janela.
                </Typography>
                <Divider style={{ height: 1 }} />
              </Paper>
            )}
            {showAlertToStepRemetidoAoJudiciario && (
              <Typography variant="h5">
                A partir de agora este inquérito não será mais movimentado pelo Consensus, pois será
                remetido ao Judiciário.
              </Typography>
            )}
            <div style={{ marginTop: 16 }}>
              <Typography variant="h5">{msg}</Typography>
            </div>
          </div>

          <Grid container spacing={1} className={classes.containerBtnArea}>
            {!noChange ? (
              !confirm ? (
                !processing ? (
                  <Grid container spacing={2}>
                    {batchDistribution ? (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <InputConsensus
                          label="Distribuição"
                          name="typeDistribution"
                          onChange={handleChange}
                          value={formState.values.typeDistribution || ''}
                          select
                          fullWidth
                        >
                          <MenuItem value="directed">Específica</MenuItem>
                          <MenuItem value="random">Aleatória</MenuItem>
                        </InputConsensus>
                      </Grid>
                    ) : (
                      inquiry?.step === STEPS_INQUIRY.PESQUISA &&
                      !inquiry?.inquiry?.prosecutor && (
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <InputConsensus
                            label="Distribuição"
                            name="typeDistribution"
                            onChange={handleChange}
                            value={formState.values.typeDistribution || ''}
                            select
                            fullWidth
                          >
                            <MenuItem value="directed">Específica</MenuItem>
                            <MenuItem value="random">Aleatória</MenuItem>
                          </InputConsensus>
                        </Grid>
                      )
                    )}
                    {inquiry?.step === STEPS_INQUIRY.ANALISE && (
                      <>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className={classes.containerSwitch}>
                            <div className={classes.divSwitch}>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={formState.values.despacho}
                                      onChange={handleChangeDespacho}
                                      name="despacho"
                                    />
                                  }
                                  label="Anexar Despacho"
                                />
                              </FormGroup>
                            </div>
                            {formState.values.despacho && (
                              <>
                                <div className={classes.btnAnexoFile}>
                                  <Tooltip title="Anexar despacho" placement="left">
                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="span"
                                      onClick={() => setOpenModalAddFile(true)}
                                    >
                                      <AttachFileIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </>
                            )}
                          </div>
                        </Grid>
                        {formState.values.despacho && (
                          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Box marginBottom={3}>
                              <ProsecutorOfficeAndProsecutorForm
                                prosecutor={formState.values.responsibleProsecutor}
                                prosecutorOffice={formState.values.responsibleProsecutorOffice}
                                onProsecutorChange={handleChangeResponsibleProsecutor}
                                onProsecutorOfficeChange={handleChangeResponsibleProsecutorOffice}
                                isProsecutor={isProsecutor}
                                prosecutorOfficeGroup={inquiry?.inquiry?.prosecutorOfficeGroup}
                              />
                            </Box>
                          </Grid>
                        )}
                      </>
                    )}
                    {!questDistribution && (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        {showAutocomplet ? (
                          <Autocomplete
                            options={prosecutors}
                            //getOptionDisabled={option => option.designations}
                            fullWidth
                            autoHighlight
                            onChange={handleChangeProsecutor}
                            key={key} //clear label on change value
                            noOptionsText="Não encontrado"
                            value={prosecutors.find(
                              value => value.id === formState.values.prosecutor?.id,
                            )}
                            getOptionLabel={option => option.name || ''}
                            filterOptions={x => x}
                            renderOption={option => <div>{option.name}</div>}
                            renderInput={params => (
                              <InputConsensus
                                {...params}
                                name="name"
                                label="Promotor"
                                variant="standard"
                                inputProps={{
                                  ...params.inputProps,
                                }}
                              />
                            )}
                          />
                        ) : (
                          <Skeleton variant="rect" animation="pulse" />
                        )}
                      </Grid>
                    )}
                    {!questDistribution && formState.values.prosecutor && (
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <InputConsensus
                          select
                          label="Promotoria"
                          name="prosecutorOffice"
                          value={formState.values.prosecutorOffice}
                          onChange={handleChange}
                          variant="standard"
                          size="small"
                          fullWidth
                        >
                          {formState.values.prosecutor?.designations?.map(office => {
                            const prosecutorOfficeGroupId =
                              batchDistribution && !inquiry?.inquiry?.prosecutorOfficeGroup?.id
                                ? groups[selectedIndexGroup]?.id
                                : inquiry?.inquiry?.prosecutorOfficeGroup?.id;
                            const blocking = office?.blockings?.find(
                              blocking =>
                                blocking.prosecutorOfficeGroup.id === prosecutorOfficeGroupId,
                            );
                            return (
                              <MenuItem key={office.name} value={office} disabled={!!blocking}>
                                {blocking ? (
                                  blocking?.startDate && blocking?.endDate ? (
                                    <Box>
                                      <span>Inativo no período:</span>
                                      <Moment format="DD/MM/YYYY" className={classes.spanBlocking}>
                                        {blocking.startDate}
                                      </Moment>
                                      <span>até</span>
                                      <Moment format="DD/MM/YYYY" className={classes.spanBlocking}>
                                        {blocking.endDate}
                                      </Moment>
                                      <Box>{office.name}</Box>
                                    </Box>
                                  ) : (
                                    <Box>
                                      <Box>Promotor Inativo</Box>
                                      <Box>{office.name}</Box>
                                    </Box>
                                  )
                                ) : (
                                  office.name
                                )}
                              </MenuItem>
                            );
                          })}
                        </InputConsensus>
                      </Grid>
                    )}
                    {showBtn && (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className={classes.containerBtn}
                      >
                        <ButtonConsensus
                          title="Cancelar"
                          backgroundColor="#fff"
                          hovercolor={colors.greySecundary}
                          color={colors.greyShadow}
                          onClick={handleCancel}
                        />
                        <ButtonConsensus
                          title="Confirmar"
                          backgroundColor={colors.primary}
                          hovercolor={colors.primary}
                          color={colors.greShadow}
                          onClick={handleConfirmNextLevel}
                          disabled={disabled}
                        />
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.containerBtn}>
                    <ButtonConsensus
                      title="Cancelar"
                      backgroundColor="#fff"
                      hovercolor={colors.greySecundary}
                      color={colors.greyShadow}
                      onClick={handleCancel}
                    />
                    <ButtonConsensus
                      title={disabled ? 'Solicitando...' : 'Confirmar'}
                      backgroundColor={colors.primary}
                      hovercolor={colors.primary}
                      color={colors.greShadow}
                      onClick={handleConfirmNextLevel}
                      disabled={disabled}
                    />
                  </Grid>
                )
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ButtonConsensus
                    title="VOLTAR"
                    backgroundColor="#fff"
                    hovercolor={colors.greySecundary}
                    color={colors.greyShadow}
                    onClick={handleBack}
                  />
                </Grid>
              )
            ) : (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ButtonConsensus
                  title="Cancelar"
                  backgroundColor="#fff"
                  hovercolor={colors.greySecundary}
                  color={colors.greyShadow}
                  onClick={handleCancel}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
      <ModalAddFile
        open={openModalAddFile}
        onClose={() => setOpenModalAddFile(false)}
        fileInBase64={fileInBase64}
        setFileInBase64={setFileInBase64}
        uploadObject={uploadObject}
        setUploadObject={setUploadObject}
      />
    </div>
  );
};

export default ModalNextLevel;

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';

export const SESSION_LOGOUT_INVESTIGATED = 'SESSION_LOGOUT_INVESTIGATED';
export const SESSION_LOGOUT_PM = 'SESSION_LOGOUT_PM';

export const SET_USER_ID = 'SET_USER_ID';
export const SET_NAME_USER = 'SET_NAME_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_ISSUED_AT = 'SET_ISSUED_AT';
export const SET_EXPIRES_AT = 'SET_EXPIRES_AT';
export const SET_FULL_USER = 'SET_FULL_USER';
export const SET_HAS_MANUAL_INVESTIGATED_SYNC = 'SET_HAS_MANUAL_INVESTIGATED_SYNC';

export const SET_PERMISSIONS_USER = 'SET_PERMISSIONS_USER';
export const CLEAR_PERMISSIONS_USER = 'CLEAR_PERMISSIONS_USER';

export const SET_CURRENT_ENVIROMENT_TYPE = 'SET_CURRENT_ENVIROMENT_TYPE';

export const setFullLogedUser = logedUser => ({
  type: SET_FULL_USER,
  payload: logedUser,
});

export const login = user => ({
  type: SESSION_LOGIN,
  payload: user,
});

export const logout = () => ({
  type: SESSION_LOGOUT,
  payload: false,
});

export const logoutInvestigated = () => ({
  type: SESSION_LOGOUT_INVESTIGATED,
});

export const logoutPm = () => ({
  type: SESSION_LOGOUT_PM,
});

export const setToken = token => ({
  type: SET_TOKEN,
  payload: token,
});

export const setIssuedAt = value => ({
  type: SET_ISSUED_AT,
  payload: value,
});

export const setExpiresAt = value => ({
  type: SET_EXPIRES_AT,
  payload: value,
});

export const setPermissions = permissions => ({
  type: SET_PERMISSIONS_USER,
  payload: permissions,
});

export const setClearPermissions = () => ({
  type: CLEAR_PERMISSIONS_USER,
});

export const setHasManualInvestigatedSync = value => ({
  type: SET_HAS_MANUAL_INVESTIGATED_SYNC,
  payload: value,
});

export const setCurrentEnviromentType = env => ({
  type: SET_CURRENT_ENVIROMENT_TYPE,
  payload: env,
});

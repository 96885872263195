import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ButtonConsensus } from 'components';
import { Form } from './components';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(1),
    },
  },
}));

const CancelAgreement = props => {
  const { hearingId, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <div className={classes.container}>
      <ButtonConsensus
        title="Acordo não realizado"
        backgroundColor={colors.primary}
        hovercolor={colors.primary}
        onClick={handleOpenModal}
        {...rest}
      />
      <Form open={open} onClose={handleCloseModal} hearingId={hearingId} />
    </div>
  );
};

export default CancelAgreement;

/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Typography,
  Grid,
  Card,
  IconButton,
  MenuItem,
  Box,
  Chip,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ButtonConsensus,
  ComboBoxMultiSelect,
  CustomerDisplayValidator,
  InputConsensus,
} from 'components';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import crimeService from 'services/crime/crimeService';
import inquiryService from 'services/inquiries/inquiryService';
import debounce from 'lodash.debounce';
import { useUserTypes } from 'hooks/useUserTypes';
import { CUSTOMER_CONFIG as CC } from 'constants/customerConfig';
import lawService from 'services/law/lawService';
import PmService from 'services/PM/pmService';
import EditIcon from '@material-ui/icons/Edit';
import { isInfraction } from 'utils/isInfraction';
import { colors } from 'utils/colorsSteps';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const CrimesDetailInquiry = props => {
  const {
    crimes = [],
    isProbableNonPersecution,
    inquiryId,
    refreshPage,
    inquiryClassCode,
    write = false,
    otherStep = false,
    canEdit = false,
  } = props;

  const useStyles = makeStyles(() => ({
    root: {
      //padding: 10,
      marginTop: 15,
    },
    titulo: {
      color: '#00597b',
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: 20,
      fontFamily: 'roboto-regular',
    },
    containerLabelInputCrime: {
      padding: 5,
    },
    containerLabelInput: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
    },
    selectForm: {
      height: 25,
      outline: 0,
      width: '100%',
      border: 0,
      borderBottom: `1px solid #000`,
      backgroundColor: '#ededed',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    form: {
      heigth: '100%',
      width: '100%',
      padding: '0 10px 0 10px',
    },
    labelForm: {
      display: 'flex',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontSize: 11,
    },
    labelFormCrime: {
      display: 'flex',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontSize: 11,
      color: '#00597b',
    },
    inputForm: {
      display: 'block',
      height: 25,
      outline: 0,
      padding: 5,
      width: '100%',
      border: 0,
      borderBottom: `1px solid #000`,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    },
    inputFormCrime: {
      display: 'block',
      height: 20,
      outline: 0,
      width: '100%',
      border: 0,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 'bold',
      color: '#00597b',
      textAlign: 'left',
    },
    inputFormCrimeEdit: {
      display: 'block',
      height: 20,
      outline: 0,
      width: '100%',
      border: 0,
      backgroundColor: 'transparent',
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
      fontWeight: 'bold',
      color: '#00597b',
      borderBottom: '1px solid #000',
    },
    inputMaterial: {
      width: '100%',
    },
    table: {
      width: '100%',
    },
    containerForm: {
      //padding: 15
    },
    containerCrimeGrid: {
      paddingRight: 20,
      paddingLeft: 10,
      marginTop: 5,
    },
    textCrime: {
      justifyContent: 'center',
      fontWeight: 'bold',
      fontSize: 13,
      fontFamily: 'roboto-regular',
      width: '100%',
    },
    autoContainer: {
      marginTop: -240,
      '@media (max-width:600px)': {
        marginTop: -340,
        Height: 130,
        maxHeight: 300,
        minHeight: 300,
        maxWidth: '100%',
        minWidth: '100%',
        position: 'relative',
      },
      maxHeight: 200,
      minHeight: 200,
      //maxWidth: 300,
      minWidth: 300,
      position: 'relative',
      zIndex: 1,
      //Height: 130,
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 1,
      },
      backgroundColor: '#ededed',
      borderRadius: 10,
      padding: 5,
      border: '1px solid #292929',
    },
    containerCrime: {
      position: 'relative',
      '@media (max-width:600px)': {
        maxHeight: '100%',
        minHeight: '100%',
        maxWidth: '100%',
        minWidth: '100%',
      },
      maxHeight: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      minWidth: '100%',
    },
    optionCrime: {
      padding: 5,
      borderRadius: 2,
      borderBottom: '1px solid #999',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#b9b9b9',
      },
    },
    itemOptionCrime: {
      cursor: 'pointer',
    },
    addCrimeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: 5,
      //width: '98.5%',
      minHeight: 80,
      maxHeight: 160,
      overflow: 'auto',
      //backgroundColor: '#f7f7f7',
      border: '1px solid #999',
      borderRadius: 5,
      marginTop: 5,
    },
    textoCrimeContainer: {
      color: '#00b1ac',
      fontWeight: 'bold',
      borderBottom: '1px solid #999',
      paddingBottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: 40,
      textAlign: 'justify',
    },
    chipNonPersecution: {
      margin: 2,
      backgroundColor: '#00597b',
      color: '#ffffff',
    },
    chip: {
      margin: 2,
      backgroundColor: '#00b1ac',
      color: '#ffffff',
    },
    cardCrimePersecution: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      overflowX: 'auto',
      float: 'left',
      '@media (max-width:600px)': {
        float: 'none',
      },
    },
    cardCrime: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'start',
      boxShadow: 'none',
      backgroundColor: 'transparent',
      minHeight: 40,
      overflowX: 'auto',
      float: 'left',
      '@media (max-width:600px)': {
        float: 'none',
      },
      marginBottom: 10,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const [crimesCometidos, setCrimesCometidos] = useState([]);
  const [editCrime, setEditCrime] = useState(false);
  const [selectedCrime, setSelectedCrime] = useState(null);
  const [subtypesSelected, setSubtypesSelected] = useState([]);
  const [subtypeOptions, setSubtypeOptions] = useState([]);

  const [inputSearchCrime, setInputSearchCrime] = useState('');
  const [loadingCrimes, setLoadingCrimes] = useState(false);
  const [pageCrimes, setPageCrimes] = useState(1);
  const [lastPageCrimes, setLastPageCrimes] = useState(1);
  const [keyCrime, setKeyCrime] = useState(false);

  const [selectedLaw, setSelectedLaw] = useState(null);
  const [optionsLaw, setOptionsLaw] = useState([]);
  const [inputSearchLaw, setInputSearchLaw] = useState('');
  const [loadingLaws, setLoadingLaws] = useState(false);
  const [pageLaws, setPageLaws] = useState(1);
  const [lastPageLaws, setLastPageLaws] = useState(1);
  const [editingSubtypes, setEditingSubtypes] = useState(false);

  const initialValues = {
    crimes: [],
    isProbableNonPersecution,
  };

  const [formState, setFormState] = useState({
    isValid: false,
    investigated: [],
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const handleEditeCrime = () => {
    setSelectedLaw(null);
    setEditCrime(true);
    debouncedGetLaws('');
    debouncedGetCrimes('');
    const updatedCrimes = crimes.map(crime => {
      const invSubtype = crime?.inquiryInvestigatedCrimeSubtype?.find(
        invCrimeSub => invCrimeSub.inquiryInvestigated.id === inquiryId,
      );
      return {
        ...crime,
        subtypesSelected: invSubtype?.subtypes || [],
      };
    });
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimes: updatedCrimes,
      },
    }));
  };

  const editCrimesInquiry = crimes => {
    let params = {
      crimes: crimes,
      isProbableNonPersecution: formState.values.isProbableNonPersecution,
    };

    if (isPM) {
      PmService.updateInquiryInvestigatedService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryInvestigatedService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        refreshPage();
      });
    }
    setSelectedCrime(null);
  };

  const handleUpdateCrime = () => {
    editCrimesInquiry(formState.values.crimes);
    setEditCrime(false);
  };

  const handleEditeCrimeToFalse = () => {
    setEditCrime(false);
    setSelectedCrime(null);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleRemoveCrime = (event, crimeId) => {
    event.persist();
    let newListCrimes = formState.values.crimes.filter(crime => crime.id !== crimeId);
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimes: newListCrimes,
      },
    }));
  };

  const getCrimes = (search = '') => {
    setLoadingCrimes(true);

    setPageCrimes(1);

    let params = {
      page: 1,
      size: 20,
      search,
    };

    if (selectedLaw) params.lawId = selectedLaw?.id;

    crimeService
      .getCrimesService(params)
      .then(response => {
        setCrimesCometidos(response.data.data);
        setLastPageCrimes(response.data.lastPage);
      })
      .finally(() => setLoadingCrimes(false));
  };

  const getLaws = (search = '') => {
    setLoadingLaws(true);

    setPageLaws(1);

    let params = {
      page: 1,
      size: 20,
      search,
    };

    lawService
      .getLawService(params)
      .then(response => {
        setOptionsLaw(response.data.data);
        setLastPageLaws(response.data.lastPage);
      })
      .finally(() => setLoadingLaws(false));
  };

  const loadMoreCrimesResults = () => {
    const nextPage = pageCrimes + 1;

    setPageCrimes(nextPage);

    setLoadingCrimes(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchCrime,
    };

    if (selectedLaw) params.lawId = selectedLaw?.id;

    crimeService
      .getCrimesService(params)
      .then(response => {
        setCrimesCometidos([...crimesCometidos, ...response.data.data]);
        setLastPageCrimes(response.data.lastPage);
      })
      .finally(() => setLoadingCrimes(false));
  };

  const loadMoreLawsResults = () => {
    const nextPage = pageLaws + 1;

    setPageLaws(nextPage);

    setLoadingLaws(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchLaw,
    };

    lawService
      .getLawService(params)
      .then(response => {
        setOptionsLaw([...optionsLaw, ...response.data.data]);
        setLastPageLaws(response.data.lastPage);
      })
      .finally(() => setLoadingLaws(false));
  };

  const debouncedGetLaws = useCallback(
    debounce(newValue => getLaws(newValue), 500),
    [],
  );

  const debouncedGetCrimes = useCallback(
    debounce(newValue => getCrimes(newValue), 500),
    [],
  );

  const handleScrollLaw = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && pageLaws < lastPageLaws && !loadingLaws) {
      loadMoreLawsResults();
    }
  };

  const handleScrollCrimes = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (listboxNode.scrollHeight - position <= 1 && pageCrimes < lastPageCrimes && !loadingCrimes) {
      loadMoreCrimesResults();
    }
  };

  const updateValueCrimes = newValue => {
    if (Boolean(!crimesCometidos.find(crime => crime.taxonomy === newValue))) {
      debouncedGetCrimes(newValue);
      setInputSearchCrime(newValue);
    }
  };

  const updateValueLaws = newValue => {
    if (Boolean(!optionsLaw.find(law => law.name === newValue))) {
      debouncedGetLaws(newValue);
      setInputSearchLaw(newValue);
    }
  };

  const handleChangeLaw = (event, newValue) => {
    setSelectedLaw(newValue);
  };

  const handleChangeCrimeAutocomplete = (event, newValue) => {
    setSelectedCrime(newValue);
  };

  const handleAddCrime = () => {
    const newValue = selectedCrime;
    const crimeExists = formState.values.crimes.some(crime => crime.id === newValue.id);

    if (crimeExists) {
      return toast.warn('O crime selecionado já existe na lista');
    }

    if (subtypesSelected?.length) {
      const subtypes = subtypesSelected.map(sub => subtypeOptions.find(item => item.id === sub));
      newValue.subtypesSelected = subtypes;
    }

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimes: [...formState.values.crimes, newValue],
      },
    }));
    setKeyCrime(!keyCrime);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  const changeSubtypesSelected = () => {
    const { crimes } = formState.values;

    const index = crimes.findIndex(crime => crime.id === selectedCrime.id);
    const subtypes = subtypesSelected?.map(sub => {
      return subtypeOptions.find(subtype => subtype.id === sub.id || subtype.id === sub);
    });

    if (index !== -1) {
      const updatedCrimes = [...crimes];

      updatedCrimes[index] = {
        ...updatedCrimes[index],
        subtypesSelected: subtypes || [],
      };

      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          crimes: updatedCrimes,
        },
      }));
    }

    setSelectedCrime({ ...selectedCrime, subtypesSelected: subtypes });
  };

  useEffect(() => {
    setSubtypeOptions(selectedCrime?.subtypes);
  }, [selectedCrime]);

  useEffect(() => {
    if (!isPM || canEdit) {
      getCrimes(inputSearchCrime);
    }
  }, [selectedLaw]);

  useEffect(() => {
    if (crimes.length) {
      const newCrimes = crimes.map(crime => {
        const subtypesSelected = crime.inquiryInvestigatedCrimeSubtype.reduce((acc, subtype) => {
          return acc.concat(subtype.subtypes);
        }, []);

        return { ...crime, subtypesSelected };
      });
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          crimes: newCrimes,
        },
      }));
    }
  }, [crimes]);

  const handleChangeCrime = () => {
    if (!selectedCrime) {
      return toast.warn('Selecione um crime para ser adicionado');
    }
    if (!editingSubtypes) {
      handleAddCrime();
    } else {
      changeSubtypesSelected();
    }
    setSelectedCrime(null);
    setSubtypesSelected([]);
    setEditingSubtypes(false);
  };

  const handleEditSubtype = crime => {
    setSubtypesSelected(crime.subtypesSelected);
    setSelectedCrime(crime);
    setEditingSubtypes(true);
  };

  const handleChangeSubtype = newValue => {
    setSubtypesSelected(newValue);
  };

  return (
    <CustomerDisplayValidator customerConfig={CC.INQUIRY_DISPLAY_CRIMES}>
      <Box className={classes.root}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: 10,
            //paddingBottom: 10,
            paddingLeft: 10,
          }}
        >
          <Box style={{ width: '50%' }}>
            <Typography className={classes.titulo}>
              {isInfraction(inquiryClassCode) ? 'Atos Infracionais' : 'Crimes'}
            </Typography>
          </Box>
          <Box
            style={{
              width: '50%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {!editCrime && ((!otherStep && !isPM) || canEdit) ? (
              <Tooltip title="Alterar crimes" arrow placement="top">
                <IconButton
                  className={classes.buttonEditar}
                  size="small"
                  onClick={() => (!write ? handleOpenSnackbar() : handleEditeCrime())}
                >
                  <CreateIcon style={{ color: '#00b1ac' }} />
                </IconButton>
              </Tooltip>
            ) : (
              editCrime && (
                <>
                  <Box style={{ marginRight: 10 }}>
                    <Tooltip title="Salvar alterações" arrow placement="top">
                      <IconButton
                        className={classes.buttonEditar}
                        size="small"
                        onClick={handleUpdateCrime}
                        form="my-form-edit"
                        type="submit"
                      >
                        <SaveIcon style={{ color: '#1976d2' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title="Cancelar" arrow placement="top">
                      <IconButton
                        className={classes.buttonEditar}
                        size="small"
                        onClick={handleEditeCrimeToFalse}
                      >
                        <ClearIcon style={{ color: '#e1296a' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )
            )}
          </Box>
        </Box>
        {!editCrime && (
          <Grid container spacing={1} className={classes.containerCrimeGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card className={classes.cardCrimePersecution}>
                <Chip
                  className={classes.chipNonPersecution}
                  size="small"
                  label={isProbableNonPersecution ? 'Não Persecução' : 'Persecução'}
                />
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Card className={classes.cardCrime}>
                {crimes.length > 0 ? (
                  crimes.map((crime, index) => (
                    <Chip
                      key={index}
                      className={classes.chip}
                      size="small"
                      label={crime.taxonomy}
                    />
                  ))
                ) : (
                  <Chip className={classes.chip} size="small" label="Não informado" />
                )}
              </Card>
            </Grid>
          </Grid>
        )}
        {editCrime && (
          <Grid container spacing={1} style={{ marginTop: 5 }}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className={classes.containerLabelInput}>
                <InputConsensus
                  label="Tipo do Crime"
                  select
                  className={classes.inputMaterial}
                  autoComplete="off"
                  name="isProbableNonPersecution"
                  onChange={handleChange}
                  value={formState.values.isProbableNonPersecution}
                >
                  <MenuItem value={false}>Persecução</MenuItem>
                  <MenuItem value={true}>Não persecução</MenuItem>
                </InputConsensus>
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className={classes.containerLabelInput}>
                <Autocomplete
                  onInputChange={(event, newInputValue) => updateValueLaws(newInputValue)}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={option => option.name}
                  filterOptions={x => x}
                  value={selectedLaw}
                  fullWidth
                  noOptionsText="Nada foi encontrado"
                  loadingText="Buscando..."
                  loading={loadingLaws}
                  clearOnBlur={false}
                  options={optionsLaw}
                  onChange={handleChangeLaw}
                  renderOption={option => (
                    <>
                      {option.name} - {option.number}
                    </>
                  )}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      label="Lei"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingLaws ? <CircularProgress color="primary" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  ListboxProps={{
                    onScroll: handleScrollLaw,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className={classes.containerLabelInput}>
                <Autocomplete
                  //value={null}
                  key={keyCrime}
                  onInputChange={(event, newInputValue) => updateValueCrimes(newInputValue)}
                  getOptionSelected={(option, value) => option.taxonomy === value.taxonomy}
                  getOptionLabel={option => option.taxonomy}
                  filterOptions={x => x}
                  fullWidth
                  noOptionsText="Nada foi encontrado"
                  loadingText="Buscando..."
                  loading={loadingCrimes}
                  clearOnBlur={false}
                  options={crimesCometidos}
                  onChange={handleChangeCrimeAutocomplete}
                  disabled={editingSubtypes}
                  value={selectedCrime}
                  renderOption={option => (
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography variant="body1">
                            {option.taxonomy && `Nome ${option.taxonomy || 'não informado'}`}
                          </Typography>
                          <Typography variant="body1">
                            {option.lawName && `Lei ${option.lawName || 'não informada'}`}
                          </Typography>
                          <Typography variant="body1">
                            {option.lawNumber && `Lei n° ${option.lawNumber || 'não informado'}`}
                          </Typography>
                          <Typography variant="body1">
                            {option.article && `Artigo ${option.article || 'não informado'}`}
                          </Typography>
                          <Typography variant="body1">
                            {option.paragraph &&
                              `Parágrafo n° ${option.paragraph || 'não informado'}`}
                          </Typography>
                          <Typography variant="body1">
                            {option.item && `Item ${option.item || 'não informado'}`}
                          </Typography>
                          <Typography variant="body1">
                            {option.point && `Ponto ${option.point || 'não informado'}`}
                          </Typography>
                          <Typography variant="body1">
                            {option?.subject?.hierarchy &&
                              `Hierarquia ${option.lawNumber || 'não informada'}`}
                          </Typography>
                        </React.Fragment>
                      }
                    >
                      <Typography>
                        {option.taxonomy} - Lei:{' '}
                        {option.lawNumber ? option.lawNumber : 'não informada'} - Artigo:{' '}
                        {option.article ? option.article : 'não informado'}{' '}
                        {option.paragraph ? '-' : ''} {option.paragraph ? option.paragraph : ''}{' '}
                        {option.item ? '-' : ''} {option.item ? option.item : ''}{' '}
                        {option.point ? '-' : ''} {option.point ? option.point : ''}
                      </Typography>
                    </HtmlTooltip>
                  )}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      label={`${
                        isInfraction(inquiryClassCode) ? 'Ato Infracional' : 'Crime'
                      } cometido *`}
                      fullWidth
                      onClick={() => {
                        setSubtypeOptions(null);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingCrimes ? <CircularProgress color="primary" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  ListboxProps={{
                    onScroll: handleScrollCrimes,
                  }}
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              {subtypeOptions?.length > 0 && (
                <div className={classes.containerLabelInput}>
                  <ComboBoxMultiSelect
                    options={subtypeOptions}
                    label="Selecione os subtipos"
                    selectedIds={subtypesSelected?.map(subtype => subtype.id)}
                    onChange={handleChangeSubtype}
                  />
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingRight: 15,
              }}
            >
              <ButtonConsensus
                title={editingSubtypes ? 'Alterar' : 'Adicionar'}
                backgroundColor={colors.primary}
                hovercolor={colors.primary}
                size="small"
                onClick={handleChangeCrime}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 10 }}
            >
              <div className={classes.addCrimeContainer}>
                {isInfraction(inquiryClassCode) ? 'Ato(s) Infracional(is)' : 'Crime(s)'}:
                {formState.values.crimes.map(crime => (
                  <div key={crime.id} className={classes.textoCrimeContainer}>
                    <Typography>
                      {crime.taxonomy} - Lei: {crime.lawNumber ? crime.lawNumber : 'não informada'}{' '}
                      - Artigo: {crime.article ? crime.article : 'não informado'}{' '}
                      {crime.paragraph ? '-' : ''} {crime.paragraph ? crime.paragraph : ''}{' '}
                      {crime.item ? '-' : ''} {crime.item ? crime.item : ''}{' '}
                      {crime.point ? '-' : ''} {crime.point ? crime.point : ''}{' '}
                      {crime.subtypesSelected?.length
                        ? `- Subtipos: ${crime.subtypesSelected.map(obj => obj.name).join(', ')}`
                        : ''}
                    </Typography>
                    <div className={classes.iconContainer}>
                      {crime.subtypes?.length > 0 && (
                        <EditIcon
                          fontSize="small"
                          onClick={() => handleEditSubtype(crime)}
                          style={{ cursor: 'pointer' }}
                        />
                      )}
                      <DeleteIcon
                        fontSize="small"
                        onClick={event => handleRemoveCrime(event, crime.id)}
                        style={{ cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </Grid>
        )}
      </Box>
    </CustomerDisplayValidator>
  );
};

export default CrimesDetailInquiry;

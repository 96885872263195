import axios from 'axios';
import { TOKENS_AUTHENTICATION } from 'constants/tokens-authentication';
import { toast } from 'react-toastify';
import history from 'utils/history';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API + 'api/',
});

instance.interceptors.request.use(
  async config => {
    const token = localStorage.getItem(TOKENS_AUTHENTICATION.investigated);
    if (!token) {
      return config;
    }
    config.headers['Authorization'] = `Bearer ${token}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response?.status === 401) {
      localStorage.removeItem(TOKENS_AUTHENTICATION.investigated);
      toast.error(error.response?.data?.message || 'Necessário realizar login novamente');
      setTimeout(() => {
        history.push('/');
      }, 3000);
      return Promise.reject(error);
    }
    toast.error(error.response?.data?.message || 'Algo deu errado...');
    return Promise.reject(error);
  },
);

export default instance;

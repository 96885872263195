export const TYPES_MOVEMENT_TASK = {
  DENUNCIATION: 'DENUNCIA',
  ARCHIVE: 'ARQUIVAR',
  ARCHIVE_CNMP_RESOLUTION: 'ARQUIVAR_RESOLUCAO_CNMP',
  FORWARD_DUE_DILIGENCE: 'ENCAMINHAR_DILIGENCIAS',
  DECLINE: 'DECLINAR',
  VERIFY_AGREEMENT_COMPLIANCE: 'VERIFICAR_CUMPRIMENTO_ACORDO',
  HEARING_CLOSING: 'FECHAMENTO_AUDIENCIA',
  DISCHARGIN: 'BAIXA_DE_CARGA',
};

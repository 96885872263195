import React from 'react';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';
import { HeaderModal, ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';

const ModalConfirm = props => {
  const { open, onClose, group, onClick, sendRequest } = props;

  const handleConfirm = () => {
    onClick(group);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <HeaderModal onClose={onClose}>{group.name}</HeaderModal>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h5">
              Deseja alterar este inquérito para o grupo ofício {group.name}?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <ButtonConsensus
            title="Cancelar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
          />
          <ButtonConsensus
            title="Confirmar"
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={handleConfirm}
            disabled={sendRequest}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalConfirm;

import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import HeaderModal from 'components/HeaderModal';
import { Box, Grid, MenuItem, Paper, Typography } from '@material-ui/core';
import colorsSteps, { colors } from 'utils/colorsSteps';
import InputConsensus from 'components/InputConsensus';
import { Autocomplete } from '@material-ui/lab';
import userService from 'services/user/userService';
import { useSelector } from 'react-redux';
import ButtonConsensus from 'components/ButtonConsensus';
import Moment from 'react-moment';

const DISTRIBUTION_TYPE = {
  DIRECTED: 'directed',
  RANDOM: 'random',
};

const initialValues = {
  typeDistribution: '',
  prosecutor: null,
  prosecutorOffice: null,
};

const ModalSelectDistributionType = props => {
  const {
    open,
    handleClose,
    title,
    inquiry,
    disbledConfirm,
    handleConfirm,
    onlyDistribution,
  } = props;

  const { selectedIndexGroup } = useSelector(state => state.groupMenu);
  const { groups } = useSelector(state => state.logedUser);

  const [formState, setFormState] = useState({ ...initialValues });
  const [prosecutors, setProsecutors] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [showAutocomplet, setShowAutocomplet] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [key, setKey] = useState(false);

  const handleClickConfirm = () => {
    if (onlyDistribution) {
      const params = {
        distributeToProsecutorId: formState.prosecutor?.id,
        prosecutorDesignation: formState.prosecutorOffice?.name,
      };

      handleConfirm(formState.typeDistribution === DISTRIBUTION_TYPE.RANDOM ? {} : params);

      return;
    }
  };

  const getProsecutors = () => {
    const params = {
      prosecutorOfficeGroupIds:
        inquiry?.inquiry?.prosecutorOfficeGroup?.id ?? groups[selectedIndexGroup]?.id,
    };

    userService.getUsersProsecutorsService(params).then(response => {
      setProsecutors(response.data.data.sort((a, b) => a.name.localeCompare(b.name)));
      setShowAutocomplet(true);
    });
  };

  const handleChange = event => {
    event.persist();
    setFormState(formState => ({
      ...formState,
      [event.target.name]:
        event.target.type === 'checkbox' ? event.target.checked : event.target.value,
    }));
  };

  const handleChangeProsecutor = (_, newValue) => {
    setFormState(formState => ({
      ...formState,
      prosecutor: newValue,
      prosecutorOffice: null,
    }));
    setKey(!key);
  };

  useEffect(() => {
    const { typeDistribution, prosecutor, prosecutorOffice } = formState;
    if (typeDistribution !== '') {
      setShowBtn(true);
    }

    if (typeDistribution === DISTRIBUTION_TYPE.RANDOM) {
      setDisabled(false);
      setFormState(formState => ({
        ...formState,
        prosecutor: null,
        prosecutorOffice: null,
      }));
    }

    if (typeDistribution === DISTRIBUTION_TYPE.DIRECTED) {
      getProsecutors();
      if (prosecutor && prosecutorOffice) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.typeDistribution, formState.prosecutor, formState.prosecutorOffice]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <HeaderModal onClose={handleClose}>{title}</HeaderModal>
      <DialogContent>
        <Paper
          elevation={2}
          style={{
            width: '100%',
            padding: 8,
            background: colorsSteps.PESQUISA_DISTRIBUICAO,
          }}
        >
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5" style={{ color: '#fff' }}>
              Ao prosseguir a supercertidão será enviada/salva.
            </Typography>
            <Typography variant="h5" style={{ color: '#fff' }}>
              Caso precise revisar algum item, feche esta janela.
            </Typography>
          </Box>
        </Paper>

        <Box mt={2} display="flex" justifyContent="center">
          <Typography variant="h5">A distribuição deve ser específica ou aleatória?</Typography>
        </Box>

        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <InputConsensus
                label="Distribuição"
                name="typeDistribution"
                onChange={handleChange}
                value={formState.typeDistribution || ''}
                select
                fullWidth
              >
                <MenuItem value={DISTRIBUTION_TYPE.DIRECTED}>Específica</MenuItem>
                <MenuItem value={DISTRIBUTION_TYPE.RANDOM}>Aleatória</MenuItem>
              </InputConsensus>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {showAutocomplet && formState.typeDistribution !== DISTRIBUTION_TYPE.RANDOM && (
                <Autocomplete
                  options={prosecutors}
                  fullWidth
                  autoHighlight
                  onChange={handleChangeProsecutor}
                  key={key}
                  noOptionsText="Não encontrado"
                  value={formState.prosecutor}
                  getOptionLabel={option => option.name || ''}
                  renderOption={option => <div>{option.name}</div>}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      name="name"
                      label="Promotor"
                      variant="standard"
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              )}
            </Grid>
            {formState.prosecutor && (
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <InputConsensus
                  select
                  label="Promotoria"
                  name="prosecutorOffice"
                  value={formState.prosecutorOffice || ''}
                  onChange={handleChange}
                  variant="standard"
                  size="small"
                  fullWidth
                >
                  {formState.prosecutor.designations.map(office => {
                    const prosecutorOfficeGroupId = inquiry.inquiry.prosecutorOfficeGroup.id;
                    const blocking = office?.blockings?.find(
                      blocking => blocking.prosecutorOfficeGroup.id === prosecutorOfficeGroupId,
                    );
                    return (
                      <MenuItem key={office.name} value={office} disabled={!!blocking}>
                        {blocking ? (
                          blocking?.startDate && blocking?.endDate ? (
                            <Box>
                              <span>Inativo no período: </span>
                              <Moment format="DD/MM/YYYY">{blocking.startDate}</Moment>
                              <span> até </span>
                              <Moment format="DD/MM/YYYY">{blocking.endDate}</Moment>
                              <Box>{office.name}</Box>
                            </Box>
                          ) : (
                            <Box>
                              <Box>Promotor Inativo</Box>
                              <Box>{office.name}</Box>
                            </Box>
                          )
                        ) : (
                          office.name
                        )}
                      </MenuItem>
                    );
                  })}
                </InputConsensus>
              </Grid>
            )}
            {showBtn && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box display="flex" justifyContent="flex-end">
                  <ButtonConsensus
                    title="Cancelar"
                    backgroundColor="#fff"
                    hovercolor={colors.greySecundary}
                    color={colors.greyShadow}
                    onClick={handleClose}
                  />
                  <ButtonConsensus
                    title="Confirmar"
                    backgroundColor={colors.primary}
                    hovercolor={colors.primary}
                    color={colors.greShadow}
                    onClick={handleClickConfirm}
                    disabled={disabled || disbledConfirm}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalSelectDistributionType;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Chip } from '@material-ui/core';
import { colors } from 'utils/colorsSteps';
import { isInfraction } from 'utils/isInfraction';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {
    color: colors.navBar,
  },
  crimes: {
    flexWrap: 'wrap',
    width: '100%',
  },
  chip: {
    padding: theme.spacing(1),
  },
  typeCrip: {
    backgroundColor: colors.navBar,
    color: '#fff',
  },
  crime: {
    backgroundColor: colors.greenLight,
    color: '#fff',
    margin: theme.spacing(0.5, 0.5, 0.5, 0),
  },
}));

const ListCrimesCompany = props => {
  const { inquiry } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3" className={classes.title}>
            {isInfraction(inquiry.inquiry.classe) ? 'Atos Infracionais' : 'Crimes'}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Chip
            label={inquiry.isProbableNonPersecution ? 'Persecução' : 'Não persecução'}
            className={classes.typeCrip}
            size="small"
          />
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <div className={classes.crimes}>
            {inquiry.crimes.map(crime => (
              <Chip key={crime.id} label={crime.taxonomy} className={classes.crime} size="small" />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ListCrimesCompany;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, Box, Tooltip, IconButton } from '@material-ui/core';
import { toast } from 'react-toastify';
import { BsFileEarmarkText } from 'react-icons/bs';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(1.5),
    backgroundColor: '#ededed',
    borderRadius: 20,
    border: '1px solid #707070',
  },
  containerTitle: {
    height: '100%',
  },
  title: {
    color: colors.navBar,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
}));

const DetailAgreementFiles = props => {
  const { signedAgreement } = props;

  const classes = useStyles();

  const getFileSignedAgreement = async () => {
    if (!signedAgreement) {
      return toast.warn('Ata/Termo de acordo não anexado');
    }
    return window.open(signedAgreement.url, '_blank');
  };

  return (
    <Box className={classes.root}>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs>
          <Box className={classes.containerTitle}>
            <Typography variant="h4" className={classes.title}>
              Informações do Acordo
            </Typography>
          </Box>
        </Grid>
        <Grid item xs component={Box} display="flex" justifyContent="flex-end" alignItems="center">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Tooltip title={'Ata/Termo de acordo'} arrow placement="top">
              <IconButton onClick={() => getFileSignedAgreement()}>
                <BsFileEarmarkText />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DetailAgreementFiles;

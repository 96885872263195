import React from 'react';
import clsx from 'clsx';
import { Dialog, Typography, Grid, DialogContent, Box, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { useDropzone } from 'react-dropzone';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import HeaderModal from 'components/HeaderModal';
import { colors } from 'utils/colorsSteps';
import ButtonConsensus from 'components/ButtonConsensus/ButtonConsensus';
import { Edit } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerModal: {
    minWidth: 500,
    maxWidth: 500,
    minHeight: 400,
    maxHeight: 450,
    width: '100%',
    overflow: 'auto',
    '@media (max-width:500px)': {
      minWidth: 300,
      minHeight: 350,
    },
    '@media (max-width:960px)': {
      minWidth: 300,
      minHeight: 350,
    },
  },
  header: {
    width: '100%',
    backgroundColor: '#00597b',
    height: 70,
    color: '#ffffff',
    paddingLeft: 30,
    fontSize: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    marginTop: 10,
    borderRadius: 25,
    padding: 20,
    height: 250,
    width: 450,
    '@media (max-width:700px)': {
      width: 355,
    },
  },
  dropzone: {
    height: '100%',
    width: '100%',
    border: '1px dashed #292929',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 10,
    cursor: 'pointer',
  },
  msgBody: {
    fontSize: 20,
    color: '#00597b',
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
  },
  dadosCriminais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 18,
  },
  containerDadosPessoais: {
    backgroundColor: '#ededed',
    marginTop: 5,
    padding: 10,
    borderRadius: 25,
  },
  labelDadoaPessoais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
  },
  labelCrime: {
    fontWeight: 'bold',
    color: '#000',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8cf5f',
    width: '60%',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  textArea: {
    width: '100%',
    padding: 5,
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
  },
  containerConfirm: {
    height: 380,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#787878',
    fontSize: 22,
    fontWeight: 'bold',
    padding: 100,
    '@media (max-width:690px)': {
      padding: 30,
    },
  },
  btnConfirm: {
    backgroundColor: '#00b1ac',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 40,
    height: 60,
    width: 170,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
}));

const ModalAddFileChangeLevelForm = props => {
  const {
    open,
    onClose,
    className,
    fileInBase64,
    setFileInBase64,
    uploadObject,
    setUploadObject,
    setManualDocToEditId,
    setOpenModalDocsEditFile,
    ...rest
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { getRootProps, getInputProps } = useDropzone({
    //accept: 'application/pdf',
    multiple: true,
    onDrop: files => filterFiles(files),
  });

  //Verifica se o arquivo já existe no state uploadObject.
  const filterFiles = files => {
    files.forEach(v => {
      var index = uploadObject.findIndex(upload => upload.name === v.name);
      if (index === -1) {
        setUploadObject(file => [...file, v]);
        getBase64(v);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    });
  };

  const getBase64 = file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function() {
      var result = reader.result;

      var index = fileInBase64.findIndex(upload => upload.name === file.name);

      if (index === -1) {
        setFileInBase64(fileInBase64 => [
          ...fileInBase64,
          {
            name: file.name,
            base64: result,
          },
        ]);
      } else {
        toast.warn('Arquivo já anexado.');
      }
    };
  };

  const handleRemoveFile = (fileANex, i) => {
    uploadObject.splice(i, 1);
    const newList = uploadObject.filter(file => file.name !== fileANex.name);
    setUploadObject(newList);
    const newListReducer = fileInBase64.filter(file => file.name !== fileANex.name);
    setFileInBase64(newListReducer);
  };

  const handleEditManualFile = (file, i) => {
    setManualDocToEditId(file.googleDocumentId);
    onClose();
    setOpenModalDocsEditFile(true);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <HeaderModal
          onClose={onClose}
          backgroundColor={colors.navBar}
          colorIconTitle={colors.greySecundary}
        >
          Adicionar arquivos
        </HeaderModal>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ minHeight: 150, padding: 20 }}
            >
              <div {...getRootProps({ className: 'dropzone' })} className={classes.dropzone}>
                <input {...getInputProps()} />
                <Typography>Arraste um arquivo, ou clique para adicionar</Typography>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              style={{ padding: 20, marginBottom: 15 }}
            >
              <Box>
                <Typography variant="h5">
                  Arquivos {uploadObject ? uploadObject.length : ''}
                </Typography>

                {uploadObject.map((file, i) => (
                  <Box key={i} display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" mr={1}>
                      <PictureAsPdfIcon style={{ marginRight: 5 }} />
                      <Typography key={i} variant="body1">
                        {file?.name || ''}
                      </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" mr={1}>
                    {file?.googleDocumentId && (
                      <IconButton onClick={() => handleEditManualFile(file, i)}>
                      <Edit style={{ color: colors.primary }} />
                    </IconButton>
                    )}
                    <IconButton onClick={() => handleRemoveFile(file, i)}>
                      <HighlightOffIcon style={{ color: '#e81224' }} />
                    </IconButton>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" my={2}>
            <ButtonConsensus title="Anexar" onClick={onClose} />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalAddFileChangeLevelForm;

import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import InputMask from 'react-input-mask';
import { InputConsensus } from 'components';
import { toast } from 'react-toastify';

const ModalChangeNpu = props => {
  const { open, onClose, task, confirmUpdateNpu } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const initialValues = {
    npu: task.task.npu,
  };

  const [formState, setFormState] = useState({ ...initialValues });

  const handleConfirmTask = () => {
    if (formState.npu.length < 25) {
      return toast.warn('NPU inválido. Verifique se o NPU digitado contém 20 números.');
    }
    let newTask = { ...task.task };
    newTask.npu = formState.npu;
    confirmUpdateNpu(newTask);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      [event.target.name]: event.target.value,
    }));
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <DialogTitle id="alert-dialog-title">{task.task.subType.name}</DialogTitle>
        <DialogContent>
          <form>
            <InputMask
              onChange={handleChange}
              value={formState.npu || ''}
              mask="9999999-99.9999.9.99.9999"
              maskChar={null}
            >
              {inputProps => (
                <InputConsensus
                  {...inputProps}
                  label="NPU"
                  name="npu"
                  variant="standard"
                  fullWidth
                />
              )}
            </InputMask>
          </form>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button onClick={onClose} size="small" variant="contained">
            Cancelar
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleConfirmTask()}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalChangeNpu;

export const SET_PAGINATION_REGISTER_GROUP_OFFICE = 'SET_PAGINATION_REGISTER_GROUP_OFFICE';
export const CLEAR_PAGINATION_REGISTER_GROUP_OFFICE = 'CLEAR_PAGINATION_REGISTER_GROUP_OFFICE';

export const setPaginationRegisterGroupOffice = payload => ({
  type: SET_PAGINATION_REGISTER_GROUP_OFFICE,
  payload,
});

export const clearPaginationRegisterGroupOffice = payload => ({
  type: CLEAR_PAGINATION_REGISTER_GROUP_OFFICE,
  payload,
});

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import TodayIcon from '@material-ui/icons/Today';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HomeIcon from '@material-ui/icons/Home';
import ViewStreamIcon from '@material-ui/icons/ViewStream';
import AddIcon from '@material-ui/icons/Add';

export default [
  {
    title: 'Menu',
    pages: [
      {
        title: 'Dashboard',
        href: '/company/dashboard',
        icon: HomeIcon,
        resource: 'dash',
      },
      {
        title: 'Visualizar Acordos',
        href: '/company/agreements',
        icon: TodayIcon,
      },
      {
        title: 'Fases',
        href: '#',
        icon: ViewStreamIcon,
        children: [
          {
            title: 'Termo de Encaminhamento',
            href: '/company/forwarding-term',
          },
          {
            title: 'Cumprimento de Acordo',
            href: '/company/agreement-compliance',
          },
        ],
      },
      {
        title: 'Cadastros',
        href: '#',
        icon: AddIcon,
        resource: '',
        children: [
          {
            title: 'Cadastro de Instituições Parceiras',
            href: '/company/partner-institution',
          },
          {
            title: 'Cadastro de Serviços e Materiais',
            href: '/company/register-services',
          },
          {
            title: 'Cadastro de Projetos',
            href: '/company/register-projects',
          },
          {
            title: 'Solicitação de Serviços e Materiais',
            href: '/company/service-solicitation',
          },
        ],
      },
      {
        title: 'Perfil',
        href: '/company/profile',
        icon: AccountCircleIcon,
      },
      {
        title: 'Sair',
        href: '/',
        icon: ExitToAppIcon,
      },
    ],
  },
];

export const hasAccessToConfidentialInquiry = ({
  isConfidential,
  hasPermissionToConfidentialInquiries,
  prosecutorId,
  advisorProsecutorId,
  loggedUserId
}) => {
  // o usuário tem permissão para acessar o inquérito confidencial se:
  const hasConfidentialAccess =
    // o inquérito não é confidencial
    !isConfidential ||
    // o usuário tem permissão para visualizar inquéritos confidenciais
    hasPermissionToConfidentialInquiries ||
    // o usuário é o promotor do inquérito
    prosecutorId === loggedUserId ||
    // o usuário é assessor de um promotor
    advisorProsecutorId !== undefined &&
    // o promotor assessorado é o promotor do inquérito
    prosecutorId === advisorProsecutorId;
  return hasConfidentialAccess;
}
import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import InputConsensus from 'components/InputConsensus';
import pjeIntegrationService from 'services/pje-integration/pjeIntegrationService';
import UniqueBy from 'utils/arrayHandler/uniqueBy';
import debounce from 'lodash.debounce';
import { Chip } from '@mui/material';

const PjeJudClassSubAutoComplete = props => {
  const { formState, setFormState, handleChange, isInAnalysis } = props;

  const [pjeSubjects, setPjeSubjects] = useState(formState.pjeSubjects || []);

  const handleChangeJurisdiction = (event, newValue) => {
    event.persist();
    setPjeClassOptions([]);
    setPjeSubjects([]);
    setPjeSubjectOptions([]);
    setInputSearchPjeSubject('');
    setInputSearchPjeMainSubject('');
    setFormState(prevState => ({
      ...prevState,
      pjeClass: '',
      pjeSubjects: [],
      pjeProvince: '',
      pjeMainSubject: '',
    }));
    setPjeProvinceOptions([]);
    setInputSearchPjeProvince('');
    handleChange('pjeJurisdiction', newValue);
  };

  const handleChangeClass = (event, newValue) => {
    event.persist();
    setPjeSubjects([]);
    setPjeSubjectOptions([]);
    setInputSearchPjeSubject([]);
    setInputSearchPjeSubject('');
    setInputSearchPjeMainSubject('');
    setFormState(prevState => ({
      ...prevState,
      pjeSubjects: [],
      pjeProvince: '',
      pjeMainSubject: '',
    }));
    setPjeProvinceOptions([]);
    setInputSearchPjeProvince('');
    handleChange('pjeClass', newValue);
  };

  const handleChangeSubject = (event, newValue) => {
    event.persist();
    setPjeSubjects(newValue);
    setInputSearchPjeSubject('');
    debouncedGetPjeSubject('', formState.pjeClass, formState.pjeJurisdiction);
    handleChange('pjeSubjects', newValue);
  };

  const handleChangeMainSubject = (event, newValue) => {
    event.persist();
    setInputSearchPjeSubject('');
    setFormState(prevState => ({
      ...prevState,
      pjeMainSubject: newValue,
      pjeProvince: '',
      pjeSubjects: [],
    }));
    setPjeProvinceOptions([]);
    setInputSearchPjeProvince('');
    if (newValue) {
      debouncedGetPjeProvince('', newValue, formState.pjeClass, formState.pjeJurisdiction);
    }
  };

  const [inputSearchJurisdiction, setInputSearchJurisdiction] = useState('');
  const [jurisdictionOptions, setJurisdictionOptions] = useState(
    formState.pjeJurisdiction ? [formState.pjeJurisdiction] : [],
  );
  const [pageJurisdiction, setPageJurisdiction] = useState(0);
  const [lastPageJurisdiction, setLastPageJurisdiction] = useState(1);
  const [loadingJurisdiction, setLoadingJurisdiction] = useState(false);

  const [inputSearchPjeClass, setInputSearchPjeClass] = useState('');
  const [pjeClassOptions, setPjeClassOptions] = useState(
    formState.pjeClass ? [formState.pjeClass] : [],
  );
  const [pagePjeClass, setPagePjeClass] = useState(0);
  const [lastPagePjeClass, setLastPagePjeClass] = useState(1);
  const [loadingPjeClass, setLoadingPjeClass] = useState(false);

  const [inputSearchPjeSubject, setInputSearchPjeSubject] = useState('');
  const [inputSearchPjeMainSubject, setInputSearchPjeMainSubject] = useState('');
  const [pjeSubjectOptions, setPjeSubjectOptions] = useState(
    formState.pjeSubjects ? formState.pjeSubjects : [],
  );
  const [pagePjeSubject, setPagePjeSubject] = useState(0);
  const [lastPagePjeSubject, setLastPagePjeSubject] = useState(1);
  const [loadingPjeSubject, setLoadingPjeSubject] = useState(false);

  const [inputSearchPjeProvince, setInputSearchPjeProvince] = useState('');
  const [pjeProvinceOptions, setPjeProvinceOptions] = useState(
    formState.pjeProvince ? [formState.pjeProvince] : [],
  );
  const [pagePjeProvince, setPagePjeProvince] = useState(0);
  const [lastPagePjeProvince, setLastPagePjeProvince] = useState(1);
  const [loadingPjeProvince, setLoadingPjeProvince] = useState(false);

  const [loadingChangeFromClassSubj, setLoadingChangeFromClassSubj] = useState(false);
  const [loadingChangeFromJudClass, setLoadingChangeFromJudClass] = useState(false);
  const [loadingChangeFromSubjectProvince, setLoadingChangeFromSubjectProvince] = useState(false);

  const loadMoreJurisdictions = () => {
    const nextPage = pageJurisdiction + 1;
    setPageJurisdiction(nextPage);
    setLoadingJurisdiction(true);
    const params = {
      page: nextPage,
      size: 20,
      jurisdictionName: inputSearchJurisdiction,
    };
    pjeIntegrationService
      .getJurisdictionOptionsService(params)
      .then(response => {
        setJurisdictionOptions([
          ...(formState.pjeJurisdiction ? [formState.pjeJurisdiction] : []),
          ...jurisdictionOptions,
          ...response.data.data,
        ]);
        setLastPageJurisdiction(response.data.lastPage);
      })
      .finally(() => setLoadingJurisdiction(false));
  };

  const getJurisdictions = (input = '') => {
    setLoadingJurisdiction(true);
    setPageJurisdiction(1);
    const params = {
      page: 1,
      size: 20,
      jurisdictionName: input,
    };
    pjeIntegrationService
      .getJurisdictionOptionsService(params)
      .then(response => {
        setJurisdictionOptions(response.data.data);
        setLastPageJurisdiction(response.data.lastPage);
      })
      .finally(() => setLoadingJurisdiction(false));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetJurisdictions = useCallback(
    debounce(newValue => getJurisdictions(newValue), 500),
    [],
  );

  const updateValueJurisdictions = newValue => {
    if (!jurisdictionOptions.find(jurisdiction => jurisdiction.name === newValue)) {
      debouncedGetJurisdictions(newValue);
      setInputSearchJurisdiction(newValue);
    }
  };

  const handleScrollJurisdictions = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageJurisdiction < lastPageJurisdiction &&
      !loadingJurisdiction
    ) {
      loadMoreJurisdictions();
    }
  };

  const loadMorePjeClass = () => {
    const nextPage = pagePjeClass + 1;
    setPagePjeClass(nextPage);
    setLoadingPjeClass(true);
    const params = {
      page: nextPage,
      size: 20,
      classDescription: inputSearchPjeClass,
      jurisdictionId: formState.pjeJurisdiction.id,
    };
    pjeIntegrationService
      .getPjeClassOptionsService(params)
      .then(response => {
        setPjeClassOptions(
          UniqueBy(
            [
              ...(formState.pjeClass ? [formState.pjeClass] : []),
              ...pjeClassOptions,
              ...response.data.data,
            ],
            'id',
          ),
        );
        setLastPagePjeClass(response.data.lastPage);
      })
      .finally(() => setLoadingPjeClass(false));
  };

  const getPjeClass = (input = '', jurisdiction) => {
    if (!loadingChangeFromJudClass) {
      setLoadingPjeClass(true);
      setPagePjeClass(1);
      const params = {
        page: 1,
        size: 20,
        classDescription: input,
        jurisdictionId: jurisdiction.id,
      };
      pjeIntegrationService
        .getPjeClassOptionsService(params)
        .then(response => {
          setPjeClassOptions(response.data.data);
          setLastPagePjeClass(response.data.lastPage);
        })
        .finally(() => setLoadingPjeClass(false));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetPjeClass = useCallback(
    debounce((newValue, jurisdiction) => getPjeClass(newValue, jurisdiction), 500),
    [],
  );

  const updateValuePjeClass = newValue => {
    if (!pjeClassOptions.find(pjeClass => pjeClass.name === newValue)) {
      debouncedGetPjeClass(newValue, formState.pjeJurisdiction);
      setInputSearchPjeClass(newValue);
    }
  };

  const loadMorePjeSubject = () => {
    const nextPage = pagePjeSubject + 1;
    setPagePjeSubject(nextPage);
    setLoadingPjeSubject(true);
    let params = {
      page: nextPage,
      size: 20,
      subjectDescription: inputSearchPjeSubject,
      classId: formState.pjeClass.id,
      jurisdictionId: formState.pjeJurisdiction.id,
    };
    pjeIntegrationService
      .getPjeSubjectOptionsService(params)
      .then(response => {
        setPjeSubjectOptions(
          UniqueBy(
            [
              ...(formState.pjeSubjects ? formState.pjeSubjects : []),
              ...pjeSubjectOptions,
              ...response.data.data,
            ],
            'id',
          ),
        );
        setLastPagePjeSubject(response.data.lastPage);
      })
      .finally(() => setLoadingPjeSubject(false));
  };

  const getPjeSubject = (input = '', classe, jurisdiction) => {
    if (classe?.id && !loadingChangeFromClassSubj) {
      setLoadingPjeSubject(true);
      setPagePjeSubject(1);
      const params = {
        page: 1,
        size: 20,
        subjectDescription: input,
        classId: classe.id,
        jurisdictionId: jurisdiction.id,
      };
      pjeIntegrationService
        .getPjeSubjectOptionsService(params)
        .then(response => {
          setPjeSubjectOptions(response.data.data);
          setLastPagePjeSubject(response.data.lassetPjeSubjectOptionstPage);
        })
        .finally(() => setLoadingPjeSubject(false));
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetPjeSubject = useCallback(
    debounce(
      (newValue, classe, jurisdiction) => getPjeSubject(newValue, classe, jurisdiction),
      500,
    ),
    [],
  );

  const updateValuePjeSubject = newValue => {
    if (!pjeSubjectOptions.find(pjeSubject => pjeSubject.name === newValue)) {
      debouncedGetPjeSubject(newValue, formState.pjeClass, formState.pjeJurisdiction);
      setInputSearchPjeSubject(newValue);
    }
  };

  const handleScrollPjeSubject = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pagePjeSubject < lastPagePjeSubject &&
      !loadingPjeSubject
    ) {
      loadMorePjeSubject();
    }
  };

  const updateValuePjeMainSubject = newValue => {
    if (!pjeSubjectOptions.find(pjeSubject => pjeSubject.name === newValue)) {
      debouncedGetPjeSubject(newValue, formState.pjeClass, formState.pjeJurisdiction);
      setInputSearchPjeMainSubject(newValue);
    }
  };

  useEffect(() => {
    formState.pjeSubjects &&
      debouncedGetPjeSubject(formState.pjeSubjects, formState.pjeClass, formState.pjeJurisdiction);
    setPjeSubjects(formState.pjeSubjects || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.pjeSubjects]);

  useEffect(() => {
    setLoadingChangeFromClassSubj(true);
    formState.pjeClass && debouncedGetPjeSubject('', formState.pjeClass, formState.pjeJurisdiction);
    setLoadingChangeFromClassSubj(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.pjeClass]);

  useEffect(() => {
    setLoadingChangeFromJudClass(true);
    formState.pjeJurisdiction && debouncedGetPjeClass('', formState.pjeJurisdiction);
    setLoadingChangeFromJudClass(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.pjeJurisdiction]);

  const handleScrollPjeClass = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pagePjeClass < lastPagePjeClass &&
      !loadingPjeClass
    ) {
      loadMorePjeClass();
    }
  };

  useEffect(() => {
    debouncedGetJurisdictions('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMorePjeProvince = () => {
    const nextPage = pagePjeProvince + 1;
    setPagePjeProvince(nextPage);
    setLoadingPjeProvince(true);
    const params = {
      page: nextPage,
      size: 20,
      provinceName: inputSearchPjeProvince,
      subjects: formState.pjeSubjects.map(subject => subject.id),
    };
    pjeIntegrationService
      .getPjeProvinceOptions(params)
      .then(response => {
        setPjeProvinceOptions(
          UniqueBy(
            [
              ...(formState.pjeProvince ? [formState.pjeProvince] : []),
              ...pjeProvinceOptions,
              ...response.data.data,
            ],
            'id',
          ),
        );
        setLastPagePjeProvince(response.data.lastPage);
      })
      .finally(() => setLoadingPjeProvince(false));
  };

  const getPjeProvince = (input = '', subject, pjeClass, jurisdiction) => {
    if (!loadingChangeFromSubjectProvince) {
      setLoadingPjeProvince(true);
      setPagePjeProvince(1);
      const params = {
        page: 1,
        size: 20,
        provinceName: input,
        jurisdictionId: jurisdiction.id,
        classId: pjeClass.id,
        subjectId: subject.id,
      };
      pjeIntegrationService
        .getPjeProvinceOptions(params)
        .then(response => {
          setPjeProvinceOptions(response.data.data);
          setLastPagePjeProvince(response.data.lastPage);
        })
        .finally(() => setLoadingPjeProvince(false));
    }
  };

  const debouncedGetPjeProvince = useCallback(
    debounce(
      (newValue, subject, pjeClass, jurisdiction) =>
        getPjeProvince(newValue, subject, pjeClass, jurisdiction),
      500,
    ),
    [],
  );

  const updateValuePjeProvince = newValue => {
    if (!pjeProvinceOptions.find(pjeProvince => pjeProvince.name === newValue)) {
      debouncedGetPjeProvince(
        newValue,
        formState.pjeMainSubject,
        formState.pjeClass,
        formState.pjeJurisdiction,
      );
      setInputSearchPjeProvince(newValue);
    }
  };

  const handleChangeProvince = (event, newValue) => {
    event.persist();
    handleChange('pjeProvince', newValue);
  };

  const handleScrollPjeProvince = event => {
    const listboxNode = event.currentTarget;
    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pagePjeProvince < lastPagePjeProvince &&
      !loadingPjeProvince
    ) {
      loadMorePjeProvince();
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Autocomplete
          value={formState.pjeJurisdiction}
          onInputChange={(_, newValue) => updateValueJurisdictions(newValue)}
          getOptionSelected={(option, value) => option.name === value.name}
          getOptionLabel={option => option.name || ''}
          filterOptions={x => x}
          fullWidth
          noOptionsText="Nada foi encontrado"
          loadingText="Buscando..."
          loading={loadingJurisdiction}
          clearOnBlur={false}
          options={jurisdictionOptions}
          onChange={handleChangeJurisdiction}
          renderOption={option => <>{option.name}</>}
          renderInput={params => (
            <InputConsensus
              {...params}
              label="Jurisdição *"
              name="jurisdiction"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingJurisdiction ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{
            onScroll: handleScrollJurisdictions,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Autocomplete
          disabled={!formState.pjeJurisdiction}
          value={formState.pjeClass}
          onInputChange={(_, newValue) => updateValuePjeClass(newValue)}
          getOptionSelected={(option, value) => option.description === value.description}
          getOptionLabel={option => option.description || ''}
          filterOptions={x => x}
          fullWidth
          noOptionsText="Nada foi encontrado"
          loadingTet="Buscando..."
          loading={loadingPjeClass}
          clearOnBlur={false}
          options={pjeClassOptions}
          onChange={handleChangeClass}
          renderOption={option => <>{option.description}</>}
          renderInput={params => (
            <InputConsensus
              {...params}
              label="Classe CNJ *"
              name="pjeClass"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingPjeClass ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{
            onScroll: handleScrollPjeClass,
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
        <Autocomplete
          disabled={!formState.pjeClass}
          value={formState.pjeMainSubject}
          onInputChange={(_, newValue) => updateValuePjeMainSubject(newValue)}
          getOptionSelected={(option, value) => option.description === value.description}
          getOptionLabel={option => option.description || ''}
          fullWidth
          noOptionsText="Nada foi encontrado"
          loadingText="Buscando..."
          loading={loadingPjeSubject}
          clearOnBlur={false}
          options={pjeSubjectOptions}
          onChange={handleChangeMainSubject}
          renderOption={option => <>{`${option.description} (${option.id})`}</>}
          renderInput={params => (
            <InputConsensus
              {...params}
              label="Assunto CNJ Principal *"
              name="pjeMainSubject"
              fullWidth
              onClick={() =>
                debouncedGetPjeSubject(
                  inputSearchPjeMainSubject,
                  formState.pjeClass,
                  formState.pjeJurisdiction,
                )
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingPjeSubject ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{
            onScroll: handleScrollPjeSubject,
          }}
        />
      </Grid>
        {!isInAnalysis && (
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Autocomplete
              disabled={!formState.pjeMainSubject}
              value={formState.pjeProvince}
              onInputChange={(_, newValue) => updateValuePjeProvince(newValue)}
              getOptionSelected={(option, value) => option.name === value.name}
              getOptionLabel={option => option.name || ''}
              filterOptions={x => x}
              fullWidth
              noOptionsText="Nada foi encontrado"
              loadingTet="Buscando..."
              loading={loadingPjeProvince}
              clearOnBlur={false}
              options={pjeProvinceOptions}
              onChange={handleChangeProvince}
              renderOption={option => <>{option.name}</>}
              renderInput={params => (
                <InputConsensus
                  {...params}
                  label="Competência *"
                  name="pjeProvince"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingPjeProvince ? <CircularProgress color="primary" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
              ListboxProps={{
                onScroll: handleScrollPjeProvince,
              }}
            />
          </Grid>
        )}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Autocomplete
          multiple={true}
          disabled={!formState.pjeMainSubject}
          value={pjeSubjects}
          onInputChange={(_, newValue) => updateValuePjeSubject(newValue)}
          getOptionSelected={(option, value) => option.description === value.description}
          getOptionLabel={option => option.description || ''}
          fullWidth
          noOptionsText="Nada foi encontrado"
          loadingText="Buscando..."
          loading={loadingPjeSubject}
          clearOnBlur={false}
          options={pjeSubjectOptions.filter(sbj => sbj.id !== formState.pjeMainSubject?.id)}
          onChange={handleChangeSubject}
          renderOption={option => <>{`${option.description} (${option.id})`}</>}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option.description}
                size="small" // Define o tamanho da tag
                style={{ fontSize: '12px' }} // Ajusta o tamanho da fonte
              />
            ))
          }
          renderInput={params => (
            <InputConsensus
              {...params}
              label="Outros Assuntos CNJ"
              name="pjeSubject"
              fullWidth
              onClick={() =>
                debouncedGetPjeSubject(
                  inputSearchPjeSubject,
                  formState.pjeClass,
                  formState.pjeJurisdiction,
                )
              }
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingPjeSubject ? <CircularProgress color="primary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{
            onScroll: handleScrollPjeSubject,
          }}
        />
      </Grid>
      {isInAnalysis && (
        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
          <Autocomplete
            disabled={!formState.pjeMainSubject}
            value={formState.pjeProvince}
            onInputChange={(_, newValue) => updateValuePjeProvince(newValue)}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={option => option.name || ''}
            filterOptions={x => x}
            fullWidth
            noOptionsText="Nada foi encontrado"
            loadingTet="Buscando..."
            loading={loadingPjeProvince}
            clearOnBlur={false}
            options={pjeProvinceOptions}
            onChange={handleChangeProvince}
            renderOption={option => <>{option.name}</>}
            renderInput={params => (
              <InputConsensus
                {...params}
                label="Competência *"
                name="pjeProvince"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingPjeProvince ? <CircularProgress color="primary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: handleScrollPjeProvince,
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default PjeJudClassSubAutoComplete;

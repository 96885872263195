import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { ButtonConsensus } from 'components';
import {
  Grid,
  TextField,
  MenuItem,
  CircularProgress,
  Tooltip,
  Typography,
} from '@material-ui/core';
import InputMask from 'react-input-mask';
import { colors } from 'utils/colorsSteps';
import { ContainerCardAgreement } from 'components';
import { ReportData } from './components';
import Pagination from '@material-ui/lab/Pagination';
import { Autocomplete } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import associationService from 'services/association/associationService';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import { genericInquiry, npuGenericInquiry, pcpeGenericInquiry } from 'masks/masks';
import { clearInquiryNumber } from 'utils/genericFunctions/formatedInquiryNumber';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import crimeService from 'services/crime/crimeService';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  containerCards: {
    minHeight: '37vh',
  },
  containerProgress: {
    minHeight: '40vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectInput: {
    height: 35,
    width: '100%',
    border: `1px solid #747474`,
    paddingLeft: 10,
    backgroundColor: 'transparent',
    marginRight: 10,
    marginTop: 5,
  },
  pagination: {
    '& > *': {
      margin: theme.spacing(1),
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(3, 0, 0, 0),
  },
}));

const FilterCardsAgreements = props => {
  const { className, step, status, hasPendenciesInAgreement, ...rest } = props;

  const isForwardInstitution = useSelector(state => state.logedUser.association.forward);
  const associationId = useSelector(state => state.logedUser.association.id);
  const { allOriginNumbersAreNpu } = useCustomerConfig();

  const classes = useStyles();

  const initialValues = {
    policeInquiryNumber: '',
    investigatedName: '',
    investigatedCpf: '',
    inquiryStep: step || (isForwardInstitution ? 'BOTH' : STEPS_INQUIRY.CUMPRIMENTO_ACORDO),
    startDate: '',
    endDate: '',
    crimeId: null,
    hasPendenciesInAgreement: hasPendenciesInAgreement || 'BOTH',
    finalInstitution: null,
    inquiryStatus: status || '',
  };

  const hasPendenciesInAgreementOptions = [
    {
      name: 'Sim',
      value: true,
    },
    {
      name: 'Não',
      value: false,
    },
    {
      name: 'Ambos (Pendentes e Não Pendentes)',
      value: 'BOTH',
    },
  ];

  const [isResult, setIsResult] = useState(false);
  const [showProgress, setShowProgress] = useState(false);

  const [inquirys, setInquirys] = useState([]);
  const [page, setPage] = useState(1);
  const size = 20;
  const [totalData, setTotalData] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [inputValueCrime, setInputValueCrime] = useState('');
  const [crimesCometidos, setCrimesCometidos] = useState([]);

  const [inputValueFinalInstitution, setInputValueFinalInstitution] = useState('');
  const [finalInstitutions, setFinalInstitutions] = useState([]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const [mask, setMask] = useState(allOriginNumbersAreNpu ? npuGenericInquiry : genericInquiry);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const timezone = () => {
    let offset = -new Date().getTimezoneOffset() / 60;
    if (offset > 0) offset = '+' + offset;
    return `GMT${offset}`;
  };

  const functionData = (data, typeDate) => {
    data = new Date(`${data} ${timezone()}`);
    if (typeDate === 'start') {
      data.setHours(0, 0, 0);
    } else {
      data.setHours(23, 59, 59);
    }
    return data.toISOString();
  };

  const getInquirys = async page => {
    const {
      policeInquiryNumber,
      investigatedName,
      investigatedCpf,
      inquiryStep,
      inquiryStatus,
      startDate,
      endDate,
      crimeId,
      finalInstitution,
      hasPendenciesInAgreement,
    } = formState.values;

    setShowProgress(true);

    let params = {
      page,
      size,
    };

    if (hasPendenciesInAgreement !== 'BOTH') {
      params.pending = hasPendenciesInAgreement;
    }

    if (inquiryStep !== 'BOTH') {
      params.inquiryStep = inquiryStep;
    }

    if (inquiryStatus) {
      params.inquiryStatus = inquiryStatus;
    }

    if (policeInquiryNumber && policeInquiryNumber !== '') {
      params.policeInquiryNumber = clearInquiryNumber(policeInquiryNumber);
    }

    if (investigatedName && investigatedName !== '') {
      params.investigatedName = investigatedName;
    }

    if (investigatedCpf && investigatedCpf !== '') {
      params.investigatedCpf = investigatedCpf.replace(/\D/g, '');
    }

    if (startDate && startDate !== '') {
      params.startHearingDate = functionData(startDate, 'start');
    }

    if (endDate && endDate !== '') {
      params.endHearingDate = functionData(endDate, 'end');
    }

    if (crimeId && crimeId !== '') {
      params.crimeId = crimeId.id;
    }
    if (finalInstitution && finalInstitution !== '') {
      params.finalInstitutionId = finalInstitution.id;
    }

    await associationService
      .getInvestigatedAssociationService(params)
      .then(response => {
        setInquirys(response.data.data);
        setTotalData(response.data.data.length);
        setTotal(response.data.total);
        setTotalPages(response.data.lastPage);
      })
      .finally(() => {
        setIsResult(true);
        setShowProgress(false);
      });
  };

  const getCrimes = async (search = '') => {
    let params = {
      page: 1,
      size: 20,
      search,
    };

    await crimeService.getCrimesService(params).then(async response => {
      if (response.data.data.length > 0) {
        setCrimesCometidos(
          response.data.data.sort(function(a, b) {
            if (a.name > b.name) {
              return 1;
            }
            if (a.name < b.name) {
              return -1;
            }
            return 0;
          }),
        );
      } else {
        setCrimesCometidos([]);
      }
    });
  };

  const getFinalInstitutions = async () => {
    await associationService
      .getRelatedAssociationsService({ associationId: associationId })
      .then(response => {
        if (response.data.length > 0) {
          setFinalInstitutions(
            response.data.sort(function(a, b) {
              if (a.name > b.name) {
                return 1;
              }
              if (a.name < b.name) {
                return -1;
              }
              return 0;
            }),
          );
        } else {
          setFinalInstitutions([]);
        }
      });
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let active = true;

    if (inputValueCrime === '') {
      setCrimesCometidos(formState.values.crimeId ? [formState.values.crimeId] : []);
      return undefined;
    }

    getCrimes(inputValueCrime);

    return () => {
      active = false;
    };
  }, [formState.values.crimeId, inputValueCrime]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let active = true;

    if (inputValueFinalInstitution === '') {
      setFinalInstitutions(
        formState.values.finalInstitution ? [formState.values.finalInstitution] : [],
      );
      return undefined;
    }

    getFinalInstitutions();

    return () => {
      active = false;
    };
  }, [formState.values.finalInstitution, inputValueFinalInstitution]);

  useEffect(() => {
    getInquirys(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleSubmit = event => {
    event.preventDefault();
    setIsResult(false);
    setShowProgress(true);
    getInquirys(page);
  };

  const handlePageChange = (_event, value) => {
    if (page !== value) {
      setPage(value);
      setIsResult(false);
      setShowProgress(true);
      getInquirys(value);
    }
  };

  const handleChangeCrimeAutocomplete = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        crimeId: newValue,
      },
    }));
  };

  const handleChangeFinalInstitutionAutocomplete = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        finalInstitution: newValue,
      },
    }));
  };

  const handlePoliceInquiryNumberChange = event => {
    event.persist();
    const inputValue = event.target.value;

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));

    if (!allOriginNumbersAreNpu) {
      const newMask = inputValue.match(/\d/g)?.length === 16 ? pcpeGenericInquiry : genericInquiry;
      setMask(newMask);
    }
  };

  const verifyMask = event => {
    const inputValue = event.target.value;
    if (inputValue.match(/\d/g)?.length === 16 && !allOriginNumbersAreNpu) {
      setMask(genericInquiry);
    }
  };

  const verifyOnPaste = event => {
    setMask(event.target.value?.length === 16 ? pcpeGenericInquiry : genericInquiry);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <InputMask
              onChange={handlePoliceInquiryNumberChange}
              value={formState.values.policeInquiryNumber || ''}
              mask={mask}
              maskChar={null}
              onBeforeInput={verifyMask}
              onPaste={event => {
                if (!allOriginNumbersAreNpu) {
                  verifyOnPaste(event);
                }
              }}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  label="Número do Inquérito"
                  name="policeInquiryNumber"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <TextField
              label="Nome do Investigado"
              name="investigatedName"
              onChange={handleChange}
              value={formState.values.investigatedName || ''}
              variant="outlined"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <InputMask
              onChange={handleChange}
              value={formState.values.investigatedCpf || ''}
              mask="999.999.999-99"
              maskChar={null}
            >
              {inputProps => (
                <TextField
                  {...inputProps}
                  label="CPF do Investigado"
                  name="investigatedCpf"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
            <TextField
              onChange={handleChange}
              value={formState.values.inquiryStep}
              name="inquiryStep"
              label="Fases Consensus"
              select
              variant="outlined"
              fullWidth
              size="small"
              clear
              disabled={step || !isForwardInstitution}
            >
              {!step && isForwardInstitution && (
                <MenuItem value="BOTH">
                  Ambas Fases (Termo de Encaminhamento e Cumprimento de Acordo)
                </MenuItem>
              )}
              {(step === STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO || !step) &&
                isForwardInstitution && (
                  <MenuItem value={STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO}>
                    Termo de Encaminhamento
                  </MenuItem>
                )}
              {(step === STEPS_INQUIRY.CUMPRIMENTO_ACORDO || !step) && (
                <MenuItem value={STEPS_INQUIRY.CUMPRIMENTO_ACORDO}>Cumprimento de Acordo</MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
            <Autocomplete
              options={crimesCometidos}
              fullWidth
              autoHighlight
              value={formState.values.crimeId}
              onChange={handleChangeCrimeAutocomplete}
              filterOptions={x => x}
              includeInputInList
              filterSelectedOptions
              noOptionsText="Não encontrado"
              onOpen={() => getCrimes()}
              onInputChange={(event, newInputValue) => {
                setInputValueCrime(newInputValue);
              }}
              getOptionLabel={option => option.taxonomy || ''}
              renderOption={option => (
                <HtmlTooltip
                  title={
                    <React.Fragment>
                      <Typography variant="body1">
                        {option.taxonomy && `Nome ${option.taxonomy || 'não informado'}`}
                      </Typography>
                      <Typography variant="body1">
                        {option.lawName && `Lei ${option.lawName || 'não informada'}`}
                      </Typography>
                      <Typography variant="body1">
                        {option.lawNumber && `Lei n° ${option.lawNumber || 'não informado'}`}
                      </Typography>
                      <Typography variant="body1">
                        {option.article && `Artigo ${option.article || 'não informado'}`}
                      </Typography>
                      <Typography variant="body1">
                        {option.paragraph && `Parágrafo n° ${option.paragraph || 'não informado'}`}
                      </Typography>
                      <Typography variant="body1">
                        {option.item && `Item ${option.item || 'não informado'}`}
                      </Typography>
                      <Typography variant="body1">
                        {option.point && `Ponto ${option.point || 'não informado'}`}
                      </Typography>
                      <Typography variant="body1">
                        {option?.subject?.hierarchy &&
                          `Hierarquia ${option.lawNumber || 'não informada'}`}
                      </Typography>
                    </React.Fragment>
                  }
                >
                  <Typography>
                    {option.taxonomy} - Lei: {option.lawNumber ? option.lawNumber : 'não informada'}{' '}
                    - Artigo: {option.article ? option.article : 'não informado'}{' '}
                    {option.paragraph ? '-' : ''} {option.paragraph ? option.paragraph : ''}{' '}
                    {option.item ? '-' : ''} {option.item ? option.item : ''}{' '}
                    {option.point ? '-' : ''} {option.point ? option.point : ''}
                  </Typography>
                </HtmlTooltip>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  name="crimeId"
                  label="Crime Cometido "
                  fullWidth
                  size="small"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
            <TextField
              label="Data Início"
              name="startDate"
              onChange={handleChange}
              value={formState.values.startDate || ''}
              variant="outlined"
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
            <TextField
              label="Data Fim"
              name="endDate"
              onChange={handleChange}
              value={formState.values.endDate || ''}
              variant="outlined"
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
          {step !== STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO && (
            <>
              <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
                <TextField
                  select
                  label="Pendente de cumprimento?"
                  name="hasPendenciesInAgreement"
                  onChange={handleChange}
                  value={formState.values.hasPendenciesInAgreement}
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  {hasPendenciesInAgreementOptions.map(option => (
                    <MenuItem key={option.name} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {isForwardInstitution && (
                <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <Autocomplete
                    options={finalInstitutions}
                    fullWidth
                    autoHighlight
                    value={formState.values.finalInstitution}
                    onChange={handleChangeFinalInstitutionAutocomplete}
                    filterOptions={x => x}
                    includeInputInList
                    filterSelectedOptions
                    noOptionsText="Não encontrado"
                    onOpen={() => getFinalInstitutions()}
                    onInputChange={(event, newInputValue) => {
                      setInputValueFinalInstitution(newInputValue);
                    }}
                    getOptionLabel={option => option.name}
                    renderOption={option => <>{option.name}</>}
                    renderInput={params => (
                      <TextField
                        {...params}
                        name="finalInstitution"
                        label="Instituição Final "
                        fullWidth
                        size="small"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}
          <Grid item xl={3} lg={3} md={3} sm={4} xs={12}>
            <ButtonConsensus
              title="Buscar"
              backgroundColor={colors.greenLight}
              hovercolor={colors.greenLight}
              type="submit"
            />
          </Grid>
        </Grid>
      </form>
      {showProgress && (
        <div className={classes.containerProgress}>
          <CircularProgress />
        </div>
      )}
      {isResult && (
        <>
          <ReportData total={total} page={page} totalData={totalData} totalPages={totalPages} />
          <ContainerCardAgreement inquirys={inquirys} className={classes.containerCards} />
        </>
      )}
      <div className={classes.pagination}>
        <Pagination
          size="small"
          count={totalPages}
          page={page}
          color="primary"
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default FilterCardsAgreements;

import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Dialog, Typography, DialogContent, IconButton, Tooltip } from '@material-ui/core';
import { HeaderModal } from 'components';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SendIcon from '@material-ui/icons/Send';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import axios from 'utils/axios';
import Timeline from './components/Timeline';
import TimelineEvent from './components/TimelineEvent';
import { formatDateAndHoursToBrazilian } from 'utils/date-time.utils';
import { listClassesAtosInfracionais } from 'utils/constsFormInvestigateds';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#00597b',
  },
  textTitle: {
    color: '#ffffff',
  },
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  closeButton: {
    color: '#ffffff',
  },
  header: {
    backgroundColor: '#00597b',
    color: '#ffffff',
    paddingLeft: 30,
  },
  body: {
    backgroundColor: '#ededed',
    borderRadius: 5,
    padding: 10,
    marginBottom: theme.spacing(1),
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: theme.spacing(2),
  },
  label2: {
    color: 'gray',
    fontWeight: 'noraml',
    fontSize: 12,
  },
}));

const ModalTrakingMail = props => {
  const { open, onClose, className, trackingCode, intimation, ...rest } = props;

  const [arSteps, setArSteps] = useState([]);

  const parseSteps = arStep => {
    switch (arStep.step) {
      case 'ARQUIVO_SERVICO':
        return { ...arStep, icon: FindInPageIcon, color: '#edc654', title: 'Em análise' };
      case 'NOTIFICACAO_ARQUIVO_SERVICO':
        return { ...arStep, icon: SendIcon, color: '#72badd', title: 'A caminho' };
      case 'DEVOLUCAO_AR':
        return { ...arStep, icon: ContactMailIcon, color: '#62c3ab', title: 'Recebida' };
      case 'ARQUIVO_CANCELAMENTO':
        return {
          ...arStep,
          icon: CancelScheduleSendIcon,
          color: '#de5c4d',
          title: 'Intimação cancelada',
        };
      default:
        return { ...arStep, icon: HelpOutlineIcon, color: 'grey', title: 'Indefinido' };
    }
  };

  const parseDate = date => {
    return date ? formatDateAndHoursToBrazilian(date) : '';
  };

  const subpoenaName = intimation => {
    const inquiryClasse = intimation.inquiryInvestigated.inquiry?.classe;
    const investigated = intimation.inquiryInvestigated.investigated;
    const useRepresentantLegalName = listClassesAtosInfracionais.some(
      e => e.code === inquiryClasse,
    );
    if (useRepresentantLegalName && investigated.representantLegal) {
      return investigated.representantLegal.name;
    }
    return investigated.name;
  };

  useEffect(() => {
    axios.get(`intimations/${intimation.id}/ar-steps`).then(response => {
      const data = response.data.sort((a, b) => {
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
      setArSteps(data.map(arStep => parseSteps(arStep)));
    });
  }, [intimation.id]);

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={onClose}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        maxWidth="md"
        fullWidth
      >
        <HeaderModal onClose={onClose}>Acompanhamento de intimação</HeaderModal>
        <DialogContent>
          <div
            style={{
              width: '100%',

              paddingTop: 5,
            }}
          >
            <Typography className={classes.label}>
              {subpoenaName(intimation) || ''}
              {trackingCode && (
                <div>
                  <span className={classes.label2}>{trackingCode}</span>
                  <Tooltip title="Copiar código de rastreamento" placement="top">
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(trackingCode);
                      }}
                    >
                      <FileCopyIcon style={{ height: '12px', color: 'gray' }} />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
            </Typography>
            <Timeline minEvents={3} placeholder>
              <TimelineEvent
                icon={MarkunreadMailboxIcon}
                title="Intimação solicitada"
                subtitle={parseDate(intimation.createdAt)}
              />
              {arSteps.map((arStep, index) => (
                <TimelineEvent
                  key={index}
                  color={arStep.color}
                  icon={arStep.icon}
                  title={arStep.title}
                  subtitle={parseDate(arStep.createdAt)}
                />
              ))}
            </Timeline>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalTrakingMail;

import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';

const generalPenaltiesParser = generals => {
  const newGeneralPenalties = [];
  generals.forEach(general => {
    newGeneralPenalties.push({
      ...general,
      project: general.project,
      association: general.association,
      penalty: PENALTY_KINDS.OTHER_PENALTIES,
    });
  });
  return newGeneralPenalties;
};

export default generalPenaltiesParser;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Paper,
  Dialog,
  DialogContent,
  useMediaQuery,
  CardActionArea,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { HeaderModal } from 'components';
import Moment from 'react-moment';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';

const useStyles = makeStyles(theme => ({
  root: {},
  card: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  span: {
    marginRight: theme.spacing(1),
  },
}));

const ModalInvestigatedsArquimedes = props => {
  const { open, onClose, investigateds, setInvestigated } = props;

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleSelectInvestigated = investigated => {
    setInvestigated(investigated);
    onClose();
  };

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth={'md'}
        fullScreen={fullScreen}
        open={open}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        aria-labelledby="responsive-dialog-title"
      >
        <HeaderModal onClose={onClose}>Investigados</HeaderModal>
        <DialogContent>
          <Card className={classes.card}>
            <CardActionArea>
              <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                  {investigateds.length < 1 && (
                    <caption style={{ textAlign: 'center' }}>Não há registros</caption>
                  )}
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Investigado</TableCell>
                      <TableCell align="center">Mãe</TableCell>
                      <TableCell align="center">Nascimento</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {investigateds.map((investigated, index) => (
                      <TableRow
                        key={index}
                        hover
                        onClick={() => handleSelectInvestigated(investigated)}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {!investigated.name || investigated.name === ''
                            ? 'não informado'
                            : investigated.name}
                        </TableCell>
                        <TableCell align="center">
                          {!investigated.motherName || investigated.motherName === ''
                            ? 'não informado'
                            : investigated.motherName}
                        </TableCell>
                        <TableCell align="center">
                          {!investigated.birthDate || investigated.birthDate === '' ? (
                            'não informado'
                          ) : (
                            <Moment format="DD/MM/YYYY" local>
                              {investigated.birthDate}
                            </Moment>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardActionArea>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalInvestigatedsArquimedes;

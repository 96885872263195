/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CardInquiry from '../CardInquiry';
import colorsSteps, { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import history from 'utils/history';
import { ButtonConsensus, ModalAddFileGeneric } from 'components';
import pmService from 'services/PM/pmService';
import { useUserTypes } from 'hooks/useUserTypes';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import { hasAccessToConfidentialInquiry } from 'utils/genericFunctions/hasAccessToConfidentialInquiry';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  gridList: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
  },
}));

const ViewCardsInquiries = props => {
  const { inquiries, linking } = props;

  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const { groups, permissions, prosecutorOffices, advisorProsecutor, type, userId } = useSelector(
    state => state.logedUser,
  );

  const prosecutorOfficesIds = [
    ...(prosecutorOffices ?? []),
    ...(advisorProsecutor?.prosecutorOffices ?? []),
  ];

  const [uploadObject, setUploadObject] = useState([]);
  const [fileInBase64, setFileInBase64] = useState([]);
  const [openModalAddFile, setOpenModalAddFile] = useState(false);

  const [selectedInquiry, setSelectedInquiry] = useState(null);
  const [loadingSendFile, setLoadingSendFile] = useState(false);

  const handleCloseModalAddFile = () => {
    setOpenModalAddFile(false);
    setUploadObject([]);
    setFileInBase64([]);
    setSelectedInquiry(null);
  };

  const handleSendFileRequest = () => {
    if (!fileInBase64.length) {
      toast.warn('Selecione pelo menos um arquivo');
      return;
    }

    setLoadingSendFile(true);

    let params = {
      name: fileInBase64[0].name,
      base64: fileInBase64[0].base64,
    };

    pmService
      .addFileInquiryPmService(selectedInquiry, params)
      .then(() => {
        toast.success('Arquivo anexado com sucesso!');
        handleCloseModalAddFile();
      })
      .finally(() => setLoadingSendFile(false));
  };

  const handleOpenSnackbar = step => {
    return toast.warn(`Você não tem permissão para acessar inquéritos em ${step}.`);
  };

  const validateOfficeAndGroup = (prosecutorOfficeGroup, prosecutorOffice) => {
    if (
      !groups.find(group => group.id === prosecutorOfficeGroup?.id) ||
      ((advisorProsecutor ||
        (type === 'Prosecutor' && !permissions.consultasNaoDistribuidas?.read)) &&
        prosecutorOffice &&
        !prosecutorOfficesIds.find(office => office.id === prosecutorOffice?.id))
    ) {
      return false;
    } else {
      return true;
    }
  };

  const validatePermissions = (
    read,
    write,
    prosecutorOfficeGroup,
    prosecutorOffice,
    step,
    route,
  ) => {
    if (!read && !write) {
      handleOpenSnackbar(step);
      return;
    }

    if (linking) {
      if (!validateOfficeAndGroup(prosecutorOfficeGroup, prosecutorOffice)) {
        toast.warn(
          `Você não tem permissão a promotoria ${prosecutorOffice.name} no grupo ofício ${prosecutorOfficeGroup.name}.`,
        );
        return;
      }
    }
    history.push(route);
  };

  const validateTo = (step, inquiry) => {
    if (inquiry.inquiry.isConfidential && inquiry.step !== STEPS_INQUIRY.DEVOLVIDO) {
      if (isPM) {
        setSelectedInquiry(inquiry.inquiry.id);
        setOpenModalAddFile(true);
        return;
      }
    }

    if (
      !hasAccessToConfidentialInquiry({
        isConfidential: inquiry.inquiry.isConfidential,
        hasPermissionToConfidentialInquiries: permissions.confidentialInquiry?.read,
        prosecutorId: inquiry.inquiry.prosecutor?.id,
        advisorProsecutorId: advisorProsecutor?.id,
        loggedUserId: userId,
      })
    ) {
      toast.warn('Você não tem permissão para acessar inquéritos confidenciais');
      return;
    }

    if (isPM) {
      history.push(`/pm/detalhe/${inquiry.id}`);
      return;
    }

    switch (step) {
      case STEPS_INQUIRY.TRIAGEM:
        validatePermissions(
          permissions.inquiriesTriagem.read,
          permissions.inquiriesTriagem.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Triagem',
          `/detalhe/triagem/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.CUMPRIMENTO_ACORDO:
        validatePermissions(
          permissions.inquiriesAgreement.read,
          permissions.inquiriesAgreement.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Cumprimento de Acordo',
          `/detalhe/acordo/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO:
        validatePermissions(
          permissions.inquiriesForwardingTerm.read,
          permissions.inquiriesForwardingTerm.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Termo de Encaminhamento',
          `/detalhe/termo-de-encaminhamento/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.INTIMACAO:
        validatePermissions(
          permissions.inquiriesIntimacao.read,
          permissions.inquiriesIntimacao.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Intimação',
          `/detalhe/intimacao/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.AUDIENCIA:
        validatePermissions(
          permissions.inquiriesAudience.read,
          permissions.inquiriesAudience.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          `Audiência${enableHearingForJuvenileOffenders ? '/Oitiva' : ''}`,
          `/detalhe/audiencia/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.PESQUISA:
        validatePermissions(
          permissions.inquiriesDistribuicao.read,
          permissions.inquiriesDistribuicao.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Pesquisa e Distribuição',
          `/detalhe/pesquisa-e-distribuicao/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.ANALISE:
        validatePermissions(
          permissions.inquiriesAnalise.read,
          permissions.inquiriesAnalise.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Análise',
          `/detalhe/analise/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.TRAMITACAO_CONTROLE:
        validatePermissions(
          permissions.inquiriesTasks.read,
          permissions.inquiriesTasks.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Tramitação e Controle',
          `/detalhe/tramitacao/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.MIGRACAO:
        validatePermissions(
          permissions.inquiriesMigration.read,
          permissions.inquiriesMigration.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Migração',
          `/detalhe/migracao/${inquiry.id}`,
        );
        break;
      case STEPS_INQUIRY.ARQUIVAR:
        validatePermissions(
          permissions.inquiriesArquivo.read,
          permissions.inquiriesArquivo.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Arquivamento',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.DECLINAR:
        validatePermissions(
          permissions.inquiriesDeclinacao.read,
          permissions.inquiriesDeclinacao.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Declinação',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.DENUNCIA:
        validatePermissions(
          permissions.inquiriesDenuncia.read,
          permissions.inquiriesDenuncia.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Denúncia',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS:
        validatePermissions(
          permissions.inquiriesDiligencia.read,
          permissions.inquiriesDiligencia.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Despacho',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.DEVOLVIDO:
        validatePermissions(
          permissions.inquiriesDevolvido.read,
          permissions.inquiriesDevolvido.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Devolvido',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.PERSECUCAO:
        validatePermissions(
          permissions.inquiriesPersecucao.read,
          permissions.inquiriesPersecucao.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Persecução',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.REMETIDO_AO_JUDICIARIO:
        validatePermissions(
          permissions.inquiriesRemetidoAoJudiciario.read,
          permissions.inquiriesRemetidoAoJudiciario.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Remetido ao Judiciário',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.REMETIDO_AO_SISTEMA_INTERNO:
        validatePermissions(
          permissions.inquiriesRemetidoAoSistemaInterno.read,
          permissions.inquiriesRemetidoAoSistemaInterno.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Remetido ao Sistema Interno',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.REQUISITORIO:
        validatePermissions(
          permissions.inquiriesRequisitorio.read,
          permissions.inquiriesRequisitorio.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Requisitório',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      case STEPS_INQUIRY.NAO_IDENTIFICADO:
        validatePermissions(
          permissions.inquiries.read,
          permissions.inquiries.write,
          inquiry.inquiry?.prosecutorOfficeGroup,
          inquiry.inquiry?.prosecutorOffice,
          'Não identificado',
          `/detalhe/outras-fases/${inquiry.id}/${inquiry.step}`,
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.gridList} container spacing={1}>
        {inquiries.map(inquiry => (
          <Grid xs={12} sm={6} md={4} lg={3} xl={3} item key={inquiry.id}>
            <div onClick={() => (inquiry?.step ? validateTo(inquiry?.step, inquiry) : null)}>
              <CardInquiry backgroundColor={colorsSteps[inquiry.step]} inquiry={inquiry} />
            </div>
          </Grid>
        ))}
      </Grid>
      {openModalAddFile && (
        <ModalAddFileGeneric
          open={openModalAddFile}
          onClose={handleCloseModalAddFile}
          fileInBase64={fileInBase64}
          setFileInBase64={setFileInBase64}
          uploadObject={uploadObject}
          setUploadObject={setUploadObject}
        >
          <ButtonConsensus
            title="Anexar arquivo"
            backgroundColor={colors.greenLight}
            hovercolor={colors.greenLight}
            onClick={handleSendFileRequest}
            disabled={loadingSendFile}
          />
        </ModalAddFileGeneric>
      )}
    </div>
  );
};

export default ViewCardsInquiries;

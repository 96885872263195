export const NEW_INQUIRY = 'NEW_INQUIRY';

export const UPLOAD_FILE_INQUIRY = 'UPLOAD_FILE_INQUIRY';
export const CLEAR_UPLOAD_FILE_INQUIRY = 'CLEAR_UPLOAD_FILE_INQUIRY';
export const SET_INQUIRY_ID = 'SET_INQUIRY_ID';
export const CLEAR_INQUIRY_ID = 'CLEAR_INQUIRY_ID';
export const INQUIRY_FORM = 'INQUIRY_FORM';
export const CLEAR_INQUIRY_FORM = 'CLEAR_INQUIRY_FORM';
export const SAVE_INVESTIGATED = 'SAVE_INVESTIGATED';
export const ARRAY_INVESTIGATED = 'ARRAY_INVESTIGATED';
export const SET_POLICE_INQUIRY_NUMBER = 'SET_POLICE_INQUIRY_NUMBER';
export const CLEAR_POLICE_INQUIRY_NUMBER = 'CLEAR_POLICE_INQUIRY_NUMBER';
export const SET_CLASSES_INQUIRY = 'SET_CLASSES_INQUIRY';
export const CLEAR_CLASSES_INQUIRY = 'CLEAR_CLASSES_INQUIRY';
export const CLEAR_INVESTIGATED_FORM = 'CLEAR_INVESTIGATED_FORM';
export const SET_STATUS_INQUIRY = 'SET_STATUS_INQUIRY';
export const SET_RESPONSIBLE_INQUIRY = 'SET_RESPONSIBLE_INQUIRY';
export const SET_REQUEST_INTIMATIONS = 'SET_REQUEST_INTIMATIONS';

export const newInquiry = () => dispatch =>
  dispatch({
    type: NEW_INQUIRY,
  });

export const uploadFilesInquiry = upload => ({
  type: UPLOAD_FILE_INQUIRY,
  payload: upload,
});

export const clearUploadFilesInquiry = () => ({
  type: CLEAR_UPLOAD_FILE_INQUIRY,
  payload: null,
});

export const setInquiryId = id => ({
  type: SET_INQUIRY_ID,
  payload: id,
});

export const clearInquiryId = () => ({
  type: CLEAR_INQUIRY_ID,
  payload: null,
});

export const setInquiryForm = form => ({
  type: INQUIRY_FORM,
  payload: form,
});

export const clearInquiryForm = value => ({
  type: CLEAR_INQUIRY_FORM,
  payload: value,
});

export const saveInvestigated = value => ({
  type: SAVE_INVESTIGATED,
  payload: value,
});

export const persistInvestigated = array => ({
  type: ARRAY_INVESTIGATED,
  payload: array,
});

export const setInquiryNumber = number => ({
  type: SET_POLICE_INQUIRY_NUMBER,
  payload: number,
});

export const clearInquiryNumber = () => ({
  type: CLEAR_POLICE_INQUIRY_NUMBER,
});

export const setClassesInquiry = classe => ({
  type: SET_CLASSES_INQUIRY,
  payload: classe,
});

export const clearClassesInquiry = () => ({
  type: CLEAR_CLASSES_INQUIRY,
});

export const setStatusInquiryReducer = status => ({
  type: SET_STATUS_INQUIRY,
  payload: status,
});

export const setResponsibleInquiryReducer = responsible => ({
  type: SET_RESPONSIBLE_INQUIRY,
  payload: responsible,
});

//requestIntimations: boolean
export const setRequestIntimationsReducer = requestIntimations => ({
  type: SET_REQUEST_INTIMATIONS,
  payload: requestIntimations,
});

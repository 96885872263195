import moment from 'moment-timezone';

export const SAO_PAULO_TZ_NAME = 'America/Sao_Paulo';

/**
 * Format a date into a string, to this model: `YYYY-MM-DD`.
 * @param {Date} date - Date in isoString.
 */
export const formatDateToISO8601 = date => moment(date).format('YYYY-MM-DD');

/**
 * Format a date into a string, to this model: `HH:mm`.
 * @param {Date} date - Date in isoString.
 */
export const formatHourToISO8601 = date => moment(date).format('HH:mm');

/**
 * Format a date into a string, to this model: `2022-03-04T03:00:00.000Z`.
 * @param {Date} date - Date object or ISO 8601 string.
 */
export const formatDateToISOString = (date, keepOffset = false) =>
  moment(date).toISOString(keepOffset);

/**
 * Format a date into a string, to this model: `DD/MM/YYYY`.
 * @param {Date} date - Date object or ISO 8601 string.
 */
export const formatDateToBrazilian = date =>
  moment(date)
    .tz(SAO_PAULO_TZ_NAME)
    .format('DD/MM/YYYY');

/**
 * Format a date into a string, to this model: `MM/YYYY`.
 * @param {Date} date - Date object or ISO 8601 string.
 */
export const formatDateToBrazilianMonthYear = date => moment(date).format('MM/YYYY');

/**
 * Format a date into a string, to this model: `DD/MM/YYYY HH:mm`.
 * @param {Date} date - Date object or ISO 8601 string.
 */
export const formatDateAndHoursToBrazilian = date =>
  moment(date)
    .tz(SAO_PAULO_TZ_NAME)
    .format('DD/MM/YYYY HH:mm');

/**
 * Add 0h 0m 1s a date: `YYYY-MM-DD HH:mm:ss`.
 * @param {Date} date - Date object or ISO 8601 string.
 */
export const addHoursMinutesSecondsToStartDatesFilter = date =>
  moment(date)
    .hours(0)
    .minutes(0)
    .seconds(1);

/**
 * Add 23h 59m 59s a date: `YYYY-MM-DD HH:mm:ss`.
 * @param {Date} date - Date object or ISO 8601 string.
 */
export const addHoursMinutesSecondsToFinalDatesFilter = date =>
  moment(date)
    .hours(23)
    .minutes(59)
    .seconds(59);

/**
 * Add hour and minutes a date: `2022-03-04T03:00:00.000-03:00`.
 * @param {Date} date
 * @param {Hour} hour
 * @param {Min} min
 *  - Date object or ISO 8601 string.
 */
export const addHoursAndMinutesInDate = (date, hour, min) =>
  formatDateToISOString(
    moment(date)
      .hours(hour)
      .minutes(min),
    true,
  );

/**
 * Validated date: `boolean`
 * @param {Date} date - Date object or ISO 8601 string.
 */
export const isValidDate = date => moment(date).isValid();

export const isValidTime = time => {
  if (!time || time.length !== 5) {
    return false;
  }

  const hour = time.substring(0, 2);
  const min = time.substring(3, 5);

  return parseInt(hour) > 23 || parseInt(min) > 59 ? false : true;
};

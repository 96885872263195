import http from '../httpService';

const pjeIntegrationService = {
  getPjeIntegrationService: params => http.get('pje-integration', { params }),

  getJurisdictionOptionsService: params => http.get('pje-integration/jurisdictions', { params }),

  getPjeClassOptionsService: params => http.get(`pje-integration/classes`, { params }),

  getPjeSubjectOptionsService: params => http.get(`pje-integration/subjects`, { params }),

  getPjeProvinceOptions: params => http.get(`pje-integration/province`, { params }),

  getPreRegisteredDocumentsService: pjeIntegrationId =>
    http.get(`pje-integration/pre-register/docs/${pjeIntegrationId}`),

  getPjeManifestationDocuments: taskId =>
    http.get(`pje-integration/manifestation/docs/${taskId}`),

  signDocumentsService: params => http.post('pje-integration/sign', params),

  sendIntegrationService: (id, params) => http.post(`pje-integration/send-integration/${id}`, params),

  createpjeIntegration: params => http.post('pje-integration/pre-register', params),

  createPjeIntegrationFromPending: params => http.post('pje-integration/pre-register/pending', params),

  integrationService: pjeManifestationId => http.post(`pje-integration/manifestate/${pjeManifestationId}/retry`),

  cancelFileProcessService: id => http.post(`pje-integration/cancel-file-process/${id}`),

  getFileToSignService: id => http.get(`pje-integration/file-to-sign/${id}`),

  getFilesToSignService: pjeFileIds => http.post(`pje-integration/files-to-sign`, { pjeFileIds }),

  retryProcessing: pjeFileProcessId => http.post(`pje-integration/process-file/${pjeFileProcessId}/retry`),

  getAttachmentsFromInquiryService: inquiryId => http.get(`pje-integration/attachments/${inquiryId}`),

  movementTaskStartService: inquiryId => http.post(`pje-integration/${inquiryId}/movement-tasks/start`),
};

export default pjeIntegrationService;

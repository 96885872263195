import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Typography,
  Grid,
  IconButton,
  Button,
  ButtonGroup,
  Tooltip,
} from '@material-ui/core';
import ModalConfirm from './components/ModalConfirm';
import DvrIcon from '@material-ui/icons/Dvr';
import ModalAddFile from './components/ModalAddFile';
import ModalOthers from './components/ModalOthers';
import RefreshIcon from '@material-ui/icons/Refresh';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import { toast } from 'react-toastify';
import criminalAntecedentService from 'services/criminal-antecedent/criminalAntecedentService';
import { useUserTypes } from 'hooks/useUserTypes';
import ButtonConsensus from 'components/ButtonConsensus/ButtonConsensus';
import ModalSyncWithInternalSystem from './components/ModalSyncWithInternalSystem/ModalSyncWithInternalSystem';
import { formatDateToBrazilian } from 'utils/date-time.utils';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const CriminalAntecendents = props => {
  const { inquiry, refreshPage, otherStep, write, canEdit = false } = props;
  const { inquiryDisplayCriminalRecordItem } = useCustomerConfig();

  const { isPM } = useUserTypes();

  const [iitb, setIitb] = useState();
  const [jfpe, setJfpe] = useState();
  const [tjpe, setTjpe] = useState();
  const [titleTooltipIitb, setTitleTooltipIitb] = useState('Não solicitado');
  const [titleTooltipJfpe, setTitleTooltipJfpe] = useState('Não solicitado');
  const [titleTooltipTjpe, setTitleTooltipTjpe] = useState('Não solicitado');
  const [titleTooltipConsensus, setTitleTooltipConsensus] = useState('Não solicitado');
  const [consensus, setConsensus] = useState();
  const [colorIitb, setColorIitb] = useState('');
  const [colorJfpe, setColorJfpe] = useState('');
  const [colorTjpe, setColorTjpe] = useState('');
  const [colorConsensus, setColorConsensus] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [organ, setOrgan] = useState('');
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [openModalAddFile, setOpenModalAddFile] = useState(false);
  const [openModalOthers, setOpenModalOthers] = useState(false);
  const [openModalSyncWithInternalSystem, setOpenModalSyncWithInternalSystem] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [titleTooltipFieldsIitb, setTitleTooltipFieldsIitb] = useState('');
  const [titleTooltipFieldsJfpe, setTitleTooltipFieldsJfpe] = useState('');
  const [titleTooltipFieldsTjpe, setTitleTooltipFieldsTjpe] = useState('');
  const [titleTooltipFieldsConsensus, setTitleTooltipFieldsConsensus] = useState('');
  const [lastSearchIitb, setLastSearchIitb] = useState('');
  const [lastSearchJfpe, setLastSearchJfpe] = useState('');
  const [lastSearchTjpe, setlastSearchTjpe] = useState('');
  const [lastSearchConsensus, setlastSearchConsensus] = useState('');

  const useStyles = makeStyles(() => ({
    containerCriminal: {
      backgroundColor: '#ededed',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      boxShadow: 'none',
    },
    titulo: {
      color: '#00597b',
      fontWeight: 'bold',
      textAlign: 'left',
      fontSize: 20,
      marginBottom: 0,
      paddingLeft: 10,
      paddingTop: 5,
    },
    containerGrid: {
      justifyContent: 'center',
      alignContent: 'center',
      padding: 10,
      marginTop: 3,
    },
    gridRight: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      borderBottom: '1px solid #ccc',
      color: '#00597b',
      fontSize: 13,
      padding: 0,
      marginBottom: 5,
      '@media (max-width:320px)': {
        marginBottom: 0,
      },
      marginRight: 0,
      '@media (max-width:1280px)': {
        marginRight: 0,
      },
    },
    labelOutrosOrgaos: {
      display: 'flex',
      alignItems: 'flex-end',
      borderBottom: '1px solid #ccc',
      color: '#00597b',
      fontSize: 13,
      padding: 0,
      marginRight: 10,
      '@media (max-width:1280px)': {
        marginRight: 10,
      },
    },
    check: {
      color: colorIitb,
      marginLeft: 63,
      height: 30,
      width: 30,
      paddingTop: 1,
      '@media (max-width:320px)': {
        marginLeft: 63,
      },
    },
    check1: {
      color: colorJfpe,
      marginLeft: 55,
      height: 30,
      width: 30,
      paddingTop: 1,
      '@media (max-width:320px)': {
        marginLeft: 55,
      },
    },
    check2: {
      color: colorTjpe,
      marginLeft: 56,
      height: 30,
      width: 30,
      paddingTop: 1,
      '@media (max-width:320px)': {
        marginLeft: 56,
      },
    },
    check3: {
      color: colorConsensus,
      marginLeft: 11,
      height: 30,
      width: 30,
      paddingTop: 1,
      '@media (max-width:320px)': {
        marginLeft: 11,
      },
    },
    iconRight: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: '#00b1ac',
      color: '#ffffff',
      boxShadow: 'none',
      cursor: 'pointer',
    },
    containerCheck: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 5,
      '@media (max-width:320px)': {
        marginBottom: 5,
      },
    },
    marginCheckItens: {
      marginLeft: 5,
      '@media (max-width:320px)': {
        marginLeft: 5,
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    setIitb(inquiry.criminalAntecedents?.find(item => item.organName === 'IITB'));
    setJfpe(inquiry.criminalAntecedents?.find(item => item.organName === 'JFPE'));
    setTjpe(inquiry.criminalAntecedents?.find(item => item.organName === 'TJPE'));
    setConsensus(inquiry.criminalAntecedents?.find(item => item.organName === 'CONSENSUS'));

    if (!inquiry.criminalAntecedents?.length) {
      setDisabledBtn(true);
      return;
    }

    if (inquiry.criminalAntecedents?.find(item => item.organName === 'CUSTOM')) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [inquiry?.criminalAntecedents]);
  //vermelho: #e81224
  useEffect(() => {
    if (iitb !== undefined) {
      setLastSearchIitb(getLastSearch(iitb));
      if (iitb.status === 'loading') {
        setColorIitb('#f7d85e');
        setTitleTooltipIitb('Aguarando retorno');
      } else if (iitb.status === 'done') {
        iitb.filteredFields && setTitleTooltipFieldsIitb(getFields(iitb.filteredFields));
        if (iitb.isOk === true) {
          setColorIitb('#00b1ac');
          setTitleTooltipIitb('Finalizado');
        } else {
          setColorIitb('#e81224');
          setTitleTooltipIitb('Finalizado');
        }
      } else if (iitb.status === 'failed') {
        setColorIitb('#ff8500');
        setTitleTooltipIitb('Não houve retorno');
      }
    }
    if (jfpe !== undefined) {
      setLastSearchJfpe(getLastSearch(jfpe));
      if (jfpe.status === 'loading') {
        setColorJfpe('#f7d85e');
        setTitleTooltipJfpe('Aguarando retorno');
      } else if (jfpe.status === 'done') {
        jfpe.filteredFields && setTitleTooltipFieldsJfpe(getFields(jfpe.filteredFields));
        if (jfpe.isOk === true) {
          setColorJfpe('#00b1ac');
          setTitleTooltipJfpe('Finalizado');
        } else {
          setColorJfpe('#e81224');
          setTitleTooltipJfpe('Finalizado');
        }
      } else if (jfpe.status === 'failed') {
        setColorJfpe('#ff8500');
        setTitleTooltipJfpe('Não houve retorno');
      }
    }
    if (tjpe !== undefined) {
      setlastSearchTjpe(getLastSearch(tjpe));
      if (tjpe.status === 'loading') {
        setColorTjpe('#f7d85e');
        setTitleTooltipTjpe('Aguarando retorno');
      } else if (tjpe.status === 'done') {
        tjpe.filteredFields && setTitleTooltipFieldsTjpe(getFields(tjpe.filteredFields));
        if (tjpe.isOk === true) {
          setColorTjpe('#00b1ac');
          setTitleTooltipTjpe('Finalizado');
        } else {
          setColorTjpe('#e81224');
          setTitleTooltipTjpe('Finalizado');
        }
      } else if (tjpe.status === 'failed') {
        setColorTjpe('#ff8500');
        setTitleTooltipTjpe('Não houve retorno');
      }
    }
    if (consensus !== undefined) {
      setlastSearchConsensus(getLastSearch(consensus));
      if (consensus.status === 'loading') {
        setColorConsensus('#f7d85e');
        setTitleTooltipConsensus('Aguarando retorno');
      } else if (consensus.status === 'done') {
        consensus.filteredFields &&
          setTitleTooltipFieldsConsensus(getFields(consensus.filteredFields));
        if (consensus.isOk === true) {
          setColorConsensus('#00b1ac');
          setTitleTooltipConsensus('Finalizado');
        } else {
          setColorConsensus('#e81224');
          setTitleTooltipConsensus('Finalizado');
        }
      } else if (consensus.status === 'failed') {
        setColorConsensus('#ff8500');
        setTitleTooltipConsensus('Não houve retorno');
      }
    }
  }, [iitb, jfpe, tjpe, consensus]);

  const getFields = filteredFields => {
    const fields = filteredFields.join(', ');
    return 'Campos utilizados: ' + fields;
  };

  const getLastSearch = antecedent => {
    return `Última consulta realizada: ${formatDateToBrazilian(antecedent?.updatedAt)}. `;
  };

  const handleGoToCertificate = () => {
    const url = `/certidao/${inquiry.id}`;
    window.open(process.env?.PUBLIC_URL ? process.env.PUBLIC_URL + url : url, '_blank');
  };

  const handleCloseModalConfirm = () => {
    setConfirm(false);
    setShowModalConfirm(false);
    setOrgan('');
  };

  const handleOpenModalConfirm = organ => {
    setOrgan(organ);
    setShowModalConfirm(true);
    setConfirm(true);
  };

  const handleCriminalAntecedentsRetry = () => {
    criminalAntecedentsRetry(inquiry.id, organ);
  };

  const criminalAntecedentsRetry = (inquiryInvestigatedId, organName) => {
    let params = {
      inquiryInvestigatedId,
      organName,
    };

    criminalAntecedentService.criminalAntecedentsRetryService(params).then(() => {
      toast.success('Solicitação realizada com sucesso!');
      refreshPage();
    });
  };

  const handleCloseModalAddFile = () => {
    setOpenModalAddFile(false);
  };

  const handleCloseModalSyncWithInternalSystem = () => {
    setOpenModalSyncWithInternalSystem(false);
  };

  const handleCloseModalOthers = () => {
    setOpenModalOthers(false);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <Card className={classes.containerCriminal}>
      <Typography className={classes.titulo}>Antecedentes Criminais</Typography>
      <Grid container className={classes.containerGrid} spacing={2}>
        <Grid item container xs={12} sm={8} md={8} lg={8} xl={8}>
          {inquiryDisplayCriminalRecordItem && (
            <Grid item container style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className={classes.label}>IITB:</Typography>
              {(otherStep || isPM) && !canEdit ? (
                <>
                  {colorIitb === '#00b1ac' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipIitb} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                      <Tooltip title={titleTooltipFieldsIitb}>
                        <SearchIcon className={classes.marginCheckItens} />
                      </Tooltip>
                    </div>
                  ) : colorIitb === '#e81224' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipIitb} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                      <Tooltip title={titleTooltipFieldsIitb}>
                        <SearchIcon className={classes.marginCheckItens} />
                      </Tooltip>
                    </div>
                  ) : colorIitb === '#f7d85e' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipIitb} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check} />
                      </Tooltip>
                      <HourglassEmptyIcon className={classes.marginCheckItens} />
                    </div>
                  ) : (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipIitb} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                    </div>
                  )}
                </>
              ) : colorIitb === '#00b1ac' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipIitb} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check} />
                  </Tooltip>
                  <CheckIcon className={classes.marginCheckItens} />
                  <Tooltip title={titleTooltipFieldsIitb}>
                    <SearchIcon className={classes.marginCheckItens} />
                  </Tooltip>
                  <Tooltip
                    title={lastSearchIitb.concat('Clique aqui para atualizar Status do IITB')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon
                      onClick={() =>
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('IITB')
                      }
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : colorIitb === '#ff8500' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipIitb} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check} />
                  </Tooltip>
                  {/* <CheckIcon className={classes.marginCheckItens} /> */}
                  <CloseIcon className={classes.marginCheckItens} />
                  <Tooltip
                    title={lastSearchIitb.concat('Clique aqui para atualizar Status do IITB')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon /*className={classes.check*/
                      onClick={() =>
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('IITB')
                      }
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : colorIitb === '#f7d85e' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipIitb} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check} />
                  </Tooltip>
                  <HourglassEmptyIcon className={classes.marginCheckItens} />
                </div>
              ) : colorIitb === '#e81224' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipIitb} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check} />
                  </Tooltip>
                  <CheckIcon className={classes.marginCheckItens} />
                  <Tooltip title={titleTooltipFieldsIitb}>
                    <SearchIcon className={classes.marginCheckItens} />
                  </Tooltip>
                  <Tooltip
                    title={lastSearchIitb.concat('Clique aqui para atualizar Status do IITB')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon
                      onClick={() =>
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('IITB')
                      }
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipIitb} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check} />
                  </Tooltip>
                  <CloseIcon className={classes.marginCheckItens} />
                  <Tooltip
                    title={lastSearchIitb.concat('Clique aqui para atualizar Status do IITB')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon /*className={classes.check*/
                      onClick={() => {
                        if (isPM) {
                          return;
                        }
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('IITB');
                      }}
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              )}
            </Grid>
          )}

          {inquiryDisplayCriminalRecordItem && (
            <Grid item container>
              <Typography className={classes.label}>JFPE:</Typography>
              {(otherStep || isPM) && !canEdit ? (
                <>
                  {colorJfpe === '#00b1ac' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipJfpe} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check1} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                      <Tooltip title={titleTooltipFieldsJfpe}>
                        <SearchIcon className={classes.marginCheckItens} />
                      </Tooltip>
                    </div>
                  ) : colorJfpe === '#e81224' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipJfpe} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check1} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                      <Tooltip title={titleTooltipFieldsJfpe}>
                        <SearchIcon className={classes.marginCheckItens} />
                      </Tooltip>
                    </div>
                  ) : colorJfpe === '#f7d85e' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipJfpe} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check1} />
                      </Tooltip>
                      <HourglassEmptyIcon className={classes.marginCheckItens} />
                    </div>
                  ) : (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipJfpe} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check1} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                    </div>
                  )}
                </>
              ) : colorJfpe === '#00b1ac' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipJfpe} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check1} />
                  </Tooltip>
                  <CheckIcon className={classes.marginCheckItens} />
                  <Tooltip title={titleTooltipFieldsJfpe}>
                    <SearchIcon className={classes.marginCheckItens} />
                  </Tooltip>
                  <Tooltip
                    title={lastSearchJfpe.concat('Clique aqui para atualizar Status do JFPE')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon
                      onClick={() =>
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('JFPE')
                      }
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : colorJfpe === '#ff8500' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipJfpe} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check1} />
                  </Tooltip>
                  {/* <CheckIcon className={classes.marginCheckItens} /> */}
                  <CloseIcon className={classes.marginCheckItens} />
                  <Tooltip
                    title={lastSearchJfpe.concat('Clique aqui para atualizar Status do JFPE')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon /*className={classes.check1}*/
                      onClick={() =>
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('JFPE')
                      }
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : colorJfpe === '#f7d85e' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipJfpe} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check1} />
                  </Tooltip>
                  <HourglassEmptyIcon className={classes.marginCheckItens} />
                </div>
              ) : colorJfpe === '#e81224' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipJfpe} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check1} />
                  </Tooltip>
                  <CheckIcon className={classes.marginCheckItens} />
                  <Tooltip title={titleTooltipFieldsJfpe}>
                    <SearchIcon className={classes.marginCheckItens} />
                  </Tooltip>
                  <Tooltip
                    title={lastSearchJfpe.concat('Clique aqui para atualizar Status do JFPE')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon
                      onClick={() =>
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('JFPE')
                      }
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div className={classes.containerCheck}>
                  <FiberManualRecordIcon className={classes.check1} />
                  <CloseIcon className={classes.marginCheckItens} />
                  <Tooltip
                    title={lastSearchJfpe.concat('Clique aqui para atualizar Status do JFPE')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon /*className={classes.check1}*/
                      onClick={() => {
                        if (isPM) {
                          return;
                        }
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('JFPE');
                      }}
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              )}
            </Grid>
          )}

          {inquiryDisplayCriminalRecordItem && (
            <Grid item container>
              <Typography className={classes.label}>TJPE:</Typography>
              {(otherStep || isPM) && !canEdit ? (
                <>
                  {colorTjpe === '#00b1ac' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipTjpe} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check2} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                      <Tooltip title={titleTooltipFieldsTjpe}>
                        <SearchIcon className={classes.marginCheckItens} />
                      </Tooltip>
                    </div>
                  ) : colorTjpe === '#e81224' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipTjpe} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check2} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                      <Tooltip title={titleTooltipFieldsTjpe}>
                        <SearchIcon className={classes.marginCheckItens} />
                      </Tooltip>
                    </div>
                  ) : colorTjpe === '#f7d85e' ? (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipTjpe} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check2} />
                      </Tooltip>
                      <HourglassEmptyIcon className={classes.marginCheckItens} />
                    </div>
                  ) : (
                    <div className={classes.containerCheck}>
                      <Tooltip title={titleTooltipTjpe} arrow placement="top">
                        <FiberManualRecordIcon className={classes.check2} />
                      </Tooltip>
                      <CheckIcon className={classes.marginCheckItens} />
                    </div>
                  )}
                </>
              ) : colorTjpe === '#00b1ac' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipTjpe} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check2} />
                  </Tooltip>
                  <CheckIcon className={classes.marginCheckItens} />
                  <Tooltip title={titleTooltipFieldsTjpe}>
                    <SearchIcon className={classes.marginCheckItens} />
                  </Tooltip>
                  <Tooltip
                    title={lastSearchTjpe.concat('Clique aqui para atualizar Status do TJPE')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon
                      onClick={() => {
                        if (isPM) {
                          return;
                        }
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('TJPE');
                      }}
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : colorTjpe === '#ff8500' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipTjpe} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check2} />
                  </Tooltip>
                  {/* <CheckIcon className={classes.marginCheckItens} /> */}
                  <CloseIcon className={classes.marginCheckItens} />
                  <Tooltip
                    title={lastSearchTjpe.concat('Clique aqui para atualizar Status do TJPE')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon /*className={classes.check2}*/
                      onClick={() => {
                        if (isPM) {
                          return;
                        }
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('TJPE');
                      }}
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : colorTjpe === '#f7d85e' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipTjpe} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check2} />
                  </Tooltip>
                  <HourglassEmptyIcon className={classes.marginCheckItens} />
                </div>
              ) : colorTjpe === '#e81224' ? (
                <div className={classes.containerCheck}>
                  <Tooltip title={titleTooltipTjpe} arrow placement="top">
                    <FiberManualRecordIcon className={classes.check2} />
                  </Tooltip>
                  <CheckIcon className={classes.marginCheckItens} />
                  <Tooltip title={titleTooltipFieldsTjpe}>
                    <SearchIcon className={classes.marginCheckItens} />
                  </Tooltip>
                  <Tooltip
                    title={lastSearchTjpe.concat('Clique aqui para atualizar Status do TJPE')}
                    arrow
                    placement="right"
                  >
                    <RefreshIcon
                      onClick={() => {
                        if (isPM) {
                          return;
                        }
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('TJPE');
                      }}
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              ) : (
                <div className={classes.containerCheck}>
                  <FiberManualRecordIcon className={classes.check2} />
                  <CloseIcon className={classes.marginCheckItens} />
                  <Tooltip
                    title="Clique aqui para atualizar Status do TJPE"
                    arrow
                    placement="right"
                  >
                    <RefreshIcon /*className={classes.check2}*/
                      onClick={() => {
                        if (isPM) {
                          return;
                        }
                        !write ? handleOpenSnackbar() : handleOpenModalConfirm('TJPE');
                      }}
                      style={{ cursor: 'pointer', marginLeft: 5 }}
                    />
                  </Tooltip>
                </div>
              )}
            </Grid>
          )}

          <Grid item container>
            <Typography className={classes.label}>CONSENSUS:</Typography>
            {(otherStep || isPM) && !canEdit ? (
              <>
                {colorConsensus === '#00b1ac' ? (
                  <div className={classes.containerCheck}>
                    <FiberManualRecordIcon className={classes.check3} />
                    <CheckIcon className={classes.marginCheckItens} />
                    <Tooltip title={titleTooltipFieldsConsensus}>
                      <SearchIcon className={classes.marginCheckItens} />
                    </Tooltip>
                  </div>
                ) : colorIitb === '#e81224' ? (
                  <div className={classes.containerCheck}>
                    <FiberManualRecordIcon className={classes.check3} />
                    <CheckIcon className={classes.marginCheckItens} />
                    <Tooltip title={titleTooltipFieldsConsensus}>
                      <SearchIcon className={classes.marginCheckItens} />
                    </Tooltip>
                  </div>
                ) : colorIitb === '#f7d85e' ? (
                  <div className={classes.containerCheck}>
                    <FiberManualRecordIcon className={classes.check3} />
                    <HourglassEmptyIcon className={classes.marginCheckItens} />
                  </div>
                ) : (
                  <div className={classes.containerCheck}>
                    <FiberManualRecordIcon className={classes.check3} />
                    <CheckIcon className={classes.marginCheckItens} />
                  </div>
                )}
              </>
            ) : colorConsensus === '#00b1ac' ? (
              <div className={classes.containerCheck}>
                <Tooltip title={titleTooltipConsensus} arrow placement="top">
                  <FiberManualRecordIcon className={classes.check3} />
                </Tooltip>
                <CheckIcon className={classes.marginCheckItens} />
                <Tooltip title={titleTooltipFieldsConsensus}>
                  <SearchIcon className={classes.marginCheckItens} />
                </Tooltip>
                <Tooltip
                  title={lastSearchConsensus.concat(
                    'Clique aqui para atualizar Status do Consensus',
                  )}
                  arrow
                  placement="right"
                >
                  <RefreshIcon
                    onClick={() =>
                      !write ? handleOpenSnackbar() : handleOpenModalConfirm('CONSENSUS')
                    }
                    style={{ cursor: 'pointer', marginLeft: 5 }}
                  />
                </Tooltip>
              </div>
            ) : colorConsensus === '#ff8500' ? (
              <div className={classes.containerCheck}>
                <Tooltip title={titleTooltipConsensus} arrow placement="top">
                  <FiberManualRecordIcon className={classes.check3} />
                </Tooltip>
                {/* <CheckIcon className={classes.marginCheckItens} /> */}
                <CloseIcon className={classes.marginCheckItens} />
                <Tooltip
                  title={lastSearchConsensus.concat(
                    'Clique aqui para atualizar Status do Consensus',
                  )}
                  arrow
                  placement="right"
                >
                  <RefreshIcon /*className={classes.check2}*/
                    onClick={() => {
                      if (isPM) {
                        return;
                      }
                      !write ? handleOpenSnackbar() : handleOpenModalConfirm('CONSENSUS');
                    }}
                    style={{ cursor: 'pointer', marginLeft: 5 }}
                  />
                </Tooltip>
              </div>
            ) : colorConsensus === '#f7d85e' ? (
              <div className={classes.containerCheck}>
                <Tooltip title={titleTooltipConsensus} arrow placement="top">
                  <FiberManualRecordIcon className={classes.check3} />
                </Tooltip>
                <HourglassEmptyIcon className={classes.marginCheckItens} />
              </div>
            ) : colorConsensus === '#e81224' ? (
              <div className={classes.containerCheck}>
                <Tooltip title={titleTooltipConsensus} arrow placement="top">
                  <FiberManualRecordIcon className={classes.check3} />
                </Tooltip>
                <CheckIcon className={classes.marginCheckItens} />
                <Tooltip title={titleTooltipFieldsConsensus}>
                  <SearchIcon className={classes.marginCheckItens} />
                </Tooltip>
                <Tooltip
                  title={lastSearchConsensus.concat(
                    'Clique aqui para atualizar Status do Consensus',
                  )}
                  arrow
                  placement="right"
                >
                  <RefreshIcon
                    onClick={() =>
                      !write ? handleOpenSnackbar() : handleOpenModalConfirm('CONSENSUS')
                    }
                    style={{ cursor: 'pointer', marginLeft: 5 }}
                  />
                </Tooltip>
              </div>
            ) : (
              <div className={classes.containerCheck}>
                <FiberManualRecordIcon className={classes.check3} />
                <CloseIcon className={classes.marginCheckItens} />
                <Tooltip
                  title={lastSearchConsensus.concat(
                    'Clique aqui para atualizar Status do Consensus',
                  )}
                  arrow
                  placement="right"
                >
                  <RefreshIcon /*className={classes.check2}*/
                    onClick={() => {
                      if (isPM) {
                        return;
                      }
                      !write ? handleOpenSnackbar() : handleOpenModalConfirm('CONSENSUS');
                    }}
                    style={{ cursor: 'pointer', marginLeft: 5 }}
                  />
                </Tooltip>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classes.gridRight}>
          {(otherStep || isPM) && !canEdit ? (
            <IconButton disabled style={{ backgroundColor: '#d7d7d7' }}>
              <DvrIcon style={{ color: '#ffffff' }} />
            </IconButton>
          ) : (
            <Tooltip title="Acessar antecedentes criminais" arrow placement="top">
              <IconButton style={{ backgroundColor: '#00b1ac' }} onClick={handleGoToCertificate}>
                <DvrIcon style={{ color: '#ededed' }} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          style={{ paddingLeft: 10, display: 'flex' }}
        >
          <Typography className={classes.labelOutrosOrgaos}>Outros Órgãos:</Typography>
          <ButtonGroup
            style={{ backgroundColor: '#00b1ac' }}
            size="small"
            aria-label="small outlined button group"
          >
            {(otherStep || isPM) && !canEdit ? (
              // <Button
              //     style={{ color: '#ffffff', backgroundColor: '#d7d7d7' }}
              // >
              //     Adicionar
              // </Button>
              <></>
            ) : (
              <Button
                style={{ color: '#ffffff' }}
                onClick={() => (!write ? handleOpenSnackbar() : setOpenModalAddFile(true))}
              >
                Adicionar
              </Button>
            )}
            {disabledBtn ? (
              <Tooltip title="Não há arquivos de outros órgãos" arrow placement="top">
                <Button style={{ color: '#ffffff', backgroundColor: '#d7d7d7' }}>Visualizar</Button>
              </Tooltip>
            ) : (
              <Button
                style={{ color: '#ffffff', backgroundColor: 'red' }}
                onClick={() => setOpenModalOthers(true)}
              >
                Visualizar
              </Button>
            )}
          </ButtonGroup>
        </Grid>
        {inquiryDisplayCriminalRecordItem && (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            style={{ paddingTop: 15, alignItems: 'center', display: 'flex' }}
          >
            <ButtonConsensus
              title="Enviar para o sistema interno"
              color="white"
              disabled={!otherStep || !isPM || canEdit}
              size="small"
              backgroundColor="#00b1ac"
              hovercolor="#00b1ac"
              onClick={() =>
                (otherStep || isPM) && !canEdit
                  ? !write
                    ? handleOpenSnackbar()
                    : setOpenModalSyncWithInternalSystem(true)
                  : null
              }
            />
          </Grid>
        )}
      </Grid>
      {openModalSyncWithInternalSystem && (
        <ModalSyncWithInternalSystem
          open={openModalSyncWithInternalSystem}
          onClose={handleCloseModalSyncWithInternalSystem}
          sendRequest={handleCriminalAntecedentsRetry}
          inquiryId={inquiry.id}
        />
      )}
      {showModalConfirm && (
        <ModalConfirm
          open={confirm}
          onClose={handleCloseModalConfirm}
          sendRequest={handleCriminalAntecedentsRetry}
          organ={organ}
        />
      )}
      {openModalAddFile && (
        <ModalAddFile
          open={openModalAddFile}
          onClose={handleCloseModalAddFile}
          inquiry={inquiry}
          refreshPage={refreshPage}
        />
      )}
      {openModalOthers && (
        <ModalOthers open={openModalOthers} onClose={handleCloseModalOthers} inquiry={inquiry} />
      )}
    </Card>
  );
};

export default CriminalAntecendents;

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { Viewer, Page, TabPanel, ButtonConsensus } from 'components';
import { AiOutlineFileWord, AiOutlineFilePdf, AiOutlineFile } from 'react-icons/ai';
import { FileTypes } from 'constants/file-types';
import { colors } from 'utils/colorsSteps';
import TermsOfUseService from 'services/terms-of-use/termsOfUseService';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import history from 'utils/history';
import { login } from 'actions';
import { USER_TYPE } from 'constants/inquirys/userType';

const useStyles = makeStyles(() => ({
  '.content > .root': {
    overflowY: 'unset',
  },
  root: {
    flexGrow: 1,
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  fileViewer: {
    zIndex: 3,
    width: 300,
    minWidth: 300,
    flex: '0 0 auto',
  },
  contentView: {
    flex: '1 1 auto',
  },
  pageTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    maxWidth: '100%',
    height: 60,
    color: '#00597b',
    fontSize: 25,
  },
  tabPanel: {
    height: 750,
  },
  tabBar: {
    backgroundColor: '#fff',
    '& button': {
      minWidth: '80px',
      maxWidth: '100%',
      '& svg': {
        fontSize: '1.5em',
      },
    },
  },
  convertMessage: {
    padding: '25px 25px 25px 25px',
    margin: 'auto',
  },
}));

const FileViewer = props => {
  const loggedUser = useSelector(state => state.logedUser);
  const [value, setValue] = useState(0);
  const [showProgress, setShowProgress] = useState(false);
  const [file, setFile] = useState({
    name: '',
    url: '',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setFile({ name: 'Termos de uso.pdf', url: loggedUser.termsOfUse.path });
  }, []);

  const classes = useStyles();

  const signTerm = () => {
    setShowProgress(true);
    TermsOfUseService.signTermsOfUseService(loggedUser.userId, loggedUser.termsOfUse.id).then(
      () => {
        toast.success('Termo assinado com sucesso!');
        delete loggedUser.termsOfUse;
        dispatch(login(loggedUser));
        setShowProgress(false);
        if (loggedUser.type === USER_TYPE.AssociationRepresentative) {
          history.push('/company/dashboard');
        } else if (loggedUser.type === USER_TYPE.InquiryRegistrationUser) {
          history.push('/pm/dashboard');
        } else {
          history.push('/Dashboard');
        }
      },
    );
  };

  const getFileType = file => {
    return file.name.match(/\.([^.]+)$/)[1].toLowerCase();
  };

  const getFileTypeIcon = file => {
    const fileType = getFileType(file);
    switch (fileType) {
      case FileTypes.DOC:
      case FileTypes.DOCX:
        return <AiOutlineFileWord />;
      case FileTypes.PDF:
        return <AiOutlineFilePdf />;
      default:
        return <AiOutlineFile />;
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page className={classes.root} title="Termos de Uso">
      <Grid container>
        <div className={classes.contentView}>
          <Typography className={classes.pageTitle}>Termos de Uso</Typography>
          {file.name && (
            <>
              <AppBar position="static" className={classes.tabBar}>
                <Tabs value={value} onChange={handleChange} textColor="primary">
                  <Tab valeu={0} icon={getFileTypeIcon(file)} />
                  <Tab disabled label={file.name} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} className={classes.tabPanel}>
                <Viewer url={file.url} type={getFileType(file)} />
              </TabPanel>
            </>
          )}
          <Box className={classes.convertMessage}>
            <ButtonConsensus
              title="Declaro que li e estou de acordo com os Termos de Uso do Consensus"
              backgroundColor={colors.primary}
              hovercolor={colors.primary}
              size="large"
              onClick={() => signTerm()}
              disabled={showProgress}
            />
            {showProgress && (
              <CircularProgress style={{ height: 25, width: 25, color: `${colors.primary}` }} />
            )}
          </Box>
        </div>
      </Grid>
    </Page>
  );
};

export default FileViewer;

const genericInquiry = '99999.9999.99999/9999-9.9';
const pcpeGenericInquiry = '9999.9999.999999-99';
const npuGenericInquiry = '9999999-99.9999.9.99.9999';
const federalInquiry = '99999999999';
const noMaskInquiry = '********************';
const pmInquiry = '9999.9.9.99999999999999';
const npuMask = '9999999-99.9999.9.99.9999';
const policeReportMask = '99E9999999999';
const internalSystemNumberNoMask = '9999999999999999999';
const cpfMask = '999.999.999-99';
const cnpjMask = '99.999.999/9999-99';
const phoneMask = '(99)99999-9999';
const seiMask = '99.99.9999.9999999/9999-99';

export {
  genericInquiry,
  pcpeGenericInquiry,
  npuGenericInquiry,
  federalInquiry,
  noMaskInquiry,
  pmInquiry,
  npuMask,
  policeReportMask,
  internalSystemNumberNoMask,
  cpfMask,
  cnpjMask,
  phoneMask,
  seiMask,
};

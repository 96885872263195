import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';

const useStyles = makeStyles(() => ({
  root: {
    color: '#ffffff',
    fontSize: 13,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  message: {
    marginLeft: 10,
    fontWeight: 'bold',
    color: '#ffffff',
    fontSize: 18,
  },
}));

const GridWrapHead = props => {
  const {
    step,
    totalTriagem,
    totalPesquisa,
    totalAnalise,
    totalIntimacao,
    totalInqueritosEncaminhamentos,
    totalAudiencia,
  } = props;

  const classes = useStyles();

  const WrapHead = props => {
    const { total } = props;
    return (
      <Box className={classes.root}>
        Total de procedimentos:
        <Typography className={classes.message}>{total}</Typography>
      </Box>
    );
  };

  return (
    <>
      {step === STEPS_INQUIRY.TRIAGEM ? (
        <WrapHead total={totalTriagem} />
      ) : step === STEPS_INQUIRY.PESQUISA ? (
        <WrapHead total={totalPesquisa} />
      ) : step === STEPS_INQUIRY.INTIMACAO ? (
        <WrapHead total={totalIntimacao} />
      ) : step === STEPS_INQUIRY.ANALISE ? (
        <WrapHead total={totalAnalise} />
      ) : step === STEPS_INQUIRY.TRAMITACAO_CONTROLE ? (
        <WrapHead total={totalInqueritosEncaminhamentos} />
      ) : step === STEPS_INQUIRY.AUDIENCIA ? (
        <WrapHead total={totalAudiencia} />
      ) : (
        <></>
      )}
    </>
  );
};

export default GridWrapHead;

import http from '../httpService';

const OrganService = {
  getDelegaciasService: params => http.get('organs', { params }),

  registerDelegaciasService: params => http.post('organs', params),

  updateDelegaciasService: (organId, params) => http.patch(`organs/${organId}`, params),

  deleteDelegaciasService: organId => http.delete(`organs/${organId}`),

  organUpdateEmailService: (id, params) => http.put(`organs/update-email?id=${id}`, params),
};

export default OrganService;

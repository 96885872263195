export const SET_PAGINATION_REGISTER_USER = 'SET_PAGINATION_REGISTER_USER';
export const CLEAR_PAGINATION_REGISTER_USER = 'CLEAR_PAGINATION_REGISTER_USER';

export const setPaginationRegisterUser = payload => ({
  type: SET_PAGINATION_REGISTER_USER,
  payload,
});

export const clearPaginationRegisterUser = payload => ({
  type: CLEAR_PAGINATION_REGISTER_USER,
  payload,
});

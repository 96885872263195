import React from 'react';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  Grid,
  Typography,
  Box,
  Paper,
  Divider,
} from '@material-ui/core';
import { HeaderModal } from 'components';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { formatDateToBrazilian } from 'utils/date-time.utils';
import Label from 'components/Label';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      flex: 0.8,
    },
  },
  containerTextArea: {
    margin: theme.spacing(2, 0),
  },
  action: {
    [theme.breakpoints.down('xs')]: {
      flex: 1,
      alignItems: 'flex-start',
      padding: theme.spacing(0, 2.5),
    },
  },
}));

const DetailReturnationInquiry = props => {
  const { open, onClose, returnation, ...rest } = props;

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      {...rest}
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
      aria-labelledby="modal-give-back-inquiry"
    >
      <HeaderModal onClose={handleClose}>Detalhes da Devolução</HeaderModal>
      <DialogContent className={classes.container}>
        <Box mb={1}>
          <Typography variant="h5" component="p">
            Data da devolução: {formatDateToBrazilian(returnation.createdAt) || 'não informado'}
          </Typography>
        </Box>
        <Paper elevation={3} component={Box} p={2} mb={1} style={{ backgroundColor: '#f8f8f8' }}>
          <Box>
            <Typography variant="h5" component="p">
              Informações do e-mail
            </Typography>
            <Box my={1}>
              <Divider variant="fullWidth" />
            </Box>
          </Box>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Typography variant="h5" component="p">
                Assunto do e-mail: {returnation?.emailSubject || 'não informado'}
              </Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <Typography variant="h5" component="p">
                Destinatário:{' '}
                {returnation?.emailTo ? (
                  <Label color={colors.primary} variant="outlined">
                    {returnation?.emailTo}
                  </Label>
                ) : (
                  'não informado'
                )}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography variant="h5" component="p">
                Corpo do e-mail: {returnation?.emailText || 'não informado'}
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box display="flex" alignItems="center" flexWrap="wrap">
                {returnation.emailCc?.length > 0 && (
                  <Typography variant="h5" component="p">
                    Copiados no e-mail:
                  </Typography>
                )}
                {returnation.emailCc?.map(cc => (
                  <Box p={0.2} key={cc} display="flex" alignItems="center">
                    <Label color={colors.primary} variant="outlined">
                      {cc}
                    </Label>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default DetailReturnationInquiry;

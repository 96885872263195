import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { makeStyles, useTheme } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  body: {
    padding: theme.spacing(2),
  },
}));

const ModalConfirmChangePersonType = props => {
  const {
    open,
    onClose,
    onSave,
    justification,
    setJustification,
    isChangeToInvestigated = false,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  const handleChange = event => {
    event.persist();
    setJustification(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullScreen={fullScreen}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        Deseja alterar o tipo da pessoa para{' '}
        {isChangeToInvestigated ? ' investigado?' : ' vítima e remover o investigado?'}
      </DialogTitle>

      <DialogContent className={classes.body}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <TextField
            multiline
            variant="outlined"
            fullWidth
            minRows={6}
            maxRows={10}
            label="Justificativa"
            name="justification"
            value={justification}
            onChange={handleChange}
            size="small"
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <ButtonConsensus
          title="FECHAR"
          size="medium"
          backgroundColor="#FFFFFF"
          hovercolor={colors.greySecundary}
          color={colors.greyShadow}
          onClick={onClose}
        />

        <ButtonConsensus
          title="CONFIRMAR"
          size="medium"
          backgroundColor={colors.primary}
          hoverColor={colors.primary}
          onClick={event => onSave(event)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ModalConfirmChangePersonType;

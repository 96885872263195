import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Button, useMediaQuery } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { colors } from 'utils/colorsSteps';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: colors.navBar,
    '&:hover': {
      backgroundColor: colors.navBar,
    },
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.3),
    },
  },
  root2: {
    backgroundColor: colors.navBar,
    '&:hover': {
      backgroundColor: colors.navBar,
    },
    color: '#fff',
    borderRadius: 25,
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  icon: {
    color: '#fff',
  },
  container: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0.3),
      width: '100%',
    },
  },
}));

const ButtonBack = props => {
  const { onClick, className, ...rest } = props;

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [fullBtn, setFullBtn] = useState(false);

  useEffect(() => {
    if (fullScreen) {
      setFullBtn(true);
    } else {
      setFullBtn(false);
    }
  }, [fullScreen]);

  if (fullBtn) {
    return (
      <div className={classes.container}>
        <Button
          {...rest}
          className={classes.root2}
          variant="contained"
          size="large"
          onClick={onClick}
        >
          <ArrowLeftIcon className={classes.icon} />
        </Button>
      </div>
    );
  } else {
    return (
      <IconButton
        {...rest}
        className={clsx(classes.root, className)}
        onClick={onClick}
        fontSize="small"
      >
        <ArrowLeftIcon className={classes.icon} fontSize="large" />
      </IconButton>
    );
  }
};

ButtonBack.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonBack;

/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Box, Button, IconButton, Toolbar, Hidden, colors } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';
import MenuIcon from '@material-ui/icons/Menu';

import useRouter from 'utils/useRouter';
import { NotificationsPopover } from 'components';
import { logoutPm } from 'actions';

import './TopBar.css';
import LogoConsensus from '../../../../assets/images/logoConsensus2.png';

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: '#00597b',
    borderColor: '#00597b',
    height: 60,
    justifyContent: 'center',
  },
  flexGrow: {
    flexGrow: 1,
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit',
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit',
    },
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  searchPopperContent: {
    marginTop: theme.spacing(1),
  },
  notificationsButton: {
    marginLeft: theme.spacing(1),
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600],
  },
  logoutButton: {
    marginLeft: theme.spacing(1),
  },
  logoutIcon: {
    marginRight: theme.spacing(1),
  },
  avatar: {
    borderRadius: 50,
    paddingTop: 1,
  },
  containerLinks: {
    paddingLeft: 100,
  },
  nameUserStyle: {
    fontSize: 12,
    color: '#ffffff',
    marginRight: 10,
    fontFamily: 'Roboto-Regular',
  },
}));

const TopBar = props => {
  const { onOpenNavBarMobile, className, ...rest } = props;

  const classes = useStyles();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);

  const nameUser = useSelector(state => state.logedUser.name);

  const handleLogout = () => {
    history.push('/');
    dispatch(logoutPm());
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/pm/dashboard" style={{ paddingTop: 5 }}>
          <img alt="Consensus" height="40" src={LogoConsensus} />
        </RouterLink>
        <ul className="header">
          <li>
            <a href="/pm/dashboard" style={{ fontSize: 18 }}>
              CONSENSUS
            </a>
          </li>
        </ul>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <Box className={classes.nameUserStyle}>
            <span>{nameUser}</span>
          </Box>
          <Button className={classes.logoutButton} color="inherit" onClick={handleLogout} to="/">
            <InputIcon className={classes.logoutIcon} />
            Sair
          </Button>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onOpenNavBarMobile}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
};

export default TopBar;

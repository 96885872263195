import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import investigatedService from 'services/investigated/investigatedService';

const ModalSyncWithInternalSystem = props => {
  const { open, onClose, inquiryId } = props;

  const handleSyncWithInternalSystem = () => {
    investigatedService
      .syncCriminalAntecedentsWithInternalSystem(inquiryId)
      .then(() => {
        toast.success('Antecedentes sincronizados com o sistema interno!');
      })
      .catch(() => {
        toast.error('Erro ao sincronizar com o sistema interno');
      });
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}
      >
        <DialogContent>
          <Box display="flex" alignItems="center" gap={2}>
            <Typography variant="body1">Deseja realmente enviar para o sistema interno?</Typography>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <ButtonConsensus
            title="Cancelar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
            size="medium"
          />
          <ButtonConsensus
            title="Confirmar"
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={() => handleSyncWithInternalSystem()}
            size="medium"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalSyncWithInternalSystem;

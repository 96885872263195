export const PENALTY_KINDS = {
  PECUNIARY: 'Pecúnias',
  COMPENSATION_TO_VICTIM: 'Ressarcimentos',
  COMPENSATION_TO_VICTIM_MPDFT: 'Reparação à vítima',
  MATERIALS: 'Materiais',
  SERVICES: 'Serviços',
  SOCIO_EDUCATIVE_LECTURES: 'Palestras socioeducativas',
  OTHER_PENALTIES_MPDFT: 'Outras Medidas',
  OTHER_PENALTIES: 'Outras Penalidades',
};

export const SET_CUSTOMER_CONFIG = 'SET_CUSTOMER_CONFIG';
export const CLEAR_CUSTOMER_CONFIG = 'CLEAR_CUSTOMER_CONFIG';

export const setCustomerConfigReducer = customerConfig => ({
  type: SET_CUSTOMER_CONFIG,
  payload: customerConfig,
});

export const clearCustomerConfigReducer = () => ({
  type: CLEAR_CUSTOMER_CONFIG,
});

import React, { useState } from 'react';
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';

const ModalConfirm = props => {
  const { open, onClose, task, confirmTask, undoResponse, undoVerification } = props;

  const [justification, setJustification] = useState('');

  const handleConfirmTask = event => {
    if (!justification && (undoResponse || undoVerification)) {
      return toast.warn('Preencha a justificativa para prosseguir');
    }
    const newTask = task.task;
    if (undoResponse || undoVerification) {
      newTask.justificationForUndoing = justification;
    }
    confirmTask(event, task.index, newTask);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'md'}
      >
        <DialogTitle id="alert-dialog-title">{task.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h5">
              {undoVerification || task.task.awaitingVerification
                ? `Deseja ${undoVerification ? 'desfazer' : 'confirmar'} a verificação da tarefa ${
                    task.task.subType?.name
                  }?`
                : `Deseja ${undoResponse ? 'desfazer' : 'confirmar'} a resposta da tarefa ${
                    task.task.subType?.name
                  }?`}
            </Typography>
          </DialogContentText>
          {(undoResponse || undoVerification) && (
            <DialogContentText id="alert-dialog-description">
              <TextField
                multiline
                variant="outlined"
                fullWidth
                minRows={6}
                maxRows={10}
                label="Justificativa"
                name="justification"
                value={justification}
                onChange={event => setJustification(event.target.value)}
                size="medium"
              />
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <ButtonConsensus
            title="Cancelar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
            size="medium"
          />
          <ButtonConsensus
            title="Confirmar"
            backgroundColor={colors.primary}
            hovercolor={colors.primary}
            onClick={event => handleConfirmTask(event)}
            size="medium"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalConfirm;

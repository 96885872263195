/*import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { createLogger } from 'redux-logger';

import rootReducer from 'reducers';

// const loggerMiddleware = createLogger();

export default function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware]; // loggerMiddleware
  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
}*/

// eslint-disable-next-line no-unused-vars
import React from 'react';
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import Reducers from '../reducers';

const persistConfig = {
  key: 'root',
  storage: storage,
  //whitelist: [ '' ]
  blacklist: ['lifetime'],
};

const myPersistReducer = persistReducer(persistConfig, Reducers);

const store = createStore(myPersistReducer);
const persistor = persistStore(store);

export { store, persistor };

import React, { Suspense } from 'react';
import { renderRoutes } from 'react-router-config';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import '../../font.css';

const useStyles = makeStyles(() => ({
  topBar: {
    zIndex: 2,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'auto',
  },
  navBar: {
    zIndex: 3,
    width: 225,
    minWidth: 225,
    flex: '0 0 auto',
    backgroundColor: '#00597b',
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
  },
}));

const NewLoginImage = props => {
  const { route } = props;

  const classes = useStyles();

  return (
    <div className="root2">
      <div className={classes.container}>
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>{renderRoutes(route.routes)}</Suspense>
        </main>
      </div>
    </div>
  );
};

export default NewLoginImage;

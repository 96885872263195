import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Grid, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import ButtonCustom from '../../../Button';
import { onCloseWithBackdropDialog } from 'utils/materialUI/backdropClickDialog';

const useStyles = makeStyles(() => ({
  root: {},
  modal: {
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  containerModal: {
    height: '100%',
    width: '100%',
    '@media (max-width:500px)': {
      width: '100%',
      height: '100%',
    },
    '@media (max-width:960px)': {
      width: '100%',
      height: '100%',
    },
  },
  header: {
    width: '100%',
    backgroundColor: '#00597b',
    height: 70,
    color: '#ffffff',
    paddingLeft: 30,
    fontSize: 20,
    display: 'flex',
    alignItems: 'center',
  },
  body: {
    marginTop: 20,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: '#ededed',
    borderRadius: 25,
    padding: 20,
    textAlign: 'center',
  },
  msgBody: {
    fontSize: 20,
    color: '#00597b',
  },
  label: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 15,
  },
  dadosCriminais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 18,
  },
  containerDadosPessoais: {
    backgroundColor: '#ededed',
    marginTop: 5,
    padding: 10,
    borderRadius: 25,
  },
  labelDadoaPessoais: {
    color: '#00597b',
    fontWeight: 'bold',
    fontSize: 12,
  },
  labelCrime: {
    fontWeight: 'bold',
    color: '#000',
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8cf5f',
    width: '60%',
    '@media (max-width:960px)': {
      width: '100%',
    },
  },
  textArea: {
    width: '100%',
    padding: 5,
  },
  containerBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerBtnArea: {
    padding: 10,
  },
  containerConfirm: {
    height: 380,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: '#787878',
    fontSize: 22,
    fontWeight: 'bold',
    padding: 100,
    '@media (max-width:690px)': {
      padding: 30,
    },
  },
  btnConfirm: {
    backgroundColor: '#00b1ac',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    borderRadius: 40,
    height: 60,
    width: 170,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#00597b',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
}));

const ModalConfirm = props => {
  const { open, onClose, className, cancellIntimation, saveIntimation, funcao, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleBack = () => {
    onClose();
  };

  useEffect(() => {}, []);

  const handleCallbackIntimation = () => {
    if (funcao === 'INTIMAR') {
      saveIntimation();
    }
    if (funcao === 'CANCELAR_INTIMACAO') {
      cancellIntimation();
    }
    onClose();
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Dialog
        fullScreen={fullScreen}
        onClose={(event, reason) => onCloseWithBackdropDialog(event, reason, onClose)}
        open={open}
        aria-labelledby="responsive-dialog-title"
        className={classes.modal}
        maxWidth="md"
      >
        <div className={classes.containerModal}>
          <div className={classes.header}>Confirmação</div>
          <div className={classes.body}>
            <Typography className={classes.msgBody}>
              Deseja realmente fazer esta solicitação?
            </Typography>
          </div>
          <Grid container style={{ padding: 10, marginBottom: 10 }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <ButtonCustom
                backgroundColor="#00b1ac"
                hovercolor="#0dd1cc"
                borderColor="#7d7d7d"
                color="#ffffff"
                tittle="Cancelar"
                height="55px"
                width="160px"
                mediaWidth="100%"
                onClick={handleBack}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 10,
              }}
            >
              <ButtonCustom
                backgroundColor="#00b1ac"
                hovercolor="#0dd1cc"
                borderColor="#7d7d7d"
                color="#ffffff"
                tittle="Confirmar"
                height="55px"
                width="160px"
                mediaWidth="100%"
                onClick={handleCallbackIntimation}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

export default ModalConfirm;

import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { colors } from 'utils/colorsSteps';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: '#d7d7d7',
    borderRadius: 20,
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
  },
  textNumber: {
    width: '100%',
    textAlign: 'center',
    color: colors.navBar,
  },
}));

const InternalNumberCompany = props => {
  const { inquiry } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h5" className={classes.textNumber}>
        {!inquiry.inquiry?.internalSystemNumber && !inquiry.inquiry?.arquimedesAuto
          ? `Não informado`
          : `Número do Sistema Interno: ${inquiry.inquiry?.internalSystemNumber} 
          ${inquiry.inquiry?.arquimedesAuto || ''}`}
      </Typography>
    </div>
  );
};

export default InternalNumberCompany;

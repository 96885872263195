import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    borderRadius: 25,
    height: 360,
    padding: theme.spacing(3, 1),
    width: '100%',
    backgroundColor: '#ededed',
    border: `1px solid #707070`,
  },
}));

const ContainerCriminalsSocials = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};

export default ContainerCriminalsSocials;

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Tooltip } from '@material-ui/core';
import {
  AgreementCrud,
  ButtonConsensus,
  ModalBalances,
  ModalConfirm,
  Penalties,
  PenaltiesInstitution,
  ProsecutorOfficeAndProsecutorForm,
} from 'components';
import penaltiesParser from 'components/Penalties/parsers/penalties.parser';
import { toast } from 'react-toastify';
import updatePenaltiesParser from 'components/Penalties/parsers/updatePenalties.parser';
import { Lawyer } from 'views/Agreement/components';
import agreementService from 'services/agreement/agreementService';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import projectService from 'services/project/projectService';
import associationService from 'services/association/associationService';
import { colors } from 'utils/colorsSteps';
import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#ededed',
    borderRadius: 20,
    padding: 15,
    width: '100%',
    height: '100%',
    minHeight: 200,
    boxShadow: 'none',
    border: '1px solid #707070',
  },
  rootWithIgnoreBackground: {
    padding: 15,
  },
  titulo: {
    color: '#1c6b88',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    paddingLeft: 6,
  },
}));

const institutionTypeOptions = {
  final: 'final',
  encaminhadora: 'encaminhadora',
};

const isAllFinalInstitutionsDefined = penalties =>
  penalties.every(
    penalty =>
      penalty.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM ||
      penalty.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT ||
      penalty.penalty === PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES ||
      penalty.penalty === PENALTY_KINDS.OTHER_PENALTIES_MPDFT ||
      penalty.penalty === PENALTY_KINDS.OTHER_PENALTIES ||
      penalty.association ||
      penalty.relation?.association,
  );

const PenaltiesEdit = props => {
  const {
    modeEditForProsecutorAndProsecutorOffice = false,
    prosecutor,
    prosecutorOffice,
    isProsecutor,
    prosecutorOfficeGroup,
    isLoading,
    inquiry,
    refreshPage,
    refresh,
    ignoreBackgroundLayout = false,
    lawyer,
    setLawyer,
    isFinalInstitutionRequired,
    isForwarderAssociation,
    allowToEdit = true,
    isIntimationStep,
    isMigration,
    agreementWithoutProsecutor = false,
    uniqueMeasure = false,
    changeMeasure,
  } = props;

  const classes = useStyles();

  const { viewModalBalances, isMPDFT } = useCustomerConfig();

  const [edit, setEdit] = useState(false);
  const [penalties, setPenalties] = useState(
    uniqueMeasure ? [] : penaltiesParser(inquiry, isMPDFT),
  );
  const [description, setDescription] = useState(
    inquiry.agreement?.description || inquiry.proposed?.description || '',
  );
  const [ressarciment, setRessarciment] = useState(
    penalties.filter(p =>
      isMPDFT
        ? p.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT
        : p.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM,
    ),
  );
  const [materials, setMaterials] = useState(
    penalties.filter(p => p.penalty === PENALTY_KINDS.MATERIALS),
  );
  const [services, setServices] = useState(
    penalties.filter(p => p.penalty === PENALTY_KINDS.SERVICES),
  );
  const [pecuniary, setPecuniary] = useState(
    penalties.filter(p => p.penalty === PENALTY_KINDS.PECUNIARY),
  );
  const [lectures, setLectures] = useState(
    penalties.filter(p => p.penalty === PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES),
  );
  const [generalPenalties, setGeneralPenalties] = useState(
    penalties.filter(
      p =>
        p.penalty === PENALTY_KINDS.OTHER_PENALTIES_MPDFT ||
        p.penalty === PENALTY_KINDS.OTHER_PENALTIES,
    ),
  );

  const [isForwardingTermStep, setIsForwardingTermStep] = useState(false);
  const [disabled, setDisabled] = useState(props?.disabled);

  const currentInstitutionType =
    penalties.length && isAllFinalInstitutionsDefined(penalties)
      ? institutionTypeOptions.final
      : institutionTypeOptions.encaminhadora;

  const [institutionType, setInstitutionType] = useState(
    isFinalInstitutionRequired ? institutionTypeOptions.final : currentInstitutionType,
  );
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const [institutionSelected, setInstitutionSelected] = useState(null);

  const [localLawyer, setLocalLawyer] = useState(lawyer);
  const [localProsecutor, setProsecutor] = useState(prosecutor);
  const [localProsecutorOffice, setProsecutorOffice] = useState(prosecutorOffice);

  const [associations, setAssociations] = useState([]);
  const [projects, setProjects] = useState([]);
  const [openModalBalances, setOpenModalBalances] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [loadingModalBalance, setLoadingModalBalance] = useState(false);

  const haveProposed = inquiry.proposed ? true : false;
  const haveAgreement = inquiry.agreement ? true : false;
  const [selectedIndexForDeletePenalty, setSelectedIndexForDeletePenalty] = useState(null);

  const [selectedIndexForEditingPecuniary, setSelectedIndexForEditingPecuniary] = useState(null);
  const [selectedIndexForEditingRessarciment, setSelectedIndexForEditingRessarciment] = useState(
    null,
  );
  const [selectedIndexForEditingMaterial, setSelectedIndexForEditingMaterial] = useState(null);
  const [selectedIndexForEditingService, setSelectedIndexForEditingService] = useState(null);
  const [selectedIndexForEditingLectures, setSelectedIndexForEditingLectures] = useState(null);
  const [
    selectedIndexForEditingGeneralPenalties,
    setSelectedIndexForEditingGeneralPenalties,
  ] = useState(null);
  const [disabledActionsPenaltyTable, setDisabledActionsPenaltyTable] = useState({});
  const [editedTab, setEditedTab] = useState(null);

  const [openModalConfirm, setOpenModalConfirm] = useState(false);

  const handleInstitutionSelected = async () => {
    const association = inquiry.agreement?.association || inquiry.proposed?.association;
    if (association) {
      setInstitutionSelected(association);
      return;
    }

    if (inquiry.inquiry?.prosecutorOfficeGroup) {
      try {
        const params = { prosecutorOfficeGroup: inquiry.inquiry.prosecutorOfficeGroup.id };
        const response = await associationService.getAssociationsService(params);
        const associations = response.data.data;
        setInstitutionSelected(associations.length === 1 ? associations[0] : null);
      } catch (error) {
        setInstitutionSelected(null);
      }
    } else {
      setInstitutionSelected(null);
    }
  };

  const handleConfirmDeletion = (item, index) => {
    setDisabledActionsPenaltyTable(prevState => ({
      ...prevState,
      [index]: { ...prevState[index], delete: true },
    }));
    setSelectedIndexForDeletePenalty(index);
    setOpenModalConfirm(true);
  };

  const handleDeletePenalty = () => {
    const penaltyEdited = penalties[selectedIndexForDeletePenalty];
    switch (penaltyEdited.penalty) {
      case PENALTY_KINDS.PECUNIARY:
        let newArrayPecuniary = [...pecuniary];
        newArrayPecuniary.splice(
          pecuniary.findIndex(penalty => penalty === penaltyEdited),
          1,
        );
        setPecuniary(newArrayPecuniary);
        setSelectedIndexForEditingPecuniary(null);
        break;
      case PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT:
      case PENALTY_KINDS.COMPENSATION_TO_VICTIM:
        let newArrayRessarciment = [...ressarciment];
        newArrayRessarciment.splice(
          ressarciment.findIndex(penalty => penalty === penaltyEdited),
          1,
        );
        setRessarciment(newArrayRessarciment);
        setSelectedIndexForEditingRessarciment(null);
        break;
      case PENALTY_KINDS.MATERIALS:
        let newArrayMaterials = [...materials];
        newArrayMaterials.splice(
          materials.findIndex(penalty => penalty === penaltyEdited),
          1,
        );
        setMaterials(newArrayMaterials);
        setSelectedIndexForEditingMaterial(null);
        break;
      case PENALTY_KINDS.SERVICES:
        let newArrayServices = [...services];
        newArrayServices.splice(
          services.findIndex(penalty => penalty === penaltyEdited),
          1,
        );
        setServices(newArrayServices);
        setSelectedIndexForEditingService(null);
        break;
      case PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES:
        let newArrayLectures = [...lectures];
        newArrayLectures.splice(
          lectures.findIndex(penalty => penalty === penaltyEdited),
          1,
        );
        setLectures(newArrayLectures);
        setSelectedIndexForEditingLectures(null);
        break;
      case PENALTY_KINDS.OTHER_PENALTIES_MPDFT:
      case PENALTY_KINDS.OTHER_PENALTIES:
        let newArrayGeneralPenalties = [...generalPenalties];
        newArrayGeneralPenalties.splice(
          generalPenalties.findIndex(penalty => penalty === penaltyEdited),
          1,
        );
        setGeneralPenalties(newArrayGeneralPenalties);
        setSelectedIndexForEditingGeneralPenalties(null);
        break;
    }

    setDisabledActionsPenaltyTable(prevState => {
      const updatedState = { ...prevState };
      delete updatedState[selectedIndexForDeletePenalty];
      Object.keys(updatedState).forEach(index => {
        if (penalties[index]?.penalty === penaltyEdited?.penalty) {
          updatedState[index] = { ...updatedState[index], edit: false };
        }
      });
      return updatedState;
    });

    let newArray = [...penalties];
    newArray.splice(selectedIndexForDeletePenalty, 1);
    setPenalties(newArray);
    setOpenModalConfirm(false);
  };

  const editPenalty = (item, index, description) => {
    setDescription(description);
    setDisabledActionsPenaltyTable(prevState => {
      const newState = { ...prevState };
      Object.keys(newState).forEach(key => {
        if (penalties[key]?.penalty === item?.penalty) {
          newState[key] = { ...newState[key], edit: false };
        }
      });
      newState[index] = { ...newState[index], edit: true, delete: false };

      return newState;
    });

    const penaltyEdited = penalties[index];

    switch (penaltyEdited.penalty) {
      case PENALTY_KINDS.PECUNIARY:
        setSelectedIndexForEditingPecuniary({
          index: pecuniary.findIndex(penalty => penalty === penaltyEdited),
          penaltiesIndex: index,
        });
        setEditedTab(0);
        break;
      case PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT:
      case PENALTY_KINDS.COMPENSATION_TO_VICTIM:
        setSelectedIndexForEditingRessarciment({
          index: ressarciment.findIndex(penalty => penalty === penaltyEdited),
          penaltiesIndex: index,
        });
        setEditedTab(1);
        break;
      case PENALTY_KINDS.MATERIALS:
        setSelectedIndexForEditingMaterial({
          index: materials.findIndex(penalty => penalty === penaltyEdited),
          penaltiesIndex: index,
        });
        setEditedTab(2);
        break;
      case PENALTY_KINDS.SERVICES:
        setSelectedIndexForEditingService({
          index: services.findIndex(penalty => penalty === penaltyEdited),
          penaltiesIndex: index,
        });
        setEditedTab(3);
        break;
      case PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES:
        setSelectedIndexForEditingLectures({
          index: lectures.findIndex(penalty => penalty === penaltyEdited),
          penaltiesIndex: index,
        });
        setEditedTab(4);
        break;
      case PENALTY_KINDS.OTHER_PENALTIES_MPDFT:
      case PENALTY_KINDS.OTHER_PENALTIES:
        setSelectedIndexForEditingGeneralPenalties({
          index: generalPenalties.findIndex(penalty => penalty === penaltyEdited),
          penaltiesIndex: index,
        });
        setEditedTab(5);
        break;
    }
  };

  const handleChangeProsecutor = (_event, newValue) => {
    setProsecutor(newValue);
  };

  const handleChangeProsecutorOffice = (_event, newValue) => {
    setProsecutorOffice(newValue);
  };

  useEffect(() => {
    const penalties = uniqueMeasure ? [] : penaltiesParser(inquiry, isMPDFT);
    setPenalties(penalties);
    setMaterials(penalties.filter(p => p.penalty === PENALTY_KINDS.MATERIALS));
    setServices(penalties.filter(p => p.penalty === PENALTY_KINDS.SERVICES));
    setPecuniary(penalties.filter(p => p.penalty === PENALTY_KINDS.PECUNIARY));
    setRessarciment(
      penalties.filter(p =>
        isMPDFT
          ? p.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT
          : p.penalty === PENALTY_KINDS.COMPENSATION_TO_VICTIM,
      ),
    );
    setLectures(penalties.filter(p => p.penalty === PENALTY_KINDS.SOCIO_EDUCATIVE_LECTURES));
    setGeneralPenalties(
      penalties.filter(p =>
        isMPDFT
          ? p.penalty === PENALTY_KINDS.OTHER_PENALTIES
          : p.penalty === PENALTY_KINDS.OTHER_PENALTIES_MPDFT,
      ),
    );
    setDescription(inquiry.agreement?.description || inquiry.proposed?.description || '');
    handleInstitutionSelected();
    if (inquiry.step === STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO) {
      setDisabled(inquiry.status === 'awaiting-verification' ? false : true);
      setEdit(inquiry.status === 'awaiting-verification' ? true : false);
      setIsForwardingTermStep(true);
    }
    if (inquiry.step === STEPS_INQUIRY.AUDIENCIA && inquiry.agreement?.signedAgreement) {
      setDisabled(true);
      setEdit(false);
    }
  }, [inquiry]);

  useEffect(() => {
    setPenalties([
      ...pecuniary,
      ...ressarciment,
      ...services,
      ...materials,
      ...lectures,
      ...generalPenalties,
    ]);
  }, [pecuniary, ressarciment, services, materials, generalPenalties, lectures]);

  const toggleEdit = () => {
    setEdit(!edit);
  };

  const handleUpdate = async () => {
    if (!inquiry.agreement && !inquiry.proposed) {
      toast.warn(
        `Houve um problema com ${
          isIntimationStep ? 'a proposta' : 'o acordo'
        }. Contate o administrador do sistema`,
      );
      return;
    }

    if (
      !pecuniary.length &&
      !ressarciment.length &&
      !materials.length &&
      !services.length &&
      !generalPenalties.length &&
      !lectures.length
    ) {
      toast.warn('informe um item de acordo para prosseguir');
      return;
    }

    if (
      institutionType === institutionTypeOptions.final &&
      !isFinalInstitutionRequired &&
      !isAllFinalInstitutionsDefined([...materials, ...services, ...pecuniary])
    ) {
      toast.warn('Necessário preencher todas as instituições');
      return;
    }

    if (!localLawyer) {
      toast.warn('É necessário informar os dados do advogado');
      return;
    }

    if (!localProsecutorOffice && !isIntimationStep && !isMigration && !uniqueMeasure) {
      toast.warn('É necessário informar os dados da promotoria');
      return;
    }

    if (!localProsecutor && !isIntimationStep && !isMigration && !uniqueMeasure) {
      toast.warn('É necessário informar os dados do promotor');
      return;
    }

    const { projects, associations, ...data } = updatePenaltiesParser(
      {
        pecuniary,
        ressarciment,
        materials,
        services,
        lectures,
        geralPenalties: generalPenalties,
      },
      { institutionType, institutionTypeOptions },
    );

    setUpdateData(data);
    validate(data, projects, associations);
  };

  const handleCloseModalBalances = () => {
    setLoadingModalBalance(false);
    setOpenModalBalances(false);
  };

  const validate = async (data, projects, associations) => {
    if (projects?.length || associations?.length) {
      setProjects(projects);
      setAssociations(associations);
      if (viewModalBalances && !isIntimationStep && !isMigration && !haveProposed) {
        const projectBalances = projects?.length && (await checkProjectBalances(projects));
        const associationAmount =
          associations?.length && (await checkAssociationAmount(associations));
        const hasNegativeBalance =
          (projectBalances?.length &&
            projectBalances.some(
              projectBalance =>
                projectBalance?.pecuniaryBalance < 0 ||
                projectBalance?.materialBalance < 0 ||
                projectBalance?.serviceBalance < 0,
            )) ||
          (associationAmount?.length &&
            associationAmount.some(
              association =>
                association.materials?.some(material => material?.materialQuantity < 0) ||
                association.services?.some(service => service?.serviceHour < 0),
            ));
        if (hasNegativeBalance) {
          setOpenModalBalances(true);
        } else {
          handleSubmit(data);
        }
      } else {
        handleSubmit(data);
      }
    } else {
      handleSubmit(data);
    }
  };

  const handleSubmit = async data => {
    setIsLoadingSave(true);

    const newAgreement = {
      ...inquiry.agreement,
      inquiryInvestigatedId: inquiry.id,
      associationId:
        institutionSelected?.id ||
        inquiry.agreement?.association?.id ||
        inquiry.agreement?.proposed?.id ||
        undefined,
      hearingId: inquiry.agreement?.hearing?.id || undefined,
      ...data,
      lawyer: localLawyer,
      prosecutorId: localProsecutor?.id,
      prosecutorOfficeId: localProsecutorOffice?.id,
    };

    if (!isMPDFT) {
      newAgreement.description = description;
    }

    if (uniqueMeasure) {
      console.log(newAgreement);
      // return;
      changeMeasure(newAgreement);
      return;
    } else if (haveAgreement) {
      await agreementService.updateAgreementService(inquiry.agreement.id, newAgreement);
    } else {
      await agreementService.updateProposedService(inquiry.proposed.id, newAgreement);
    }

    toast.success('Dados atualizados com sucesso');

    setEdit(!edit);
    setIsLoadingSave(false);
    handleCloseModalBalances();
    if (isIntimationStep) {
      refresh();
    }
    refreshPage();
  };

  const [tableProjectBalancesData, setTableProjectBalancesData] = useState([]);
  const [loadingTableProjectBalancesData, setLoadingTableProjectBalancesData] = useState(false);

  const [tableAssociationAmountData, setTableAssociationAmountData] = useState([]);
  const [loadingTableAssociationAmountData, setLoadingTableAssociationAmountData] = useState(false);

  const checkProjectBalances = async projects => {
    setLoadingTableProjectBalancesData(true);
    const params = { projects };
    params.excludeBalanceAgreementId = inquiry.agreement.id;

    return await projectService
      .checkProjectBalances(params)
      .then(response => {
        setTableProjectBalancesData(response.data);
        return response.data;
      })
      .finally(() => setLoadingTableProjectBalancesData(false))
      .catch(error => {
        toast.error('Erro ao obter Saldos dos Projetos:', error);
      });
  };

  const checkAssociationAmount = async associations => {
    setLoadingTableAssociationAmountData(true);
    const params = { associations };
    params.excludeBalanceAgreementId = inquiry.agreement.id;

    return await associationService
      .checkAssociationBalances(params)
      .then(response => {
        setTableAssociationAmountData(response.data);
        return response.data;
      })
      .finally(() => setLoadingTableAssociationAmountData(false))
      .catch(error => {
        toast.error('Erro ao obter Saldos das Instituições:', error);
      });
  };

  const handleCloseModalConfirm = () => {
    setDisabledActionsPenaltyTable(prevState => ({
      ...prevState,
      [selectedIndexForDeletePenalty]: {
        ...prevState[selectedIndexForDeletePenalty],
        delete: false,
      },
    }));
    setOpenModalConfirm(false);
  };

  return (
    <Box className={!ignoreBackgroundLayout ? classes.root : classes.rootWithIgnoreBackground}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={1.5}>
        <Box>
          <Typography className={classes.titulo}>{isMPDFT ? 'Medidas' : 'Penalidades'}</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {!edit && !disabled && allowToEdit ? toggleEdit : null}
        </Box>
      </Box>
      <Box width="100%">
        {!uniqueMeasure && (
          <Box mb={2}>
            <Lawyer
              key={edit}
              disabled={!edit}
              lawyer={localLawyer}
              setLawyer={setLocalLawyer}
              externalEdit={isForwarderAssociation ? false : true}
              action="respectExternalEdit"
            />
          </Box>
        )}
        {!edit ? (
          <>
            {!isIntimationStep && !isMigration && (
              <Box mb={4}>
                <ProsecutorOfficeAndProsecutorForm
                  edit={agreementWithoutProsecutor || false}
                  prosecutor={localProsecutor}
                  prosecutorOffice={localProsecutorOffice}
                  onProsecutorChange={handleChangeProsecutor}
                  onProsecutorOfficeChange={handleChangeProsecutorOffice}
                  isProsecutor={isProsecutor}
                  prosecutorOfficeGroup={prosecutorOfficeGroup}
                />
              </Box>
            )}
          </>
        ) : (
          <>
            {!isIntimationStep && !isMigration && !uniqueMeasure && (
              <Box mb={4}>
                <ProsecutorOfficeAndProsecutorForm
                  edit={modeEditForProsecutorAndProsecutorOffice || agreementWithoutProsecutor}
                  prosecutor={localProsecutor}
                  prosecutorOffice={localProsecutorOffice}
                  onProsecutorChange={handleChangeProsecutor}
                  onProsecutorOfficeChange={handleChangeProsecutorOffice}
                  isProsecutor={isProsecutor}
                  prosecutorOfficeGroup={prosecutorOfficeGroup}
                />
              </Box>
            )}
            {!uniqueMeasure && (
              <PenaltiesInstitution
                institutionType={institutionType}
                setInstitutionType={!isFinalInstitutionRequired && setInstitutionType}
                institutionSelected={institutionSelected}
                setInstitutionSelected={setInstitutionSelected}
                disabledInstitutionType={isForwarderAssociation}
                institutionTypeOptions={institutionTypeOptions}
                enableResponsibleSector={isMPDFT && isForwardingTermStep}
              />
            )}
            <Box mt={2}>
              <AgreementCrud
                setDisabledActionsPenaltyTable={setDisabledActionsPenaltyTable}
                editedTab={editedTab}
                isLoading={!isLoading}
                pecuniary={pecuniary}
                setPecuniary={setPecuniary}
                description={description}
                setDescription={setDescription}
                institutionType={institutionType}
                showInvestigated
                inquiry={inquiry}
                ressarciment={ressarciment}
                setRessarciment={setRessarciment}
                materials={materials}
                setMaterials={setMaterials}
                services={services}
                setServices={setServices}
                lectures={lectures}
                setLectures={setLectures}
                generalPenalties={generalPenalties}
                setGeneralPenalties={setGeneralPenalties}
                institutionSelected={institutionSelected}
                setSelectedIndexForEditingPecuniary={setSelectedIndexForEditingPecuniary}
                selectedIndexForEditingPecuniary={selectedIndexForEditingPecuniary}
                setSelectedIndexForEditingMaterial={setSelectedIndexForEditingMaterial}
                selectedIndexForEditingMaterial={selectedIndexForEditingMaterial}
                setSelectedIndexForEditingRessarciment={setSelectedIndexForEditingRessarciment}
                selectedIndexForEditingRessarciment={selectedIndexForEditingRessarciment}
                setSelectedIndexForEditingService={setSelectedIndexForEditingService}
                selectedIndexForEditingService={selectedIndexForEditingService}
                setSelectedIndexForEditingGeneralPenalties={
                  setSelectedIndexForEditingGeneralPenalties
                }
                selectedIndexForEditingGeneralPenalties={selectedIndexForEditingGeneralPenalties}
                setSelectedIndexForEditingLectures={setSelectedIndexForEditingLectures}
                selectedIndexForEditingLectures={selectedIndexForEditingLectures}
              />
            </Box>
          </>
        )}
        <Penalties
          disabledActions={disabledActionsPenaltyTable}
          penalties={penalties}
          edit={edit}
          description={description}
          editPenalty={editPenalty}
          handleConfirmDeletion={handleConfirmDeletion}
        />
        {edit ? (
          <Box style={{ marginRight: 10, display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonConsensus
              title={
                uniqueMeasure
                  ? isMPDFT
                    ? 'Atualizar Medida'
                    : 'Atualizar Penalidade'
                  : 'Salvar Acordo'
              }
              type="submit"
              size="medium"
              onClick={handleUpdate}
              form="my-form-edit"
              backgroundColor={colors.greenLight}
              hovercolor={colors.greenLight}
              disabled={false}
            />
          </Box>
        ) : null}
      </Box>
      {openModalConfirm && (
        <ModalConfirm
          open={openModalConfirm}
          handleClose={handleCloseModalConfirm}
          onClick={handleDeletePenalty}
          title={`Deletar ${isMPDFT ? 'Medida' : 'Penalidade'}`}
        />
      )}
      {openModalBalances && viewModalBalances && (
        <ModalBalances
          projects={projects}
          associations={associations}
          open={openModalBalances}
          loadingModalBalance={loadingModalBalance}
          onClose={handleCloseModalBalances}
          onClick={() => {
            setLoadingModalBalance(true);
            handleSubmit(updateData);
          }}
          loadingTableProjectBalancesData={loadingTableProjectBalancesData}
          loadingTableAssociationAmountData={loadingTableAssociationAmountData}
          tableProjectBalancesData={tableProjectBalancesData}
          tableAssociationAmountData={tableAssociationAmountData}
        />
      )}
    </Box>
  );
};

export default PenaltiesEdit;

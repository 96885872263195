import * as actionTypes from 'actions';

const initialState = {
  policeInquiryNumber: '',
};

const policeInquiryNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_POLICE_INQUIRY_NUMBER: {
      return {
        ...state,
        policeInquiryNumber: action.payload,
      };
    }

    case actionTypes.CLEAR_POLICE_INQUIRY_NUMBER: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default policeInquiryNumberReducer;

import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const ModalConfirm = props => {
  const { open, onClose, person, onClick } = props;

  const handleConfirm = () => {
    onClick(person.id, person.peopleType);
    setTimeout(() => {
      onClose();
    }, 2000);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle>{person?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja excluir{' '}
            {person?.peopleType === ' victim'
              ? `a vítima ${person?.name}`
              : `o representante legal ${person?.name}`}
            ?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: 15 }}>
          <Button onClick={onClose} size="small" variant="contained">
            Cancelar
          </Button>
          <Button size="small" variant="contained" color="primary" onClick={() => handleConfirm()}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalConfirm;

import http from '../httpInvestigatedService';

const InvestigatedService = {
  loginService: () => {
    const params = {
      cpf: '65002105491',
      rg: '3214087',
      dataNascimento: '1969-09-11',
      senha: '123',
    };
    return http.post('auth/login-investigado', params);
  },

  getInitialChatMessageService: () => http.get('chatbot/initial-message'),

  sendMessageChatService: params => http.post('chatbot', params),

  checkStatusChatService: () => http.get('chatbot/chatbot-has-agreed'),

  getHistoryChatService: () => http.get('chatbot/find-chat-by-person'),

  getHearingService: () => http.get('hearings/hearing-for-investigated'),

  getDetailsMeService: () => http.get('persons/me'),

  syncCriminalAntecedentsWithInternalSystem: inquiryInvestigatedId =>
    http.post(
      `inquiries/investigated/${inquiryInvestigatedId}/criminal-antecedents-certificate/integrations`,
    ),
};

export default InvestigatedService;

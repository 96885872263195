import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Card,
  IconButton,
  MenuItem,
  Box,
  Chip,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { InputConsensus } from 'components';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import { useUserTypes } from 'hooks/useUserTypes';
import PmService from 'services/PM/pmService';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 10,
    paddingLeft: 10,
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  containerJudicial: {
    marginTop: theme.spacing(1),
  },
  boxLeft: {
    textAlign: 'left',
    paddingLeft: theme.spacing(1),
  },
  boxRight: {
    textAlign: 'left',
    paddingRight: theme.spacing(1),
  },
  labelTitle: {
    color: '#00597b',
  },
  inputResult: {
    color: '#00597b',
    fontWeight: 'bold',
  },
  cardCrimePersecution: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    overflowX: 'auto',
    float: 'left',
    '@media (max-width:600px)': {
      float: 'none',
    },
  },
  chipNonPersecution: {
    margin: 2,
    backgroundColor: '#00597b',
    color: '#ffffff',
  },
}));

const InvestigatedArrested = props => {
  const {
    inquiryId,
    isArrested,
    refreshPage,
    write = false,
    otherStep = false,
    canEdit = false,
  } = props;

  const { isPM } = useUserTypes();

  const classes = useStyles();

  const initialValues = {
    isArrested: !isArrested ? false : true,
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: { ...initialValues },
    touched: {},
    errors: {},
  });

  const [editArrested, setEditArrested] = useState(false);

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  const handleEditArrested = () => {
    if (!write) {
      handleOpenSnackbar();
      return;
    }
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        isArrested: !isArrested ? false : true,
      },
    }));
    setEditArrested(true);
  };

  const handleEditArrestedToFalse = () => {
    setEditArrested(false);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleUpdateArrested = () => {
    let params = {
      isArrested: formState.values.isArrested,
    };

    if (isPM) {
      PmService.updateInquiryInvestigatedService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        setEditArrested(false);
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryInvestigatedService(inquiryId, params).then(() => {
        toast.success('Dados alterados com sucesso!');
        setEditArrested(false);
        refreshPage();
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Typography className={classes.titulo}>Investigado está preso?</Typography>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {!editArrested && ((!otherStep && !isPM) || canEdit) ? (
              <Tooltip title="Alterar dados" arrow placement="top">
                <IconButton size="small" onClick={handleEditArrested}>
                  <CreateIcon style={{ color: '#00b1ac' }} />
                </IconButton>
              </Tooltip>
            ) : (
              editArrested && (
                <>
                  <Box style={{ marginRight: 10 }}>
                    <Tooltip title="Salvar alterações" arrow placement="top">
                      <IconButton
                        size="small"
                        onClick={handleUpdateArrested}
                        form="my-form-edit"
                        type="submit"
                      >
                        <SaveIcon style={{ color: '#1976d2' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <Tooltip title="Cancelar" arrow placement="top">
                      <IconButton size="small" onClick={handleEditArrestedToFalse}>
                        <ClearIcon style={{ color: '#e1296a' }} />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </>
              )
            )}
          </Box>
        </Box>
      </Box>
      <Grid container spacing={2} className={classes.containerJudicial}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Box className={classes.boxLeft}>
            {!editArrested ? (
              <Card className={classes.cardCrimePersecution}>
                <Chip
                  className={classes.chipNonPersecution}
                  size="small"
                  label={`Preso: ${isArrested ? 'Sim' : 'Não'}`}
                />
              </Card>
            ) : (
              <InputConsensus
                label="Encontra-se preso?"
                select
                fullWidth
                autoComplete="off"
                name="isArrested"
                onChange={handleChange}
                value={formState.values.isArrested}
              >
                <MenuItem value={true}>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </InputConsensus>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvestigatedArrested;

import { CUSTOMER_CONFIG } from 'constants/customerConfig';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';

export const changeNameStep = (step, customerConfig) => {
  switch (step) {
    case STEPS_INQUIRY.REMETIDO_AO_SISTEMA_INTERNO:
      step = 'Remetido Ao Sistema Interno';
      break;
    case STEPS_INQUIRY.DENUNCIA:
      step = 'Denúncia';
      break;
    case STEPS_INQUIRY.DECLINAR:
      step = 'Declinação';
      break;
    case STEPS_INQUIRY.ARQUIVAR:
      step = 'Arquivamento';
      break;
    case STEPS_INQUIRY.PERSECUCAO:
      step = 'Persecução';
      break;
    case STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS:
      step = 'Despacho';
      break;
    case STEPS_INQUIRY.TRIAGEM:
      step = 'Triagem';
      break;
    case STEPS_INQUIRY.PESQUISA:
      step = 'Pesquisa e Distribuição';
      break;
    case STEPS_INQUIRY.ANALISE:
      step = 'Análise';
      break;
    case null:
      step = 'Análise';
      break;
    case STEPS_INQUIRY.INTIMACAO:
      step = 'Intimação';
      break;
    case STEPS_INQUIRY.TRAMITACAO_CONTROLE:
      step = 'Tramitação e Controle';
      break;
    case STEPS_INQUIRY.AUDIENCIA:
      step = `Audiência${
        customerConfig[CUSTOMER_CONFIG.ENABLE_HEARING_FOR_JUVENILE_OFFENDERS] ? '/Oitiva' : ''
      }`;
      break;
    case STEPS_INQUIRY.CUMPRIMENTO_ACORDO:
      step = 'Cumprimento de Acordo';
      break;
    case STEPS_INQUIRY.REQUISITORIO:
      step = 'Requisitório';
      break;
    case STEPS_INQUIRY.REMETIDO_AO_JUDICIARIO:
      step = 'Remetido ao Judiciário';
      break;
    case STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO:
      step = 'Termo de Encaminhamento';
      break;
    case STEPS_INQUIRY.NAO_IDENTIFICADO:
      step = 'Não Identificado';
      break;
    case STEPS_INQUIRY.MIGRACAO:
      step = 'Migração'
      break;
    default:
      break;
  }
  return step;
};

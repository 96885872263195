import React, { useState } from 'react';
import { Dialog, DialogContent, Grid, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { ButtonConsensus, HeaderModal, InputConsensus, KeyboardDatePicker } from 'components';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { colors } from 'utils/colorsSteps';
import { phoneMask } from 'masks/masks';
import {
  formatDateToISO8601,
  formatDateToISOString,
  formatHourToISO8601,
  isValidTime,
} from 'utils/date-time.utils';
import { addHoursAndMinutesInDate } from 'utils/date-time.utils';

const ModalSaveVictimIntimation = props => {
  const {
    open,
    onClose,
    selectedItem,
    victims,
    inquiryId,
    confirmSaveVictimIntimation,
    loading,
    editable,
  } = props;

  const useStyles = makeStyles(() => ({
    selectInput: {
      height: 35,
      width: '100%',
      border: `1px solid #747474`,
      paddingLeft: 10,
      backgroundColor: 'transparent',
      marginRight: 10,
      marginTop: 5,
    },
  }));

  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const initialState = {
    victimId: '0',
    observation: '',
    dateContactedAt: '',
    hourContactedAt: '',
    documentSendingDeadline: '',
    email: '',
    phone: '',
    wasContacted: false,
  };

  const [formState, setFormState] = useState({
    values: selectedItem
      ? {
          victimId: selectedItem.victimIntimation.victim.id,
          observation: selectedItem.victimIntimation.observation,
          dateContactedAt: formatDateToISO8601(selectedItem.victimIntimation.contactedAt),
          hourContactedAt: formatHourToISO8601(selectedItem.victimIntimation.contactedAt),
          documentSendingDeadline: formatDateToISO8601(
            selectedItem.victimIntimation.documentSendingDeadline,
          ),
          email: selectedItem.victimIntimation.email,
          phone: selectedItem.victimIntimation.phone,
          wasContacted: selectedItem.victimIntimation.wasContacted,
        }
      : { ...initialState },
  });

  const handleConfirmVictimIntimation = event => {
    event.preventDefault();

    const newVictimIntimation = { ...selectedItem?.victimIntimation };

    const dateContactedAtValue = formState.values.dateContactedAt;
    const hourContactedAtValue = formState.values.hourContactedAt;

    if (!dateContactedAtValue) {
      toast.warn('Informe a data do contato.');
      return;
    }

    if (!isValidTime(hourContactedAtValue)) {
      toast.warn('Informe o horário do contato válido no formato 00:00.');
      return;
    }

    const onlyDateContactedAt = formatDateToISOString(dateContactedAtValue);
    const docSendingDeadline = formatDateToISOString(formState.values.documentSendingDeadline);

    if (Date.parse(onlyDateContactedAt) > Date.parse(docSendingDeadline)) {
      toast.warn('A data do contato não pode ser maior que o prazo para envio dos documentos.');
      return;
    }

    const hour = hourContactedAtValue.substring(0, 2);
    const min = hourContactedAtValue.substring(3, 5);

    newVictimIntimation.contactedAt = addHoursAndMinutesInDate(dateContactedAtValue, hour, min);

    if (Date.parse(newVictimIntimation.contactedAt) > Date.now()) {
      toast.warn('A data/hora do contato não pode ser maior que o momento atual.');
      return;
    }

    newVictimIntimation.victim = { id: formState.values.victimId };
    newVictimIntimation.inquiry = { id: inquiryId };
    newVictimIntimation.observation = formState.values.observation;
    newVictimIntimation.documentSendingDeadline = docSendingDeadline;
    newVictimIntimation.email = formState.values.email;
    newVictimIntimation.phone = formState.values.phone;
    newVictimIntimation.wasContacted = formState.values.wasContacted;

    if (newVictimIntimation.victim.id === '0') {
      toast.warn('Selecione uma vítima.');
      return;
    }
    if (!newVictimIntimation.documentSendingDeadline) {
      toast.warn('Informe o prazo para envio de documentos.');
      return;
    }

    confirmSaveVictimIntimation(newVictimIntimation);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const getTitle = () => {
    return selectedItem
      ? editable
        ? 'Alterar Intimação de Vítima'
        : 'Intimação de Vítima'
      : 'Incluir Intimação de Vítima';
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
      >
        <HeaderModal onClose={onClose}>{getTitle()}</HeaderModal>
        <DialogContent>
          <form onSubmit={handleConfirmVictimIntimation}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <select
                  className={classes.selectInput}
                  onChange={handleChange}
                  disabled={!editable}
                  value={formState.values.victimId}
                  name="victimId"
                >
                  <option value="0">Selecione uma vítima</option>
                  {victims?.length > 0
                    ? victims.map(victim => (
                        <option key={victim.id} value={victim.id}>
                          {victim.name}
                        </option>
                      ))
                    : ''}
                </select>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <InputConsensus
                  label="Observação"
                  name="observation"
                  value={formState.values.observation}
                  disabled={!editable}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <KeyboardDatePicker
                  variant="dialog"
                  name="dateContactedAt"
                  label="Data do contato *"
                  disabled={!editable}
                  size="small"
                  value={formState.values.dateContactedAt || null}
                  onChange={newDate =>
                    setFormState(formState => ({
                      ...formState,
                      values: {
                        ...formState.values,
                        dateContactedAt: newDate,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <InputMask
                  onChange={handleChange}
                  value={formState.values.hourContactedAt || ''}
                  mask="99:99"
                  maskChar={null}
                >
                  {inputProps => (
                    <InputConsensus
                      {...inputProps}
                      label="Hora do contato *"
                      name="hourContactedAt"
                      fullWidth
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <KeyboardDatePicker
                  variant="dialog"
                  name="documentSendingDeadline"
                  label="Prazo para envio de documentos *"
                  disabled={!editable}
                  size="small"
                  value={formState.values.documentSendingDeadline || null}
                  onChange={newDate =>
                    setFormState(formState => ({
                      ...formState,
                      values: {
                        ...formState.values,
                        documentSendingDeadline: newDate,
                      },
                    }))
                  }
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <InputConsensus
                  label="E-mail"
                  name="email"
                  type="email"
                  disabled={!editable}
                  value={formState.values.email}
                  onChange={handleChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <InputMask
                  onChange={handleChange}
                  disabled={!editable}
                  value={formState.values.phone}
                  mask={phoneMask}
                  maskChar={null}
                >
                  {inputProps => (
                    <InputConsensus
                      {...inputProps}
                      label="Telefone"
                      name="phone"
                      disabled={!editable}
                      fullWidth
                      type="phone"
                    />
                  )}
                </InputMask>
              </Grid>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formState.values.wasContacted}
                      onChange={handleChange}
                      disabled={!editable}
                      name="wasContacted"
                    />
                  }
                  label="Intimação bem sucedida"
                  labelPlacement="end"
                />
              </Grid>
              {editable ? (
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Box display="flex" justifyContent="flex-end" mb={1}>
                    <ButtonConsensus
                      title="Confirmar"
                      backgroundColor={colors.primary}
                      hovercolor={colors.primary}
                      type="submit"
                      margin={0}
                      disabled={loading}
                    />
                  </Box>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalSaveVictimIntimation;

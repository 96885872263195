import http from '../httpService';

const PersonService = {
  getZipCodeService: zipCode => http.get(`persons/adresses/${zipCode}`),

  getPersonInArquimedesService: params => http.get('persons/arquimedes', { params }),

  getSdsDataService: params => http.get(`persons/sds-personal-data`, { params }),

  updatePersonInquiry: (personId, params) => http.patch(`persons/${personId}`, params),
};

export default PersonService;

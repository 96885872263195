import { clearSpecialCharacterName } from './clearSpecialCharacterName';

export const formatedTaxpayerRegister = (number = '') => {
  number = clearSpecialCharacterName(number);

  if (number?.length !== 11 && number.length !== 14) {
    return number;
  }

  if (number.length === 11) {
    const fn = number.split('');
    return `${fn[0]}${fn[1]}${fn[2]}.${fn[3]}${fn[4]}${fn[5]}.${fn[6]}${fn[7]}${fn[8]}-${fn[9]}${fn[10]}`;
  }

  if (number.length === 14) {
    const fn = number.split('');
    return `${fn[0]}${fn[1]}.${fn[2]}${fn[3]}${fn[4]}.${fn[5]}${fn[6]}${fn[7]}/${fn[8]}${fn[9]}${fn[10]}${fn[11]}-${fn[12]}${fn[13]}`;
  }
};

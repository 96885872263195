import React, { Suspense, useState, useEffect } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, Fab, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import { NavBar, TopBar } from './components';
import '../../font.css';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import ChatAi from './components/ChatAi';
import BetoAIIcon from '../../assets/images/Beto AI.png';
import { useDispatch, useSelector } from 'react-redux';
import { clearAiMessages, setChatAiSituation } from 'actions';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CloseIcon from '@mui/icons-material/Close'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { colors } from 'utils/colorsSteps';
import { chatAiPossibleStates } from 'reducers/chatAiReducer';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: '#ffffff',
  },
  topBar: {
    zIndex: 2,
    position: 'relative',
  },
  container: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  navBar: {
    zIndex: 3,
    width: 225,
    minWidth: 225,
    flex: '0 0 auto',
    backgroundColor: '#00597b',
  },
  content: {
    overflowY: 'auto',
    flex: '1 1 auto',
  },
  chatAiButton: {
    position: 'fixed',
    bottom: 50,
    right: 20,
    boxSizing: 'border-box',
    background: 'linear-gradient(180deg, #00A3E1 4%, #003D54 85.5%)',
    border: '3px solid #FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
  chatAi: {
    position: 'fixed',
    bottom: 20,
    right: 20,
    backgroundColor: '#ffffff',
  },
  aiIcon: {
    height: 50,
    zIndex: 2,
    position: 'relative',
    right: 12,
  },
  aiSymbol: {
    color: 'yellow',
    position: 'relative',
    zIndex: 1,
    fontSize: 100,
    right: 5,
  },
  minimizedChat: {
    position: 'fixed',
    bottom: 2,
    right: 20,
    backgroundColor: colors.primary,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 154,
    height: 33,
    borderRadius: 5,
    cursor: 'pointer',
  },
}));

const Dashboard = props => {
  const { route } = props;
  const { connectWithAIService } = useCustomerConfig();
  const chatAiReducer = useSelector(state => state.chatAi);
  const { aiAssistant } = useSelector(
    state => state.logedUser.permissions,
  );
  const dispatch = useDispatch();

  const classes = useStyles();
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [aiChatSituation, setAiChatSituation] = useState(chatAiReducer.situation);

  const handleNavBarMobileOpen = () => {
    setOpenNavBarMobile(true);
  };

  const handleNavBarMobileClose = () => {
    setOpenNavBarMobile(false);
  };

  const handleCloseAiChat = () => {
    handleChangeAiChatSituation(chatAiPossibleStates.closed);
    dispatch(clearAiMessages());
  };

  const handleChangeAiChatSituation = newState => {
    dispatch(setChatAiSituation(newState));
  }

  useEffect(() => {
    setAiChatSituation(chatAiReducer.situation)
  }, [chatAiReducer.situation])

  return (
    <div className={classes.root}>
      <TopBar className={classes.topBar} onOpenNavBarMobile={handleNavBarMobileOpen} />
      <div className={classes.container}>
        <NavBar
          className={classes.navBar}
          onMobileClose={handleNavBarMobileClose}
          openMobile={openNavBarMobile}
        />
        <main className={classes.content}>
          <Suspense fallback={<LinearProgress />}>{renderRoutes(route.routes)}</Suspense>
        </main>
      </div>
      {connectWithAIService && aiAssistant?.read && (
        <>
          {aiChatSituation === chatAiPossibleStates.closed ? (
            <>
                <Avatar
                  onClick={() => handleChangeAiChatSituation(chatAiPossibleStates.minimized)}
                  style={{
                    backgroundColor: '#F0F0F0',
                    cursor: 'pointer',
                    color: colors.primary,
                    width: 20,
                    height: 20,
                    position: 'fixed',
                    bottom: 90,
                    right: 70,
                  }}
                >
                  <CloseIcon sx={{ color: colors.primary }} />
                </Avatar>
              <Tooltip arrow placement="left" title="Assistente IA">
                <Fab
                  color="primary"
                  aria-label="chat"
                  onClick={() => handleChangeAiChatSituation(chatAiPossibleStates.open)}
                  disabled={!aiAssistant?.write}
                  className={classes.chatAiButton}
                >
                  <AutoAwesomeIcon className={classes.aiSymbol} />
                  <img src={BetoAIIcon} alt="Ícone de Beto IA" className={classes.aiIcon} />
                </Fab>
              </Tooltip>
            </>
          ) : aiChatSituation === chatAiPossibleStates.open ? (
            <ChatAi handleClose={handleCloseAiChat} />
          ) : (
            <Box className={classes.minimizedChat} onClick={() => handleChangeAiChatSituation(chatAiPossibleStates.closed)}>
              <AutoAwesomeIcon style={{ color: 'yellow', margin: 10, fontSize: 20  }} />
              <Typography style={{ color: 'white', fontFamily: 'Roboto', fontWeight: 900 }}>BETO 1.0</Typography>
              <KeyboardArrowUpIcon style={{ color: 'white', margin: 10 }} />
            </Box>
          )}
        </>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  route: PropTypes.object,
};

export default Dashboard;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Grid,
  Box,
  Divider,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { setLinkingInquiryId, clearLinkingInquiryId } from '../../actions';
import { InputConsensus, ButtonConsensus, ModalIntegrationInquiry } from 'components';
import { Alert, Autocomplete } from '@material-ui/lab';
import { colors } from 'utils/colorsSteps';
import debounce from 'lodash.debounce';
import pmService from 'services/PM/pmService';
import inquiryService from 'services/inquiries/inquiryService';
import SearchIcon from '@material-ui/icons/Search';
import { useUserTypes } from 'hooks/useUserTypes';
import { useLinkingPendingInquiryContext } from 'views/DetailPendingInquiry/contexts/LinkingPendingInquiryContext';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    //height: '100%',
    minHeight: 50,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    marginTop: 10,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
  },
  text: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
}));

const LinkingInquiryFromAuto = props => {
  const {
    clearLinkingInquiry: clearLinking,
    setClearLinkingInquiryId,
    findIntegration = false, //if you do not find the survey linked in Archimedes, do an external search in Archimedes
    disabled,
  } = props;

  const { isPM } = useUserTypes();

  const {
    disabledDueToLinkedSurvey,
    setDisabledDueToLinkedSurvey,
  } = useLinkingPendingInquiryContext();

  const classes = useStyles();
  const linkingIdReducer = useSelector(state => state.linkingInquiry);
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    values: {
      boundInquiryId: linkingIdReducer?.boundInquiryId || null,
    },
  });

  const [linking, setLinking] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [loadingGetInquiries, setLoadingGetInquiries] = useState(false);

  const [openModalIntegration, setOpenModalIntegration] = useState(false);

  const handleOpenModalIntegration = () => {
    setOpenModalIntegration(true);
  };

  const handleCloseModalIntegration = () => {
    setOpenModalIntegration(false);
  };

  useEffect(() => {
    if (clearLinking) {
      setFormState(formState => ({
        ...formState,
        values: {
          ...formState.values,
          boundInquiryId: null,
        },
      }));
      setLinking(false);
      dispatch(clearLinkingInquiryId());
      setClearLinkingInquiryId(false);
      if (setDisabledDueToLinkedSurvey) {
        setDisabledDueToLinkedSurvey(false);
      }
    }
  }, [clearLinking]);

  const getInquiry = (search = '') => {
    setLoadingGetInquiries(true);

    let params = {
      page: 1,
      size: 15,
    };

    if (search) {
      params.arquimedesAuto = search;
    }

    if (isPM) {
      pmService
        .getInquiriesPmService(params)
        .then(response => {
          setOptions(response.data.data);
          if (!response.data.data.length && findIntegration && search.length > 4) {
            handleOpenModalIntegration();
          }
        })
        .finally(() => setLoadingGetInquiries(false));
    } else {
      inquiryService
        .getInquiriesService(params)
        .then(response => {
          setOptions(response.data.data);
          if (!response.data.data.length && findIntegration && search.length > 4) {
            handleOpenModalIntegration();
          }
        })
        .finally(() => setLoadingGetInquiries(false));
    }
  };

  const debouncedGetInquiries = useCallback(
    debounce(newValue => getInquiry(newValue), 1000),
    [],
  );

  const updateValueInquiries = newValue => {
    if (newValue === 'Não integrado') {
      return;
    }
    debouncedGetInquiries(newValue);
    setInputValue(newValue);
  };

  const handleChangeInquiry = (event, newValue) => {
    if (!newValue) {
      if (setDisabledDueToLinkedSurvey) {
        setDisabledDueToLinkedSurvey(false);
      }
      dispatch(clearLinkingInquiryId());
      getInquiry();
    } else {
      if (setDisabledDueToLinkedSurvey) {
        setDisabledDueToLinkedSurvey(newValue?.arquimedesAuto ? false : true);
      }
    }

    dispatch(setLinkingInquiryId(newValue));

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        boundInquiryId: newValue ? newValue : null,
      },
    }));
  };

  const handleLinkingFalse = () => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        boundInquiryId: null,
      },
    }));
    dispatch(clearLinkingInquiryId());
    setLinking(false);
    getInquiry();
    if (setDisabledDueToLinkedSurvey) {
      setDisabledDueToLinkedSurvey(false);
    }
  };

  const handleLinkingTrue = () => {
    setInputValue('');
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        boundInquiryId: linkingIdReducer?.boundInquiryId || null,
      },
    }));

    setLinking(true);

    if (setDisabledDueToLinkedSurvey) {
      setDisabledDueToLinkedSurvey(() => {
        if (linkingIdReducer?.boundInquiryId) {
          return linkingIdReducer.boundInquiryId?.arquimedesAuto ? false : true;
        } else {
          return false;
        }
      });
    }
  };

  useEffect(() => {
    getInquiry();

    if (linkingIdReducer?.boundInquiryId) {
      setLinking(true);
      if (setDisabledDueToLinkedSurvey) {
        setDisabledDueToLinkedSurvey(
          linkingIdReducer?.boundInquiryId?.arquimedesAuto ? false : true,
        );
      }
    }
  }, []);

  return (
    <Box className={classes.root}>
      <Grid container spacing={1} className={classes.container}>
        {disabledDueToLinkedSurvey && (
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Alert severity="warning">
              O inquérito vinculado selecionado ainda não foi integrado ao arquimedes. Para
              prosseguir, integre no arquimedes o inquérito que deseja vincular ou selecione um
              inquérito integrado para vincular.
            </Alert>
          </Grid>
        )}
        <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
          <Box flexDirection="column" display="flex" alignItems="center" justifyContent="center">
            <Typography variant="body2" className={classes.text}>
              Vincular a um inquérito existente?
            </Typography>
            {linking ? (
              <ButtonConsensus
                title="Cancelar"
                backgroundColor="#fff"
                hovercolor={colors.greySecundary}
                color={colors.greyShadow}
                onClick={handleLinkingFalse}
                size="medium"
              />
            ) : (
              <ButtonConsensus
                title="Vincular"
                onClick={() =>
                  disabled
                    ? toast.warn('Você não tem permissão para tomar esta ação')
                    : handleLinkingTrue()
                }
                size="medium"
              />
            )}
          </Box>
        </Grid>
        {linking && (
          <Grid item xl={9} lg={9} md={9} sm={9} xs={12}>
            <Box display="flex" alignItems="center">
              <Autocomplete
                onInputChange={(event, newInputValue) => updateValueInquiries(newInputValue)}
                getOptionSelected={(option, value) =>
                  option?.arquimedesAuto === value?.arquimedesAuto || false
                }
                getOptionLabel={option => option?.arquimedesAuto || 'Não integrado'}
                filterOptions={x => x}
                value={formState.values?.boundInquiryId || null}
                noOptionsText="Nada foi encontrado"
                onOpen={() => getInquiry(inputValue)}
                loadingText="Buscando..."
                loading={loadingGetInquiries}
                clearOnBlur={false}
                options={options}
                onChange={handleChangeInquiry}
                fullWidth
                renderOption={option => (
                  <Box style={{ width: '100%' }}>
                    <Typography variant="body2">
                      N° arquimedes: {option.arquimedesAuto || 'Não integrado'}
                    </Typography>
                    <Typography variant="body2">
                      N° interno: {option.internalSystemNumber || 'Não integrado'}
                    </Typography>
                    <Typography variant="body2">
                      N° de origem: {option.policeInquiryNumber || 'Não integrado'}
                    </Typography>
                    <Divider />
                  </Box>
                )}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    name="boundInquiryId"
                    label="Pesquisar código arquimedes"
                    fullWidth
                    inputProps={{
                      ...params.inputProps,
                      endAdornment: (
                        <>
                          {loadingGetInquiries ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <Box ml={1}>
                <Tooltip arrow placement="top" title="Buscar inquérito no arquimedes">
                  <IconButton onClick={handleOpenModalIntegration}>
                    <SearchIcon color="primary" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
      {openModalIntegration && !isPM && (
        <ModalIntegrationInquiry
          open={openModalIntegration}
          onClose={handleCloseModalIntegration}
          handleChangeInquiry={handleChangeInquiry}
        />
      )}
    </Box>
  );
};

export default LinkingInquiryFromAuto;

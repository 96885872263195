/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Grid, IconButton, Tooltip, Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setOrganOriginReducer } from 'actions';
import { InputConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-toastify';
import inquiryService from 'services/inquiries/inquiryService';
import debounce from 'lodash.debounce';
import { useUserTypes } from 'hooks/useUserTypes';
import organService from 'services/organ/organService';
import pmService from 'services/PM/pmService';
import PmService from 'services/PM/pmService';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  containerTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleLeft: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  titleRight: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  form: {
    heigth: '100%',
    width: '100%',
  },
  containerLabelInput: {
    padding: 5,
    width: '100%',
  },
  inputFormCrime: {
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 'bold',
    color: '#00597b',
    textAlign: 'left',
  },
}));

const FormOrganOrigin = props => {
  const {
    clearFormOrganOrigin,
    setClearFormOrganOrigin,
    sourceOrgan = undefined,
    editSourceOrgan,
    setEditSourceOrgan,
    inquiryId,
    otherStep,
    write,
    handleOpenSnackbar,
    refreshPage,
    newInquiry,
    canEdit = false,
  } = props;

  const initialState = {
    id: null,
    name: null,
    codeArquimedes: null,
    registration: null,
    createdAt: null,
    updatedAt: null,
  };

  const classesInquiryReducer = useSelector(state => state.classesInquiry);

  const { isPM } = useUserTypes();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [loadingDelegacias, setLoadingDelegacias] = useState(false);
  const [pageDelegacias, setPageDelegacias] = useState(1);
  const [lastPageDelegacias, setLastPageDelegacias] = useState(1);
  const [inputSearchDelegacias, setInputSearchDelegacias] = useState('');
  const [options, setOptions] = useState([]);

  const [formState, setFormState] = useState({
    sourceOrgan: sourceOrgan ? { ...sourceOrgan } : { ...initialState },
  });

  const getDelegacias = (input = '', classInquiryDescription = null) => {
    setLoadingDelegacias(true);

    setPageDelegacias(1);

    const isJudiciary = ['Processos Judiciais', 'Processo Judicial'].some(description =>
      classInquiryDescription.includes(description),
    );

    let params = {
      page: 1,
      size: 20,
      search: input,
      category: isJudiciary ? 'JUDICIARY' : 'INVESTIGATIVE',
    };

    if (isPM) {
      pmService
        .getDelegaciasPmService(params)
        .then(response => {
          setOptions(response.data.data);
          setLastPageDelegacias(response.data.lastPage);
        })
        .finally(() => setLoadingDelegacias(false));
    } else {
      organService
        .getDelegaciasService(params)
        .then(response => {
          setOptions(response.data.data);
          setLastPageDelegacias(response.data.lastPage);
        })
        .finally(() => setLoadingDelegacias(false));
    }
  };

  const loadMoreDelegaciasResults = (classInquiryDescription = null) => {
    const nextPage = pageDelegacias + 1;

    setPageDelegacias(nextPage);

    setLoadingDelegacias(true);

    const isJudiciary = ['Processos Judiciais', 'Processo Judicial'].some(description =>
      classInquiryDescription.includes(description),
    );

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchDelegacias,
      category: isJudiciary ? 'JUDICIARY' : 'INVESTIGATIVE',
    };

    if (isPM) {
      pmService
        .getDelegaciasPmService(params)
        .then(response => {
          setOptions([...options, ...response.data.data]);
          setLastPageDelegacias(response.data.lastPage);
        })
        .finally(() => setLoadingDelegacias(false));
    } else {
      organService
        .getDelegaciasService(params)
        .then(response => {
          setOptions([...options, ...response.data.data]);
          setLastPageDelegacias(response.data.lastPage);
        })
        .finally(() => setLoadingDelegacias(false));
    }
  };

  const handleScrollDelegacias = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageDelegacias < lastPageDelegacias &&
      !loadingDelegacias
    ) {
      loadMoreDelegaciasResults(classesInquiryReducer?.description);
    }
  };

  const debouncedGetDelegacias = useCallback(
    debounce((newValue, classCode) => getDelegacias(newValue, classCode), 500),
    [],
  );

  const updateValueDelegacias = newValue => {
    if (!options.find(delegacia => delegacia.name === newValue)) {
      debouncedGetDelegacias(newValue, classesInquiryReducer?.description);
      setInputSearchDelegacias(newValue);
    }
  };

  useEffect(() => {
    if (newInquiry) {
      setClearFormOrganOrigin(true);
      debouncedGetDelegacias('', classesInquiryReducer?.description);
    }
  }, [newInquiry, classesInquiryReducer?.description]);

  const handleChangeSourceOrgan = (event, newValue) => {
    setFormState(formState => ({
      ...formState,
      sourceOrgan: newValue,
    }));
  };

  useEffect(() => {
    if (!sourceOrgan && formState.sourceOrgan !== null) {
      dispatch(setOrganOriginReducer(formState.sourceOrgan));
    } else if (
      (!sourceOrgan && formState.sourceOrgan === null) ||
      (!sourceOrgan && formState.sourceOrgan === '')
    ) {
      dispatch(setOrganOriginReducer(initialState));
    }
  }, [formState.sourceOrgan]);

  useEffect(() => {
    if (clearFormOrganOrigin) {
      setFormState(formState => ({
        ...formState,
        sourceOrgan: initialState,
      }));
      setClearFormOrganOrigin(false);
    }
  }, [clearFormOrganOrigin]);

  const handleEditSourceOrganToFalse = () => {
    setFormState(formState => ({
      ...formState,
      sourceOrgan: sourceOrgan ? { ...sourceOrgan } : { ...initialState },
    }));
    setEditSourceOrgan(false);
  };

  const handleEditSourceOrganToTrue = () => {
    debouncedGetDelegacias('', classesInquiryReducer?.description);
    setEditSourceOrgan(true);
  };

  const handleUpdateSourceOrgan = () => {
    if (formState.sourceOrgan === '' || !formState.sourceOrgan) {
      toast.warn('Selecione um Órgão de Origem');
      return;
    }

    let params = {
      sourceOrganId: formState.sourceOrgan?.id,
    };

    if (isPM) {
      PmService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Orgão de Origem alterado com sucesso');
        setEditSourceOrgan(false);
        refreshPage();
      });
    } else {
      inquiryService.updateInquiryService(inquiryId, params).then(() => {
        toast.success('Órgão de Origem alterado com sucesso');
        setEditSourceOrgan(false);
        refreshPage();
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.containerTitle}>
        <Box className={classes.titleLeft}>
          <Typography className={classes.titulo}>Órgão de Origem</Typography>
        </Box>
        <Box className={classes.titleRight}>
          {!editSourceOrgan && ((!otherStep && !isPM) || canEdit) && /*sourceOrgan*/ !newInquiry ? (
            <Tooltip title="Alterar Órgão de Origem" arrow placement="top">
              <IconButton
                className={classes.buttonEditar}
                size="small"
                onClick={() => (!write ? handleOpenSnackbar() : handleEditSourceOrganToTrue())}
              >
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            editSourceOrgan && (
              <>
                <Box style={{ marginRight: 10 }}>
                  <Tooltip title="Salvar alterações" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleUpdateSourceOrgan}
                      form="my-form-edit"
                      type="submit"
                    >
                      <SaveIcon style={{ color: '#1976d2' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title="Cancelar" arrow placement="top">
                    <IconButton
                      className={classes.buttonEditar}
                      size="small"
                      onClick={handleEditSourceOrganToFalse}
                    >
                      <ClearIcon style={{ color: '#e1296a' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </>
            )
          )}
        </Box>
      </Box>
      {editSourceOrgan || newInquiry ? (
        <form className={classes.form}>
          <Grid container spacing={1}>
            <div className={classes.containerLabelInput} style={{ padding: 10 }}>
              <Autocomplete
                onInputChange={(event, newInputValue) => updateValueDelegacias(newInputValue)}
                getOptionSelected={(option, value) => option.name === value.name}
                getOptionLabel={option => option.name}
                filterOptions={x => x}
                value={formState.sourceOrgan}
                fullWidth
                noOptionsText="Nada foi encontrado"
                loadingText="Buscando..."
                loading={loadingDelegacias}
                clearOnBlur={false}
                options={options}
                onChange={handleChangeSourceOrgan}
                renderOption={option => <>{option.name}</>}
                renderInput={params => (
                  <InputConsensus
                    {...params}
                    label="Órgão de Origem *"
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingDelegacias ? (
                            <CircularProgress color="primary" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                ListboxProps={{
                  onScroll: handleScrollDelegacias,
                }}
              />
            </div>
          </Grid>
        </form>
      ) : (
        <Grid container spacing={1}>
          <Grid item>
            <div className={classes.containerLabelInput}>
              <Typography className={classes.inputFormCrime}>
                {formState.sourceOrgan?.name || 'Não informado'}
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default FormOrganOrigin;

import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { ModalAddFile } from '../../views/Agreement/components';
import { ModalConfirm } from 'components';
import { toast } from 'react-toastify';
import { handlePermissionWrite } from 'utils/permission.utils';
import agreementService from 'services/agreement/agreementService';

const ButtonUploadAgreement = props => {
  const {
    agreementId,
    signedAgreement,
    files,
    setFiles,
    uploadFiles,
    setUploadFiles,
    refreshPage,
    allowToEdit = true,
  } = props;

  const [openModalAddFile, setOpenModalAddFile] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [disabled, setDisabled] = useState(false);

  const handleOpenModalAddFiles = () => {
    setOpenModalAddFile(true);
  };

  const handleCloseModalAddFile = () => {
    setOpenModalAddFile(false);
  };

  const handleSendFile = () => {
    setDisabled(true);

    agreementService.uploadSignedAgreementService(agreementId, uploadFiles[0]).then(() => {
      toast.success('Arquivo anexado com sucesso!');
      setUploadFiles([]);
      handleCloseAgreement();
    });
  };

  const handleCloseAgreement = () => {
    setOpenModalAddFile(false);
    setDisabled(false);
    refreshPage();
  };

  const handleConfirmSendFile = () => {
    setOpenConfirm(true);
  };

  return (
    <Box>
      <Tooltip title="Anexar termo do acordo" arrow placement="top">
        <IconButton
          onClick={() =>
            handlePermissionWrite(handleOpenModalAddFiles, allowToEdit || signedAgreement)
          }
        >
          <AttachFileIcon fontSize="medium" />
        </IconButton>
      </Tooltip>
      <ModalAddFile
        open={openModalAddFile}
        onClose={handleCloseModalAddFile}
        files={files}
        setFiles={setFiles}
        uploadFiles={uploadFiles}
        setUploadFiles={setUploadFiles}
        handleSendFile={handleConfirmSendFile}
        disabled={disabled}
        signedAgreement={signedAgreement}
        agreementId={agreementId}
      />
      <ModalConfirm
        title="Confirmação"
        open={openConfirm}
        handleClose={() => setOpenConfirm(false)}
        onClick={handleSendFile}
      >
        <Typography variant="h5">
          {uploadFiles.length > 1
            ? 'Deseja realmente enviar os arquivos?'
            : 'Deseja realmente enviar o arquivo?'}
        </Typography>
      </ModalConfirm>
    </Box>
  );
};

export default ButtonUploadAgreement;

import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';
import { redirectToInquiryIdStep } from 'utils/genericFunctions/redirectToInquiryIdStep';

const Info = props => {
  const { investigated, redirect } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Box display="flex" justifyContent="flex-end">
          <ButtonConsensus
            size="small"
            title="Ver Inquérito"
            color="#FFF"
            margin={0}
            backgroundColor={colors.greenLight}
            hovercolor={colors.greenLight}
            onClick={() => redirectToInquiryIdStep(redirect)}
          />
        </Box>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Typography>{investigated?.investigated?.name || 'não informado'}</Typography>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Typography>Mãe: {investigated?.investigated?.motherName || 'não informado'}</Typography>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Typography>
          Nacionalidade: {investigated?.investigated?.nationality || 'não informado'}
        </Typography>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
        <Typography>RG: {investigated?.investigated?.rg?.code || 'não informado'}</Typography>
      </Grid>
    </Grid>
  );
};

export default Info;

import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { HeaderModal, InputConsensus, ButtonConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { colors } from 'utils/colorsSteps';
import { toast } from 'react-toastify';
import organService from 'services/organ/organService';
import movementTaskService from 'services/movement-task/movementTaskService';

const ModalIntimationToComplaint = props => {
  const { open, onClose, inquiry } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const [key, setKey] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [sendRequest, setSendRequest] = useState(false);

  const initialValues = {
    place: null,
    inquiryInvestigatedId: inquiry.id,
    shouldMoveInquiry: true,
    type: 'DENUNCIA',
    subType: {
      code: '920015',
      name: 'Denúncia',
    },
  };

  const [formState, setFormState] = useState({ ...initialValues });

  const getDelegacias = (input = '') => {
    let params = {
      page: 1,
      size: 10,
      search: input,
    };

    organService.getDelegaciasService(params).then(response => {
      setOptions(response.data.data);
    });
  };

  useEffect(() => {
    getDelegacias();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let active = true;

    if (inputValue === '') {
      setOptions(formState.place ? [formState.place] : []);
      return undefined;
    }

    getDelegacias(inputValue);

    return () => {
      active = false;
    };
  }, [formState.place, inputValue]);

  const handleChangeJudgmentPlace = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      place: newValue ? newValue : null,
    }));
    setKey(!key);
  };

  const handleSendTask = event => {
    event.preventDefault();

    const { place } = formState;

    if (!place || place === '') {
      toast.warn('Informe uma Vara/Local para prosseguir');
      return;
    }

    addTask(formState);
  };

  const addTask = async params => {
    setSendRequest(true);
    await movementTaskService
      .registerMovementTasksService(params)
      .then(() => {
        sendTramition();
      })
      .catch(() => {
        setSendRequest(false);
      });
  };

  const sendTramition = async () => {
    await movementTaskService
      .movementTaskStartService(inquiry.id)
      .then(() => {
        toast.success('Inquérito enviado para a fase de Denúncia');
        setFormState({ ...initialValues });
        setTimeout(() => {
          onClose();
        }, 1500);
      })
      .finally(() => setSendRequest(false));
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose} maxWidth="md" fullWidth>
        <HeaderModal onClose={onClose}>Enviar inquérito para Denúncia</HeaderModal>
        <DialogContent>
          <form onSubmit={handleSendTask} id="formComplaint">
            <Grid container spacing={1}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  options={options}
                  fullWidth
                  autoHighlight
                  onChange={handleChangeJudgmentPlace}
                  value={formState.place || ''}
                  filterOptions={x => x}
                  key={key}
                  includeInputInList
                  filterSelectedOptions
                  noOptionsText="Não encontrado"
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  getOptionLabel={option => option.name}
                  renderOption={option => <React.Fragment>{option.name}</React.Fragment>}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      name="place"
                      label="Vara/Local"
                      variant="outlined"
                      fullWidth
                      inputProps={{
                        ...params.inputProps,
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <ButtonConsensus
            title="Cancelar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={onClose}
          />
          <ButtonConsensus
            title="Confirmar"
            backgroundColor={colors.greenLight}
            hovercolor={colors.greenLight}
            form="formComplaint"
            type="submit"
            disabled={sendRequest}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalIntimationToComplaint;

import * as actionTypes from 'actions';

const initialState = {
  tabIndexServicesMaterials: 0,
  tabIndexServicesMaterialsSolicitation: 0,
};

const tabsServicesMaterialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TAB_INDEX_SERVICES_MATERIALS: {
      return {
        ...state,
        tabIndexServicesMaterials: action.payload,
      };
    }

    case actionTypes.SET_TAB_INDEX_SERVICES_MATERIALS_SOLICITATION: {
      return {
        ...state,
        tabIndexServicesMaterialsSolicitation: action.payload,
      };
    }

    default:
      return state;
  }
};

export default tabsServicesMaterialsReducer;

import React, { useState, useEffect } from 'react';
import InputConsensus from 'components/InputConsensus';
import AddIcon from '@material-ui/icons/Add';
import {
  IconButton,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import crimeService from 'services/crime/crimeService';

const useStyles = makeStyles(() => ({
  containerChip: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
}));

const TypificationSubtypes = props => {
  const { subtypes, updateSubtypes } = props;

  const classes = useStyles();

  const [newSubtype, setNewSubtype] = useState(null);
  const [subtypesList, setSubtypesList] = useState([]);

  const handleAddSubtype = () => {
    if (!newSubtype) {
      toast.warn('Insira um subtipo');
      return;
    }

    if (!subtypes.some(subtype => subtype.name === newSubtype)) {
      updateSubtypes([...subtypes, { name: newSubtype }]);
      setNewSubtype('');
    } else {
      toast.warn(`Subtipo ${newSubtype} já adicionado`);
    }
  };

  const handleRemoveSubtype = subtype => {
    updateSubtypes(subtypes.filter(item => item !== subtype));
  };

  useEffect(() => {
    crimeService.getCrimesSubtypes().then(response => setSubtypesList(response.data));
  }, [subtypes]);

  return (
    <Box>
      <Box margin={1} display="flex" justifyContent="flex-end" alignItems="center">
        <InputConsensus
          label="Subtipo"
          name="subtypes"
          variant="standard"
          select
          fullWidth
          onChange={e => setNewSubtype(e.target.value)}
          value={newSubtype}
        >
          {subtypesList.map((v, i) => (
            <MenuItem key={i} value={v.name}>
              {v.name}
            </MenuItem>
          ))}
        </InputConsensus>
        <IconButton color="primary" aria-label="add subtype" onClick={() => handleAddSubtype()}>
          <AddIcon style={{ cursor: 'pointer' }} />
        </IconButton>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Subtipos</TableCell>
              <TableCell align="center" width={150}>
                <Box display="flex" position="relative" justifyContent="center">
                  Ações
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subtypes?.map((item, index) => (
              <TableRow key={index} hover>
                <TableCell component="th" scope="row" align="center">
                  {item.name}
                </TableCell>
                <TableCell align="center">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleRemoveSubtype(item)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default TypificationSubtypes;

export const getColorForDeadline = daysToDeadline => {
  if (daysToDeadline === 0 || daysToDeadline === 1) {
    return DEADLINE_COLORS.YELLOW;
  }

  if (daysToDeadline < 0) {
    return DEADLINE_COLORS.RED;
  }

  if (daysToDeadline > 1) {
    return DEADLINE_COLORS.GREEN;
  }
};

export const DEADLINE_COLORS = {
  YELLOW: '#fff200',
  RED: '#ff0000',
  GREEN: '#00b09d',
};

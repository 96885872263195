import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Grid, Box, Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useSelector, useDispatch } from 'react-redux';
import { setOrganOriginReducer } from '../../../../actions';
import CreateIcon from '@material-ui/icons/Create';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { InputConsensus } from 'components';
import { Autocomplete } from '@material-ui/lab';
import { toast } from 'react-toastify';
import organService from 'services/organ/organService';
import debounce from 'lodash.debounce';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10,
  },
  titulo: {
    color: '#00597b',
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 20,
    fontFamily: 'roboto-regular',
  },
  containerLabelInputCrime: {
    padding: 5,
  },
  selectForm: {
    height: 25,
    outline: 0,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: '#ededed',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  form: {
    heigth: '100%',
    width: '100%',
  },
  labelForm: {
    display: 'flex',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 11,
  },
  labelFormCrime: {
    display: 'flex',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontSize: 11,
    color: '#00597b',
  },
  inputForm: {
    display: 'block',
    height: 25,
    outline: 0,
    padding: 5,
    width: '100%',
    border: 0,
    borderBottom: `1px solid #000`,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
  },
  inputFormCrime: {
    display: 'block',
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 'bold',
    color: '#00597b',
    textAlign: 'left',
  },
  inputFormCrimeEdit: {
    display: 'block',
    height: 20,
    outline: 0,
    width: '100%',
    border: 0,
    backgroundColor: 'transparent',
    fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif',
    fontWeight: 'bold',
    color: '#00597b',
    borderBottom: '1px solid #000',
  },
  label: {
    display: 'block',
  },
  input: {
    width: '100%',
  },
  listbox: {
    width: '100%',
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'relative',
    listStyle: 'none',
    backgroundColor: '#ededed',
    overflow: 'auto',
    maxHeight: 150,
    //border: '1px solid rgba(0,0,0,.25)',
    '& li[data-focus="true"]': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  },
}));

const OrganOriginPending = props => {
  const {
    clearReducer,
    setClearReducer,
    clearFormOrganOrigin = false,
    setClearFormOrganOrigin,
    sourceOrgan = undefined,
    instaurationDelegacy,
    write,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [loadingDelegacias, setLoadingDelegacias] = useState(false);
  const [pageDelegacias, setPageDelegacias] = useState(1);
  const [lastPageDelegacias, setLastPageDelegacias] = useState(1);
  const [inputSearchDelegacias, setInputSearchDelegacias] = useState('');

  const [editOrganOrigin, setEditOrganOrigin] = useState(false);
  const [checkReducer, setCheckReducer] = useState(false);
  const [options, setOptions] = useState([]);

  const organOriginReducer = useSelector(state => state.organOrigin);

  const initialState = {
    id: '',
    name: '',
    codeArquimedes: '',
    registration: null,
    createdAt: '',
    updatedAt: '',
  };

  const [formState, setFormState] = useState({
    sourceOrgan: sourceOrgan === undefined ? { ...initialState } : { ...sourceOrgan },
  });

  const getDelegacias = (input = '') => {
    setLoadingDelegacias(true);

    setPageDelegacias(1);

    let params = {
      page: 1,
      size: 20,
      search: input,
    };

    organService
      .getDelegaciasService(params)
      .then(response => {
        setOptions(response.data.data);
        setLastPageDelegacias(response.data.lastPage);
      })
      .finally(() => setLoadingDelegacias(false));
  };

  const loadMoreDelegaciasResults = () => {
    const nextPage = pageDelegacias + 1;

    setPageDelegacias(nextPage);

    setLoadingDelegacias(true);

    let params = {
      page: nextPage,
      size: 20,
      search: inputSearchDelegacias,
    };

    organService
      .getDelegaciasService(params)
      .then(response => {
        setOptions([...options, ...response.data.data]);
        setLastPageDelegacias(response.data.lastPage);
      })
      .finally(() => setLoadingDelegacias(false));
  };

  const handleScrollDelegacias = event => {
    const listboxNode = event.currentTarget;

    const position = listboxNode.scrollTop + listboxNode.clientHeight;

    if (
      listboxNode.scrollHeight - position <= 1 &&
      pageDelegacias < lastPageDelegacias &&
      !loadingDelegacias
    ) {
      loadMoreDelegaciasResults();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetDelegacias = useCallback(
    debounce(newValue => getDelegacias(newValue), 500),
    [],
  );

  const updateValueDelegacias = newValue => {
    if (!options.find(delegacia => delegacia.name === newValue)) {
      debouncedGetDelegacias(newValue);
      setInputSearchDelegacias(newValue);
    }
  };

  const handleChangeSourceOrgan = (_event, newValue) => {
    setFormState(formState => ({
      ...formState,
      sourceOrgan: newValue,
    }));
  };

  const handleCancelEdit = () => {
    setFormState(formState => ({
      ...formState,
      sourceOrgan: { ...organOriginReducer },
    }));
    setEditOrganOrigin(false);
  };

  const handleEditOrganOrigin = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    if (checkReducer) {
      setFormState(formState => ({
        ...formState,
        sourceOrgan: { ...organOriginReducer },
      }));
    } else {
      setFormState(formState => ({
        ...formState,
        sourceOrgan: { ...sourceOrgan },
      }));
    }
    setEditOrganOrigin(true);
    //getDelegacias();
  };

  const handleUpdateOrganOrigin = () => {
    if (!write) {
      return toast.warn('Você não tem permissão para tomar esta ação');
    }
    if (!formState.sourceOrgan) {
      toast.warn('Selecione um Órgão de Origem');
      return;
    }
    dispatch(setOrganOriginReducer(formState.sourceOrgan));
    setCheckReducer(true);
    setEditOrganOrigin(false);
  };

  useEffect(() => {
    if (clearReducer) {
      setFormState(_formState => ({
        sourceOrgan: { ...sourceOrgan },
      }));
      setClearReducer(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearReducer]);

  useEffect(() => {
    if (clearFormOrganOrigin) {
      setFormState(formState => ({
        ...formState,
        sourceOrgan: { ...initialState },
      }));
      setClearFormOrganOrigin(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFormOrganOrigin]);

  useEffect(() => {
    if (sourceOrgan) {
      dispatch(setOrganOriginReducer(sourceOrgan));
    } else {
      dispatch(setOrganOriginReducer(initialState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceOrgan]);

  useEffect(() => {
    getDelegacias();
  }, []);

  return (
    <div className={classes.root}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Box>
          <Typography className={classes.titulo}>Órgão de Origem</Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {!editOrganOrigin ? (
            <Tooltip
              title="Alterar Órgão de Origem"
              arrow
              placement="top"
              style={{ marginLeft: 10 }}
            >
              <IconButton
                className={classes.buttonEditar}
                size="small"
                onClick={handleEditOrganOrigin}
              >
                <CreateIcon style={{ color: '#00b1ac' }} />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Box style={{ marginRight: 10 }}>
                <Tooltip title="Salvar alterações" arrow placement="top" style={{ marginLeft: 10 }}>
                  <IconButton
                    className={classes.buttonEditar}
                    size="small"
                    onClick={handleUpdateOrganOrigin}
                    form="my-form-edit"
                    type="submit"
                  >
                    <SaveIcon style={{ color: '#1976d2' }} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box>
                <Tooltip title="Cancelar" arrow placement="top">
                  <IconButton
                    className={classes.buttonEditar}
                    size="small"
                    onClick={handleCancelEdit}
                  >
                    <ClearIcon style={{ color: '#e1296a' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </Box>
      <form className={classes.form}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.containerLabelInput}>
              {!editOrganOrigin ? (
                <Typography className={classes.inputFormCrime}>
                  {formState.sourceOrgan?.name || 'Não informado'}
                </Typography>
              ) : (
                <Autocomplete
                  onInputChange={(event, newInputValue) => updateValueDelegacias(newInputValue)}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={option => option.name}
                  filterOptions={x => x}
                  value={formState.sourceOrgan}
                  fullWidth
                  noOptionsText="Nada foi encontrado"
                  loadingText="Buscando..."
                  loading={loadingDelegacias}
                  clearOnBlur={false}
                  options={options}
                  onChange={handleChangeSourceOrgan}
                  renderOption={option => <>{option.name}</>}
                  renderInput={params => (
                    <InputConsensus
                      {...params}
                      label="Órgão de Origem"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loadingDelegacias ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  ListboxProps={{
                    onScroll: handleScrollDelegacias,
                  }}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </form>
      {!editOrganOrigin && (
        <Box mt={1}>
          <hr />
        </Box>
      )}
      <Box my={1}>
        {instaurationDelegacy && !sourceOrgan && (
          <>
            <Typography className={classes.inputFormCrime} align="left" variant="h5">
              Delegação de instauração
            </Typography>
            <Typography className={classes.inputFormCrime}>{instaurationDelegacy.name}</Typography>
          </>
        )}
      </Box>
    </div>
  );
};

export default OrganOriginPending;

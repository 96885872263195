import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import { colors } from 'utils/colorsSteps';
import Moment from 'react-moment';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    color: colors.navBar,
  },
  result: {
    color: colors.navBar,
    fontWeight: 'bold',
  },
  resultReport: {
    color: colors.navBar,
    fontWeight: 'bold',
    textAlign: 'justify',
  },
}));

const FactInquiryCompany = props => {
  const { inquiry } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h3" className={classes.title}>
            Fato
          </Typography>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <Typography variant="body1" className={classes.title}>
            Data
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.inquiry.fact?.date ? (
              <Moment format="DD/MM/YYYY" local>
                {inquiry.inquiry.fact?.date}
              </Moment>
            ) : (
              'não informado'
            )}
          </Typography>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <Typography variant="body1" className={classes.title}>
            Hora
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.inquiry.fact?.time || 'não informado'}
          </Typography>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <Typography variant="body1" className={classes.title}>
            Turno
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.inquiry.fact?.shift || 'não informado'}
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Typography variant="body1" className={classes.title}>
            Local
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.inquiry.fact?.locality || 'não informado'}
          </Typography>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <Typography variant="body1" className={classes.title}>
            Comarda
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.inquiry.fact?.judicialDistrict || 'não informado'}
          </Typography>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
          <Typography variant="body1" className={classes.title}>
            UF
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.inquiry.fact?.uf || 'não informado'}
          </Typography>
        </Grid>
        <Grid item xl={3} lg={3} md={3} sm={6} xs={6}>
          <Typography variant="body1" className={classes.title}>
            Flagrante
          </Typography>
          <Typography variant="body1" className={classes.result}>
            {inquiry.inquiry.fact?.isFlagrant === null
              ? 'não informado'
              : inquiry.inquiry.fact?.isFlagrant
              ? 'Sim'
              : 'Não'}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.title}>
            Observação
          </Typography>
          <Typography variant="body1" className={classes.resultReport}>
            {inquiry.inquiry?.status || 'não informado'}
          </Typography>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="body1" className={classes.title}>
            Relato
          </Typography>
          <Typography variant="body1" className={classes.resultReport}>
            {inquiry.inquiry.fact?.report || 'não informado'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default FactInquiryCompany;

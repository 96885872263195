import React from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { currencyFormat } from 'utils/currency';

const useStyles = makeStyles(() => ({
  table: {
    width: '100%',
    minWidth: 700,
  },
  container: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

const TableProjectBalances = props => {
  const { tableProjectBalancesData } = props;

  const classes = useStyles();

  return (
    <>
      <Box style={{ marginBottom: 30 }} width="100%">
        <Box my={1}>
          {tableProjectBalancesData && (
            <TableContainer component={Paper} className={classes.container}>
              <Table className={classes.table} size="small" aria-label="a dense table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={4}>
                      Saldo restante por projeto
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">Projeto</TableCell>
                            <TableCell align="center">Saldo em valor (Pecúnia)</TableCell>
                            <TableCell align="center">Saldo em quantidade (Materiais)</TableCell>
                            <TableCell align="center">Saldo em hora (Serviços)</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tableProjectBalancesData?.map(item => (
                            <TableRow key={item.projectId} hover>
                              <TableCell component="th" scope="row">
                                <Typography variant="body2">{item.projectName}</Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                <Typography variant="body2">
                                  {currencyFormat(item.pecuniaryBalance)}
                                </Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                <Typography variant="body2">{item.materialBalance}</Typography>
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                <Typography variant="body2">{item.serviceBalance}</Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
    </>
  );
};

export default TableProjectBalances;

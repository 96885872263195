export const SET_SELECTED_INDEX_GROUP = 'SET_SELECTED_INDEX_GROUP';
export const SET_GROUP = 'SET_GROUP';

export const setSelectedIndexGroup = index => ({
  type: SET_SELECTED_INDEX_GROUP,
  payload: index,
});

export const setGroupReducer = group => ({
  type: SET_GROUP,
  payload: group,
});

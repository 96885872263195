import React, { useState, useEffect } from 'react';
import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  PecuniaryPenalties,
  CompensationToVictims,
  MaterialsForAssociations,
  CommunityServices,
  GeneralPenalties,
} from './components';
import { TabPanel } from 'components';
import { useCustomerConfig } from 'hooks/useCustomerConfig';
import SocioeducationalLectures from './components/SocioeducationalLectures';
import { PENALTY_KINDS } from 'constants/agreement/penaltyKinds';

const AgreementCrud = props => {
  const {
    isLoading,
    pecuniary,
    setPecuniary,
    description,
    setDescription,
    institutionType,
    showInvestigated,
    inquiry,
    ressarciment,
    setRessarciment,
    materials,
    setMaterials,
    services,
    setServices,
    lectures,
    setLectures,
    institutionSelected,
    investigateds = [],
    generalPenalties,
    setGeneralPenalties,
    setDisabledActionsPenaltyTable = {},
    selectedIndexForEditingPecuniary = null,
    setSelectedIndexForEditingPecuniary = {},
    selectedIndexForEditingRessarciment = null,
    setSelectedIndexForEditingRessarciment = {},
    selectedIndexForEditingMaterial = null,
    setSelectedIndexForEditingMaterial = {},
    selectedIndexForEditingService = null,
    setSelectedIndexForEditingService = {},
    selectedIndexForEditingLectures = null,
    setSelectedIndexForEditingLectures = {},
    selectedIndexForEditingGeneralPenalties = null,
    setSelectedIndexForEditingGeneralPenalties = {},
    editedTab = null,
  } = props;

  let index = 1;

  const [tab, setTab] = useState(0);
  const {
    showGeneralPenalties,
    isMPDFT,
    showSocioEducationalLecturePenalties,
    showMaterialsTab,
  } = useCustomerConfig();

  const handleTabsChange = (_event, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (editedTab !== null) {
      setTab(editedTab);
    }
  }, [editedTab]);

  return (
    <Box width="100%">
      <Paper square>
        <Tabs
          onChange={handleTabsChange}
          indicatorColor="primary"
          scrollButtons="auto"
          value={tab}
          variant="scrollable"
        >
          {
            <Tab
              label={
                isLoading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography variant="body1">Pecúnia </Typography>
                )
              }
            />
          }
          {
            <Tab
              label={
                isLoading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography variant="body1">
                    {isMPDFT
                      ? PENALTY_KINDS.COMPENSATION_TO_VICTIM_MPDFT
                      : PENALTY_KINDS.COMPENSATION_TO_VICTIM}
                  </Typography>
                )
              }
            />
          }
          {showMaterialsTab && (
            <Tab
              label={
                isLoading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography variant="body1">Materiais</Typography>
                )
              }
            />
          )}
          {
            <Tab
              label={
                isLoading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography variant="body1">Serviços</Typography>
                )
              }
            />
          }
          {showSocioEducationalLecturePenalties && (
            <Tab
              label={
                isLoading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography variant="body1">Palestras Socioeducativas</Typography>
                )
              }
            />
          )}
          {showGeneralPenalties && (
            <Tab
              label={
                isLoading ? (
                  <Skeleton animation="wave" width="100%" />
                ) : (
                  <Typography variant="body1">
                    Outras {isMPDFT ? 'Medidas' : 'Penalidades'}
                  </Typography>
                )
              }
            />
          )}
        </Tabs>
      </Paper>

      <TabPanel value={tab} index={0} style={{ padding: inquiry ? 10 : 0 }}>
        <PecuniaryPenalties
          setDisabledActionsPenaltyTable={setDisabledActionsPenaltyTable}
          setSelectedIndex={setSelectedIndexForEditingPecuniary}
          selectedIndex={selectedIndexForEditingPecuniary}
          pecuniary={pecuniary}
          setPecuniary={setPecuniary}
          description={description}
          setDescription={setDescription}
          institutionType={institutionType}
          institutionSelected={institutionSelected}
        />
      </TabPanel>
      <TabPanel value={tab} index={index++} style={{ padding: inquiry ? 10 : 0 }}>
        {showInvestigated && (
          <CompensationToVictims
            setDisabledActionsPenaltyTable={setDisabledActionsPenaltyTable}
            setSelectedIndex={setSelectedIndexForEditingRessarciment}
            selectedIndex={selectedIndexForEditingRessarciment}
            victims={inquiry?.inquiry?.victims ?? investigateds ?? []}
            ressarciment={ressarciment}
            setRessarciment={setRessarciment}
            institutionType={institutionType}
          />
        )}
      </TabPanel>
      {showMaterialsTab && (
        <TabPanel value={tab} index={index++} style={{ padding: inquiry ? 10 : 0 }}>
          <MaterialsForAssociations
            setDisabledActionsPenaltyTable={setDisabledActionsPenaltyTable}
            setSelectedIndex={setSelectedIndexForEditingMaterial}
            selectedIndex={selectedIndexForEditingMaterial}
            materials={materials}
            setMaterials={setMaterials}
            description={description}
            setDescription={setDescription}
            institutionType={institutionType}
            institutionSelected={institutionSelected}
          />
        </TabPanel>
      )}
      <TabPanel value={tab} index={index++} style={{ padding: inquiry ? 10 : 0 }}>
        <CommunityServices
          setDisabledActionsPenaltyTable={setDisabledActionsPenaltyTable}
          setSelectedIndex={setSelectedIndexForEditingService}
          selectedIndex={selectedIndexForEditingService}
          services={services}
          setServices={setServices}
          description={description}
          setDescription={setDescription}
          institutionType={institutionType}
          institutionSelected={institutionSelected}
        />
      </TabPanel>
      {showSocioEducationalLecturePenalties && (
        <TabPanel value={tab} index={index++} style={{ padding: inquiry ? 10 : 0 }}>
          <SocioeducationalLectures
            setDisabledActionsPenaltyTable={setDisabledActionsPenaltyTable}
            setSelectedIndex={setSelectedIndexForEditingLectures}
            selectedIndex={selectedIndexForEditingLectures}
            lectures={lectures}
            setLectures={setLectures}
            institutionType={institutionType}
            institutionSelected={institutionSelected}
          />
        </TabPanel>
      )}
      {showGeneralPenalties && (
        <TabPanel value={tab} index={index++} style={{ padding: inquiry ? 10 : 0 }}>
          <GeneralPenalties
            setDisabledActionsPenaltyTable={setDisabledActionsPenaltyTable}
            setSelectedIndex={setSelectedIndexForEditingGeneralPenalties}
            selectedIndex={selectedIndexForEditingGeneralPenalties}
            generalPenalties={generalPenalties}
            setGeneralPenalties={setGeneralPenalties}
            institutionType={institutionType}
            institutionSelected={institutionSelected}
          />
        </TabPanel>
      )}
    </Box>
  );
};

export default AgreementCrud;

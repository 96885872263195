import http from '../httpService';

const ProjectService = {
  getProjectsService: params => http.get('project', { params }),

  deleteProjectService: projectId => http.delete(`project/${projectId}`),

  registerProjectService: params => http.post('project', params),

  updateProjectService: (projectId, params) => http.patch(`project/${projectId}`, params),

  getProjectThemesService: params => http.get('project/projectTheme', { params }),

  getDestinedAndFulfilledValues: projectId =>
    http.get(`project/destinedAndFulfilledValues/${projectId}`),

  getCancelJustificationsService: params =>
    http.get('project/projectCancellationJustification', { params }),

  updateProjectForReactivateService: (projectId, params) =>
    http.patch(`project/${projectId}/reactivate`, params),

  updateProjectForConcludeService: (projectId, params) =>
    http.patch(`project/${projectId}/conclude`, params),

  updateProjectForCancelService: (projectId, params) =>
    http.patch(`project/${projectId}/cancel`, params),

  getProjectService: projectId => http.get(`project/${projectId}`),

  checkProjectBalances: params => http.post('project/balances', params),

  getProjectPenalties: projectId => http.get(`project/penalties-list/${projectId}`),
};

export default ProjectService;

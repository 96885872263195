import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ButtonConsensus } from 'components';
import { ModalJustification, ModalSelectHearing } from './components';
import '../../font.css';
import { colors } from 'utils/colorsSteps';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useCustomerConfig } from 'hooks/useCustomerConfig';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const CancelHearing = props => {
  const { hearings, refreshPage, inquiryProsecutor, inquiryProsecutorOffice } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [openModalHearing, setOpenModalHearing] = useState(false);
  const [selectedHearing, setselectedHearing] = useState(null);
  const { enableHearingForJuvenileOffenders } = useCustomerConfig();

  const { inquiriesAudience } = useSelector(state => state.logedUser.permissions);

  const handleOpenModal = () => {
    if (hearings.filter(hearing => hearing.status === 'CONFIRMED').length === 1) {
      setselectedHearing(hearings.find(hearing => hearing.status === 'CONFIRMED'));
      setOpen(true);
    } else {
      setOpenModalHearing(true);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    refreshPage();
  };

  const handleCloseModalHearing = () => {
    setOpenModalHearing(false);
  };

  const handleOpenModalJustification = () => {
    setOpen(true);
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <div className={classes.root}>
      <ButtonConsensus
        title={`Audiência${enableHearingForJuvenileOffenders ? '/Oitiva' : ''} não realizada`}
        color="#ffffff"
        backgroundColor={colors.navBar}
        hovercolor={colors.navBar}
        onClick={() => (!inquiriesAudience.write ? handleOpenSnackbar() : handleOpenModal())}
      />
      {openModalHearing && (
        <ModalSelectHearing
          open={openModalHearing}
          handleClose={handleCloseModalHearing}
          hearings={hearings.filter(hearing => hearing.status === 'CONFIRMED')}
          setselectedHearing={setselectedHearing}
          onSelectHearing={handleOpenModalJustification}
        />
      )}
      {open && (
        <ModalJustification
          open={open}
          handleClose={handleCloseModal}
          hearing={selectedHearing}
          inquiryProsecutor={inquiryProsecutor}
          inquiryProsecutorOffice={inquiryProsecutorOffice}
        />
      )}
    </div>
  );
};

export default CancelHearing;

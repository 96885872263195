import uuid from 'uuid';

export const STEPS_INQUIRY = {
  CADASTRO: 'CADASTRO',
  TRIAGEM: 'TRIAGEM_NAO_PERSECUCAO',
  PESQUISA: 'PESQUISA_DISTRIBUICAO',
  ANALISE: 'ANALISE',
  TRAMITACAO_CONTROLE: 'TRAMITACAO_CONTROLE',
  INTIMACAO: 'INTIMACAO',
  AUDIENCIA: 'AUDIENCIA',
  CUMPRIMENTO_ACORDO: 'CUMPRIMENTO_ACORDO',
  CONCLUIDO: 'CONCLUIDO',
  PERSECUCAO: 'PERSECUCAO',
  DENUNCIA: 'DENUNCIA',
  ARQUIVAR: 'ARQUIVAR',
  ENCAMINHAR_DILIGENCIAS: 'ENCAMINHAR_DILIGENCIAS',
  DECLINAR: 'DECLINAR',
  DEVOLVIDO: 'DEVOLVIDO',
  NAO_IDENTIFICADO: 'NAO_IDENTIFICADO',
  REQUISITORIO: 'REQUISITORIO',
  REMETIDO_AO_JUDICIARIO: 'REMETIDO_AO_JUDICIARIO',
  REMETIDO_AO_SISTEMA_INTERNO: 'REMETIDO_AO_SISTEMA_INTERNO',
  TERMO_DE_ENCAMINHAMENTO: 'TERMO_DE_ENCAMINHAMENTO',
  MIGRACAO: 'MIGRACAO',
};

export const REOPENABLE_STEPS = [
  STEPS_INQUIRY.ARQUIVAR,
  STEPS_INQUIRY.DENUNCIA,
  STEPS_INQUIRY.DECLINAR,
  STEPS_INQUIRY.DEVOLVIDO,
  STEPS_INQUIRY.REMETIDO_AO_JUDICIARIO,
  STEPS_INQUIRY.REMETIDO_AO_SISTEMA_INTERNO,
];

export const listSteps = customerConfig => {
  let allSteps = [
    {
      id: uuid(),
      name: 'Triagem',
      value: STEPS_INQUIRY.TRIAGEM,
    },
    {
      id: uuid(),
      name: 'Pesquisa e Distribuição',
      value: STEPS_INQUIRY.PESQUISA,
    },
    {
      id: uuid(),
      name: 'Análise',
      value: STEPS_INQUIRY.ANALISE,
    },
    {
      id: uuid(),
      name: 'Tramitação e Controle',
      value: STEPS_INQUIRY.TRAMITACAO_CONTROLE,
    },
    {
      id: uuid(),
      name: 'Intimação',
      value: STEPS_INQUIRY.INTIMACAO,
    },
    {
      id: uuid(),
      name: 'Arquivamento',
      value: STEPS_INQUIRY.ARQUIVAR,
    },
    {
      id: uuid(),
      name: 'Persecução',
      value: STEPS_INQUIRY.PERSECUCAO,
    },
    {
      id: uuid(),
      name: 'Despacho',
      value: STEPS_INQUIRY.ENCAMINHAR_DILIGENCIAS,
    },
    {
      id: uuid(),
      name: 'Declinação',
      value: STEPS_INQUIRY.DECLINAR,
    },
    {
      id: uuid(),
      name: 'Denúncia',
      value: STEPS_INQUIRY.DENUNCIA,
    },
    {
      id: uuid(),
      name: `Audiência${customerConfig.enableHearingForJuvenileOffenders ? '/Oitiva' : ''}`,
      value: STEPS_INQUIRY.AUDIENCIA,
    },
    {
      id: uuid(),
      name: 'Devolvido',
      value: STEPS_INQUIRY.DEVOLVIDO,
    },
    {
      id: uuid(),
      name: 'Requisitório',
      value: STEPS_INQUIRY.REQUISITORIO,
    },
    {
      id: uuid(),
      name: 'Remetido ao Judiciário',
      value: STEPS_INQUIRY.REMETIDO_AO_JUDICIARIO,
    },
    {
      id: uuid(),
      name: 'Remetido ao Sistema Interno',
      value: STEPS_INQUIRY.REMETIDO_AO_SISTEMA_INTERNO,
    },
    {
      id: uuid(),
      name: 'Termo de Encaminhamento',
      value: STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO,
    },
    {
      id: uuid(),
      name: 'Cumprimento de Acordo',
      value: STEPS_INQUIRY.CUMPRIMENTO_ACORDO,
    },
    {
      id: uuid(),
      name: 'Migração',
      value: STEPS_INQUIRY.MIGRACAO,
    },
    /*{
          id: uuid(),
          name: 'Não Identificado',
          value: 'NAO_IDENTIFICADO'
      }*/
  ];

  if (!customerConfig?.displayTriageStep) {
    allSteps = allSteps.filter(step => step.value !== STEPS_INQUIRY.TRIAGEM);
  }
  if (!customerConfig?.displayDistributionStep) {
    allSteps = allSteps.filter(step => step.value !== STEPS_INQUIRY.PESQUISA);
  }

  return allSteps;
};

import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CardPendingInquiry from '../CardPendingInquiry';
import { toast } from 'react-toastify';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
  },
  gridList: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
  },
  card: {
    height: '100%',
  },
}));

const ViewCardPendingInquiries = props => {
  const {
    inquiries,
    isSearch,
    isReturned,
    isBatchReturn,
    total,
    inquiryList,
    setInquiryList,
    page,
    size,
  } = props;

  const classes = useStyles();
  const [batchReturnCheckboxValue, setBatchReturnCheckboxValue] = useState([]);

  const permissionsReducer = useSelector(state => state.logedUser.permissions);

  useEffect(() => {
    const batchReturnCheck = [];
    for (let i = 0; i <= total; i++) {
      batchReturnCheck.push(false);
    }
    setBatchReturnCheckboxValue(batchReturnCheck);
  }, [total]);

  const handleOpenSnackbar = step => {
    return toast.warn(`Você não tem permissão para acessar inquéritos em ${step}.`);
  };

  const position = index => index + (size || 10) * ((page || 1) - 1);

  const handleChangeBatchReturnCheckbox = (event, index) => {
    let arrayOfData = batchReturnCheckboxValue;
    arrayOfData[position(index)] = !batchReturnCheckboxValue[position(index)];
    setBatchReturnCheckboxValue(arrayOfData);
    const oldList = inquiryList;
    if (!oldList.map(e => e.id).includes(inquiries[index].id)) {
      oldList.push(inquiries[index]);
    } else {
      const indexToDelete = oldList.findIndex(e => e?.id === inquiries[index]?.id);
      oldList.splice(indexToDelete, 1);
    }
    setInquiryList(oldList);
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.gridList} container spacing={1}>
        {inquiries.map((inquiry, index) => (
          <Grid xs={12} sm={6} md={4} lg={3} xl={3} item key={inquiry.id + '-' + index}>
            <RouterLink
              onClick={() =>
                !permissionsReducer.receivedDocuments.read
                  ? handleOpenSnackbar('Documentos Recebidos')
                  : null
              }
              to={
                isReturned
                  ? `detalhe/outras-fases/${inquiry.id}/${STEPS_INQUIRY.DEVOLVIDO}`
                  : permissionsReducer.receivedDocuments.read
                  ? `/inqueritos-pendentes/${inquiry.id}`
                  : '#'
              }
            >
              <CardPendingInquiry
                className={classes.card}
                backgroundColor={
                  inquiry?.returnation?.id ||
                  isReturned ||
                  (inquiry.returnations && inquiry.returnations.length > 0)
                    ? '#90948D'
                    : '#ff0000'
                }
                inquiry={inquiry}
                isSearch={isSearch}
                isBatchReturn={isBatchReturn}
                batchReturnCheckboxValue={
                  batchReturnCheckboxValue ? batchReturnCheckboxValue[position(index)] : undefined
                }
                handleChangeBatchReturnCheckbox={handleChangeBatchReturnCheckbox}
                checkboxNumber={index}
              />
            </RouterLink>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default ViewCardPendingInquiries;

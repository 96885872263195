import React from 'react';
import InputMask from 'react-input-mask';
import InputConsensus from 'components/InputConsensus';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import {
  IconButton,
  Tooltip,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  containerChip: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '5px',
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
}));

const AlternativePhones = props => {
  const {
    phones,
    showInputPhones,
    editInvestigatedInArray,
    handleChangeShowInputPhonesToFalse,
    handleChangeShowInputPhonesToTrue,
    handleRemovePhone,
    handleAddPhone,
    handleChange,
    formState,
  } = props;

  const classes = useStyles();

  return (
    <Box>
      {showInputPhones ? (
        <>
          <Box margin={1} display="flex" justifyContent="flex-end" alignItems="center">
            <InputMask
              onChange={handleChange}
              value={formState.values.phones || ''}
              mask="(99)99999-9999"
              maskChar={null}
            >
              {inputProps => (
                <InputConsensus
                  {...inputProps}
                  label="Telefone Alternativo"
                  name="phones"
                  type="phones"
                  variant="standard"
                  autoComplete="no"
                  fullWidth
                />
              )}
            </InputMask>
            <IconButton color="primary" aria-label="add phone" onClick={() => handleAddPhone()}>
              <AddIcon style={{ cursor: 'pointer' }} />
            </IconButton>
          </Box>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Telefones Alternativos</TableCell>
                  <TableCell align="center" width={150}>
                    <Box display="flex" position="relative" justifyContent="center">
                      Ações
                      {editInvestigatedInArray && (
                        <Box position="absolute" right={0} top={-3}>
                          <IconButton size="small" onClick={handleChangeShowInputPhonesToFalse}>
                            <IndeterminateCheckBoxIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {phones.map((item, index) => (
                  <TableRow key={index} hover>
                    <TableCell component="th" scope="row" align="center">
                      {item}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleRemovePhone(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography align="left">Telefones Alternativos</Typography>
            <Tooltip title="Alterar Telefones Alternativos" arrow placement="right">
              <IconButton color="primary" size="small" onClick={handleChangeShowInputPhonesToTrue}>
                <CreateIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {editInvestigatedInArray &&
            !showInputPhones &&
            (phones.length > 0 ? (
              <Box className={classes.containerChip}>
                {phones.map((phone, i) => (
                  <Chip label={phone} key={i} color="primary" size="small" variant="outlined" />
                ))}
              </Box>
            ) : (
              <Typography align="left" variant="h6" className={classes.form}>
                {'Sem telefones alternativos cadastrados'}
              </Typography>
            ))}
        </>
      )}
    </Box>
  );
};

export default AlternativePhones;

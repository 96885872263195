import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Box, Paper } from '@material-ui/core';
import { changeNameStep } from 'utils/changeNameStep';
import { formatDateAndHoursToBrazilian } from 'utils/date-time.utils';
import { useSelector } from 'react-redux';
import { STEPS_INQUIRY } from 'constants/inquirys/stepsInquiry';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  textsimple: {
    wordWrap: 'break-word',
  },
  divDate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100px',
  },
}));

const ButtonTimeline = props => {
  const { transition, events } = props;

  const customerConfig = useSelector(state => state.customerConfig);

  const classes = useStyles();

  return (
    <Paper elevation={2} className={classes.root}>
      <Box>
        <Box className={classes.textsimple} style={{ display: 'flex' }}>
          <Typography variant="h6" className={classes.textsimple}>
            {changeNameStep(transition.toStep, customerConfig)}
          </Typography>
        </Box>
        <Typography variant="h6" className={classes.textsimple}>
          {transition.justification === 'proxima_fase' ||
          [STEPS_INQUIRY.TERMO_DE_ENCAMINHAMENTO, STEPS_INQUIRY.CUMPRIMENTO_ACORDO].includes(
            transition?.toStep,
          )
            ? ''
            : transition.justification}
        </Typography>
        {events &&
          events.length > 0 &&
          events.map(item => (
            <Typography key={item.description} variant="h6" className={classes.textsimple}>
              {formatDateAndHoursToBrazilian(item.date)} - {item.description}{' '}
              {item.executedBy && `- Realizado por: ${item.executedBy}`}
            </Typography>
          ))}
      </Box>
      <Box className={classes.divDate}>
        <Typography variant="h6" className={classes.textsimple}>
          {formatDateAndHoursToBrazilian(transition.createdAt)}
        </Typography>
      </Box>
    </Paper>
  );
};

export default ButtonTimeline;

import React from 'react';
import { Dialog, DialogActions, DialogContent, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { HeaderModal, ButtonConsensus } from 'components';
import { colors } from 'utils/colorsSteps';

const ModalJustification = props => {
  const { handleClose, open, children } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        maxWidth="xs"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <HeaderModal onClose={handleClose}>Justificativa</HeaderModal>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <ButtonConsensus
            title="Fechar"
            backgroundColor="#fff"
            hovercolor={colors.greySecundary}
            color={colors.greyShadow}
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalJustification;

import React, { useState } from 'react';
import { ButtonConsensus } from 'components';
import '../../font.css';
import { ModalRedistribution } from './components';
import { toast } from 'react-toastify';
import { colors } from 'utils/colorsSteps';

const ButtonRedistribution = props => {
  const {
    write,
    inquiry,
    title,
    disabled,
    backgroundColor = colors.navBar,
    hovercolor = colors.navBar,
    specialMode = false,
    color = '#ffffff',
    displayDistributionType = true,
    displayTargetOfficeGroup = true,
    hasSameBranchDeclineTask = false,
    hasTaskActiveAwaitingResponse = false,
  } = props;
  const [open, setOpen] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    if (hasTaskActiveAwaitingResponse) {
      return toast.warn('É necessário concluir todas as tarefas antes de realizar a ação');
    }
    if (write) {
      setOpen(true);
    } else {
      handleOpenSnackbar();
    }
  };

  const handleOpenSnackbar = (message = 'Você não tem permissão para tomar esta ação.') => {
    return toast.warn(message);
  };

  return (
    <>
      <ButtonConsensus
        backgroundColor={backgroundColor}
        hovercolor={hovercolor}
        color={color}
        title={title}
        disabled={disabled}
        onClick={handleOpenModal}
      />
      {open && (
        <ModalRedistribution
          specialMode={specialMode}
          displayTargetOfficeGroup={displayTargetOfficeGroup}
          displayDistributionType={displayDistributionType}
          open={open}
          onClose={handleCloseModal}
          inquiry={inquiry}
          hasSameBranchDeclineTask={hasSameBranchDeclineTask}
        />
      )}
    </>
  );
};

export default ButtonRedistribution;

import React from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import HeaderModal from 'components/HeaderModal';
import { redirectToInquiryIdStep } from 'utils/genericFunctions/redirectToInquiryIdStep';

const ModalApplyToAllError = props => {
  const { open, onClose, canBeAppliedToAll } = props;
  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <HeaderModal onClose={onClose}>Movimentação</HeaderModal>
      <DialogContent>
        <Typography variant="h5">{canBeAppliedToAll.reason}</Typography>

        <Box my={2}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Investigado</TableCell>
                  <TableCell>Fase</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {canBeAppliedToAll.reasonData.map(row => (
                  <Tooltip key={row.id} title="Ir para tela de detalhe" placement="top" arrow>
                    <TableRow
                      onClick={() => redirectToInquiryIdStep({ id: row.id, step: row.step })}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.step}</TableCell>
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalApplyToAllError;
